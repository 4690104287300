import React from 'react'
import { TbArrowBarToRight, TbChevronRight } from 'react-icons/tb'

function TransferTypes({ handleTransferType }) {
    return (
        <div>
            <div
                className="transfer-transfers"
                onClick={() =>
                    handleTransferType("Internal Transfer", 3)
                }
            >
                <div className="flex gap-20 items-center">
                    <TbArrowBarToRight
                        style={{
                            background: "#ededed",
                            padding: "8px",
                            borderRadius: "50%",
                        }}
                        color="#777777"
                        size={39}
                    />
                    <p>Internal Transfer</p>
                </div>

                <TbChevronRight color="#757575" />
            </div>
            <div
                className="transfer-transfers"
                onClick={() => handleTransferType("Lite Transfer", 2)}
            >
                <div className="flex gap-20 items-center">
                    <TbArrowBarToRight
                        style={{
                            background: "#ededed",
                            padding: "8px",
                            borderRadius: "50%",
                        }}
                        color="#777777"
                        size={39}
                    />
                    <p>Lite Transfer</p>
                </div>

                <TbChevronRight color="#757575" />
            </div>
            {/* <div
                className="transfer-transfers"
                onClick={() => handleTransferType("Transfer", 1)}
            >
                <div className="flex gap-20 items-center">
                    <TbArrowBarToRight
                        style={{
                            background: "#ededed",
                            padding: "8px",
                            borderRadius: "50%",
                        }}
                        color="#777777"
                        size={39}
                    />
                    <p>Transfer</p>
                </div>

                <TbChevronRight color="#757575" />
            </div> */}
        </div>
    )
}

export default TransferTypes