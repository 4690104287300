import React from "react";

function ReusableStatusComponent({status, smallFont}) {
    // status can be 1 ,2 ,3, 4, 5
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",

                gap: "0.3em",
            }}
        >
      <span
          style={{
              backgroundColor:
                  status === 1
                      ? "orange"
                      : status === 2
                          ? "lightgreen"
                          : status === 3
                              ? "lightseagreen"
                              : status === 4
                                  ? "darkgreen"
                                  : status === 5
                                      ? "red"
                                      : "",
              width: smallFont ? "0.7rem" : "1em",
              height: smallFont ? "0.7rem" : "1em",
              borderRadius: "50%",
          }}
      ></span>
            <span style={{fontSize: smallFont ? "13px" : "inherit"}}>
        {
            status === 1
                ? "Pending"
                : status === 2
                    ? "Approved" :
                    status === 3
                        ? "In Progress"
                        : status === 4
                            ? "Completed"
                            : status === 5
                                ? "Declined"
                                : ""}
      </span>
        </div>
    );
}

export default ReusableStatusComponent;

