export const checkAccountingAmount = (data) => {

    if (data === undefined) {
        return;
    }
    const {type, side} = data;

    switch (side?.toLowerCase()) {
        case 'debit':
        case 'buy':
        case 'withdrawal':
        case 'send': {
            switch (type) {
                case 'exchange':
                    return '+';
            }
            return "-"
        }
        case 'credit':
        case "sell":
        case 'deposit':
        case 'receive':
            switch (type) {
                case 'exchange':
                    return '-';
            }
            return "+"
        default: {
            return '+';
        }
    }
}

export const checkTransferAmount = (data, partner) => {
    const {transfer_type, transaction_type, source_partner: {_id: sId}} = data;
    const {partnerId, isAdmin, isMainOffice} = partner;
    const {_id: pId} = partnerId || {};

    switch (transaction_type?.toLowerCase()) {
        case 'debit':
        case 'buy':
        case 'withdrawal':
        case 'send': {
            if (isAdmin || isMainOffice) {
                return "";
            }
            return (sId !== pId) ? '+' : '-'
        }
        case 'credit':
        case "sell":
        case 'deposit':
        case 'receive':
            if (isAdmin || isMainOffice) {
                return "";
            }
            return (sId !== pId) ? '-' : '+'
        default: {
            return '+';
        }
    }
}


export const checkAccountingBalance = (data) => {
    const {type, side} = data;

    switch (side.toLowerCase()) {
        case 'debit':
        case 'buy':
        case 'withdrawal':
        case 'send': {
            switch (type) {
                case 'exchange':
                    return '↑';
            }
            return "↓"
        }
        case 'credit':
        case "sell":
        case 'deposit':
        case 'receive':
            switch (type) {
                case 'exchange':
                    return '↓';
            }
            return "↑"
        default: {
            return '↑';
        }
    }
}
