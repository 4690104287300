import React from 'react'
import "./header.css"

import tresorLogo from "../../assets/tresorX.png";
import { RxHamburgerMenu } from "react-icons/rx"
import { TbListDetails } from "react-icons/tb"
import { GrClose } from "react-icons/gr"

function Header({ setTabsToggle, tabsToggle }) {
    return (
        <div className="header-menu">
            <div style={{ minWidth: "27px" }} onClick={() => setTabsToggle({ right: false, left: !tabsToggle.left })} className="cursor-pointer">
                {tabsToggle.left ?
                    <GrClose size={21} /> :
                    <RxHamburgerMenu size={27} />
                }
            </div>

            <div>
                <img style={{ transform: "translateY(3px)" }} className="header-logo" src={tresorLogo} alt="" />
            </div>

            <div onClick={() => setTabsToggle({ left: false, right: !tabsToggle.right })} className="cursor-pointer" style={{ transform: "rotate(180deg) translateY(2px)" }}>
                {tabsToggle.right ?
                    <GrClose size={21} /> :
                    <TbListDetails size={27} />
                }
            </div>
        </div>
    )
}

export default Header