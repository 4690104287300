import {Paper} from "@material-ui/core";
import React from "react";
import Moment from "moment";

function Details({data}) {
    return (
        <Paper
            variant="outlined"
            style={{maxWidth: "100%", marginTop: "1em", paddingBottom: "0.5em"}}
        >
            <center>
                <div
                    style={{
                        borderBottom: "1px solid lightgrey",
                        maxWidth: "95%",
                        marginBottom: "2em",
                        paddingTop: "0.5em",
                        paddingBottom: "0.5em",
                    }}
                >
                    <span style={{fontSize: "16px", fontWeight: "600"}}>Details</span>
                </div>
            </center>

            <div
                style={{
                    display: "grid",
                    placeItems: "center",
                    margin: "0 2.5%",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "start",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            maxWidth: "20%",
                            wordBreak: "break-all",
                        }}
                    >
            <span
                style={{
                    fontWeight: "400",
                    color: "#666666",
                    fontSize: "14px",
                    textAlign: "center",
                    lineHeight: "1.5",
                }}
            >
              ID
            </span>
                        <span
                            style={{
                                overflow: "hidden",
                                fontWeight: "600",
                                textOverflow: "ellipsis",
                                color: "rgba(0, 0, 0, 0.87)",
                                fontSize: "14px",
                                lineHeight: "1.5",
                                textAlign: "center",
                            }}
                        >
              {data.order_number}
            </span>
                    </div>
                    <div style={{display: "flex", flexDirection: "column"}}>
            <span
                style={{
                    fontWeight: "400",
                    color: "#666666",
                    fontSize: "14px",
                    textAlign: "center",
                    lineHeight: "1.5",
                }}
            >
              User
            </span>
                        <span
                            style={{
                                overflow: "hidden",
                                fontWeight: "600",
                                textOverflow: "ellipsis",
                                color: "rgba(0, 0, 0, 0.87)",
                                fontSize: "14px",
                                lineHeight: "1.5",
                                textAlign: "center",
                            }}
                        >
              {data.user_id?.fullName || data.user_confirmation_id?.fullName}
            </span>
                    </div>
                    <div style={{display: "flex", flexDirection: "column"}}>
            <span
                style={{
                    fontWeight: "400",
                    color: "#666666",
                    fontSize: "14px",
                    textAlign: "center",
                    lineHeight: "1.5",
                }}
            >
              Partner
            </span>
                        <span
                            style={{
                                overflow: "hidden",
                                fontWeight: "600",
                                textOverflow: "ellipsis",
                                color: "rgba(0, 0, 0, 0.87)",
                                fontSize: "14px",
                                lineHeight: "1.5",
                                textAlign: "center",
                            }}
                        >
              {data.partner_id?.partnerName}
            </span>
                    </div>
                    <div style={{display: "flex", placeItems: "center"}}>
                        <div style={{display: "flex", flexDirection: "column"}}>
              <span
                  style={{
                      fontWeight: "400",
                      color: "#666666",
                      fontSize: "14px",
                      textAlign: "center",
                      lineHeight: "1.5",
                  }}
              >
                Client
              </span>
                            <span
                                style={{
                                    overflow: "hidden",
                                    fontWeight: "600",
                                    textOverflow: "ellipsis",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    fontSize: "14px",
                                    lineHeight: "1.5",
                                    textAlign: "center",
                                }}
                            >
                {data.client_id?.firstName} {data.client_id?.lastName}
              </span>
                        </div>
                    </div>
                </div>
                <div
                    className="w-full flex justify-center items-start"
                    style={{marginTop: "15px", gap: "50px"}}
                >
                    <div className="grid place-items">
                        <div style={{display: "flex", flexDirection: "column"}}>
              <span
                  style={{
                      fontWeight: "400",
                      color: "#666666",
                      fontSize: "14px",
                      textAlign: "center",
                      lineHeight: "1.5",
                  }}
              >
                Date
              </span>
                        </div>
                        <span
                            style={{
                                overflow: "hidden",
                                fontWeight: "600",
                                textOverflow: "ellipsis",
                                color: "rgba(0, 0, 0, 0.87)",
                                fontSize: "14px",
                                lineHeight: "1.5",
                                textAlign: "center",
                            }}
                        >
             {Moment(data?.createdAt).format(
                 "DD.MM.YYYY HH:mm"
             )}
            </span>
                    </div>
                    {(data.status !== 1 && data.user_confirmation_id) && (
                        <>
                            <div className="grid place-items">
                                <div style={{display: "flex", flexDirection: "column"}}>
                  <span
                      style={{
                          fontWeight: "400",
                          color: "#666666",
                          fontSize: "14px",
                          textAlign: "center",
                          lineHeight: "1.5",
                      }}
                  >
                    {data.status === 5 ? "Declined" : "Approved"} By
                  </span>
                                </div>
                                <span
                                    style={{
                                        overflow: "hidden",
                                        fontWeight: "600",
                                        textOverflow: "ellipsis",
                                        color: "rgba(0, 0, 0, 0.87)",
                                        fontSize: "14px",
                                        lineHeight: "1.5",
                                        textAlign: "center",
                                    }}
                                >
                  {data.user_confirmation_id?.fullName}
                </span>
                            </div>
                            <div className="grid place-items">
                                <div style={{display: "flex", flexDirection: "column"}}>
                  <span
                      style={{
                          fontWeight: "400",
                          color: "#666666",
                          fontSize: "14px",
                          textAlign: "center",
                          lineHeight: "1.5",
                      }}
                  >
                    {data.status === 5 ? "Declined" : "Approved"} On
                  </span>
                                </div>
                                <span
                                    style={{
                                        overflow: "hidden",
                                        fontWeight: "600",
                                        textOverflow: "ellipsis",
                                        color: "rgba(0, 0, 0, 0.87)",
                                        fontSize: "14px",
                                        lineHeight: "1.5",
                                        textAlign: "center",
                                    }}
                                >
                  {Moment(data?.user_confirmation_date).format(
                      "DD.MM.YYYY HH:mm"
                  )}
                </span>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Paper>
    );
}

export default Details;

