import React from "react";
import "./rules.css";
import RulesTable from "./RulesTable/RulesTable";

const Rules = () => {
  return (
    <div className="users">
        <RulesTable/>
    </div>
  );
};

export default Rules;

