import React, {useState} from "react";
import "./accounting.css";
import Details from "./components/Details/Details";
import Transactions from "./components/Transactions/Transactions";
import Reports from "./components/Reports/Reports";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import "react-tabs/style/react-tabs.css";

const Accounting = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedPartner, setSelectedPartner] = useState("");

  return (
    <div className="users">
      <Tabs
          selectedIndex={tabIndex}
          onSelect={(index) => setTabIndex(index)}
          style={{
            maxWidth: "100%",
            width: "100%",
          }}
      >
        <TabList
            style={{
            padding: "7px 20px 0 20px",
            background: "white",
            margin: "0",
          }}
        >
          <Tab>Balance</Tab>
          <Tab>History</Tab>
          {/*<Tab>Reports</Tab>*/}
        </TabList>

        <TabPanel>
          <div className="users" style={{ padding: "0" }}>
            <div
              className="usersUserListContainer"
              style={{ paddingBottom: "" }}
            >
              <Details
                setTabIndex={setTabIndex}
                setSelectedPartner={setSelectedPartner}
                selectedPartner={selectedPartner}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="users" style={{ padding: "0" }}>
            <div
              className="usersUserListContainer"
              style={{ paddingBottom: "" }}
            >
              <Transactions selectedPartner={selectedPartner} />
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="users" style={{ padding: "0" }}>
            <div
              className="usersUserListContainer"
              style={{ paddingBottom: "" }}
            >
              <Reports />
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Accounting;

