import React, {useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {publicRequest} from "../../utils/requestMethods";
import iconGenerator from "../../utils/iconGenerator";
import {FaCheck, FaTimes} from "react-icons/fa";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import Swal from "sweetalert2";
import MainTable from "../ReusableComponents/Tables/MRT_Main";

const CoinsTable = () => {
  const user = useSelector((state) => state.user.currentUser);
  const menu = useSelector((state) => state.user.menu);

  const [selectedRow, setSelectedRow] = useState(null);
  const [coins, setCoins] = useState([]);
  const [loading, setLoading] = useState(-1);
  const [tableLoading, setTableLoading] = useState(false);
  const [requestURL, setRequestURL] = useState("v2/coins");
  const [action, setAction] = useState({ type: "", data: null, id: 0 });

  const logTableRef = useRef();

  const mainColumns = useMemo(
    () => [
      {
          accessorKey: "symbol",
          header: "Symbol",
          size: 100,
          accessorFn: (e) => (
              <div className="flex items-center gap-5">
                  <img
                      src={iconGenerator(128, "icon", e.symbol)}
                      alt=""
                      className="dashboardChartHeaderIcon"
                  />
                  {e.symbol}
              </div>
          ),
      },
        {
            accessorKey: "name",
            header: "Name",
            enableGrouping: false,
            size: 150,
        },
      {
        accessorKey: "details.isBinanceAvailable",
        header: "Binance Available",
        size: 80,
        accessorFn: (e) => (
          <div style={{ marginLeft: "20%" }}>
            {e.details.isBinanceAvailable === true ? (
              <FaCheck color="green" />
            ) : (
              <FaTimes color="red" />
            )}
          </div>
        ),
      },
      {
        accessorKey: "details.isDexAvailable",
        header: "Dex Available",
        size: 80,
        accessorFn: (e) => (
          <div style={{ marginLeft: "20%" }}>
            {e.details.isDexAvailable === true ? (
              <FaCheck color="green" />
            ) : (
              <FaTimes color="red" />
            )}
          </div>
        ),
      },
    ],
    []
  );

  const handleSwitch = async (data) => {
    try {
      setLoading(data._id);

      const response2 = await publicRequest.patch(`coins/${data._id}`, {
        is_enabled: !data.is_enabled,
      });
      if (response2.status === 200) {
        setAction({
          type: "edited",
          id: data._id,
          data: response2.data,
        });
        setLoading(-1);
      } else {
        setLoading(-1);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = (propsData) => {
    const deleteFunc = async () => {
      const response = await publicRequest.delete(`coins/${propsData._id}`);
      setAction({
        type: "deleted",
        id: propsData._id,
        data: null,
      });
    };

    Swal.fire({
      title: `Do you want to delete ${propsData.symbol}?`,
      showDenyButton: true,
      confirmButtonText: "Accept",
      denyButtonText: `Refuse`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteFunc();
      }
    });
  };

  return (
    <div className="users" style={{ padding: "0" }}>
      <Tabs
        style={{
          maxWidth: "100%",
          width: "100%",
        }}
      >
        <TabList
          style={{
            padding: "7px 20px 0 20px",
            background: "white",
            margin: "0",
          }}
        >
          <Tab>Coins</Tab>
        </TabList>

        <TabPanel>
          <div
            className="users"
            style={{
              padding: 0,
              marginTop: "20px",
            }}
          >
            <div
              className="usersUserListContainer"
              style={{ paddingBottom: "" }}
            >
              <div
                className="clientsTableMaterialTableContainer"
                style={{ width: "100%", maxWidth: "100%" }}
              >
                <MainTable
                    columns={mainColumns}
                    requestURL={requestURL}
                    setAction={setAction}
                    action={action}
                    isSocketUpdatable={false}
                    tableContainerRef={logTableRef}
                    tableHeight={"calc(100vh - 187px)"}
                    enableGrouping={true}
                    onRowClick={() => {}}
                    toolbarActions={{
                    search: true,
                    columnsFilter: true,
                    columnsVisible: true,
                    density: true,
                    fullScreen: true,
                  }}
                    renderRowActions={[
                        {
                            type: "switch",
                            name: "Switch",
                            onClick: handleSwitch,
                        },
                        // {
                        //   type: "delete",
                        //   name: "Delete",
                        //   icon: <FaTrash size={15} />,
                        //   onClick: handleDelete,
                        // },
                    ]}
                />
              </div>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default CoinsTable;

