import React, {useEffect, useRef, useState} from "react";
// Redux
import {useDispatch, useSelector} from "react-redux";

// CSS
import "./loginPage.css";
import tresorLogo from "../../assets/tresorX.png";
import Icon2 from "../../assets/loginIcon3.png";
import Icon3 from "../../assets/loginIcon4.png";
import {ClipLoader} from "react-spinners";
import {useCookies} from "react-cookie";
import {
    loginFailure,
    loginStart,
    loginSuccess,
    logout,
} from "../../redux/userRedux";
import {publicRequest} from "../../utils/requestMethods";
import {HiOutlineChevronLeft, HiOutlineChevronRight} from "react-icons/hi";
import AuthCode, {AuthCodeRef} from "react-auth-code-input";

const LoginPage = () => {
    const fieldRef = useRef();

    const dispatch = useDispatch();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [token, setToken] = useState("");
    const [lock, setLock] = useState(true);
    const [type, setType] = useState(0);
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    const loading = useSelector((state) => state.user.isFetching);
    const error = useSelector((state) => state.user.error);
    const AuthInputRef = useRef(null);


    useEffect(() => {
        setToken("");
    }, [])


    useEffect(() => {
        if (token.length === 6) {
            handleSubmit();
        }
    }, [token])
    const handleOnOTPChange = (res) => {
        if (res.length === 6) {
            setLock(false);
        }
        setToken(res);
    }

    const handleSubmit = async () => {
        try {
            dispatch(loginStart());
            const response = await publicRequest.post("auth/login", {
                username,
                password,
                token,
            });

            // Create a new Date object
            var currentDate = new Date();
            // Add 10 hours to the current date
            // currentDate.setHours(currentDate.getHours() + 10);

            // Add 10 sec to the current date
            currentDate.setSeconds(currentDate.getSeconds() + 10);
            if (response.data.token) {
                setCookie(
                    'token',
                    response.data.token,
                    {
                        maxAge: 36000,
                    })
                localStorage.setItem("token", response.data.token);
            }
            dispatch(loginSuccess(response.data));
        } catch (err) {
            console.log(err);
            AuthInputRef.current.clear();
            dispatch(loginFailure());
        }
    };

    useEffect(() => {
        fieldRef.current?.focus();
    }, [type]);

    return (
        <div className="loginPage">
            {type === 0 ? (
                <p className="loginSlogan">
                    Fast. Secure. <br/> Reliable.
                </p>
            ) : (
                <p className="loginSlogan">
                    Trading Made <br/> Easy.
                </p>
            )}
            <img
                className="loginPageIconThree"
                src={type === 0 ? Icon3 : Icon2}
                alt=""
            />

            <div className="loginPageForm">
                <img className="loginPageLogo" src={tresorLogo} alt=""/>
                {type === 0 ? (
                    <h1 className="loginHeader">Login in to your account</h1>
                ) : (
                    <h1 className="loginHeader">Enter your token</h1>
                )}
                <div className="input-icons">
                    {type === 1 && (
                        <div style={{marginTop: 20}}>
                            {/*<input*/}
                            {/*    value={token}*/}
                            {/*    ref={fieldRef}*/}
                            {/*    placeholder="Auth Token"*/}
                            {/*    onChange={(event) => setToken(event.target.value)}*/}
                            {/*    onKeyDown={(e) => e.key === "Enter" && handleSubmit()}*/}
                            {/*    type="text"*/}
                            {/*    className="loginPageFormInput"*/}
                            {/*    required*/}
                            {/*/>*/}
                            <AuthCode ref={AuthInputRef} onChange={handleOnOTPChange} allowedCharacters={"numeric"}
                                      inputClassName={'two-fa-input'}></AuthCode>
                        </div>
                    )}
                    {type === 0 && (
                        <div className="input-wrapper">
                            <input
                                value={username}
                                placeholder="Username"
                                onChange={(event) => setUsername(event.target.value)}
                                onKeyDown={(e) => e.key === "Enter" && setType(1)}
                                type="text"
                                className="loginPageFormInput"
                                required
                            />
                            <span className="animated-underline">
                <span className="fa fa-user icon"></span>
              </span>
                        </div>
                    )}
                </div>
                {type === 0 && (
                    <div className="input-icons2">
                        <input
                            value={password}
                            placeholder="Password"
                            onChange={(event) => setPassword(event.target.value)}
                            type="password"
                            className="loginPageFormInput"
                            onKeyDown={(e) => e.key === "Enter" && setType(1)}
                            required
                        />
                        <div className="animated-underline">
                            <span className="fa fa-lock icon"></span>
                        </div>
                    </div>
                )}
                <div
                    className="loginPageContainerRemeberAndForget"
                    style={{marginBottom: "10px"}}
                >
                    <div className="loginPageForget">
                        <a>Forgot Pasword?</a>
                    </div>
                </div>
                {error === true && (
                    <span className="loginPageFormError">Wrong Credentials!</span>
                )}
                {type === 0 && (
                    <button
                        onClick={() => setType(1)}
                        className="loginPageFormSubmitButton"
                    >
                        Next
                    </button>
                )}
                {type === 1 && (
                    <>
                        <button
                            onClick={() => handleSubmit()}
                            className="loginPageFormSubmitButton"
                            disabled={lock}
                        >
                            {loading ? (
                                <ClipLoader color="#fff" size={18}/>
                            ) : (
                                <div className="flex items-center justify-center w-full">
                                    Login
                                    <HiOutlineChevronRight color="#fff" size={20}/>
                                </div>
                            )}
                        </button>
                        <button
                            onClick={() => setType(0)}
                            className="normal-btn flex items-center cursor-pointer"
                            style={{
                                position: "fixed",
                                top: "30px",
                                left: "30px",
                                fontSize: "15px",
                                fontWeight: "600",
                            }}
                        >
                            <HiOutlineChevronLeft color="#777777" size={20}/>
                            Back
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

export default LoginPage;

