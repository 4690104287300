import {Cut, Image, Line, Printer, QRCode, Row, Text} from "react-thermal-printer";

import React from "react";
import print from "../printer";
import iconGenerator from "../iconGenerator";

export const cryptoAddress = async (currency, network, data, coinInfo) => {
    let icon = currency ? currency?.symbol : data?.info?.coin
    let coinName = currency ? currency?.name : coinInfo?.name
    let address = network ? network?.address : data?.address
    let networkName = network ? network?.networkName : data?.networkName

    await print(
        <Printer type="epson" width={48} characterSet="iso8859_2_latin2">
            <Text bold={true} align={'center'}
                size={{ width: 1, height: 1 }}>DEPOSIT ADDRESS</Text>
            <Image src={iconGenerator(64, null, `${icon}`)} align={"center"} />
            <Text bold={true} align={'center'} size={{ width: 1, height: 1 }}>{coinName}</Text>
            <Line />
            <QRCode cellSize={8} model={'model2'} align={"center"} content={address} />
            <Text align={'center'} size={{ width: 1, height: 1 }}>
                {address}
            </Text>
            <Line />
            <Row left={<></>} right={<></>} />
            <Row left={<Text bold={true}>Network Name</Text>}
                right={<Text bold={true}>{networkName}</Text>} />
            {network?.tag && (
                <Row left={<Text bold={true}>Memo</Text>}
                    right={<Text bold={true}>{network?.tag}</Text>} />
            )}

            <Line />
            <Cut lineFeeds={4} />
        </Printer>
    );
}
