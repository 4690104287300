import _ from "lodash";
import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import {publicRequest} from "../../utils/requestMethods";
import {FaTimes} from "react-icons/fa"

function MainModal({
                       isOpen,
                       closeModal,
                       setModifiedData,
                       closable,
                       content,
                       user,
                       modifiedData,
                       partnerData,
                       setPartnerData,
                       footer,
                       endpoint,
                       setEntity,
                       entity,
                       editEndpoint,
                       successEdit,
                       submitOrEdit,
                       customSubmit,
                       cls,
                       deleteButton,
                       deleteFunc,
                       success,
                       big,
                       closeOnEsc,
                       requiredFields,
                       setAction,
                       inputErrors,
                       setInputErrors,
                       validator
                   }) {
    const [binanceError, setBinanceError] = useState(false);
    const [dexError, setDexError] = useState(false);
    const [stateChange, setStateChange] = useState(false);

    const noErrors = inputErrors ? Object.keys(inputErrors).length === 0 : true
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    const customStyles = {
        content: {
            display: "flex",
            alignItems: "start",
            justifyContent: "flex-start",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: big ? "1000px" : "665px",
            borderRadius: "8px",
            maxHeight: "90vh",
            flexWrap: "wrap",
            padding: 0,
            overflowX: "hidden",
            minHeight: "500px",
            maxWidth: "97vw"
        },
        overlay: {
            backgroundColor: "rgb(0 0 0 / 50%)",
            zIndex: "100000",
        },
    };

    const closeModalFunc = () => {
        setBinanceError(false);
        setDexError(false);
        closeModal();
        if (validator) {
            setInputErrors({});
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();


        const {secret, roles, isAdmin, _id, ...others} = user;

        if (submitOrEdit === "submit" || submitOrEdit === "edit") {
            modifiedData.roles &&
            setModifiedData((prev) => ({
                ...prev,
                roles: modifiedData.roles._id,
            }));
            modifiedData.partnerId &&
            setModifiedData((prev) => ({
                ...prev,
                partnerId: modifiedData.partnerId._id,
            }));
            try {
                const response =
                    submitOrEdit === "submit"
                        ? await publicRequest.post(endpoint, modifiedData)
                        : await publicRequest.patch(
                            `${editEndpoint}/${modifiedData._id}`,
                            modifiedData
                        );

                if (submitOrEdit === "submit") {
                    setEntity([response.data, ...entity]);
                    successEdit("Added", response.data, "backToTable");
                    setAction({
                        type: "added",
                        id: 1,
                        data: response.data,
                    });
                } else {
                    let objIndex = entity.findIndex(
                        (obj) => obj._id === response.data._id
                    );
                    entity[objIndex] = response.data;
                    successEdit("Edited");
                    setAction({
                        type: "edited",
                        id: response.data._id,
                        data: response.data,
                    });
                }
                let arr = entity;
                setEntity(arr);
            } catch (err) {
                console.log(err);
            }
        } else {
            if (submitOrEdit === "partnerSubmit" || submitOrEdit === "partnerEdit") {
                partnerData.user?.roles && submitOrEdit === "partnerSubmit" &&
                setPartnerData((prev) => ({
                    ...prev,
                    user: {...partnerData.user, roles: partnerData.user.roles._id},
                }));
                partnerData.currencies &&
                setPartnerData((prev) => ({
                    ...prev,
                    currencies: partnerData.currencies?.map((x) => x._id && x._id),
                }));
                if (
                    partnerData.exchanges?.binance?.apiKey === "" &&
                    partnerData.exchanges?.binance?.privateKey === "" &&
                    partnerData.exchanges?.dex?.apiKey === "" &&
                    partnerData.exchanges?.dex?.privateKey === ""
                ) {
                    delete partnerData.exchanges;
                    setBinanceError(false);
                    setDexError(false);
                } else {
                    if (
                        !partnerData.exchanges?.binance?.apiKey ||
                        partnerData.exchanges?.binance?.apiKey === "" ||
                        partnerData.exchanges?.binance?.privateKey ||
                        partnerData.exchanges?.binance?.privateKey === ""
                    ) {
                        setBinanceError(true);
                    }
                    if (
                        (partnerData.exchanges?.binance?.apiKey !== "" &&
                            partnerData.exchanges?.binance?.privateKey !== "") ||
                        (partnerData.exchanges?.binance?.apiKey === "" &&
                            partnerData.exchanges?.binance?.privateKey === "")
                    ) {
                        setBinanceError(false);
                    }
                }

                if (
                    partnerData.exchanges?.binance?.apiKey === "" &&
                    partnerData.exchanges?.binance?.privateKey === ""
                ) {
                    if (!binanceError && !dexError) {
                        delete partnerData.exchanges?.binance;
                    }
                }
                if (
                    partnerData.exchanges?.dex?.apiKey === "" &&
                    partnerData.exchanges?.dex?.privateKey === ""
                ) {
                    if (!binanceError && !dexError) {
                        delete partnerData.exchanges?.dex;
                    }
                }
                if (_.isEmpty(partnerData.exchanges)) {
                    delete partnerData.exchanges;
                }


                requiredFields.map((field) => {
                    if (Object.keys(partnerData).includes(field)) {
                        if (field === "country") {
                            if (!partnerData[field]) {
                                inputErrors[field] = "is required!"
                            } else {
                                delete inputErrors[field]
                                setInputErrors({...inputErrors})
                            }
                        } else {
                            if (partnerData[field]?.length < 1) {
                                inputErrors[field] = "is required!"
                            } else {
                                delete inputErrors[field]
                                setInputErrors({...inputErrors})
                            }
                        }
                    }
                    // if (Object.keys(partnerData.user).includes(field)) {
                    //   if (field === "phone1") {
                    //     if (!partnerData[field]) {
                    //       inputErrors.userPhone1 = "is required!"
                    //     } else {
                    //       delete inputErrors[field]
                    //       setInputErrors({ ...inputErrors })
                    //     }
                    //   } else {
                    //     if (partnerData.user[field]?.length < 1) {
                    //       inputErrors[field] = "is required!"
                    //     } else {
                    //       delete inputErrors[field]
                    //       setInputErrors({ ...inputErrors })
                    //     }
                    //   }
                    // }
                })

                if (partnerData.email.length > 0 && !regex.test(partnerData.email)) {
                    inputErrors.email = "is not valid!"
                    setInputErrors({...inputErrors})
                } else if (partnerData.email.length < 1) {
                    inputErrors.email = "is required!"
                    setInputErrors({...inputErrors})
                } else {
                    delete inputErrors.email
                    setInputErrors({...inputErrors})
                }

                setStateChange(true);
            }
        }
    };

    useEffect(() => {
        if (!binanceError && !dexError && noErrors && stateChange) {
            handleAddPartner();
            setStateChange(false);
        }
    }, [binanceError, dexError, stateChange, noErrors]);

    const handleAddPartner = async () => {
        if (binanceError === false && dexError === false) {
            let tempCountry = partnerData.country
            try {
                partnerData.country = partnerData.country.name

                const response =
                    submitOrEdit === "partnerSubmit"
                        ? await publicRequest.post(endpoint, partnerData)
                        : await publicRequest.put(
                            `${editEndpoint}/${partnerData?.id}`,
                            partnerData
                        );
                if (submitOrEdit === "partnerSubmit") {
                    setEntity([response.data, ...entity]);
                    successEdit("Added");
                    setAction({
                        type: "added",
                        id: 1,
                        data: response.data,
                    });
                } else {
                    let objIndex = entity.findIndex(
                        (obj) => obj._id === response.data._id
                    );
                    entity[objIndex] = response.data;
                    successEdit("Edited");
                    setAction({
                        type: "edited",
                        id: response.data._id,
                        data: response.data,
                    });
                }

                setPartnerData({
                    partnerName: "",
                    address: "",
                    phone1: "",
                    phone2: "",
                    email: "",
                    city: "",
                    country: "",
                    percentage: "",
                    exchanges: {
                        binance: {
                            apiKey: "",
                            privateKey: "",
                        },
                        dex: {
                            apiKey: "",
                            privateKey: "",
                        },
                    },
                    user: {
                        username: "",
                        fullName: "",
                        phone1: "",
                        phone2: "",
                        email: "",
                        password: "",
                        roles: "",
                    },
                    currencies: [],
                });
                setBinanceError(false);
                setDexError(false);
            } catch (err) {
                if (err.response.status === 500) {
                    let field = Object.entries(err.response.data.keyValue)
                    inputErrors[field[0][0]] = "already exists!",
                        setInputErrors({...inputErrors})
                }
                setPartnerData((prev) => ({
                    ...prev,
                    country: tempCountry
                }))
                console.log(err, "test");
            }
        }
    };

    useEffect(() => {
        document.body.style.overflow = isOpen ? "hidden" : "unset";
    }, [isOpen]);

    return (
        <Modal
            ariaHideApp={false}
            shouldCloseOnOverlayClick={closeOnEsc}
            isOpen={isOpen}
            onRequestClose={closeOnEsc ? closeModalFunc : null}
            animationType="slide"
            style={customStyles}
            className={cls}
        >
            <div style={{paddingTop: 10, width: "95%"}}>
                {closable && (
                    <div className="modalClose" onClick={closeModalFunc}>
                        <FaTimes size={20}/>
                    </div>
                )}

                <div
                    style={{
                        padding: "20px",
                        marginTop: -20,
                        transition: "0.3s ease-in-out",
                        width: "100%",
                        overflowX: "hidden",
                        paddingBottom: "10px",
                        minHeight: "420px",
                        maxHeight: "fit-content",
                        overflowY: "scroll",
                    }}
                >
                    {content}
                </div>
            </div>

            <div className="w-full">
                {footer && !success ? (
                    <>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                width: "100%",
                                position: "relative",
                                bottom: "0",
                                background: "#fff",
                                borderTop: "1px solid #d8d8d8",
                            }}
                        >
                            {deleteButton && submitOrEdit === "edit" && (
                                <button
                                    onClick={deleteFunc}
                                    className="usersTableRegisterFormSendButton"
                                    style={{
                                        margin: "5px",
                                        background: "red",
                                        position: "absolute",
                                        left: "10px",
                                    }}
                                >
                                    Delete
                                </button>
                            )}
                            <button
                                onClick={closeModalFunc}
                                className="usersTableRegisterFormSendButton"
                                style={{margin: "5px", background: "#a7a7a7"}}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={customSubmit ? customSubmit : handleSubmit}
                                className="usersTableRegisterFormSendButton"
                                style={{margin: "5px"}}
                            >
                                Save
                            </button>
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </div>
        </Modal>
    );
}

export default MainModal;

