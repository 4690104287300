import TransportWebHID from "@ledgerhq/hw-transport-webhid";
import AppTRX from "@ledgerhq/hw-app-trx";

const TronWeb = require("tronweb");
const HttpProvider = TronWeb.providers.HttpProvider;
const fullNode = new HttpProvider(process.env.REACT_APP_TRX_URL);
const solidityNode = new HttpProvider(process.env.REACT_APP_TRX_URL);
const eventServer = new HttpProvider(process.env.REACT_APP_TRX_URL);
const tronWeb = new TronWeb(fullNode, solidityNode, eventServer);
const CONTRACTS = JSON.parse(process.env.REACT_APP_TRX_CONTRACTS);

export const makeSmartContractTransaction = async (data, address_from) => {
    const symbol = data.symbol.split('/')[0];
    const CONTRACT = CONTRACTS[symbol];

    const transport = await TransportWebHID.create();
    try {
        let {
            transaction,
            result
        } = await tronWeb.transactionBuilder.triggerSmartContract(
            CONTRACT, 'transfer(address,uint256)', {
                feeLimit: 100000000,
                callValue: 0,
            },
            [{
                type: 'address',
                value: data.address_to.address,
            }, {
                type: 'uint256',
                value: Math.ceil(data.quantity_sent * 1000000),
            }],
            address_from
        );

        if (!result.result) {
            return {error: 500, message: result.toString()}
        }


        const trxAPP = new AppTRX(transport);

        const signature = await trxAPP.signTransaction("44'/195'/0'/0/0", transaction.raw_data_hex);
        transaction["signature"] = [signature];

        const broadcast = await tronWeb.trx.sendRawTransaction(transaction);
        const {
            message
        } = broadcast;

        if (message) {
            return {error: 500, message: message.toString()}
        }

        return broadcast;
    } catch (e) {
        console.log(e);
        return {error: 500, message: e.toString()}
    } finally {
        await transport.close();
    }
}

export const makeTRXTransaction = async (data, walletAddress) => {
    const transaction = await tronWeb.transactionBuilder.sendTrx(
        data.address_to.address,
        data.quantity_sent * 1000000,
        walletAddress
    );

    const transport = await TransportWebHID.create();
    try {
        const trx = new AppTRX(transport);
        const signature = await trx.signTransaction("44'/195'/0'/0/0", transaction.raw_data_hex);
        transaction["signature"] = [signature];
        return await tronWeb.trx.sendRawTransaction(transaction);
    } catch (e) {
        console.log(e);
    } finally {
        await transport.close();
    }
}
