import React, { useContext, useEffect, useState } from "react";
import iconGenerator from "../../../utils/iconGenerator";
// CSS
import "./dashboardSliderFirstLeft.css";
// Sweet Alert 2
import { publicRequest } from "../../../utils/requestMethods";
import { HiOutlineChevronLeft, HiOutlineClock } from "react-icons/hi";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { MdEdit } from "react-icons/md";
import Moment from "moment";
import { ClipLoader } from "react-spinners";
import ReusableTitle from "../../ReusableComponents/ReusableTitle";
import ReusableStatusComponent from "../../ReusableComponents/ReusableStatusComponent";
import { SocketContext } from "../../../context/socket";
import { NumberFormatter } from "../../ReusableComponents/NumberFormat";

import useSound from "use-sound";
import new_transaction from "../../../assets/sounds/new_transaction.mp3";

const DashboardSliderFirst = ({
    pendingQuery,
    setApproveSliderInfo,
    setApproveSliderInfoType,
    approveSliderInfoType,
    approveTransaction,
    setApproveTransaction,
    permissions,
}) => {
    const [transactions, setTransactions] = useState([]);
    const [pendingTransactions, setPendingTransactions] = useState([]);
    const [more, setMore] = useState(false);
    const [itemsStart, setItemsStart] = useState(true);
    const [loading, setLoading] = useState(false);
    const [play, { stop }] = useSound(new_transaction);
    const socket = useContext(SocketContext);
    useEffect(() => {
        socket.updatePendingTransactions((data) => {
            // if (data.notification) {
            //     play()
            // }
            getTransactions()
        });
    }, [socket]);

    useEffect(() => {
        setPendingTransactions(transactions);
    }, [transactions]);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    useEffect(() => {
        if (approveTransaction._id !== null) {
            let pending = pendingTransactions.map((pt) => {
                if (pt._id === approveTransaction._id) {
                    pt.status = 4;
                }

                return pt;
            });

            setPendingTransactions(pending);
        }
    }, [approveTransaction])

    const getTransactions = async () => {
        try {
            setLoading(true);
            const response = await publicRequest.get(
                "transactions/auto/transactions"
            );

            setTransactions(response.data);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };


    useEffect(() => {
        getTransactions();
    }, []);

    useEffect(() => {
        const getPendingTransaction = async () => {
            if (pendingQuery === "") {
                setPendingTransactions(transactions);
            } else {
                try {
                    const data = { search: pendingQuery };

                    const response = await publicRequest.post(
                        "transactions/pending",
                        data
                    );
                    setPendingTransactions(response.data);
                } catch (err) {
                    console.log(err);
                }
            }
        };

        getPendingTransaction();
    }, [pendingQuery]);

    const handlePendingClick = (data) => {
        setApproveSliderInfo(data);
        setApproveSliderInfoType(true);
        setItemsStart(true);
    };

    // let arr = Object.values(permissions.permissions).map((y) =>
    //   Object.entries(y)
    // );

    // let newArr = arr.map((x, i) => x[0]);

    // let approve = newArr.find((x) => x[0] === "approve");
    // let buySell = newArr.find((x) => x[0] === "buySell");

    return (
        <div className="dashboardSliderFirst">
            <div
                className="dashboardSliderFirstWrapper"
                style={{
                    position: "relative",
                    justifyContent:
                        // !approveSliderInfoType && itemsStart ? "flex-start" : "center",
                        "flex-start",
                }}
            >
                {!permissions.approve ? (
                    <>
                        <ReusableTitle
                            title="Auto Trade Transactions"
                            style={{
                                width: "100%",
                                marginTop: "-10px",
                                marginBottom: "38px",
                            }}
                        />
                        <span style={{ margin: "0 auto" }}>No permissions.</span>
                    </>
                ) : (
                    <>
                        {" "}
                        {approveSliderInfoType && (
                            <button
                                onClick={() => {
                                    setApproveSliderInfoType(false);
                                    setItemsStart(true);
                                }}
                                className=" flex items-center cursor-pointer"
                                style={{
                                    boxShadow: "none",
                                    backgroundColor: "white",
                                    border: 0,
                                    position: "absolute",
                                    top: "-7px",
                                    left: "-20px",
                                    zIndex: "10",
                                }}
                            >
                                <HiOutlineChevronLeft color="#777777" size={20} />
                                Back
                            </button>
                        )}
                        <ReusableTitle
                            title="Auto Trade Transactions"
                            style={{
                                width: "100%",
                                marginTop: "-10px",
                                marginBottom: "38px",
                            }}
                        />
                        {loading ? (
                            <div className="grid h-full w-full place-center">
                                <ClipLoader size={30} color="orange" />
                            </div>
                        ) : (
                            <>
                                {pendingTransactions.map((data, index) =>
                                    more ? (
                                        <div
                                            key={`trans${index}`}
                                            className="dashboardSliderFirstLeft"
                                        >
                                            <div className="dashboardSliderFirstLeftItem">
                                                <div className="dashboardSliderFirstLeftItemInfoLeftIconContainer">
                                                    <img
                                                        className="dashboardSliderFirstLeftItemIcon"
                                                        src={iconGenerator(
                                                            128,
                                                            "icon",
                                                            `${data.symbol.split("/")[0]}`
                                                        )}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="dashboardSliderFirstLeftItemInfo">
                                                    <div className="dashboardSliderFirstLeftItemInfoLeft">
                                                        <span className="dashboardSliderFirstLeftItemInfoLeftSpan">
                                                            {data.symbol.split("/")[0]}
                                                        </span>
                                                        <p className="dashboardSliderFirstLeftItemInfoLeftP">
                                                            {data.symbol.split("/")[0]}
                                                        </p>
                                                    </div>
                                                    {data.exchange === "Binance" ? (
                                                        <img
                                                            className="dashboardSliderFirstLeftItemIconIcon"
                                                            src={require("../../../assets/binance_favicon.png")}
                                                            alt=""
                                                        />
                                                    ) : (
                                                        <img
                                                            className="dashboardSliderFirstLeftItemIconIcon"
                                                            src={require("../../../assets/dex.png")}
                                                            alt=""
                                                        />
                                                    )}
                                                    <span className="dashboardSliderFirstLeftItemInfoLeftValue">
                                                        {data.symbol.split("/")[0] === "USDT" || data.symbol.split("/")[0] === "BUSD" ?
                                                            <NumberFormatter value={data.quantity_sent || data.quantity} />
                                                            :
                                                            <div className="flex-column gap-5">
                                                                <p style={{ textAlign: "right" }}>{data.quantity_sent || data.quantity}</p>
                                                                {(data.symbol.split("/")[0].toLowerCase() !== "usdt" && data.symbol.split("/")[0].toLowerCase() !== "busd") && (
                                                                    <p style={{ marginBottom: 0, fontSize: "13px", color: "#989898", textAlign: "right" }}>≈ <NumberFormatter value={data.quantity * data.last_price_filled} /> <small>USD</small></p>
                                                                )}
                                                            </div>
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="dashboardSliderFirstLeftItemButtonContainer">
                                                <div
                                                    className="dashboardSliderFirstLeftItemInfoLeftButtonContainerWrapper">
                                                    <ReusableStatusComponent status={data.status} />
                                                    {data.status === 1 && (
                                                        <MdEdit
                                                            onClick={() => handlePendingClick(data)}
                                                            color="#fff"
                                                            className="dashboardSliderFirstLeftItemInfoLeftButtonContainerButtonIcon"
                                                        />
                                                    )}
                                                </div>
                                                <div className="dashboardSliderFirstLeftItemButtonContainerTime">
                                                    <span>🕓</span>
                                                    <span style={{ marginLeft: "5px", color: "gray" }}>
                                                        {Moment(data.createdAt).format("DD.MM.YYYY HH:mm")}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        index < 8 && (
                                            <div
                                                key={`dashboard${index}`}
                                                className="dashboardSliderFirstLeft"
                                            >
                                                <div className="dashboardSliderFirstLeftItem">
                                                    <div className="dashboardSliderFirstLeftItemInfoLeftIconContainer">
                                                        <img
                                                            className="dashboardSliderFirstLeftItemIcon"
                                                            src={iconGenerator(
                                                                128,
                                                                "icon",
                                                                `${data.symbol.split("/")[0]}`
                                                            )}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="dashboardSliderFirstLeftItemInfo">
                                                        <div className="dashboardSliderFirstLeftItemInfoLeft">
                                                            <span className="dashboardSliderFirstLeftItemInfoLeftSpan">
                                                                {data.symbol.split("/")[0]}
                                                            </span>
                                                            <p className="dashboardSliderFirstLeftItemInfoLeftP">
                                                                {data.network_name}
                                                            </p>
                                                        </div>
                                                        {data.exchange === "Binance" ? (
                                                            <img
                                                                className="dashboardSliderFirstLeftItemIconIcon"
                                                                src={require("../../../assets/binance_favicon.png")}
                                                                alt=""
                                                            />
                                                        ) : (
                                                            <img
                                                                className="dashboardSliderFirstLeftItemIconIcon"
                                                                style={{ width: "20px", height: "auto" }}
                                                                src={require("../../../assets/dex.png")}
                                                                alt=""
                                                            />
                                                        )}
                                                        <span className="dashboardSliderFirstLeftItemInfoLeftValue">
                                                            {data.symbol.split("/")[0] === "USDT" || data.symbol.split("/")[0] === "BUSD" ?
                                                                <NumberFormatter value={data.quantity_sent || data.quantity} />
                                                                :
                                                                <div className="flex-column gap-5">
                                                                    <p style={{ textAlign: "right" }}>{data.quantity_sent || data.quantity}</p>
                                                                    {(data.symbol.split("/")[0].toLowerCase() !== "usdt" && data.symbol.split("/")[0].toLowerCase() !== "busd") && (
                                                                        <p style={{ marginBottom: 0, fontSize: "13px", color: "#989898", textAlign: "right" }}>≈ <NumberFormatter value={data.quantity * data.last_price_filled} /> <small>USD</small></p>
                                                                    )}
                                                                </div>
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="dashboardSliderFirstLeftItemButtonContainer">
                                                    <div
                                                        className="dashboardSliderFirstLeftItemInfoLeftButtonContainerWrapper">
                                                        <ReusableStatusComponent status={data.status} />
                                                        {data.status === 1 && (
                                                            <MdEdit
                                                                onClick={() => handlePendingClick(data)}
                                                                color="#fff"
                                                                className="dashboardSliderFirstLeftItemInfoLeftButtonContainerButtonIcon"
                                                            />
                                                        )}
                                                    </div>
                                                    <div
                                                        className="dashboardSliderFirstLeftItemButtonContainerTime"
                                                        style={{ marginTop: "5px" }}
                                                    >
                                                        <HiOutlineClock size={16} color="#828282" />
                                                        <span
                                                            style={{ marginLeft: "5px", color: "#828282" }}
                                                        >
                                                            {Moment(data.updatedAt).format(
                                                                "DD.MM.YYYY HH:mm"
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )
                                )}
                            </>
                        )}
                        {pendingTransactions.length < 1 && !loading ? (
                            <span style={{ margin: "0 auto" }}>
                                No auto trade transactions.
                            </span>
                        ) : null}
                        {pendingTransactions.length > 10 && (
                            <span
                                style={{ cursor: "pointer", margin: "0 auto" }}
                                onClick={() => setMore(!more)}
                            >
                                {more ? (
                                    <div className="flex items-center gap-5">
                                        Show Less
                                        <BiChevronUp size={25} />
                                    </div>
                                ) : (
                                    <div className="flex items-center gap-5">
                                        Show More
                                        <BiChevronDown size={25} />
                                    </div>
                                )}
                            </span>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default DashboardSliderFirst;

