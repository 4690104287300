import React, {useEffect, useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {publicRequest} from "../../utils/requestMethods";
import MainTable from "../ReusableComponents/Tables/MRT_Main";
import {FaSave} from "react-icons/fa";
import iconGenerator from "../../utils/iconGenerator";

const PartnersCurrencyTable = ({localCurrencies, selectedPartner, fiat}) => {
    const user = useSelector((state) => state.user.currentUser);

    const [localData, setLocalData] = useState([]);
    const [requestURL, setRequestURL] = useState("v2/currencies");
    const [action, setAction] = useState({type: "", id: 0, data: null});
    const [success, setSuccess] = useState(false);
    const [addLoading, setAddLoading] = useState(false);

    const logTableRef = useRef();

    const mainColumns = useMemo(
        () => [
            {
                accessorKey: "symbol",
                header: "Symbol",
                size: 80,
                accessorFn: (item) => (
                    <div className="flex items-center gap-5">
                        <img
                            src={iconGenerator(64, null, item.symbol, true)}
                            alt={item.symbol}
                            width={32}
                        />
                        {item.symbol}
                    </div>
                ),
            },
            {
                accessorKey: "name",
                header: "Name",
                enableGrouping: false,
                size: 150,
            },
            {
                accessorKey: "rate",
                header: "Rate",
                size: 80,
                accessorFn: (item) => (
                    <input
                        name="rate"
                        value={item.rate}
                        readOnly={item.auto_rate}
                        disabled={item.auto_rate}
                        className="usersTableRegisterFormInput"
                        style={{background: "transparent", width: 111}}
                        onChange={(e) => handleInputChange(item, e)}
                        onClick={(e) =>
                            setTimeout(() => {
                                e.target.select();
                            }, 100)
                        }
                        onFocus={(e) =>
                            setTimeout(() => {
                                e.target.select();
                            }, 100)
                        }
                    />
                ),
            },
            {
                accessorKey: "buying_rate",
                header: "Buying Rate",
                size: 80,
                accessorFn: (item) => (
                    <div
                        className="flex items-center ga-10"
                        style={{
                            border: "1px solid #d7d7d7",
                            borderRadius: "5px",
                            padding: 5,
                        }}
                    >
                        <input
                            name="buying_rate"
                            value={item.buying_rate}
                            type="text"
                            readOnly={item.auto_rate}
                            disabled={item.auto_rate}
                            className="usersTableRegisterFormInput"
                            style={{
                                width: item.auto_rate ? 70 : 111,
                                border: 0,
                                marginTop: "0",
                                padding: 0,
                                background: "transparent",
                            }}
                            onChange={(e) => handleInputChange(item, e)}
                            onClick={(e) =>
                                setTimeout(() => {
                                    e.target.select();
                                }, 100)
                            }
                            onFocus={(e) =>
                                setTimeout(() => {
                                    e.target.select();
                                }, 100)
                            }
                        />
                        {item.auto_rate && (
                            <input
                                name="buying_rate_fee"
                                type="text"
                                value={item.buying_rate_fee}
                                onChange={(e) => handleInputChange(item, e)}
                                onClick={(e) =>
                                    setTimeout(() => {
                                        e.target.select();
                                    }, 100)
                                }
                                onFocus={(e) =>
                                    setTimeout(() => {
                                        e.target.select();
                                    }, 100)
                                }
                                className="usersTableRegisterFormInput"
                                style={{
                                    width: 30,
                                    border: 0,
                                    borderLeft: "1px solid #d7d7d7",
                                    borderRadius: "0px 0 0 0px",
                                    marginTop: 0,
                                    background: "transparent",
                                    padding: 0,
                                    paddingLeft: 10,
                                }}
                            ></input>
                        )}
                    </div>
                ),
            },
            {
                accessorKey: "selling_rate",
                header: "Selling Rate",
                size: 80,
                accessorFn: (item) => (
                    <div
                        className="flex items-center ga-10"
                        style={{
                            border: "1px solid #d7d7d7",
                            borderRadius: "5px",
                            padding: 5,
                        }}
                    >
                        <input
                            name="selling_rate"
                            value={item.selling_rate}
                            type="text"
                            readOnly={item.auto_rate}
                            disabled={item.auto_rate}
                            className="usersTableRegisterFormInput"
                            style={{
                                width: item.auto_rate ? 70 : 111,
                                border: 0,
                                marginTop: "0",
                                padding: 0,
                                background: "transparent",
                            }}
                            onChange={(e) => handleInputChange(item, e)}
                            onClick={(e) =>
                                setTimeout(() => {
                                    e.target.select();
                                }, 100)
                            }
                            onFocus={(e) =>
                                setTimeout(() => {
                                    e.target.select();
                                }, 100)
                            }
                        />
                        {item.auto_rate && (
                            <input
                                name="selling_rate_fee"
                                type="text"
                                value={item.selling_rate_fee}
                                onChange={(e) => handleInputChange(item, e)}
                                onClick={(e) =>
                                    setTimeout(() => {
                                        e.target.select();
                                    }, 100)
                                }
                                onFocus={(e) =>
                                    setTimeout(() => {
                                        e.target.select();
                                    }, 100)
                                }
                                className="usersTableRegisterFormInput"
                                style={{
                                    width: 30,
                                    border: 0,
                                    borderLeft: "1px solid #d7d7d7",
                                    borderRadius: "0px 0 0 0px",
                                    background: "transparent",
                                    marginTop: 0,
                                    padding: 0,
                                    paddingLeft: 10,
                                }}
                            />
                        )}
                    </div>
                ),
            },
            {
                header: "Auto Rate",
                accessorKey: "auto_rate",
                size: 50,
                accessorFn: (item) => (
                    <label
                        className="custom-checkbox-container"
                        style={{marginTop: 0, marginLeft: "20%", marginRight: "0"}}
                    >
                        <input
                            type="checkbox"
                            checked={item.auto_rate}
                            onChange={(e) => handleAutoRate(item, e)}
                        />
                        <span className="custom-checkmark"></span>
                    </label>
                ),
            },
        ],
        []
    );

    if (!user.isAdmin) {
        mainColumns.pop();
    }

    const handleInputChange = (item, e) => {
        const {name, value} = e.target;

        item[name] = value;

        setAction({
            type: "editedRow",
            id: item._id,
            data: item,
            name: name,
            value: value,
        });
    };

    useEffect(() => {
        setLocalData(localCurrencies);
    }, []);

    // useEffect(() => {
    //     if (!user.isAdmin && !user.isMainOffice) {
    //         setRequestURL(`v2/currencies/${user.partnerId._id}`);
    //     } else {
    //         setRequestURL("v2/currencies");
    //     }
    // }, []);

    useEffect(() => {
        if (!user.isAdmin && !user.isMainOffice) {
            setRequestURL(`v2/currencies/${user.partnerId._id}`);
        } else if (selectedPartner.length === 1) {
            setRequestURL(`v2/currencies/${selectedPartner}`);
        } else {
            setRequestURL(`v2/currencies`);
        }
    }, [selectedPartner]);

    const handleAutoRate = (item, e) => {
        const {name, value} = e.target;

        item.auto_rate = e.target.checked;

        setAction({
            type: "editedRow",
            id: item._id,
            data: item,
            name: name,
            value: value,
        });
    };

    const handleSave = async () => {
        let currencies = Object.entries(
            logTableRef.current.getRowModel().rowsById
        ).map((x) => x[1].original);
        try {
            setAddLoading(true);
            const response = await publicRequest.put("/currency/update/rate", {
                partners: selectedPartner,
                rates: currencies,
            });

            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
            }, 2000);
            setAddLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div
            className="clientsTable"
            style={{
                width: "100%",
                padding: 0,
            }}
        >
            <div className="w-full">
                {success && <div className="success-alert">Saved Successfully!</div>}
            </div>
            <div
                className="clientsTableMaterialTableContainer"
                style={{width: "100%", maxWidth: "100%"}}
            >
                <MainTable
                    columns={mainColumns}
                    requestURL={requestURL}
                    setAction={setAction}
                    action={action}
                    selectedPartner={selectedPartner}
                    isSocketUpdatable={false}
                    tableContainerRef={logTableRef}
                    renderFetchFooterToolbar={false}
                    renderCurrencyFooterToolbar={false}
                    tableHeight={"calc(100vh - 150px)"}
                    stickyFooter={false}
                    fiat={fiat}
                    onRowClick={() => {
                    }}
                    renderTopToolbarCustomActions={[
                        {
                            type: "save",
                            name: addLoading ? "Saving..." : "Save",
                            icon: <FaSave size={20}/>,
                            onClick: handleSave,
                        },
                    ]}
                    toolbarActions={{
                        search: true,
                        columnsFilter: true,
                        columnsVisible: true,
                        density: true,
                        fullScreen: true,
                    }}
                />
            </div>
        </div>
    );
};

export default PartnersCurrencyTable;

