import React, {Fragment, useEffect, useState} from "react";
import {FaCheck} from "react-icons/fa";
import {useSelector} from "react-redux";
import {balkanCountries} from "./Countries";
import MainSelect from "./Select";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

function ClientRegisterFields({
  modifiedData,
  setModifiedData,
  success,
  message,
  submitOrEdit,
}) {
  const user = useSelector((state) => state.user.currentUser);
  const [phonePrefix, setPhonePrefix] = useState("MK");

  useEffect(() => {
    setModifiedData((prev) => ({
      ...prev,
      partnerId: user?.partnerId?._id,
    }));
  }, []);

  const handleChange = ({ target: { name, value } }) => {
    setModifiedData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Fragment>
      {success ? (
        <div
          className="grid place-center w-full h-full"
          style={{ transform: "translateY(150%)" }}
        >
          <FaCheck
            size={40}
            color="white"
            style={{
              background: "#25da25",
              padding: "10px",
              borderRadius: "50%",
            }}
          />
          <div
            style={{
              fontWeight: "300",
              fontSize: "14px",
              margin: "5px 0",
            }}
          >
            Client {message} Successfully!
          </div>
        </div>
      ) : (
        <>
          <div className="usersTableRegisterFormItem">
            <label className="usersTableRegisterFormLabel">First Name</label>
            <input
              name="firstName"
              onChange={handleChange}
              value={modifiedData?.firstName}
              type="text"
              className="usersTableRegisterFormInput"
            />
          </div>
          <div className="usersTableRegisterFormItem">
            <label className="usersTableRegisterFormLabel">Last Name</label>
            <input
              name="lastName"
              onChange={handleChange}
              value={modifiedData?.lastName}
              type="text"
              className="usersTableRegisterFormInput"
            />
          </div>
          <div className="usersTableRegisterFormItem">
            <label className="usersTableRegisterFormLabel">Email</label>
            <input
              name="email"
              onChange={handleChange}
              value={modifiedData?.email}
              type="text"
              className="usersTableRegisterFormInput"
            />
          </div>
          <div className="usersTableRegisterFormItem">
            <label className="usersTableRegisterFormLabel">ZIP</label>
            <input
              name="zip"
              onChange={handleChange}
              value={modifiedData?.zip}
              type="text"
              className="usersTableRegisterFormInput"
            />
          </div>
          <div className="usersTableRegisterFormItem">
            <label className="usersTableRegisterFormLabel">Address</label>
            <input
              name="address"
              onChange={handleChange}
              value={modifiedData?.address}
              type="text"
              className="usersTableRegisterFormInput"
            />
          </div>
          <div className="usersTableRegisterFormItem">
            <label className="usersTableRegisterFormLabel">City</label>
            <input
              name="city"
              onChange={handleChange}
              value={modifiedData?.city}
              type="text"
              className="usersTableRegisterFormInput"
            />
          </div>
          <div
            className="usersTableRegisterFormItem"
            style={{ marginLeft: "-7px", width: "48%" }}
          >
            <label
              className="usersTableRegisterFormLabel"
              style={{ marginBottom: "7px" }}
            >
              Country
            </label>
            <MainSelect
              cls={`top-450 container-104`}
              customPrefix={`black-placeholder`}
              isClearable={false}
              isSearchable={true}
              options={balkanCountries}
              onChange={(e) => {
                if (e === null) {
                  setModifiedData((prev) => ({
                    ...prev,
                    country: "",
                  }));
                } else {
                  setModifiedData((prev) => ({
                    ...prev,
                    country: e.name,
                  }));
                  setPhonePrefix(e.code);
                }
              }}
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.code}
              defaultValue={modifiedData?.country}
              placeholder={
                modifiedData?.country.length
                  ? modifiedData?.country
                  : "Select a Country"
              }
            />
          </div>
          <div
            className="usersTableRegisterFormItem"
            style={{ marginLeft: "7px" }}
          >
            <label className="usersTableRegisterFormLabel">Phone 1</label>
            <PhoneInput
              placeholder="Enter phone number"
              name="phone1"
              defaultCountry={phonePrefix}
              onChange={(e) =>
                setModifiedData((prev) => ({
                  ...prev,
                  phone1: e,
                }))
              }
              value={modifiedData?.phone1}
              className="usersTableRegisterFormInput"
            />
          </div>
          <div className="usersTableRegisterFormItem">
            <label className="usersTableRegisterFormLabel">Phone 2</label>
            <PhoneInput
              placeholder="Enter phone number"
              name="phone2"
              defaultCountry={phonePrefix}
              onChange={(e) =>
                setModifiedData((prev) => ({
                  ...prev,
                  phone2: e,
                }))
              }
              value={modifiedData?.phone2}
              className="usersTableRegisterFormInput"
            />
          </div>
        </>
      )}
    </Fragment>
  );
}

export default ClientRegisterFields;

