import React, {useState} from "react";
import Table from "./Table";
import Currencies from "../../../ReusableComponents/Modals/Currencies";


function Details({
                     setTabIndex,
                     setSelectedPartner,
                     selectedPartner,
                     filteredData,
                     onlyTable,
                     noMarginTop,
                     customHeight,
                     customSecondHeight,

                 }) {
    const [singleCurrencyData, setSingleCurrencyData] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [title, setTitle] = useState("");
    const [modalType, setModalType] = useState(null);

    const closeModal = () => {
        setIsOpen(false);
        setModalType(null)
        setTitle("")
    };

    const handleModal = (title, type) => {
        setTitle(title);
        setModalType(type)
        setIsOpen(true);
    };

    const [action, setAction] = useState({type: "", data: null, id: 1});

    return (
        <div
            className="accounting-history"
            style={{maxWidth: "100%", marginTop: noMarginTop ? "0" : "10px"}}
        >

            {/*<MainModal*/}
            {/*    closeOnEsc={true}*/}
            {/*    closable={true}*/}
            {/*    isOpen={isOpen}*/}
            {/*    submitOrEdit={'submit'}*/}
            {/*    onRequestClose={closeModal}*/}
            {/*    closeModal={closeModal}*/}
            {/*    content={*/}
            {/*      <Content*/}
            {/*          title={title}*/}
            {/*          setIsOpen={setIsOpen}*/}
            {/*          setAction={setAction}*/}
            {/*      />*/}
            {/*    }*/}
            {/*/>*/}

            <Currencies
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                closable={true}
                onRequestClose={closeModal}
                title={title}
                type={modalType}
                selectedPartner={selectedPartner}
            />
            <Table
                setTabIndex={setTabIndex}
                setSelectedPartner={setSelectedPartner}
                selectedPartner={selectedPartner}
                singleCurrencyData={singleCurrencyData}
                setSingleCurrencyData={setSingleCurrencyData}
                filteredData={filteredData}
                customHeight={customHeight}
                customSecondHeight={customSecondHeight}
                handleModal={handleModal}
                setAction={setAction}
                action={action}
            />
        </div>
    );
}

export default Details;



