import { Document, Page, Text, View, StyleSheet, Svg, Path } from '@react-pdf/renderer';
import moment from 'moment';
import QRCode from 'qrcode.react';
import { renderToStaticMarkup } from 'react-dom/server';
import ReactHtmlParser from 'react-html-parser';

import { NumericFormat } from "react-number-format";


function FormatInput({ value, suffix }) {
    return (
        <NumericFormat
            value={value}
            displayType={"text"}
            thousandSeparator=","
            decimalSeparator="."
            suffix={` ${suffix}`}
            decimalScale={2}
            thousandsGroupStyle={"none"}
            fixedDecimalScale={true}
            renderText={(formattedValue) => (
                formattedValue
            )}
        />)
}


const styles = StyleSheet.create({
    document: {
        width: 300,
        paddingTop: 30,
        paddingBottom: 5,
        flexDirection: "row",
        flexGrow: 1
    },
    page: {
        paddingVertical: 20,
        display: "flex",
        flexDirection: "column"
    },
    title: {
        fontWeight: "bold",
        textAlign: "center",
        fontSize: 16,
        marginBottom: 10,
    },
    divider: {
        borderBottomWidth: 1,
        borderBottomStyle: "dashed",
        marginBottom: 10,
    },
    view: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 5,
        paddingHorizontal: 10
    },
    text: {
        fontSize: 12,
        marginBottom: 5
    },
    boldText: {
        fontWeight: "bold",
        fontSize: 14
    },
    image: {
        width: 300,
        height: 300
    }
});

const parseQrCodeMarkup = (markup) => {
    let parsedQrCodeSvg = null;

    ReactHtmlParser(markup).forEach(el => {
        const { type } = el;
        if (type === 'svg') {
            parsedQrCodeSvg = el;
        }
    });

    return parsedQrCodeSvg;
};

const qrCodeComponent = (
    <QRCode
        value={localStorage.getItem("orderId")}
        renderAs="svg"
        size={80}
    />
);

const qrCodeComponentStaticMarkup = renderToStaticMarkup(qrCodeComponent);

const parsedQrCodeSvg = parseQrCodeMarkup(qrCodeComponentStaticMarkup);
if (!parsedQrCodeSvg) {

}

// let data = JSON.parse(localStorage.getItem("order"))



export const ExchangePdf = ({ data }) => (

    <Document style={styles.document}>
        <Page style={styles.page} size="A6">
            <View style={styles.divider}>
                <Text style={styles.title}>EXCHANGE</Text>
                <View style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: 5 }}>
                    <Text style={styles.text}>{data?.symbol}</Text>
                </View>
            </View>
            <View style={styles.divider}>
                <View style={styles.view}>
                    <Text style={styles.text}>Date</Text>
                    <Text style={styles.text}>{moment(data?.createdAt).format('DD.MM.Y')}</Text>
                </View>

                <View style={styles.view}>
                    <Text style={styles.boldText}>Exchange ID</Text>
                    <Text style={styles.boldText}>{data?.order_number}</Text>
                </View>
            </View>
            <View style={styles.divider}>
                <View style={styles.view}>
                    <Text style={{ fontSize: 12, fontWeight: "500" }}>Description</Text>
                    <Text style={{ fontSize: 12, fontWeight: "500" }}>Amount</Text>
                </View>
                <View style={styles.view}>
                    <Text style={styles.text}>You sold</Text>
                    <Text style={styles.text}>{data.side === 'BUY' ?
                        <FormatInput value={data.amount_from} suffix={data.currency_from.symbol} /> :
                        <FormatInput value={data.amount_to} suffix={data.currency_to.symbol} />}
                    </Text>
                </View>
                <View style={styles.view}>
                    <Text style={styles.text}>Rate</Text>
                    <Text style={styles.text}>{data?.conversion_fee}
                    </Text>
                </View>
                <View style={styles.view}>
                    <Text style={styles.text}>You bought</Text>
                    <Text style={styles.text}>{data?.side === 'BUY' ?
                        <FormatInput value={data?.amount_to} suffix={data?.currency_to.symbol} /> :
                        <FormatInput value={data?.amount_from} suffix={data?.currency_from.symbol} />}
                    </Text>
                </View>
                <View style={styles.view}>
                    <Text style={styles.text}>Rate</Text>
                    <Text style={styles.text}>{data?.conversion_fee}
                    </Text>
                </View>
            </View>
            <View style={styles.divider}>
                <View style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: 5, paddingBottom: 15 }}>
                    <Svg
                        style={{ width: 85, height: 85 }}
                        viewBox="0 0 29 29"
                    >
                        {parsedQrCodeSvg.props.children.filter(c => c.type === 'path').map((child, index) => (
                            <Path
                                key={index}
                                d={child.props.d}
                                fill={child.props.fill}
                            />
                        ))}
                    </Svg>
                </View>
            </View>
            <View style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: 5 }}>
                <Text style={styles.text}>Thanks for using our service</Text>
            </View>
        </Page>
    </Document >

)
