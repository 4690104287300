import React, {useEffect, useState} from "react";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import "react-tabs/style/react-tabs.css";
import TransferTable from "./TransferTable";
import TransactionsTable from "./Transactions/TransactionsTable";

const Transfer = () => {
    const [index, setIndex] = useState(0);
    const [currencyId, setCurrencyId] = useState(null);

    useEffect(() => {
        // socket.on("accounting", (data) => {
        //   dispatch(updateAccounting(data.data));
        // });
        //
        // return () => {
        //   socket?.off();
        // };
    }, []);

    useEffect(() => {
        if (index === 0) {
            setCurrencyId({});
        }
    }, [index]);

    return (
        <div className="users">
            <Tabs
                style={{
                    maxWidth: "100%",
                    width: "100%",
                }}
                selectedIndex={index}
            >
                <TabList
                    style={{background: "white", margin: 0, padding: "7px 20px 0 20px"}}
                >
                    <Tab onClick={() => setIndex(0)}>Details</Tab>
                    <Tab onClick={() => setIndex(1)}>Transactions</Tab>
                    {/*<Tab onClick={() => setIndex(2)}>Reports</Tab>*/}
                </TabList>

                <TabPanel>
                    <div className="users" style={{padding: "0", marginTop: "20px"}}>
                        <div
                            className="usersUserListContainer"
                            style={{paddingBottom: ""}}
                        >
                            <TransferTable
                                setIndex={setIndex}
                                setCurrencyId={setCurrencyId}
                                customHeight="calc(100vh - 130px)"
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="users" style={{padding: "0", marginTop: "20px"}}>
                        <div
                            className="usersUserListContainer"
                            style={{paddingBottom: ""}}
                        >
                            <TransactionsTable
                                currencyId={currencyId}
                                setCurrencyId={setCurrencyId}
                                customHeight="calc(100vh - 213px)"
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="users" style={{padding: "0"}}>
                        <div
                            className="usersUserListContainer"
                            style={{marginTop: "10px", paddingBottom: ""}}
                        ></div>
                    </div>
                </TabPanel>
            </Tabs>
        </div>
    );
};

export default Transfer;

