import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import Button from "../../ReusableComponents/Button";
import { FaTimes } from "react-icons/fa";
import Moment from "moment";
import { publicRequest } from "../../../utils/requestMethods";
import { ClipLoader } from "react-spinners";

function FeesPanel({ setExternalFees, externalFees }) {
  const [feesList, setFeesList] = useState([]);
  const [showRemove, setShowRemove] = useState(-1);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [partnerOptions, setPartnerOptions] = useState([
    { _id: 0, value: "Turkey", label: "Turkey" },
    { _id: 1, value: "COININVEST", label: "COININVEST" },
  ]);

  useEffect(() => {
    const retrieveList = async () => {
      const response = await publicRequest.get("/currency/external/fee");
      setFeesList(response.data);
    };

    retrieveList();
  }, []);
  const handleListAdd = async (e) => {
    if (externalFees.fee.length < 1 || externalFees.partnerName.length < 1) {
      setError(true);
    } else {
      setError(false);
      setLoading(true);
      const response = await publicRequest.post("/currency/external/fee", {
        partnerName: externalFees.partnerName.label,
        fee: externalFees.fee,
      });

      let arr = [...[response.data], ...feesList];
      setFeesList(arr);
      setExternalFees((prev) => ({ ...prev, fee: "" }));
      setLoading(false);
    }
  };

  const removeCurrency = async (id) => {
    setDeleteLoading(true);
    const response = await publicRequest.delete("/currency/external/fee/" + id);
    if (response.status === 200) {
      setFeesList(feesList.filter((x) => x._id !== id));
      setDeleteLoading(false);
      setShowRemove(false);
    }
  };

  const createOption = (label) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ""),
  });

  const handleCreate = async (inputValue) => {
    setCreateLoading(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      setCreateLoading(false);
      setPartnerOptions((prev) => [...prev, newOption]);
      setExternalFees((prev) => ({ ...prev, partnerName: newOption }));
    }, 1000);
  };

  return (
    <div className="grid gap-10" style={{ marginTop: "20px" }}>
      <h4
        style={{
          fontWeight: "400",
          borderBottom: "3px solid orange",
          width: "fit-content",
        }}
      >
        External Fee
      </h4>
      <CreatableSelect
        isSearchable={true}
        isClearable={true}
        isDisabled={createLoading}
        isLoading={createLoading}
        onChange={(e) => {
          if (e === null) {
            setExternalFees((prev) => ({ ...prev, partnerName: "" }));
          } else {
            setExternalFees((prev) => ({ ...prev, partnerName: e }));
          }
        }}
        onCreateOption={(e) => handleCreate(e)}
        options={partnerOptions}
        placeholder={createLoading ? "" : "Select or add a partner..."}
        classNamePrefix={error ? "error-client" : "custom-select"}
        value={externalFees.partnerName}
      />

      <div
        className="flex items-center justify-between"
        style={{
          background: "#fff",
          borderRadius: "8px",
          border: error ? "1px solid red" : "",
        }}
      >
        <div style={{ flex: 1, width: "103%" }}>
          <input
            type="number"
            onChange={(e) =>
              setExternalFees((prev) => ({ ...prev, fee: e.target.value }))
            }
            className="normal-input"
            style={{ width: "90%" }}
            value={externalFees.fee}
          />
        </div>
        <div style={{ width: "fit-content" }}>
          <Button
            title={loading ? <ClipLoader size={14} color="white" /> : "Add"}
            cls="no-radius w-full border-left-bottom-radius"
            onClick={handleListAdd}
            disabled={loading}
          />
        </div>
      </div>

      <div>
        <div
          style={{
            minHeight: "300px",
            boxShadow: "0 0 10px rgb(0 0 0 / 10%)",
            background: "#fff",
            borderRadius: "8px",
            broderRadius: "4px",
            maxHeight: "300px",
            overflowY: "scroll",
          }}
        >
          <div className="grid gap-10" style={{ padding: "6px 10px" }}>
            <table id="externalFees">
              <tr className="th-padding-6">
                <th style={{ width: "30%" }}>Date</th>
                <th style={{ width: "40%" }}>Partner Name</th>
                <th style={{ width: "10%" }}>Fee</th>
              </tr>
              {feesList?.map((x, i) => (
                <tr
                  className="hover-fees"
                  onDoubleClick={() => {
                    setShowRemove(i);
                  }}
                  onMouseLeave={() => setShowRemove(-1)}
                >
                  <td> {Moment(x.createdAt).format("DD.MM.YYYY HH:mm")}</td>
                  <td>{x.partnerName}</td>
                  <td
                    className="flex items-center"
                    style={{ position: "relative" }}
                  >
                    {x.fee}{" "}
                    {showRemove === i && (
                      <FaTimes
                        onClick={() => removeCurrency(x._id)}
                        color="white"
                        size={12}
                        className="cursor-pointer"
                        style={{
                          position: "absolute",
                          left: "80%",
                          top: "-8px",
                          padding: "3px",
                          background: "orange",
                          borderRadius: "50%",
                        }}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeesPanel;


