import React, {useCallback, useEffect, useState} from "react";
import {publicRequest} from "../../../utils/requestMethods";
import MainSelect from "../../ReusableComponents/Select";
import {FaTimes} from "react-icons/fa";
import iconGenerator from "../../../utils/iconGenerator";

function PartnerCurrencies({
                               handleListChange,
                               removeCurrency,
                               tempCurrencies,
                               setTempCurrencies,
                               partnerData,
                           }) {
    const [selectCurrency, setSelectCurrency] = useState([]);

    useEffect(() => {
        if (partnerData?.currencies.length > 0 && tempCurrencies.length < 1) {
            setTempCurrencies(partnerData.currencies);
        }
    }, []);

    useEffect(() => {
        const getCurrencies = async () => {
            try {
                const response = await publicRequest.get("exchanges/rate");

                setSelectCurrency(response.data);
            } catch (err) {
                console.log(err);
            }
        };

        getCurrencies();
    }, []);

    const customFilter = useCallback((candidate, input) => {
        if (input.length) {
            if (
                candidate.data.name.toLowerCase().includes(input.toLowerCase()) ||
                candidate.data.symbol.toLowerCase().includes(input.toLowerCase())
            ) {
                return (tempCurrencies.findIndex((sc) => sc.symbol === candidate.data.symbol) === -1)
            } else {
                return false;
            }
        }
        return (tempCurrencies.findIndex((sc) => sc.symbol === candidate.data.symbol) === -1)
    }, [tempCurrencies]);

    return (
        <div>
            <MainSelect
                isClearable={true}
                isSearchable={true}
                onChange={handleListChange}
                options={selectCurrency}
                defaultValue={null}
                getOptionLabel={(e) => (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <img
                            src={iconGenerator(128, "icon", e.symbol, true)}
                            style={{height: "30px"}}
                        />
                        <span style={{marginLeft: 5}}>
              {e.name} ({e.symbol})
            </span>
                    </div>
                )}
                filterOption={customFilter}
                getOptionValue={(e) => e._id}
            />
            <div
                style={{
                    minHeight: "250px",
                    border: "1px solid #d8d8d8",
                    broderRadius: "4px",
                    marginTop: "20px",
                    maxHeight: "250px",
                    overflowY: "scroll"
                }}
            >
                <div className="grid gap-10" style={{padding: "6px"}}>
                    {tempCurrencies?.map((x) => (
                        <div className="listOfCurrency">
                            <div className="flex items-center gap-5">
                                <img
                                    src={iconGenerator(128, "icon", x.symbol, true)}
                                    style={{height: "30px"}}
                                />
                                <span>{x.name}</span>
                                <span>{x.symbol}</span>
                            </div>
                            <FaTimes
                                onClick={() => removeCurrency(x._id)}
                                color="white"
                                size={14}
                                className="remove-currency cursor-pointer"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default PartnerCurrencies;

