import React, { Fragment, useEffect, useState } from "react";
import Moment from "moment";
import {FaEdit} from "react-icons/fa";
import {capitalizeWords} from "../../../utils/utils";

function PanelInfo({ modifiedData, handleUserEditInfo, success }) {
  const [staticData, setStaticData] = useState();
  const [partner, setPartner] = useState(null);

  useEffect(() => {
    if (modifiedData.partnerId) {
      setPartner(modifiedData.partnerId);
    }
    setStaticData(modifiedData);
  }, [success]);

  return (
    <Fragment>
      <div className="grid w-full gap-20">
        <span
          className="cursor-pointer flex items-center gap-5 justify-end"
          style={{ marginBottom: "-20px" }}
          onClick={handleUserEditInfo}
        >
          <FaEdit color="orange" size={18} style={{ width: "25px" }} />
        </span>
        <div className="flex items-start justify-between w-full">
          <div className="userinfo-items">
              <span>Full Name</span>
              <p>
                  {capitalizeWords(staticData?.firstName)} {capitalizeWords(staticData?.lastName)}
              </p>
          </div>
          <div className="userinfo-items">
              <span>Partner Name</span>
              <p>{capitalizeWords(partner?.partnerName)}</p>
          </div>
          <div className="userinfo-items">
            <span>Zip</span>
            <p>{staticData?.zip}</p>
          </div>
          <div className="userinfo-items">
              <span>City</span>
              <p>{capitalizeWords(staticData?.city)}</p>
          </div>
          <div className="userinfo-items">
              <span>Address</span>
              <p>{capitalizeWords(staticData?.address)}</p>
          </div>
          <div className="userinfo-items">
              <span>Country</span>
              <p>{capitalizeWords(staticData?.country)}</p>
          </div>
        </div>
        <div className="flex items-start justify-between w-full">
          <div className="userinfo-items">
            <span>Phone 1</span>
            <p>{staticData?.phone1}</p>
          </div>
          <div className="userinfo-items">
            <span>Phone 2</span>
            <p>{staticData?.phone2}</p>
          </div>
          <div className="userinfo-items">
            <span>Email</span>
            <p>{staticData?.email}</p>
          </div>
          <div className="userinfo-items">
            <span>Date Created</span>
            {Moment(staticData?.createdAt).format("DD.MM.YYYY HH:mm")}
          </div>
          <div className="userinfo-items">
            <span>Date Updated</span>
            {Moment(staticData?.updatedAt).format("DD.MM.YYYY HH:mm")}
          </div>
          <div className="userinfo-items">
            <span>Created By</span>
              {capitalizeWords(staticData?.whoCreateIt?.fullName)}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default PanelInfo;

