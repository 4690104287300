import React, {useEffect, useMemo, useRef, useState} from "react";
import MainTable from "../ReusableComponents/Tables/MRT_Main";
import {publicRequest} from "../../utils/requestMethods";
import Swal from "sweetalert2";
import {FaSave, FaTrash} from "react-icons/fa";
import iconGenerator from "../../utils/iconGenerator";
import {useSelector} from "react-redux";

const PartnersPairsTable = ({
                                exchangeRates,
                                setExchangeRates,
                                fiat,
                                selectedPartner,
                                setSelectedPartner,
                                addLoading,
                                setAddLoading,
                                setSuccess,
                                partnerCurrencies
                            }) => {
    const user = useSelector((state) => state.user.currentUser);

    const [requestURL, setRequestURL] = useState("v2/exchanges/");
    const [action, setAction] = useState({type: "", id: 0, data: null});

    const logTableRef = useRef();

    const mainColumns = useMemo(
        () => [
            {
                accessorKey: "currency_from.symbol",
                header: "Currency From",
                size: 120,
                accessorFn: (item) => (
                    <div className="flex items-center gap-5">
                        {item.currency_from && (
                            <img
                                src={iconGenerator(64, null, item.currency_from?.symbol, true)}
                                alt={item.currency_from?.symbol}
                                width={32}
                            />
                        )}
                        {item.currency_from ? item.currency_from.symbol : ""}
                    </div>
                ),
            },
            {
                accessorKey: "currency_to.symbol",
                header: "Currency To",
                enableGrouping: false,
                size: 110,
                accessorFn: (item) => (
                    <div className="flex items-center gap-5">
                        {item.currency_from && (
                            <img
                                src={iconGenerator(64, null, item.currency_to.symbol, true)}
                                alt={item.currency_to.symbol}
                                width={32}
                            />
                        )}
                        {item.currency_to ? item.currency_to.symbol : ""}
                    </div>
                ),
            },
            {
                accessorKey: "rate",
                header: "Rate",
                size: 120,
                accessorFn: (item) => (
                    <input
                        name="rate"
                        value={item.rate}
                        disabled
                        className="usersTableRegisterFormInput"
                        style={{background: "transparent", width: 111}}
                        onChange={(e) => handleInputChange(item, e)}
                        onClick={(e) =>
                            setTimeout(() => {
                                e.target.select();
                            }, 100)
                        }
                        onFocus={(e) =>
                            setTimeout(() => {
                                e.target.select();
                            }, 100)
                        }
                    />
                ),
            },
            {
                accessorKey: "buying_rate",
                header: "Buying Rate",
                size: 120,
                accessorFn: (item) => (
                    <div
                        className="flex items-center ga-10"
                        style={{
                            border: "1px solid #d7d7d7",
                            maxWidth: "fit-content",
                            borderRadius: "5px",
                            padding: 5,
                        }}
                    >
                        <input
                            name="buying_rate"
                            value={item.buying_rate}
                            type="text"
                            className="usersTableRegisterFormInput"
                            onFocus={(e) => {
                                setTimeout(() => {
                                    e.target.select();
                                }, 100);
                            }}
                            onClick={(e) => {
                                setTimeout(() => {
                                    e.target.select();
                                }, 100);
                            }}
                            style={{
                                width: 70,
                                border: 0,
                                borderRight: "1px solid #d7d7d7",
                                paddingRight: "5px",
                                marginTop: "0",
                                borderRadius: "5px 0 0 5px",
                                padding: 0,
                                background: "transparent",
                            }}
                            onChange={(e) => handleInputChange(item, e)}
                        />
                        <input
                            name="buying_rate_fee"
                            type="text"
                            value={item.buying_rate_fee || 0}
                            onChange={(e) => handleInputChange(item, e)}
                            onFocus={(e) => {
                                setTimeout(() => {
                                    e.target.select();
                                }, 100);
                            }}
                            onClick={(e) => {
                                setTimeout(() => {
                                    e.target.select();
                                }, 100);
                            }}
                            className="usersTableRegisterFormInput"
                            style={{
                                width: 30,
                                border: 0,
                                marginLeft: "10px",
                                marginTop: "0",
                                padding: 0,
                                background: "transparent",
                            }}
                        />
                    </div>
                ),
            },
            {
                accessorKey: "selling_rate",
                header: "Selling Rate",
                size: 120,
                accessorFn: (item) => (
                    <div
                        className="flex items-center ga-10"
                        style={{
                            border: "1px solid #d7d7d7",
                            maxWidth: "fit-content",
                            borderRadius: "5px",
                            padding: 5,
                        }}
                    >
                        <input
                            name="selling_rate"
                            value={item.selling_rate}
                            type="text"
                            className="usersTableRegisterFormInput"
                            onFocus={(e) => {
                                setTimeout(() => {
                                    e.target.select();
                                }, 100);
                            }}
                            onClick={(e) => {
                                setTimeout(() => {
                                    e.target.select();
                                }, 100);
                            }}
                            style={{
                                width: 70,
                                border: 0,
                                borderRight: "1px solid #d7d7d7",
                                paddingRight: "5px",
                                marginTop: "0",
                                borderRadius: "5px 0 0 5px",
                                padding: 0,
                                background: "transparent",
                            }}
                            onChange={(e) => handleInputChange(item, e)}
                        />
                        <input
                            name="selling_rate_fee"
                            type="text"
                            value={item.selling_rate_fee || 0}
                            onChange={(e) => handleInputChange(item, e)}
                            onFocus={(e) => {
                                setTimeout(() => {
                                    e.target.select();
                                }, 100);
                            }}
                            onClick={(e) => {
                                setTimeout(() => {
                                    e.target.select();
                                }, 100);
                            }}
                            className="usersTableRegisterFormInput"
                            style={{
                                width: 30,
                                border: 0,
                                marginLeft: "10px",
                                marginTop: "0",
                                padding: 0,
                                background: "transparent",
                            }}
                        />
                    </div>
                ),
            },
            {
                accessorKey: " ",
                header: " ",
                size: 70,
                accessorFn: (item) => (
                    <div>
                        <FaTrash
                            onClick={() => handleDelete(item)}
                            size={15}
                            color="#0000008a"
                        />
                    </div>
                ),
            },
        ],
        []
    );

    useEffect(() => {
        if (selectedPartner.length === 1) {
            setRequestURL(`v2/currencies/pairs/${selectedPartner}`);
        } else {
            setRequestURL(`v2/currencies/pairs`);
        }
    }, [selectedPartner]);


    useEffect(() => {
        if (!user.isAdmin && !user.isMainOffice) {
            setSelectedPartner([user.partnerId._id]);
            setRequestURL(`v2/currencies/pairs/${user.partnerId._id}`);
        }
    }, [])

    // useEffect(() => {
    //     if (fiat) {
    //         let newPairsRate = exchangeRates.map((pair) => {
    //             let symbol1 = fiat.find(
    //                 (ff) => ff.symbol === pair.currency_from.symbol
    //             );
    //             let symbol2 = fiat.find((ff) => ff.symbol === pair.currency_to.symbol);
    //
    //             let buyingPip = pair.buying_rate_fee ?? 0;
    //             let sellingPip = pair.selling_rate_fee ?? 0;
    //
    //             let pairRate = Number(symbol2.rate) / Number(symbol1.rate);
    //             let buyingRateWithFee =
    //                 Number(pairRate) + Number(pairRate) * (buyingPip / 100);
    //             let sellingRateWithFee =
    //                 Number(pairRate) - Number(pairRate) * (sellingPip / 100);
    //
    //             return {
    //                 ...pair,
    //                 ...{
    //                     rate: Number(pairRate).toFixed(5),
    //                     buying_rate: Number(buyingRateWithFee).toFixed(5),
    //                     selling_rate: Number(sellingRateWithFee).toFixed(5),
    //                     buying_rate_fee: buyingPip,
    //                     selling_rate_fee: sellingPip,
    //                 },
    //             };
    //         });
    //         setExchangeRates(newPairsRate);
    //     }
    // }, [fiat]);

    const handleInputChange = (item, e) => {
        const {name, value} = e.target;

        item[name] = value;

        setAction({
            type: "editedRow",
            id: item._id,
            data: item,
            name: name,
            value: value,
        });
    };

    const handleSave = async () => {
        let pairs = Object.entries(logTableRef.current.getRowModel().rowsById).map(
            (x) => x[1].original
        );
        try {
            setAddLoading(true);
            const response = await publicRequest.put("/exchanges/exchange/pairs", {
                pairs: pairs,
                partners: selectedPartner,
            });
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
            }, 2000);
            setAddLoading(false);
        } catch (e) {
            console.log(e);
        }
    };

    const handleDelete = (propsData) => {
        const deleteFunc = async () => {
            try {
                // await publicRequest.delete(`exchanges/exchange/pairs/${propsData._id}`);
                await publicRequest.delete(`exchanges/exchange/pairs/${propsData._id}`)
                    .then((response) => {
                        if (response.status === 200) {
                            setAction({
                                type: "deleted",
                                id: propsData._id,
                                data: {},
                            });
                        }
                    });
            } catch (err) {
                console.log(err);
            }
        };

        Swal.fire({
            title: `Do you want to delete ${propsData.currency_from.symbol}/${propsData.currency_to.symbol} pair?`,
            showDenyButton: true,
            confirmButtonText: "Accept",
            denyButtonText: `Refuse`,
        }).then((result) => {
            if (result.isConfirmed) {
                deleteFunc();
            }
        });
    };

    return (
        <div
            className="clientsTable"
            style={{
                width: "100%",
                padding: 0,
            }}
        >
            <div
                className="clientsTableMaterialTableContainer"
                style={{width: "100%", maxWidth: "100%"}}
            >
                <MainTable
                    columns={mainColumns}
                    requestURL={requestURL}
                    setAction={setAction}
                    action={action}
                    selectedPartner={selectedPartner}
                    isSocketUpdatable={false}
                    tableContainerRef={logTableRef}
                    renderFetchFooterToolbar={false}
                    tableHeight={"calc(100vh - 150px)"}
                    enableGrouping={true}
                    fiat={fiat}
                    pairs={true}
                    onRowClick={() => {
                    }}
                    partnerCurrencies={partnerCurrencies}
                    renderTopToolbarCustomActions={[
                        {
                            type: "save",
                            name: addLoading ? "Saving..." : "Save",
                            icon: <FaSave size={20}/>,
                            onClick: handleSave,
                        },
                    ]}
                    toolbarActions={{
                        search: true,
                        columnsFilter: true,
                        columnsVisible: true,
                        density: true,
                        fullScreen: true,
                    }}
                />
            </div>
        </div>
    );
};

export default PartnersPairsTable;

