import React, {useEffect, useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import MainTable from "../../ReusableComponents/Tables/MRT_Main";
import Swal from "sweetalert2";
import UsersDetail from "../UsersDetail/UsersDetail";
import {publicRequest} from "../../../utils/requestMethods";
import MainModal from "../../ReusableComponents/MainModal";
import UserRegisterFields from "./UserRegisterFields";
import "./usersTable.css";
import {FaArrowLeft, FaPlusCircle} from "react-icons/fa";
import PanelInfo from "./PanelInfo";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {capitalizeWords} from "../../../utils/utils";
import Moment from "moment";

const UsersTable = () => {
    const user = useSelector((state) => state.user.currentUser);
    const menu = useSelector((state) => state.user.menu);

    const [selectedRow, setSelectedRow] = useState(null);
    const [users, setUsers] = useState([]);
    const [register, setRegister] = useState(false);
    const [edit, setEdit] = useState(false);
    const [usersDetail, setUsersDetail] = useState(null);
    const [details, setDetails] = useState(false);
    const [getRoles, setGetRoles] = useState([]);
    const [choosenRole, setChoosenRole] = useState(null);
    const [partners, setPartners] = useState([]);
    const [error, setError] = useState(false);
    const [submitOrEdit, setSubmitOrEdit] = useState("");
    const [modalType, setModalType] = useState("");
    const [infoData, setInfoData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [rowClicked, setRowClicked] = useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState("");
    const [requestURL, setRequestURL] = useState("v2/users");
    const [action, setAction] = useState({type: "", id: null, data: null});

    const logTableRef = useRef();

    const logColumns = useMemo(
        () => [
            {
                accessorKey: "type",
                header: "Log Name",
                Cell: ({cell}) => {
                    return capitalizeWords(cell.getValue());
                },
            },
            {
                accessorKey: "transactionType",
                header: "Action",
                Cell: ({cell}) => {
                    return capitalizeWords(cell.getValue());
                },
            },
            {
                accessorKey: "createdAt",
                header: "Date",
                accessorFn: (e) => (
                    <div>{Moment(e.createdAt).format("DD.MM.YYYY HH:mm")}</div>
                ),
            },
        ],
        []
    );

    const mainColumns = useMemo(
        () => [
            {
                accessorKey: "fullName",
                header: "Full Name",
                size: 50,
                Cell: ({cell}) => {
                    return capitalizeWords(cell.getValue());
                },
            },
            {
                accessorKey: "partnerId",
                header: "Partner Name",
                size: 50,
                accessorFn: (e) => <span>{capitalizeWords(e?.partnerId?.partnerName)}</span>,
            },
            {
                accessorKey: "username",
                header: "Username",
                size: 50,
            },
            {
                accessorKey: "email",
                header: "Email",
                size: 50,
            },
            {
                accessorKey: "phone1",
                header: "Phone 1",
                size: 50,
            },
            {
                accessorKey: "phone2",
                header: "Phone2",
                size: 50,
            },
        ],
        []
    );

    const partnerId = user.partnerId;

    // Input States

    const [isOpen, setIsOpen] = useState(false);

    const openModal = (params) => {
        setIsOpen(true);
        setSubmitOrEdit(params);
    };

    const closeModal = () => {
        setIsOpen(false);
        setModalType("");
    };

    const [modifiedData, setModifiedData] = useState({
        id: "",
        username: "",
        password: "",
        fullName: "",
        phone1: null,
        phone2: "",
        email: "",
        submitPartner: "",
        partnerId: null,
        isOffice: "",
    });

    const [viewAll, setViewAll] = useState(false);
    const [viewOther, setViewOther] = useState(false);
    const [viewOwn, setViewOwn] = useState(false);

    useEffect(() => {
        if (users.length) {
            setLoading(false);
        }
    }, [users]);

    useEffect(() => {
        if (user.isAdmin) {
            setViewAll(true);
            setViewOther(true);
            setViewOwn(true);
        } else {
            let userRules = menu.find((mm) => mm.value === "users");
            if (userRules) {
                userRules.permissions.map((up) => {
                    let permission = Object.entries(up)[0];
                    switch (permission[0]) {
                        case "viewAll":
                            setViewAll(permission[1]);
                            break;
                        case "viewOther":
                            setViewOther(permission[1]);
                            break;
                        case "viewOwn":
                            setViewOwn(permission[1]);
                            break;
                    }
                });
            }
        }
    }, []);

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await publicRequest.get("roles/");
                setGetRoles(response.data.data);
            } catch (err) {
                console.log(err);
            }
        };

        const getPartnerData = async () => {
            try {
                const response = await publicRequest.get(`roles/${partnerId._id}`);
                setGetRoles(response.data);
            } catch (err) {
                console.log(err);
            }
        };

        user.isAdmin ? getData() : getPartnerData();
    }, []);

    useEffect(() => {
        const getPartners = async () => {
            try {
                const response = await publicRequest.get("partner/");
                setPartners(response.data);
            } catch (err) {
                console.log(err);
            }
        };

        user.isAdmin ? getPartners() : null;
    }, []);

    useEffect(() => {
        reFetch();
    }, [viewAll, viewOther, viewOwn]);

    const reFetch = () => {
        if (viewAll === true) {
            getUsers();
        } else if (viewOther === true) {
            getOtherUsers();
        } else if (viewOwn === true) {
            getOwnUsers();
        }
    };
    const getUsers = async () => {
        try {
            const response = await publicRequest.get("user/");

            setUsers(response.data);
        } catch (err) {
            console.log(err);
            setError(true);
        }
    };

    const getOwnUsers = async () => {
        try {
            const response = await publicRequest.get(`user/getOwn`);
            setUsers(response.data);
            setSubmitPartner(partnerId);
        } catch (err) {
            console.log(err);
            setError(true);
        }
    };

    const getOtherUsers = async () => {
        try {
            const response = await publicRequest.get(`user/getOther`);

            setUsers(response.data);
            setSubmitPartner(partnerId);
        } catch (err) {
            console.log(err);
            setError(true);
        }
    };

    const handleUserEditInfo = () => {
        setIsOpen(true);
        setSubmitOrEdit("edit");
        setEdit(true);
        setRegister(false);
    };

    const handleDelete = () => {
        setIsOpen(false);
        const admin = {isAdmin: user.isAdmin};

        const deleteUser = async () => {
            try {
                await publicRequest.delete(`user/${modifiedData._id}`);
                setAction({
                    type: "deleted",
                    id: modifiedData._id,
                    data: {},
                });
            } catch (err) {
                console.log(err);
            }
        };

        Swal.fire({
            title: `Do you want to delete ${modifiedData.fullName}?`,
            showDenyButton: true,
            confirmButtonText: "Accept",
            denyButtonText: `Refuse`,
        }).then((result) => {
            if (result.isConfirmed) {
                rowBackClicked();
                deleteUser();
            }
        });
    };

    const cancelSubmit = () => {
        setEdit(false);
        setRegister(false);
        setUsername("");
        setPassword("");
        setFullName("");
        setPhone1("");
        setPhone2("");
        setEmail("");
    };

    const handleChoosenPartner = (id) => {
        const choosen = partners.find((x) => x._id === id);

        setSubmitPartner(choosen._id);
    };

    const handleInfo = (event, propsData) => {
        setIsOpen(true);
        setModalType("info");
        setInfoData(propsData);
    };

    const handleRowClick = (data) => {
        setModifiedData(data);
        setRowClicked(true);
        setRequestURL(`v2/users/log?userId=${data._id}`);
    };

    const secoundTableRowClick = (data) => {
        console.log(data);
    }

    const rowBackClicked = () => {
        setRequestURL("v2/users");

        setRowClicked(false);
        setModifiedData({
            id: "",
            username: "",
            password: "",
            fullName: "",
            phone1: null,
            phone2: "",
            email: "",
            submitPartner: "",
            partnerId: null,
        });
    };

    const successEdit = (params, data, navigate) => {
        setSuccess(true);
        setMessage(params);
        setTimeout(() => {
            if (navigate === "backToTable") {
                rowBackClicked();
            } else {
                handleRowClick(modifiedData);
            }
            setSuccess(false);
            setIsOpen(false);
        }, 2000);
    };

    useEffect(() => {
        if (!user.isAdmin && !user.isMainOffice) {
            modifiedData['partnerId'] = user.partnerId?._id;
        }
    }, []);

    return (
        <div
            className="clientsTable"
            style={{
                width: "100%",
                padding: 0,
            }}
        >
            <Tabs
                style={{
                    maxWidth: "100%",
                    width: "100%",
                }}
            >
                <TabList
                    style={{
                        padding: "7px 20px 0 20px",
                        background: "white",
                        margin: "0",
                    }}
                >
                    <Tab>Users</Tab>
                </TabList>

                <TabPanel>
                    <div className="users" style={{padding: "0", marginTop: "20px"}}>
                        <div
                            className="usersUserListContainer"
                            style={{paddingBottom: ""}}
                        >
                            <MainModal
                                closable={true}
                                isOpen={isOpen}
                                setEntity={setUsers}
                                entity={users}
                                deleteFunc={handleDelete}
                                setAction={setAction}
                                setRowClicked={setRowClicked}
                                successEdit={successEdit}
                                success={success}
                                deleteButton={true}
                                setIsOpen={setIsOpen}
                                onRequestClose={closeModal}
                                closeModal={closeModal}
                                refetch={reFetch}
                                footer={true}
                                user={user}
                                endpoint="auth/register"
                                editEndpoint="user"
                                modifiedData={modifiedData}
                                setModifiedData={setModifiedData}
                                submitOrEdit={submitOrEdit}
                                content={
                                    <div className="flex gap-10 flex-wrap justify-between">
                                        <h3 className="usersTableRegisterClientFormTitle">
                                            {submitOrEdit === "submit"
                                                ? "Register New User"
                                                : "Edit User"}
                                        </h3>

                                        <UserRegisterFields
                                            submitOrEdit={submitOrEdit}
                                            onChange={(e) => handleChange(e)}
                                            partnerChange={handleChoosenPartner}
                                            modifiedData={modifiedData}
                                            partners={partners}
                                            setModifiedData={setModifiedData}
                                            user={user}
                                            getRoles={getRoles}
                                            message={message}
                                            success={success}
                                        />
                                    </div>
                                }
                            />
                            {rowClicked && (
                                <div
                                    className="flex items-center justify-between"
                                    style={{
                                        width: "calc(100% - 20px)",
                                        overflowY: "scroll",
                                        background: "white",
                                        padding: "10px",
                                        borderRadius: "8px 8px 0 0",
                                        marginRight: "auto",
                                    }}
                                >
                                    <PanelInfo
                                        modifiedData={modifiedData}
                                        handleUserEditInfo={handleUserEditInfo}
                                        success={success}
                                    />
                                </div>
                            )}

                            <div
                                className="clientsTableMaterialTableContainer"
                                style={{width: "100%"}}
                            >
                                <MainTable
                                    action={action}
                                    setAction={setAction}
                                    isSocketUpdatable={false}
                                    noBorderRadius={rowClicked}
                                    columns={rowClicked ? logColumns : mainColumns}
                                    requestURL={requestURL}
                                    tableContainerRef={logTableRef}
                                    tableHeight={
                                        rowClicked ? "calc(100vh - 330px)" : "calc(100vh - 186px)"
                                    }
                                    onRowClick={!rowClicked ? handleRowClick : secoundTableRowClick}
                                    renderTopToolbarCustomActions={
                                        rowClicked
                                            ? [
                                                {
                                                    type: "back",
                                                    name: "Back",
                                                    icon: <FaArrowLeft size={20}/>,
                                                    onClick: rowBackClicked,
                                                },
                                            ]
                                            : [
                                                {
                                                    type: "add",
                                                    name: "Add",
                                                    icon: <FaPlusCircle size={20}/>,
                                                    onClick: () => {
                                                        openModal("submit");
                                                    },
                                                },
                                            ]
                                    }
                                    toolbarActions={{
                                        search: true,
                                        range: false,
                                        advancedFilter: false,
                                        columnsFilter: true,
                                        columnsVisible: true,
                                        density: true,
                                        fullScreen: true,
                                    }}
                                />
                            </div>
                            {details && (
                                <UsersDetail
                                    usersDetail={usersDetail}
                                    setDetails={setDetails}
                                />
                            )}
                        </div>
                    </div>
                </TabPanel>
            </Tabs>
        </div>
    );
};

export default UsersTable;

