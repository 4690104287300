import React, {useEffect, useState} from "react";
import {FaSave} from "react-icons/fa";
import Button from "../../ReusableComponents/Button";
import {publicRequest} from "../../../utils/requestMethods";


const PasswordChange = () => {
    const [passwords, setPasswords] = useState({});
    const [error, setError] = useState({message: ''})
    const [success, setSuccess] = useState({message: ''})
    const [passwordShown, setPasswordShown] = useState(false);

    useEffect(() => {
        setPasswords({
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
        });
        setError({message: ''})
        setSuccess({message: ''})
    }, [])

    const handleInputOnChange = (e) => {
        setPasswords((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    }

    const validatePassword = (passwordInputValue) => {
        const uppercaseRegExp = /(?=.*?[A-Z])/;
        const lowercaseRegExp = /(?=.*?[a-z])/;
        const digitsRegExp = /(?=.*?[0-9])/;
        const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
        const minLengthRegExp = /.{8,}/;
        const passwordLength = passwordInputValue.length;
        const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
        const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
        const digitsPassword = digitsRegExp.test(passwordInputValue);
        const specialCharPassword = specialCharRegExp.test(passwordInputValue);
        const minLengthPassword = minLengthRegExp.test(passwordInputValue);

        let errMsg = "";
        if (passwordLength === 0) {
            errMsg = "Password is empty";
        } else if (!uppercasePassword) {
            errMsg = "At least one Uppercase";
        } else if (!lowercasePassword) {
            errMsg = "At least one Lowercase";
        } else if (!digitsPassword) {
            errMsg = "At least one digit";
        } else if (!specialCharPassword) {
            errMsg = "At least one Special Characters";
        } else if (!minLengthPassword) {
            errMsg = "At least minimum 8 characters";
        } else {
            errMsg = "";
        }

        if (errMsg.length) {
            setError({message: errMsg});
            return false;
        }
        return true;
    }

    const handleOnClick = async () => {
        setError({message: ''})
        setSuccess({message: ''})

        if (!validatePassword(passwords.newPassword)) {
            return;
        }

        const response = await publicRequest.post('/user/password/change', passwords);
        if (response.status === 200) {
            const results = response.data;
            if (results.status) {
                setPasswords({
                    oldPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                });
                setSuccess({message: results.message})
            } else {
                setError({message: results.message})
            }
        } else {
            setError({message: "Something wrong, try again!"})
        }
    }

    const togglePassword = () => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setPasswordShown(!passwordShown);
    };


    return (
        <>
            <div
                className="userSecurityInputs"
            >
                <label className="usersTableRegisterFormLabel">Old Password</label>
                <input
                    name="oldPassword"
                    type={passwordShown ? "text" : "password"}
                    value={passwords.oldPassword}
                    onChange={handleInputOnChange}
                    className="userSecurityFormInput"
                    style={{width: "calc(100% - 10px)"}}
                />
            </div>
            <div
                className="userSecurityInputs"
            >
                <label className="usersTableRegisterFormLabel">New Password</label>
                <input
                    name="newPassword"
                    type={passwordShown ? "text" : "password"}
                    value={passwords.newPassword}
                    onChange={handleInputOnChange}
                    className="userSecurityFormInput"
                    style={{width: "calc(100% - 10px)"}}
                />
            </div>
            <div
                className="userSecurityInputs"
            >
                <label className="usersTableRegisterFormLabel">New Password (Confirmation)</label>
                <input
                    name="confirmPassword"
                    type={passwordShown ? "text" : "password"}
                    value={passwords.confirmPassword}
                    onChange={handleInputOnChange}
                    className="userSecurityFormInput"
                    style={{width: "calc(100% - 10px)"}}
                />
            </div>
            <div style={{display: "flex", justifyContent: "end", alignItems: "center", marginTop: 5}}>
                <span>Show Password</span>
                <label className="custom-switch" style={{width: 40, height: 25, marginLeft: 5}}>
                    <input
                        onChange={togglePassword}
                        type="checkbox"
                        checked={passwordShown}
                    />
                    <span className="custom-slider-bigger custom-round"></span>
                </label>
            </div>
            <div className={'userSecurityInputs'}>
                <button
                    title={'Change Password'}
                    className={`rounded-btn`}
                    onClick={handleOnClick}
                >
                    <FaSave color="white" size={20}/>
                    <p>Change Password</p>
                </button>
            </div>
            <span style={{color: 'green', marginTop: 5}}>{success.message}</span>
            <span style={{color: 'red', marginTop: 5}}>{error.message}</span>
        </>
    )
}

export default PasswordChange