import {Paper} from "@material-ui/core";
import Moment from "moment";
import React from "react";
import {BsPrinterFill} from "react-icons/bs";
import {IoMdDownload} from "react-icons/io";
import {ClipLoader} from "react-spinners";
import {NumberFormatter} from "../../NumberFormat";
import ReusableStatusComponent from "../../ReusableStatusComponent";

function Details({ data, getDetails, loading, noPrint }) {
    return (
        <>
            <Paper
                variant="outlined"
                style={{ maxWidth: "100%", marginTop: "2em", paddingBottom: "0.5em" }}
            >
                <center>
                    <div
                        style={{
                            borderBottom: "1px solid lightgrey",
                            maxWidth: "95%",
                            marginBottom: "2em",
                            paddingTop: "0.5em",
                            paddingBottom: "0.5em",
                        }}
                    >
                        <span style={{ fontSize: "14px", fontWeight: "600" }}>Details - {data.ref_number}</span>
                    </div>
                </center>

                <div
                    style={{
                        display: "grid",
                        placeItems: "center",
                        margin: "0 2.5%",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            marginBottom: "20px",
                        }}
                    >
                        <div
                            style={{ display: "flex", flexDirection: "column", width: "25%" }}
                        >
                            <span
                                style={{
                                    fontWeight: "400",
                                    color: "#666666",
                                    fontSize: "12px",
                                    lineHeight: "1.5",
                                    textAlign: "center",
                                }}
                            >
                                Details
                            </span>
                            <span
                                style={{
                                    overflow: "hidden",
                                    fontWeight: "600",
                                    textOverflow: "ellipsis",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    fontSize: "14px",
                                    lineHeight: "1.5",
                                    textAlign: "center",
                                    wordBreak: "break-all",
                                }}
                            >
                                {data.details}
                            </span>
                        </div>
                        <div
                            style={{ display: "flex", flexDirection: "column", width: "25%" }}
                        >
                            <span
                                style={{
                                    fontWeight: "400",
                                    color: "#666666",
                                    fontSize: "12px",
                                    textAlign: "center",
                                    lineHeight: "1.5",
                                }}
                            >
                                Amount
                            </span>
                            <span
                                style={{
                                    overflow: "hidden",
                                    fontWeight: "600",
                                    textOverflow: "ellipsis",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    fontSize: "14px",
                                    lineHeight: "1.5",
                                    textAlign: "center",
                                }}
                            >
                                <NumberFormatter value={data.amount} />
                            </span>
                        </div>
                        <div
                            style={{ display: "flex", flexDirection: "column", width: "25%" }}
                        >
                            <span
                                style={{
                                    fontWeight: "400",
                                    color: "#666666",
                                    fontSize: "12px",
                                    textAlign: "center",
                                    lineHeight: "1.5",
                                }}
                            >
                                Balance
                            </span>
                            <span
                                style={{
                                    overflow: "hidden",
                                    fontWeight: "600",
                                    textOverflow: "ellipsis",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    fontSize: "14px",
                                    lineHeight: "1.5",
                                    textAlign: "center",
                                }}
                            >
                                <NumberFormatter value={data.balance} />
                            </span>
                        </div>
                        <div
                            style={{ display: "flex", flexDirection: "column", width: "25%" }}
                        >
                            <span
                                style={{
                                    fontWeight: "400",
                                    color: "#666666",
                                    fontSize: "12px",
                                    textAlign: "center",
                                    lineHeight: "1.5",
                                }}
                            >
                                Note
                            </span>
                            <span
                                style={{
                                    overflow: "hidden",
                                    fontWeight: "600",
                                    textOverflow: "ellipsis",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    fontSize: "14px",
                                    lineHeight: "1.5",
                                    textAlign: "center",
                                }}
                            >
                                {data.note}
                            </span>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            marginBottom: "20px",
                        }}
                    >
                        <div
                            style={{ display: "flex", flexDirection: "column", width: "25%" }}
                        >
                            <span
                                style={{
                                    fontWeight: "400",
                                    color: "#666666",
                                    fontSize: "12px",
                                    lineHeight: "1.5",
                                    textAlign: "center",
                                }}
                            >
                                User
                            </span>
                            <span
                                style={{
                                    overflow: "hidden",
                                    fontWeight: "600",
                                    textOverflow: "ellipsis",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    fontSize: "14px",
                                    lineHeight: "1.5",
                                    textAlign: "center",
                                    wordBreak: "break-all",
                                }}
                            >
                                {data.userId?.fullName}
                            </span>
                        </div>
                        <div
                            style={{ display: "flex", flexDirection: "column", width: "25%" }}
                        >
                            <span
                                style={{
                                    fontWeight: "400",
                                    color: "#666666",
                                    fontSize: "12px",
                                    textAlign: "center",
                                    lineHeight: "1.5",
                                }}
                            >
                                Partner
                            </span>
                            <span
                                style={{
                                    overflow: "hidden",
                                    fontWeight: "600",
                                    textOverflow: "ellipsis",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    fontSize: "14px",
                                    lineHeight: "1.5",
                                    textAlign: "center",
                                }}
                            >
                                {data.partnerId?.partnerName}
                            </span>
                        </div>
                        <div
                            style={{ display: "flex", flexDirection: "column", width: "25%" }}
                        >
                            <span
                                style={{
                                    fontWeight: "400",
                                    color: "#666666",
                                    fontSize: "12px",
                                    textAlign: "center",
                                    lineHeight: "1.5",
                                }}
                            >
                                Side
                            </span>
                            <span
                                style={{
                                    overflow: "hidden",
                                    fontWeight: "600",
                                    textOverflow: "ellipsis",
                                    fontSize: "14px",
                                    lineHeight: "1.5",
                                    textAlign: "center",
                                    color: data.side === "buy" ? "green" : "red",
                                    textTransform: "uppercase",
                                }}
                            >
                                {data.side}
                            </span>
                        </div>
                        <div
                            style={{ display: "flex", flexDirection: "column", width: "25%" }}
                        >
                            <span
                                style={{
                                    fontWeight: "400",
                                    color: "#666666",
                                    fontSize: "12px",
                                    textAlign: "center",
                                    lineHeight: "1.5",
                                }}
                            >
                                Type
                            </span>
                            <span
                                style={{
                                    overflow: "hidden",
                                    fontWeight: "600",
                                    textOverflow: "ellipsis",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    fontSize: "14px",
                                    lineHeight: "1.5",
                                    textAlign: "center",
                                    textTransform: "capitalize",
                                }}
                            >
                                {data.type}
                            </span>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            marginBottom: "20px",
                        }}
                    >
                        <div
                            style={{ display: "flex", flexDirection: "column", width: "25%" }}
                        >
                            <span
                                style={{
                                    fontWeight: "400",
                                    color: "#666666",
                                    fontSize: "12px",
                                    lineHeight: "1.5",
                                    textAlign: "center",
                                }}
                            >
                                Currency
                            </span>
                            <span
                                style={{
                                    overflow: "hidden",
                                    fontWeight: "600",
                                    textOverflow: "ellipsis",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    fontSize: "14px",
                                    lineHeight: "1.5",
                                    textAlign: "center",
                                    wordBreak: "break-all",
                                }}
                            >
                                {data.currency.symbol}
                            </span>
                        </div>
                        {data.clientId && (
                            <div
                                style={{ display: "flex", flexDirection: "column", width: "25%" }}
                            >
                                <span
                                    style={{
                                        fontWeight: "400",
                                        color: "#666666",
                                        fontSize: "12px",
                                        textAlign: "center",
                                        lineHeight: "1.5",
                                    }}
                                >
                                    Client
                                </span>
                                <span
                                    style={{
                                        overflow: "hidden",
                                        fontWeight: "600",
                                        textOverflow: "ellipsis",
                                        color: "rgba(0, 0, 0, 0.87)",
                                        fontSize: "14px",
                                        lineHeight: "1.5",
                                        textAlign: "center",
                                    }}
                                >
                                    {data.clientId?.firstName} {data.clientId?.lastName}
                                </span>
                            </div>
                        )}
                        <div
                            style={{ display: "flex", flexDirection: "column", width: "25%" }}
                        >
                            <span
                                style={{
                                    fontWeight: "400",
                                    color: "#666666",
                                    fontSize: "12px",
                                    textAlign: "center",
                                    lineHeight: "1.5",
                                }}
                            >
                                Created
                            </span>
                            <span
                                style={{
                                    overflow: "hidden",
                                    fontWeight: "600",
                                    textOverflow: "ellipsis",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    fontSize: "14px",
                                    lineHeight: "1.5",
                                    textAlign: "center",
                                }}
                            >
                                {Moment(data.createdAt).format("DD.MM.YYYY HH:mm")}
                            </span>
                        </div>
                        <div
                            className="flex flex-column items-center"
                            style={{ width: "25%" }}
                        >
                            <span
                                style={{
                                    fontWeight: "400",
                                    color: "#666666",
                                    fontSize: "12px",
                                    textAlign: "center",
                                    lineHeight: "1.5",
                                    width: "25%",
                                }}
                            >
                                Status
                            </span>
                            <ReusableStatusComponent
                                status={data.status}
                                centered={true}
                                smallFont={true}
                            />
                        </div>
                    </div>

                    {data.txId && (
                        <div
                            className="grid place-center w-full"
                            style={{ marginTop: "30px" }}
                        >
                            {loading ? (
                                <div className="grid place-center w-full h-full">
                                    <ClipLoader size={24} color="orange" />
                                </div>
                            ) : (
                                <span
                                    className="cursor-pointer"
                                    style={{ color: "#4f8ae8", fontSize: "12px" }}
                                    onClick={() => getDetails(data.type, data.txId)}
                                >
                                    View details
                                </span>
                            )}
                        </div>
                    )}
                    {data.status === 4 && !noPrint && (
                        <div
                            style={{
                                width: "100%",
                                position: "absolute",
                                bottom: "0em",
                                borderTop: "1px solid #d8d8d8",
                                left: "0",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                    width: "97%",
                                    borderBottom: "0",
                                    background: "#fff",
                                }}
                            >
                                <div
                                    style={{
                                        background: "#efefef",
                                        padding: "10px",
                                        borderRadius: "50%",
                                        margin: "5px 5px 5px 20px",
                                        width: "25px",
                                        height: "25px",
                                        fontSize: "25px",
                                    }}
                                    title="Decline"
                                    className="cursor-pointer"
                                    onClick={() => console.log("download")}
                                >
                                    <IoMdDownload color="#8a8a8a" />
                                </div>
                                <div
                                    style={{
                                        background: "#efefef",
                                        padding: "10px",
                                        borderRadius: "50%",
                                        margin: "5px 5px 5px 10px",
                                        width: "25px",
                                        height: "25px",
                                        fontSize: "25px",
                                    }}
                                    title="Decline"
                                    className="cursor-pointer"
                                    onClick={() => console.log("print")}
                                >
                                    <BsPrinterFill color="#8a8a8a" />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Paper>
        </>
    );
}

export default Details;

