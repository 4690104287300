import iconGenerator from "../../utils/iconGenerator";
import React from "react";
import {TbArrowsSort} from "react-icons/tb";

function CryptoOption({
                          chooseSymbol,
                          pairs,
                          setPairs,
                          pairPrice,
                          setPairPrice,
                          fee,
                          setFee,
                          pairFee,
                          setPairFee,
                          choosenOrderType,
                          currencyList,
                          reverseCurrency
                      }) {
    const handlePairsPriceChange = (event) => {
        const update = pairs.map((pair) => {
            if (pair.symbol === event.target.name) {
                pair.amount = event.target.value;
                pair.changed = true;
            } else {
                pair.changed = false;
            }
            return pair;
        });
        setPairs(update);
    };

    const handleFeeChange = (event) => {
        setFee({
            fee: event.target.value,
            manual: true,
        });

        if (!reverseCurrency) {
            currencyList.map((cur) => {
                cur.manualPrice = false;
                cur.manualRate = false;
            });
        }
    };

    const handleNetworkFeeChange = () => {
        setPairFee(!pairFee);
        currencyList.map((cur) => {
            cur.manualPrice = false;
            cur.manualRate = false;
        });
    };

    const handlePairPriceActiveRealPrice = () => {
        pairPrice.changed = false;
        pairPrice.manual = false;
        setPairPrice(pairPrice);
    };

    const handlePairPriceActiveForChange = (onFocus = true) => {
        pairPrice.changed = onFocus;
        setPairPrice(pairPrice);
    };

    const handlePairPriceChange = (event) => {
        setPairPrice({
            symbol: chooseSymbol,
            price: event.target.value,
            manual: true,
            changed: false,
        });
    };

    const revertPairs = () => {
        pairs.map((pair) => {
            pair.amount = 0;
        });
        setPairs(pairs.reverse());
    };

    return (
        <>
            <div className="secondSliderRightConOne">
                <div className="secondSlderFirstSelectWrapper">
                    <p
                        className="secondSlderFirstSelectCoinIconP"
                        style={{position: "relative", left: "40px"}}
                    >
                        {pairs[0]?.label}
                    </p>
                    <div className="secondSlderFirstSelectCoinContainer">
                        <>
                            <img
                                className="secondSlderFirstSelectCoinIcon"
                                src={iconGenerator(128, "color", pairs[0]?.symbol)}
                                alt=""
                            />
                            <span
                                className="secondSlderFirstSelectCoinSpan"
                                style={{position: "relative", left: "40px"}}
                            >
                {pairs[0]?.symbol}
              </span>
                        </>
                    </div>
                </div>
                <div className="secondSliderFirstInputWrapper">
                    <input
                        className="dashboardSliderSecondRightExchangeCurrencyValueSpan"
                        type="text"
                        name={pairs[0]?.symbol}
                        onFocus={(e) =>
                            setTimeout(() => {
                                e.target.select();
                            }, 100)
                        }
                        onChange={(event) => handlePairsPriceChange(event)}
                        value={pairs[0]?.amount}
                    />
                </div>
            </div>
            <div className="dashboardSliderSecondRightExchangeCurrencyTransferContainer">
                <TbArrowsSort
                    onClick={() => revertPairs()}
                    color="#fff"
                    size={30}
                    className="dashboardSliderFirstRightExchangeCurrencyTransferContainerIcon"
                />
                <div className="dashboardSliderSecondRightExchangeCurrencyTransferQty">
          <span className="dashboardSliderSecondRightExchangeCurrencyTransferQtySpan">
            Price:
          </span>
                    <div className="dashboardSliderSecondRightExchangeCurrencyTransferQtyValue">
                        <input
                            className="dashboardSliderSecondRightExchangeCurrencyValueSpan"
                            type="text"
                            onFocus={(event) => {
                                setTimeout(() => {
                                    event.target.select();
                                }, 100);

                                handlePairPriceActiveForChange(true);
                            }}
                            onBlur={(e) => {
                                handlePairPriceActiveForChange(false);
                            }}
                            onDoubleClick={(e) => {
                                handlePairPriceActiveRealPrice();
                            }}
                            onChange={handlePairPriceChange}
                            value={pairPrice.price}
                        />
                    </div>
                </div>
                <div className="dashboardSliderFirstRightExchangeCurrencyTransferFee">
          <span className="dashboardSliderFirstRightExchangeCurrencyTransferFeeSpan">
            Fee:
          </span>
                    <div className="dashboardSliderSecondRightExchangeCurrencyTransferFeeValue">
                        <input
                            type="text"
                            className="dashboardSliderSecondRightExchangeCurrencyValueSpan"
                            onFocus={(event) => {
                                setTimeout(() => {
                                    event.target.select();
                                }, 100);
                            }}
                            onDoubleClick={() => {
                                setFee({...fee, manual: false})
                            }
                            }
                            onChange={(event) => handleFeeChange(event)}
                            value={fee.fee}
                        />
                        %
                    </div>
                </div>
            </div>

            <div className="secondSliderRightConOne">
                <div className="secondSlderFirstSelectWrapper">
                    <p
                        className="secondSlderFirstSelectCoinIconP"
                        style={{position: "relative", left: "40px"}}
                    >
                        {pairs[1]?.label}
                    </p>
                    <div className="secondSlderFirstSelectCoinContainer">
                        <>
                            <img
                                className="secondSlderFirstSelectCoinIcon"
                                src={iconGenerator(128, "color", pairs[1]?.symbol)}
                                alt=""
                            />
                            <span
                                className="secondSlderFirstSelectCoinSpan"
                                style={{position: "relative", left: "40px"}}
                            >
                {pairs[1]?.symbol}
              </span>
                        </>
                    </div>
                </div>
                <div className="secondSliderFirstInputWrapper">
                    <input
                        className="dashboardSliderSecondRightExchangeCurrencyValueSpan"
                        type="text"
                        name={pairs[1]?.symbol}
                        readOnly={true}
                        // onFocus={(event) => event.target.select()}
                        // onChange={(event) => handlePairsPriceChange(event)}
                        value={pairs[1]?.amount}
                    />
                </div>
            </div>
            {choosenOrderType === "SELL" && (
                <label className="custom-checkbox-container">
                    Include fee on price
                    <input
                        type="checkbox"
                        checked={pairFee}
                        onChange={() => handleNetworkFeeChange()}
                    />
                    <span className="custom-checkmark"></span>
                </label>
            )}
        </>
    );
}

export default CryptoOption;

