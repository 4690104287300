import React from "react";
import "./partners.css";
import PartnersTable from "./PartnersTable/PartnersTable";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";

const Partners = () => {
    return (
        <div className="partners">
            {/*<PartnersTable />*/}
            <Tabs
                style={{
                    maxWidth: "100%",
                    width: "100%",
                }}
            >
                <TabList
                    style={{
                        padding: "7px 20px 0 20px",
                        background: "white",
                        margin: "0",
                    }}
                >
                    <Tab>Partners</Tab>
                </TabList>
                <TabPanel>
                    <PartnersTable/>
                </TabPanel>
            </Tabs>
        </div>
    );
};

export default Partners;
