import React, {useEffect, useState} from "react";
import "./networkModal.css";
import iconGenerator from "../../utils/iconGenerator";
import {Tab, Tabs, TabList, TabPanel} from "react-tabs";
import "react-tabs/style/react-tabs.css";

const NetworkModal = ({
                          setNetworkModal,
                          setChoosenNetwork,
                          choosenCrypto,
                          choosenOrderType,
                          setChoosenOrderType,
                          coinInfo,
                          choosenCryptoBNBPrice
                      }) => {
    const [activetab, setActiveTab] = useState(-1);
    const [networks, setNetwork] = useState([])

    useEffect(() => {
        const network = [
            {
                id: 2,
                title: "BNB",
                icon: "binance_favicon",
                value:
                    coinInfo.symbol === "USDT" ? Number(1).toFixed(3) : choosenCryptoBNBPrice,
                available: coinInfo.details.isBinanceAvailable,
            },
            {
                id: 3,
                title: "DEX",
                icon: "dex_favicon",
                value: coinInfo.symbol === "USDT" ? Number(1).toFixed(3) : 0,
                available: coinInfo.details.isDexAvailable,
            },
        ];
        setNetwork(network);
    }, [coinInfo, choosenCryptoBNBPrice])

    const handleClick = (value, networkTitle) => {
        if (value !== null) {
            setChoosenNetwork(networkTitle);
            setNetworkModal(false);
            window.scrollTo(0, document.body.scrollHeight);
        }
    };

    return (
        <>
            <div
                className="dashboardSliderSecondAddressModalAddressWrapper"
                style={{width: "100%", alignItems: "start"}}
            >
                <div className="flex items-center justify-center w-full">
                    <img
                        src={iconGenerator(128, "icon", `${coinInfo.symbol}`)}
                        alt=""
                        className="leftSidebarIcons"
                        style={{width: 70, height: 70}}
                    />
                    <div className="grid">
            <span
                className="networkModalCryptoHeader"
                style={{fontWeight: "bold"}}
            >
              {coinInfo.name}
            </span>
                        <span
                            className="networkModalCryptoHeader"
                            style={{fontSize: "14px"}}
                        >
              {coinInfo.symbol}
            </span>
                    </div>
                </div>
                <div
                    style={{
                        width: "100%",
                        borderTop: "1px solid #d2d2d2",
                        paddingTop: "30px",
                        display: "grid",
                        gap: "10px",
                    }}
                >
                    <Tabs
                        style={{
                            maxWidth: "100%",
                            width: "100%",
                        }}
                        selectedIndex={choosenOrderType === "BUY" ? 0 : 1}
                    >
                        <TabList
                            style={{
                                background: "white",
                                margin: "0",
                            }}
                        >
                            <Tab
                                style={{
                                    width: "46%",
                                    borderBottom:
                                        choosenOrderType === "BUY" ? "3px solid green" : "0",
                                }}
                                onClick={() => {
                                    setChoosenOrderType("BUY");
                                }}
                            >
                                BUY
                            </Tab>
                            <Tab
                                style={{
                                    width: "46%",
                                    borderBottom:
                                        choosenOrderType === "SELL" ? "3px solid red" : "0",
                                }}
                                onClick={() => {
                                    setChoosenOrderType("SELL");
                                }}
                            >
                                SELL
                            </Tab>
                        </TabList>

                        <TabPanel>
                            <div className="networkModalWrapper">
                                {networks.map((network) => (
                                    <>
                                        <div
                                            className="networkModalNetworkItem"
                                            key={network.id}
                                            style={
                                                !network.available
                                                    ? {opacity: "0.4", cursor: "not-allowed"}
                                                    : {}
                                            }
                                        >
                                            <div className="networkModalNetworkItemNetworkWrapper">
                                                <img
                                                    className="networkModalNetworkItemLogo"
                                                    src={require(`../../assets/${network.icon}.png`)}
                                                    alt=""
                                                />
                                                <span className="networkModalNetworkItemTitle">
                          {network.title}
                        </span>
                                            </div>
                                            <div className="networkModalNetworkItemRight">
                        <span className="networkModalNetworkItemRightSpan">
                          {network.value}
                        </span>
                                                {choosenOrderType === "BUY" ? (
                                                    <button
                                                        style={
                                                            !network.available
                                                                ? {opacity: "0.4", cursor: "not-allowed"}
                                                                : {}
                                                        }
                                                        onClick={() =>
                                                            handleClick(network.value, network.title)
                                                        }
                                                        className="networkModalNetworkItemRightButtonBuy"
                                                    >
                                                        {choosenOrderType}
                                                    </button>
                                                ) : (
                                                    <button
                                                        style={
                                                            network.value === null
                                                                ? {opacity: "0.4", cursor: "not-allowed"}
                                                                : {}
                                                        }
                                                        onClick={() =>
                                                            handleClick(network.value, network.title)
                                                        }
                                                        className="networkModalNetworkItemRightButtonSell"
                                                    >
                                                        {choosenOrderType}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="networkModalWrapper">
                                {networks.map((network) => (
                                    <>
                                        <div
                                            className="networkModalNetworkItem"
                                            key={network.id}
                                            style={
                                                !network.available
                                                    ? {opacity: "0.4", cursor: "not-allowed"}
                                                    : {}
                                            }
                                        >
                                            <div className="networkModalNetworkItemNetworkWrapper">
                                                <img
                                                    className="networkModalNetworkItemLogo"
                                                    src={require(`../../assets/${network.icon}.png`)}
                                                    alt=""
                                                />
                                                <span className="networkModalNetworkItemTitle">
                          {network.title}
                        </span>
                                            </div>
                                            <div className="networkModalNetworkItemRight">
                        <span className="networkModalNetworkItemRightSpan">
                          {network.value}
                        </span>
                                                {choosenOrderType === "BUY" ? (
                                                    <button
                                                        style={
                                                            !network.available
                                                                ? {opacity: "0.4", cursor: "not-allowed"}
                                                                : {}
                                                        }
                                                        onClick={() =>
                                                            handleClick(network.value, network.title)
                                                        }
                                                        className="networkModalNetworkItemRightButtonBuy"
                                                    >
                                                        {choosenOrderType}
                                                    </button>
                                                ) : (
                                                    <button
                                                        style={
                                                            network.value === null
                                                                ? {opacity: "0.4", cursor: "not-allowed"}
                                                                : {}
                                                        }
                                                        onClick={() =>
                                                            handleClick(network.value, network.title)
                                                        }
                                                        className="networkModalNetworkItemRightButtonSell"
                                                    >
                                                        {choosenOrderType}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </>
    );
};

export default NetworkModal;

