import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import iconGenerator from "../../../../utils/iconGenerator";
import { NumberFormatter } from "../../NumberFormat";

const CurrenciesList = ({
    currentStep,
    setCurrentStep,
    setSelectedCurrency,
    setDirection
}) => {
    const currencies = useSelector((state) => state.user.accounting);
    const [currencyList, setCurrencyList] = useState([]);

    useEffect(() => {
        const output = Object.values(currencies.reduce((acc, cur) => {
            const { currency, balance, pending_in, pending_out } = cur;
            const { symbol } = currency;
            const found = acc[symbol];
            if (!found) {
                acc[symbol] = { currency, balance, pending_in, pending_out };
            } else {
                acc[symbol] = {
                    currency,
                    balance: found.balance + balance,
                    pending_in: found.pending_in + pending_in,
                    pending_out: found.pending_out + pending_out,
                };
            }
            return acc;
        }, {}));
        setCurrencyList(output);
    }, [currencies])

    const handleCardClick = (item) => {
        setCurrentStep(currentStep + 1);
        setSelectedCurrency(item);
        setDirection('forward')
    }

    return (
        <div className='flex flex-column gap-10' style={{ width: 400, padding: 5, borderRadius: 12 }}>
            {currencyList.map((item, i) => (
                <div className="currencies-card" key={i} onClick={() => handleCardClick(item)}>
                    <div className='flex gap-10 items-center'>
                        <div className='currencies-card-img'>
                            <img src={iconGenerator(128, "icon", item.currency.symbol, true)}
                                alt={item.currency.symbol} />
                        </div>
                        <div className='flex flex-column gap-5 currencies-card-labels'>
                            <div className='currencies-card-title'>
                                {item.currency.name}
                            </div>
                            <div className='currencies-card-subtitle'>
                                {item.currency.symbol}
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-column gap-5 justify-center'>
                        <div className='currencies-card-balance'>
                            <NumberFormatter
                                value={item.balance}
                                suffix={` ${item.currency.symbol}`}
                            />
                        </div>
                        {/*<div className='currencies-card-to-euro'>*/}
                        {/*    <NumberFormatter*/}
                        {/*        value={item.balance}*/}
                        {/*        suffix={` ${item.currency.symbol}`}*/}
                        {/*    />*/}
                        {/*</div>*/}
                    </div>
                </div>
            ))}
        </div>

    );
}


export default CurrenciesList
