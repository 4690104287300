import React, { useEffect, useMemo, useRef, useState } from "react";
import "./partnersTable.css";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import MainModal from "../../ReusableComponents/MainModal";
import PartnerRegisterFields from "./PartnerRegisterFields";
import { publicRequest } from "../../../utils/requestMethods";
import { FaPencilAlt, FaPlusCircle, FaTrash } from "react-icons/fa";
import "react-phone-number-input/style.css";
import "react-tabs/style/react-tabs.css";
import MainTable from "../../ReusableComponents/Tables/MRT_Main";


const PartnersTable = () => {
  const user = useSelector((state) => state.user.currentUser);
  const [users, setUsers] = useState([]);
  const [register, setRegister] = useState(false);
  const [edit, setEdit] = useState(false);
  const [submitOrEdit, setSubmitOrEdit] = useState("");
  const [modalType, setModalType] = useState("");
  const [infoData, setInfoData] = useState(null);
  const [partners, setPartners] = useState(null);
  const [listOfCurrencies, setListOfCurrencies] = useState([]);
  const [getRoles, setGetRoles] = useState([]);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [action, setAction] = useState({ type: "", id: null, data: null });

  const [inputErrors, setInputErrors] = useState({})
  const [requiredFields, setRequiredFields] = useState([
    "partnerName", "email", "city", "country", "phone1", "username", "password", "fullName", "phone1", "roles"
  ])

  const tableRef = useRef()

  const columns = useMemo(
    () => [
      {
        accessorKey: "partnerName",
        header: "Name",
        size: 60,
      },
      {
        accessorKey: "city",
        header: "City",
        size: 60,
      },
      {
        accessorKey: "address",
        header: "Address",
        size: 60,
      },
      {
        accessorKey: "country",
        header: "Country",
        size: 60,
      },
      {
        accessorKey: "email",
        header: "Email",
        size: 60,
      },
      {
        accessorKey: "phone1",
        header: "Phone",
        size: 60,
      },
    ],
    []
  );

  const [partnerData, setPartnerData] = useState({
    partnerName: "",
    address: "",
    phone1: "",
    phone2: "",
    email: "",
    city: "",
    country: "",
    exchanges: {
      binance: {
        apiKey: "",
        privateKey: "",
      },

      dex: [],
    },
    user: {
      username: "",
      fullName: "",
      phone1: "",
      phone2: "",
      email: "",
      password: "",
      roles: "",
    },
    limits: {
      options: {
        printList: "",
      },
      accounting: {
        buy_limit: 0,
        sell_limit: 0,
      },
      crypto: {
        max_buy_limit: 10000,
        max_sell_limit: 10000,
      },
      after_work: {
        enabled: false,
        amountLimit: 0,
        percentageReadonly: false,
        open_time:
          "Tue Nov 29 2022 08:00:00 GMT+0100 (Central European Standard Time)",
        close_time:
          "Tue Nov 29 2022 20:00:00 GMT+0100 (Central European Standard Time)",
      },
    },
    percentagesMethod: {
      type: 1,
      fields: {
        partner: 0,
        expenses: 0,
      },
    },
    currencies: [],
  });
  const partnerId = user.partnerId;
  const [isOpen, setIsOpen] = useState(false);

  const openModal = (params) => {
    setInputErrors({})
    setIsOpen(true);
    setSubmitOrEdit(params);
  };

  const closeModal = () => {
    setIsOpen(false);
    setModalType("");
    setPartnerData({
      partnerName: "",
      address: "",
      phone1: "",
      phone2: "",
      email: "",
      city: "",
      country: "",
      exchanges: {
        binance: {
          apiKey: "",
          privateKey: "",
        },

        dex: [],
      },
      user: {
        username: "",
        fullName: "",
        phone1: "",
        phone2: "",
        email: "",
        password: "",
        roles: "",
      },
      limits: {
        options: {
          printList: "",
        },
        accounting: {
          buy_limit: 0,
          sell_limit: 0,
        },
        crypto: {
          max_buy_limit: 10000,
          max_sell_limit: 10000,
        },
        after_work: {
          enabled: false,
          amountLimit: 0,
          percentageReadonly: false,
          open_time:
            "Tue Nov 29 2022 08:00:00 GMT+0100 (Central European Standard Time)",
          close_time:
            "Tue Nov 29 2022 20:00:00 GMT+0100 (Central European Standard Time)",
        },
      },
      percentagesMethod: {
        type: 1,
        fields: {
          partner: 0,
          expenses: 0,
        },
      },
      currencies: [],
    });
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await publicRequest.get("roles/");
        setGetRoles(response.data.data);
      } catch (err) {
        console.log(err);
      }
    };

    const getPartnerData = async () => {
      try {
        const response = await publicRequest.get(`roles/${partnerId._id}`);
        setGetRoles(response.data);
      } catch (err) {
        console.log(err);
      }
    };

    // partnerId ? getPartnerData() :
    user.isAdmin ? getData() : getPartnerData();
  }, []);

  const handleUserEditInfo = (propsData) => {
    setIsOpen(true);
    setSubmitOrEdit("partnerEdit");
    setEdit(true);
    setRegister(false);
    setPartnerData({
      id: propsData._id,
      partnerName: propsData.partnerName,
      address: propsData.address,
      phone1: propsData.phone1,
      phone2: propsData.phone2,
      email: propsData.email,
      city: propsData.city,
      country: propsData.country,
      percentages: propsData.percentages,
      currencies: propsData.currencies,
      exchanges: propsData.exchanges,
      limits: propsData.limits,
      percentagesMethod: propsData.percentagesMethod,
    });
  };

  const handleDelete = (propsData) => {
    const deleteUser = async () => {
      try {
        await publicRequest.delete(`partner/${propsData._id}`);

        setAction({
          type: "deleted",
          id: propsData._id,
          data: {},
        });
      } catch (err) {
        console.log(err);
      }
    };

    Swal.fire({
      title: `Do you want to delete ${propsData.partnerName}?`,
      showDenyButton: true,
      confirmButtonText: "Accept",
      denyButtonText: `Refuse`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUser();
      }
    });
  };

  const handleChoosenPartner = (id) => {
    const choosen = partners.find((x) => x._id === id);
    setSubmitPartner(choosen._id);
  };

  const handleInfo = (propsData) => {
    setIsOpen(true);
    setModalType("info");
    setInfoData(propsData);
  };

  const successEdit = (params) => {
    setSuccess(true);
    setMessage(params);
    setTimeout(() => {
      setSuccess(false);
      setIsOpen(false);
    }, 2000);
  };

  return (
    <div className="users">
      <div
        className="usersUserListContainer"
        style={{ paddingBottom: "", marginTop: "20px" }}
      >
        {modalType === "info" ? (
          <MainModal
            closable={true}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            success={success}
            onRequestClose={closeModal}
            closeModal={closeModal}
            footer={false}
            content={
              <>
                <h3
                  className="usersTableRegisterClientFormTitle"
                  style={{ marginBottom: "20px" }}
                >
                  {infoData.partnerName} - Partner Info
                </h3>
                <div className="partnerDetailContainer">
                  <div className="partnerDetailsItem">
                    <span className="partnerDetailsHeader">ID: </span>
                    <span className="partnerDetailsSpanValue">
                      {infoData._id}
                    </span>
                  </div>
                  <div className="partnerDetailsItem">
                    <span className="partnerDetailsHeader">Partner Name: </span>
                    <span className="partnerDetailsSpanValue">
                      {infoData.partnerName}
                    </span>
                  </div>
                  <div className="partnerDetailsItem">
                    <span className="partnerDetailsHeader">Address: </span>
                    <span className="partnerDetailsSpanValue">
                      {infoData.address}
                    </span>
                  </div>
                  <div className="partnerDetailsItem">
                    <span className="partnerDetailsHeader">City: </span>
                    <span className="partnerDetailsSpanValue">
                      {infoData.city}
                    </span>
                  </div>
                  <div className="partnerDetailsItem">
                    <span className="partnerDetailsHeader">Country: </span>
                    <span className="partnerDetailsSpanValue">
                      {infoData.country}
                    </span>
                  </div>
                  <div className="partnerDetailsItem">
                    <span className="partnerDetailsHeader">Phone 1: </span>
                    <span className="partnerDetailsSpanValue">
                      {infoData.phone1}
                    </span>
                  </div>
                  <div className="partnerDetailsItem">
                    <span className="partnerDetailsHeader">Phone 2: </span>
                    <span className="partnerDetailsSpanValue">
                      {infoData.phone2}
                    </span>
                  </div>

                  <div
                    className="partnerDetailsItem"
                    style={{ marginTop: "10px" }}
                  >
                    <span className="partnerDetailsHeader">Email: </span>
                    <span className="partnerDetailsSpanValue">
                      {infoData.email}
                    </span>
                  </div>
                  <div className="partnerDetailsItem">
                    <span className="partnerDetailsHeader">White Label: </span>
                    <span className="partnerDetailsSpanValue">
                      {infoData.whiteLabel}
                    </span>
                  </div>
                  <div className="partnerDetailsItem">
                    <span className="partnerDetailsHeader">Created At: </span>
                    <span className="partnerDetailsSpanValue">
                      {infoData.createdAt}
                    </span>
                  </div>
                </div>
              </>
            }
          />
        ) : (
          <MainModal
            closable={true}
            isOpen={isOpen}
            setEntity={setUsers}
            entity={users}
            setAction={setAction}
            setIsOpen={setIsOpen}
            onRequestClose={closeModal}
            closeModal={closeModal}
            footer={true}
            user={user}
            endpoint="partner/register"
            editEndpoint="partner"
            successEdit={successEdit}
            success={success}
            partnerData={partnerData}
            setPartnerData={setPartnerData}
            submitOrEdit={submitOrEdit}
            inputErrors={inputErrors}
            setInputErrors={setInputErrors}
            requiredFields={requiredFields}
            content={
              <div className="flex gap-10 flex-wrap justify-between">
                <h3 className="usersTableRegisterClientFormTitle">
                  {submitOrEdit === "partnerSubmit"
                    ? "Register New Partner"
                    : "Edit Partner"}
                </h3>

                <PartnerRegisterFields
                  submitOrEdit={submitOrEdit}
                  onChange={(e) => handleChange(e)}
                  partnerData={partnerData}
                  setPartnerData={setPartnerData}
                  userInfo={user}
                  handleChoosenPartner={handleChoosenPartner}
                  partners={partners}
                  listOfCurrencies={listOfCurrencies}
                  setListOfCurrencies={setListOfCurrencies}
                  getRoles={getRoles}
                  success={success}
                  message={message}
                  inputErrors={inputErrors}
                  setInputErrors={setInputErrors}
                />
              </div>
            }
          />
        )}
        <div
          className="TitleAndButtonContainer"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div className="w-full" style={{ overflowX: "hidden" }}>
            <MainTable
              action={action}
              setAction={setAction}
              columns={columns}
              isSocketUpdatable={false}
              requestURL={"v2/partners"}
              tableContainerRef={tableRef}
              density={"comfortable"}
              tableHeight="calc(100vh - 186px)"
              renderTopToolbarCustomActions={[
                {
                  type: "add",
                  name: "Add",
                  icon: <FaPlusCircle size={20} />,
                  onClick: () => {
                    openModal("partnerSubmit");
                  },
                },
              ]}
              toolbarActions={{
                search: true,
                range: false,
                advancedFilter: false,
                columnsFilter: true,
                columnsVisible: true,
                density: true,
                fullScreen: true,
              }}
              renderRowActions={[
                {
                  type: "edit",
                  name: "Edit",
                  icon: <FaPencilAlt size={15} />,
                  onClick: handleUserEditInfo,
                },
                {
                  type: "delete",
                  name: "Delete",
                  icon: <FaTrash size={15} />,
                  onClick: handleDelete,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnersTable;

