import {createSlice} from "@reduxjs/toolkit";
import {publicRequest} from "../utils/requestMethods";

const adminRules = [
    {
        id: 1,
        value: "dashboard",
        label: "Dashboard",
        permissions: [
            {
                buySell: true,
            },
            {
                approve: true,
            },
        ],
    },
    {
        id: 2,
        value: "exchange",
        label: "Exchange",
        permissions: [
            {
                buySell: true,
            },
            {
                approve: true,
            },
        ],
    },
    {
        id: 3,
        value: "transfer",
        label: "Transfer",
        permissions: [
            {
                hasAccess: true,
            },
        ],
    },
    {
        id: 4,
        value: "transactions",
        label: "Transactions",
        permissions: [
            {
                viewAll: true,
            },
            {
                viewOwn: true,
            },
            {
                viewOther: true,
            },
        ],
    },
    {
        id: 5,
        value: "accounting",
        label: "Accounting",
        permissions: [
            {
                hasAccess: true,
            },
        ],
    },
    // {
    //     id: 6,
    //     value: "wallet",
    //     label: "Wallet",
    //     permissions: [
    //         {
    //             hasAccess: false,
    //         },
    //     ],
    // },
    {
        id: 7,
        value: "clients",
        label: "Clients",
        permissions: [
            {
                viewAll: true,
            },
            {
                viewOwn: true,
            },
            {
                viewOther: true,
            },
        ],
    },
    {
        id: 8,
        value: "users",
        label: "Users",
        permissions: [
            {
                viewAll: true,
            },
            {
                viewOwn: true,
            },
            {
                viewOther: true,
            },
        ],
    },
    {
        id: 9,
        value: "partners",
        label: "Partners",
        permissions: [
            {
                hasAccess: true,
            },
        ],
    },
    // {
    //     id: 10,
    //     value: "messages",
    //     label: "Messages",
    //     permissions: [
    //         {
    //             hasAccess: false,
    //         },
    //     ],
    // },
    {
        id: 11,
        value: "settings",
        label: "Settings",
        permissions: [
            {
                hasAccess: true,
            },
        ],
    },
    {
        id: 12,
        value: "globalSettings",
        label: "Global Settings",
        parent: "settings",
        permissions: [
            {
                hasAccess: true,
            },
        ],
    },
    {
        id: 13,
        value: "percentages",
        label: "Percentage",
        parent: "settings",
        permissions: [
            {
                hasAccess: true,
            },
        ],
    },
    {
        id: 14,
        value: "currency",
        label: "Currency",
        parent: "settings",
        permissions: [
            {
                hasAccess: true,
            },
        ],
    },
    {
        id: 15,
        value: "coins",
        label: "Coins",
        parent: "settings",
        permissions: [
            {
                hasAccess: true,
            },
        ],
    },
    {
        id: 16,
        value: "rules",
        label: "Rules",
        parent: "settings",
        permissions: [
            {
                hasAccess: true,
            },
        ],
    },

    {
        id: 17,
        value: "back",
        label: "Back",
        parent: "settings",
    },
];

const userSlice = createSlice({
    name: "user",
    initialState: {
        currentUser: null,
        accounting: [],
        currencies: [],
        percentages: [],
        externalFee: {},
        params: {},
        isFetching: false,
        menu: null,
        settings: null,
        error: false,
    },
    reducers: {
        loginStart: (state) => {
            state.isFetching = true;
        },
        loginSuccess: (state, action) => {
            state.isFetching = false;
            state.error = false;
            state.currentUser = action.payload;
            state.accounting = state.currentUser.details.accounts;
            state.currencies = state.currentUser.details.currencies;
            state.params = state.currentUser.params;
            state.externalFee = state.currentUser.details.externalFee;
            state.percentages = state.currentUser?.partnerId?.percentages ?? [];

            if (state.currentUser.isAdmin) {
                state.menu = adminRules.filter((rr) => rr.parent === undefined);
                state.settings = adminRules.filter((ar) => ar.parent === "settings");
            } else {
                state.menu = state.currentUser.roles.rules.filter((mm) => {
                    let hasAccess = mm.permissions.filter((mp) => {
                        let flag = Object.entries(mp).map((access) => {
                            return access[1];
                        });

                        return !!flag[0];
                    });

                    if (hasAccess.length) {
                        if (mm.parent === undefined) {
                            return mm;
                        }
                    }

                    return undefined;
                });

                state.settings = state.currentUser.roles.rules.filter((mm) => {
                    let hasAccess = mm.permissions.filter((mp) => {
                        let flag = Object.entries(mp).map((access) => {
                            return access[1];
                        });

                        return !!flag[0];
                    });

                    if (hasAccess.length) {
                        if (mm.parent === "settings") {
                            return mm;
                        }
                    }

                    return undefined;
                });
                if (state.settings.length) {
                    state.settings.push({
                        id: 6,
                        value: "back",
                        label: "Back",
                    });
                }
            }
        },
        loginFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        update2FAAuth: (state, action) => {
            console.log(action.payload);
            state.currentUser.secret = action.payload;
        },
        updateAccounting: (state, action) => {
            state.accounting = action.payload;
        },
        updatePercentages: (state, action) => {
            state.currentUser.partnerId.percentages = action.payload;
        },
        updateCurrencies: (state, action) => {
            state.currencies = action.payload;
        },
        updateExFee: (state, action) => {
            state.externalFee = action.payload;
        },
        updateSorting: (state, action) => {
            state.currentUser.options.dashboard.coins = action.payload;
            publicRequest.patch('user/sorting/coins', {list: action.payload});
        },
        updatePairSorting: (state, action) => {
            publicRequest.post(
                "exchanges/exchange/pairs/reorder",
                {
                    order: action.payload
                }
            );
            state.currentUser.options.exchange.pairs = action.payload;
        },
        reFetch: (state, action) => {
            if (action.payload === undefined) {
                return;
            }
            state.currentUser.details = action.payload;
            state.accounting = action.payload.accounts;
            state.currencies = action.payload.currencies;
            state.externalFee = action.payload.externalFee;
            state.percentages = action.payload.percentages;
        },
        logout: (state) => {
            state.currentUser = null;
            state.error = false;
            state.isFetching = false;
            state.menu = null;
            state.settings = null;
            state.accounting = [];
            state.externalFee = {}
        },
    },
});

export const {
    loginStart,
    update2FAAuth,
    updateAccounting,
    updatePercentages,
    updateExFee,
    updateSorting,
    updatePairSorting,
    reFetch,
    loginSuccess,
    loginFailure,
    logout,
} = userSlice.actions;

export default userSlice.reducer;

