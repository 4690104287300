import React, {useLayoutEffect, useState} from "react";
import {FaCheck, FaTimes} from "react-icons/fa";
import Button from "../..//Button";
import {ClipLoader} from "react-spinners";
import {ImCancelCircle} from "react-icons/im";
import generateUrl from "../../../../utils/explorer";
import Moment from "moment";
import Content from "./Content";
import {IoMdDownload} from "react-icons/io";
import {BsPrinterFill} from "react-icons/bs";
import {NumberFormatter} from "../..//NumberFormat";
import {cryptoPrint} from "../../../../utils/templates/crypto";
import {CryptoPdf} from "../../../../utils/templates/pdfs/crypto"
import {ApproveButton} from "../../Status/ApproveButton";
import {PDFDownloadLink} from "@react-pdf/renderer";
import LedgerSteps from "../../LedgerSteps";
import {publicRequest} from "../../../../utils/requestMethods";
import {checkForConnection} from "../../../../utils/ledger";
import {useSelector} from "react-redux";
import Swal from "sweetalert2";


function CryptoPopupInfo({
    data,
    setStep,
    step,
    loading,
    setLoading,
    setIsOpenPopUp,
    denyRequest,
    noApprove,
    setAction
}) {
    const user = useSelector((state) => state.user.currentUser);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [transaction, setTransaction] = useState({});
    const [tableLoading, setTableLoading] = useState(false);
    const [walletAddress, setWalletAddress] = useState("");

    useLayoutEffect(() => {
        localStorage.setItem("orderId", btoa((JSON.stringify({ id: data._id, type: 'crypto' }))))
    }, [data])

    const connectLedger = async (id) => {
        if (data.exchange !== "DEX" || data.side.toLowerCase() === "buy") {
            Swal.fire({
                title: `Are you sure you want to approve this transaction?`,
                icon: 'question',
                showDenyButton: true,
                confirmButtonText: "Yes",
                denyButtonText: `Cancel`,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    setLoading(true);
                    setTableLoading(true);
                    await publicRequest.post("/transactions/approval/" + id)
                        .then((response) => {
                            if (response.status === 200 && response.data.status) {
                                setSuccess(true);
                                setStep(4);
                                setAction({
                                    type: "edited",
                                    id: response.data.message._id,
                                    data: response.data.message,
                                });
                                setTimeout(() => {
                                    setIsOpenPopUp(false);
                                    setTableLoading(false);
                                }, 2000);
                            } else {
                                setStep(5);
                                setError(response.data.message);
                            }

                        }).catch((error) => {
                            console.log(error);
                            setStep(5);
                            setError(error.data.message);
                        });
                    setLoading(false);
                    setTableLoading(false);
                }
            })

        } else {
            setLoading(true);
            setTableLoading(true);
            const { address, connected } = await checkForConnection(true, data);

            if (connected === true) {
                setWalletAddress(address);
                setLoading(false);
                setTableLoading(false);
                setStep(2);
            } else {
                setLoading(false);
                setTableLoading(false);
                setStep(1);
            }
        }
    };

    return (
        <div style={{ marginTop: "20px", maxHeight: "80vh", overflowY: "scroll" }}>
            {step === 1 || step === 2 ? (
                <LedgerSteps step={step} setStep={setStep} loading={loading} data={data} setLoading={setLoading}
                    connectLedger={connectLedger} walletAddress={walletAddress}
                    setTransaction={setTransaction} setAction={setAction} setError={setError} />
            ) : step === 3 ? (
                <>
                    <div
                        className="flex flex-column items-center justify-center w-full h-full"
                        style={{ position: "absolute", top: "-6%", left: "0" }}
                    >
                        <FaCheck
                            size={40}
                            color="white"
                            style={{
                                background: "#25da25",
                                padding: "10px",
                                borderRadius: "50%",
                            }}
                        />
                        <div
                            style={{
                                marginTop: "20px",
                                textAlign: "center",
                                fontSize: "12px",
                                display: "grid",
                                color: "#9c9c9c",
                            }}
                        >
                            <span style={{ fontWeight: "600" }}>
                                Transaction has been broadcast.
                            </span>
                            <span
                                style={{
                                    padding: "0 85px",
                                }}
                            >
                                Transaction has been broadcast successfully.
                            </span>
                            <span
                                className="cursor-pointer"
                                style={{ color: "#4f8ae8", marginTop: "25px" }}
                                onClick={() => {
                                    setStep(4);
                                }}
                            >
                                View details
                            </span>
                        </div>
                        {loading ? (
                            <div
                                style={{
                                    position: "absolute",
                                    bottom: "50px",
                                    width: "100%",
                                    left: "50%",
                                }}
                            >
                                <ClipLoader size={24} color="orange" />
                            </div>
                        ) : (
                            <div
                                style={{
                                    position: "absolute",
                                    bottom: "50px",
                                    width: "100%",
                                    left: "4.5%",
                                }}
                            >
                                <Button
                                    title="Done"
                                    onClick={() => {
                                        setStep(-1);
                                        setIsOpenPopUp(false);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </>
            ) : step === 4 ? (
                <>
                    <div
                        className="flex flex-column items-center justify-center w-full h-full"
                        style={{ position: "absolute", top: "-6%", left: "0" }}
                    >
                        <FaCheck
                            size={40}
                            color="white"
                            style={{
                                background: "#25da25",
                                padding: "10px",
                                borderRadius: "50%",
                            }}
                        />
                        <div
                            style={{ fontWeight: "300", fontSize: "14px", margin: "5px 0" }}
                        >
                            Success transfer
                        </div>
                        <div>
                            <NumberFormatter value={data.amount} />{" "}
                            {data.symbol.split("/")[0]}
                        </div>
                        <div
                            style={{
                                background: "#f7f8fa",
                                padding: "20px 20px",
                                marginTop: "20px",
                                width: "60%",
                                textAlign: "left",
                                fontSize: "14px",
                                display: "grid",
                                color: "#9c9c9c",
                                gap: "20px",
                            }}
                        >
                            <span style={{ display: "grid", gap: "5px" }}>
                                Transfer account:{" "}
                                <a
                                    style={{ color: "#4f8ae8", textDecoration: "none" }}
                                    href={generateUrl(data.network, "address", walletAddress)}
                                    target="_blank"
                                >
                                    {walletAddress}
                                </a>
                            </span>
                            <span style={{ display: "grid", gap: "5px" }}>
                                Receiving account:{" "}
                                <a
                                    style={{ color: "#4f8ae8", textDecoration: "none" }}
                                    href={generateUrl(
                                        data.network,
                                        "address",
                                        data.address_to?.address
                                    )}
                                    target="_blank"
                                >
                                    {data.address_to?.address}
                                </a>
                            </span>
                        </div>

                        <div
                            style={{
                                padding: "20px 20px",
                                marginTop: "20px",
                                width: "60%",
                                textAlign: "left",
                                fontSize: "14px",
                                display: "grid",
                                color: "#9c9c9c",
                                gap: "20px",
                            }}
                        >
                            <span>
                                Transaction ID:{" "}
                                <a
                                    style={{
                                        color: "#4f8ae8",
                                        textDecoration: "none",
                                        wordBreak: "break-word",
                                    }}
                                    href={generateUrl(
                                        data.network,
                                        "transaction",
                                        transaction.txId
                                    )}
                                    target="_blank"
                                >
                                    {transaction.txId}
                                </a>
                            </span>

                            <span>
                                Transaction Time:{" "}
                                {Moment(transaction.createdAt).format("DD.MM.YYYY HH:mm")}
                            </span>
                        </div>
                    </div>
                </>
            ) : step === 5 ? (
                <>
                    <div
                        className="flex flex-column items-center justify-center w-full h-full"
                        style={{ position: "absolute", top: "-6%", left: "0" }}
                    >
                        <FaTimes
                            size={30}
                            color="white"
                            style={{
                                background: "red",
                                padding: "10px",
                                borderRadius: "50%",
                            }}
                        />
                        <div
                            style={{ fontWeight: "300", fontSize: "14px", margin: "5px 0" }}
                        >
                            The request has been denied
                        </div>

                        {error && (
                            <div
                                style={{ fontWeight: "300", fontSize: "14px", margin: "5px 0" }}
                            >
                                {error.toString()}
                            </div>
                        )}
                    </div>
                </>
            ) : loading ? (
                <div
                    className="grid h-full w-full place-center"
                    style={{ position: "absolute", top: "-20px", left: 0 }}
                >
                    <ClipLoader color="orange" size={30} />
                </div>
            ) : (
                <Content data={data} />
            )}

            {data.status === 4 && (
                <div
                    style={{
                        width: "100%",
                        position: "sticky",
                        bottom: "-5px",
                        borderTop: "1px solid #d8d8d8",
                        left: "0",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            width: "100%",
                            borderBottom: "0",
                            background: "#fff",
                        }}
                    >
                        <div style={{
                            background: "#efefef",
                            padding: "10px",
                            borderRadius: "50%",
                            margin: "5px 5px 5px 20px",
                            width: "25px",
                            height: "25px",
                            fontSize: "25px",
                        }}
                            title="Download"
                            className="cursor-pointer">
                            {data && (
                                <PDFDownloadLink document={<CryptoPdf data={data} />}
                                    fileName={`${data.order_number}.pdf`}>
                                    {({ blob, url, loading, error }) => (<IoMdDownload color="#8a8a8a" />)}
                                </PDFDownloadLink>
                            )}
                        </div>
                        <div
                            style={{
                                background: "#efefef",
                                padding: "10px",
                                borderRadius: "50%",
                                margin: "5px 5px 5px 10px",
                                width: "25px",
                                height: "25px",
                                fontSize: "25px",
                            }}
                            title="Print"
                            className="cursor-pointer"
                            onClick={async () => {
                                await cryptoPrint(data);
                            }}
                        >
                            <BsPrinterFill color="#8a8a8a" />
                        </div>
                    </div>
                </div>
            )}

            {step === -1 && !loading && data.status === 1 && !noApprove && data.mode === "AUTO" ? (
                data.partner_id._id === user?.partnerId?._id ? (
                    <div
                        style={{
                            width: "100%",
                            position: "sticky",
                            bottom: "-5px",
                            borderTop: "1px solid #d8d8d8",
                            left: "0",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                width: "100%",
                                borderBottom: "0",
                                background: "#fff",
                            }}
                        >
                            <div
                                style={{
                                    background: "#efefef",
                                    padding: "10px",
                                    borderRadius: "50%",
                                    margin: "5px 5px 5px 20px",
                                    width: "25px",
                                    height: "25px",
                                    fontSize: "25px",
                                }}
                                title="Decline"
                                className="cursor-pointer"
                                onClick={() => denyRequest(data._id)}
                            >
                                <ImCancelCircle color="red" />
                            </div>
                            <ApproveButton
                                data={data}
                                checkVisibility={true}
                                handleOnClick={() => connectLedger(data._id)}
                                type={'transfer'}
                            />
                        </div>
                    </div>
                ) : (<></>)

            ) : step === -1 && !loading && data.status === 1 && !noApprove && data.mode !== "AUTO" ? (
                <div
                    style={{
                        width: "100%",
                        position: "sticky",
                        bottom: "-5px",
                        borderTop: "1px solid #d8d8d8",
                        left: "0",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            width: "100%",
                            borderBottom: "0",
                            background: "#fff",
                        }}
                    >
                        <div
                            style={{
                                background: "#efefef",
                                padding: "10px",
                                borderRadius: "50%",
                                margin: "5px 5px 5px 20px",
                                width: "25px",
                                height: "25px",
                                fontSize: "25px",
                            }}
                            title="Decline"
                            className="cursor-pointer"
                            onClick={() => denyRequest(data._id)}
                        >
                            <ImCancelCircle color="red" />
                        </div>
                        <ApproveButton
                            data={data}
                            checkVisibility={true}
                            handleOnClick={() => connectLedger(data._id)}
                            type={'transfer'}
                        />
                    </div>
                </div>
            ) : <></>
            }
        </div >
    );
}

export default CryptoPopupInfo;


