import {FaTimes} from "react-icons/fa";
import React from "react";

const Failed = ({type, message = ""}) => {

    return (
        <div className="flex flex-column items-center justify-center content-center-element">
            <FaTimes
                size={40}
                color="white"
                style={{
                    background: "red",
                    padding: "10px",
                    borderRadius: "50%",
                }}
            />
            <div style={{fontWeight: "300", fontSize: "14px", margin: "5px 0"}}>
                Failed {type}

            </div>
            <div style={{fontWeight: "300", fontSize: "14px", margin: "5px 0"}}>
                {message}
            </div>
        </div>
    )
}

export default Failed;
