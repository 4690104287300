import React, {useEffect, useMemo, useRef, useState} from "react";
import "./Table.css";
import moment from "moment";
import Moment from "moment";
import iconGenerator from "../../utils/iconGenerator";
import {NumberFormatter} from "../ReusableComponents/NumberFormat";
import ReusableStatusComponent from "../ReusableComponents/ReusableStatusComponent";
import MainTable from "../ReusableComponents/Tables/MRT_Main";
import {NumericFormat} from "react-number-format";
import CryptoPopupInfo from "../ReusableComponents/PopUpInfo/Crypto/CryptoPopupInfo";
import {publicRequest} from "../../utils/requestMethods";
import MainModal from "../ReusableComponents/MainModal";
import Swal from "sweetalert2";
import {MdOutlineHdrAuto} from "react-icons/md"
import {BiExport} from "react-icons/bi";
import qs from 'qs';
import {useSelector} from "react-redux";

function DashboardTable({popUpData, customHeight, scanData, setScanData}) {
    const tableContainerRef = useRef(null);

    const [walletAddress, setWalletAddress] = useState("");
    const [transaction, setTransaction] = useState({});
    const [step, setStep] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [rejected, setRejected] = useState(false);
    const [success, setSuccess] = useState(false);
    const [fail, setFail] = useState(false);
    const [action, setAction] = useState({type: "", id: null, data: null});
    const [isOpenPopUp, setIsOpenPopUp] = useState(false);
    const params = useSelector((state) => state.user.params);

    useEffect(() => {
        const getScanData = async () => {
            try {

                const response = await publicRequest.get(`/transactions/${scanData.id}`);
                if (response.status === 200) {
                    popUpData.current = response.data;
                    setIsOpenPopUp(true);
                    setScanData({});
                }
            } catch (e) {
                console.error(e);
            }
        };

        if (scanData?.type === 'crypto') {
            getScanData();
        }
    }, [scanData]);


    const denyRequest = async (id) => {
        Swal.fire({
            title: `Are you sure you want to deny this transaction?`,
            icon: 'question',
            showDenyButton: true,
            confirmButtonText: "Yes",
            denyButtonText: `Cancel`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);
                const response = await publicRequest.post("/transactions/declined/" + id);
                setLoading(false);
                setStep(5);
                setAction({
                    type: "edited",
                    id: response.data._id,
                    data: response.data,
                });
                setTimeout(() => {
                    setTableLoading(false);
                }, 2000);
            }
        })
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: "order_number",
                header: "ID",
                size: 50,
                muiTableHeadCellProps: () => ({
                    sx: {
                        paddingLeft: "25px",
                    },
                }),
                muiTableBodyCellProps: () => ({
                    sx: {
                        paddingLeft: "25px",
                    },
                }),
                Cell: ({cell}) => {
                    return <span>{cell.getValue()}</span>;
                },
                filterFn: 'startsWith',
            },
            {
                accessorKey: "createdAt",
                header: "Time",
                muiTableBodyCellProps: {
                    align: "center",
                },
                size: 120,
                Cell: ({cell}) => {
                    return (
                        <span>{moment(cell.getValue()).format("DD.MM.YYYY HH:mm")}</span>
                    );
                },
            },
            {
                accessorKey: "user_id",
                header: "User",
                muiTableBodyCellProps: {
                    align: "center",
                },
                size: 80,
                accessorFn: (e) => (e.user_id?.fullName || e.user_confirmation_id?.fullName),
                filterSelectOptions: params.filters.global.users.filterSelectOptions,
                filterVariant: params.filters.global.users.filterVariant,
            },
            {
                accessorKey: "partner_id",
                header: "Partner",
                filterable: false,
                muiTableBodyCellProps: {
                    align: "center",
                },
                size: 60,
                Cell: ({cell}) => {
                    return cell.getValue()?.partnerName;
                },
                filterSelectOptions: params.filters.global.partners.filterSelectOptions,
                filterVariant: params.filters.global.partners.filterVariant,
            },
            {
                accessorKey: "side",
                header: "Type",
                muiTableBodyCellProps: {
                    align: "center",
                },
                size: 50,
                filterSelectOptions: params.filters.transactions.crypto.type.filterSelectOptions,
                filterVariant: params.filters.transactions.crypto.type.filterVariant,
                accessorFn: (e) => (
                    <span
                        style={{
                            color: e.side === "buy" ? "green" : "red",
                        }}
                    >
                        {e.side.toUpperCase()}
                        {e.mode === "AUTO" &&
                            <MdOutlineHdrAuto color="green" size={13} style={{position: "relative", top: "-7px"}}/>}
                    </span>
                ),
            },
            {
                accessorKey: "symbol",
                header: "Symbol",
                muiTableBodyCellProps: {
                    align: "center",
                },
                size: 70,
                Cell: ({cell}) => {
                    let symbol = cell.getValue()?.split("/")[0];
                    return (
                        <div
                            style={{
                                display: "grid",
                                gridAutoFlow: "column",
                                gridColumnGap: "5px",
                            }}
                        >
                            <img
                                width={22}
                                src={iconGenerator(200, null, symbol)}
                                alt={symbol}
                            />
                            <span>{symbol}</span>
                        </div>
                    );
                },
                filterSelectOptions: params.filters.transactions.crypto.coins.filterSelectOptions,
                filterVariant: params.filters.transactions.crypto.coins.filterVariant,
            },
            {
                accessorKey: "quantity_sent",
                header: "Amount",
                muiTableBodyCellProps: {
                    align: "center",
                },
                size: 90,
                accessorFn: (e) =>
                    <div className="flex-column gap-5">
                        {e.quantity_sent >= 1000 || e.quantity >= 1000 ?
                            <NumberFormatter
                                value={e.quantity_sent || e.quantity}
                            />
                            :
                            (e.quantity_sent || e.quantity)}
                        {(e.symbol.split("/")[0] !== "USDT" && e.symbol.split("/")[0] !== "BUSD") && (
                            <p style={{marginBottom: 0, fontSize: "13px", color: "#989898"}}>≈ <NumberFormatter
                                value={e.quantity * e.last_price_filled}/> <small>USD</small></p>
                        )}
                    </div>,
            },
            {
                accessorKey: "last_price_filled",
                header: "Price",
                size: 80,
                Cell: ({cell}) => {
                    return <NumericFormat
                        displayType={"text"}
                        value={cell.getValue()}
                        suffix=" USD"
                        thousandSeparator=","
                        decimalSeparator="."
                    />;
                }
            },
            {
                accessorKey: "value_fee",
                header: "Fee",
                muiTableBodyCellProps: {
                    align: "center",
                },
                size: 50,
                Cell: ({cell}) => {
                    return (
                        <NumberFormatter
                            style={{color: cell.getValue()?.manual ? "red" : "black"}}
                            value={cell.getValue()?.fee}
                            suffix=" %"
                        />
                    );
                },

            },
            {
                accessorKey: "fiat_payment",
                header: "Total Amount",
                muiTableBodyCellProps: {
                    align: "center",
                },
                size: 110,
                Cell: ({cell}) => {
                    return (
                        <NumberFormatter
                            style={{color: cell.getValue()?.manual ? "red" : "black"}}
                            value={cell.getValue()}
                            suffix=" EUR"
                        />
                    );
                },
            },
            {
                accessorKey: "exchange_rate",
                header: "FX Rate x Paid",
                muiTableBodyCellProps: {
                    align: "center",
                },
                minSize: 190,
                accessorFn: (e) =>
                    (e.status === 1 || e.status === 5) && e.mode === "AUTO" ? (<></>) :
                        <div>
                            {e.exchange_rate?.map((singleObject, i) => (
                                <div
                                    key={i}
                                    className={"fx-rate-flex w-full"}

                                >
                                    <NumberFormatter
                                        style={{
                                            color: singleObject.manualRate ? "red" : "black",
                                        }}
                                        value={singleObject.rate}
                                        customDecimal={singleObject.symbol.toLowerCase() === "usd" || singleObject.symbol.toLowerCase() === "eur" ? 4 : 2}
                                    />
                                    <span>x</span>
                                    <NumberFormatter
                                        style={{
                                            color: singleObject.manualPrice ? "red" : "black",
                                            position: "relative",
                                            left: "3px"
                                        }}
                                        value={singleObject.price}
                                    />
                                    <span>{singleObject.symbol}</span>
                                </div>
                            ))}
                        </div>
            },
            {
                accessorKey: "status",
                header: "Status",
                muiTableBodyCellProps: {
                    align: "center",
                },
                maxSize: 100,
                filterSelectOptions: [
                    {text: "Pending", value: "1"},
                    {text: "Approved", value: "2"},
                    {text: "In Progress", value: "3"},
                    {text: "Completed", value: "4"},
                    {text: "Declined", value: "5"},
                ],
                filterVariant: "multi-select",
                Cell: ({cell}) => {
                    return <ReusableStatusComponent status={cell.getValue()}/>;
                },
            },
        ],
        []
    );

    const handleInfo = (data) => {
        popUpData.current = data;
        setStep(-1);
        setLoading(false);
        setIsOpenPopUp(true);
    };

    const [tableButtons, setTableButtons] = useState([]);
    const user = useSelector((state) => state.user.currentUser);
    useEffect(() => {
        if (user.isAdmin || user.isMainOffice) {
            tableButtons.push({
                type: "export",
                name: "Export",
                icon: <BiExport size={20}/>,
                onClick: handleExport,
            });
            setTableButtons(tableButtons);
        }
    }, []);

    const handleExport = async () => {
        const filters = tableContainerRef.current.getState().columnFilters;
        const response = await publicRequest.get(`/v2/reports/crypto?${qs.stringify(filters)}`, {responseType: 'blob'});
        const url = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        const date = new Date();
        link.download = `Report_${Moment(date).format('DD_MM_Y_HH_mm')}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }

    return (
        <div
            className="clientsTable"
            style={{
                width: "100%",
                overflowX: "hidden",
                padding: 0,
                marginTop: 0,
            }}
        >
            <div
                className="clientsTableMaterialTableContainer"
                style={{width: "100%", overflowX: "hidden"}}
            >
                <MainModal
                    closeOnEsc={true}
                    closable={true}
                    isOpen={isOpenPopUp}
                    onRequestClose={() => {
                        setStep(-1);
                        setIsOpenPopUp(false);
                        setTableLoading(false);
                    }}
                    closeModal={() => {
                        setStep(-1);
                        setIsOpenPopUp(false);
                        setTableLoading(false);
                    }}
                    content={
                        <>
                            <CryptoPopupInfo
                                data={popUpData.current}
                                setIsOpenPopUp={setIsOpenPopUp}
                                step={step}
                                setStep={setStep}
                                loading={loading}
                                setLoading={setLoading}
                                walletAddress={walletAddress}
                                rejected={rejected}
                                transaction={transaction}
                                setTransaction={setTransaction}
                                denyRequest={denyRequest}
                                setAction={setAction}
                            />
                        </>
                    }
                />
                <MainTable
                    columns={columns}
                    density={'compact'}
                    action={action}
                    setAction={setAction}
                    requestURL="transactions"
                    onRowClick={handleInfo}
                    renderCurrencyFooterToolbar={false}
                    tableContainerRef={tableContainerRef}
                    customHeight={customHeight}
                    renderTopToolbarCustomActions={tableButtons}
                />
            </div>
        </div>
    );
}

export default DashboardTable;

