import React, {useContext, useEffect, useState} from "react";
import CustomAriaLive from "../../React-Select/React-Select";
import {useSelector} from "react-redux";
import iconGenerator from "../../../utils/iconGenerator";
import Moment from "moment";
// CSS
import "./dashboardSliderFirstRight.css";
import {publicRequest} from "../../../utils/requestMethods";
import {RiUserAddLine} from "react-icons/ri";
import {BsFillPrinterFill, BsPrinterFill} from "react-icons/bs";
import {BiChevronDown, BiChevronUp} from "react-icons/bi";
import ClientRegisterFields from "../../ReusableComponents/ClientRegisterFields";
import MainModal from "../../ReusableComponents/MainModal";
import CurrencyOption from "../CurrencyOption";
import {ClipLoader} from "react-spinners";
import {FaCheck, FaTimes} from "react-icons/fa";
import {NumberFormatter} from "../../ReusableComponents/NumberFormat";
import {HiOutlineClock} from "react-icons/hi";
import ReusableTitle from "../../ReusableComponents/ReusableTitle";
import qs from "qs";
import ReusableStatusComponent from "../../ReusableComponents/ReusableStatusComponent";
import {cryptoPrint} from "../../../utils/templates/crypto";
import {SocketContext} from "../../../context/socket";
import useSound from "use-sound";
import new_transaction from "../../../assets/sounds/new_transaction.mp3";
import Swal from "sweetalert2";

const DashboardSliderFirstRight = ({
                                       approveSliderInfo,
                                       approveSliderInfoType,
                                       setAfterClick,
                                       setSlide,
                                       setApproveSliderInfo,
                                       setApproveSliderInfoType,
                                       setApproveTransaction,
                                       success,
                                       setSuccess,
                                       loading,
                                       setLoading,
                                   }) => {
    const user = useSelector((state) => state.user.currentUser);
    const accounts = useSelector((state) => state.user.accounting);
    const percentage = useSelector((state) => state.user.percentages?.crypto);
    const [successAdd, setSuccessAdd] = useState(false);
    const [message, setMessage] = useState("");

    const [fail, setFail] = useState(false);
    const [client, setClient] = useState("");

    const [clientError, setClientError] = useState(false);
    const [transactions, setTransactions] = useState([]);
    // const [pendingTransactions, setPendingTransactions] = useState([]);
    const [more, setMore] = useState(false);

    const [inputList, setInputList] = useState([
        {
            symbol: "EUR",
            rate: 0,
            price: 0,
            manualPrice: false,
            manualRate: false,
            default: true,
            changeRate: false,
            changePrice: false,
            attached: false,
        },
    ]);

    const successEdit = (params, user) => {
        setSuccessAdd(true);
        setMessage(params);
        setClient(user);
        setTimeout(() => {
            setSuccessAdd(false);
            setIsOpen(false);
        }, 2000);
    };

    const updateTransaction = async (data) => {
        return transactions.map((t) => {
            if (t._id === data._id) {
                return data;
            }
            return t;
        })
    }

    const socket = useContext(SocketContext);
    const [play, {stop}] = useSound(new_transaction);

    const [socketData, setSocketData] = useState({});

    useEffect(() => {
        if (socketData.type === 'edited') {
            if (transactions.length) {
                updateTransaction(socketData.data).then((result) => {
                    // if (socketData.notification) {
                    //     play()
                    // }
                    setTransactions(result);
                });
            }
        }
    }, [socketData])

    useEffect(() => {
        socket.updateRow((data) => {
            setSocketData(data);
        });
    }, [socket]);

    useEffect(() => {
        const getTransactions = async () => {
            try {
                setLoading(true);

                let params = {
                    start: 0,
                    size: 10,
                    filters: JSON.stringify([{
                        id: 'mode', value: {$ne: "AUTO"}
                    }]),
                };

                const response = await publicRequest.get(
                    `transactions?${qs.stringify(params)}`
                );
                setTransactions(response.data.data);
                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        };
        getTransactions();
    }, []);

    const [currencyList, setCurrencyList] = useState([]);

    // Input States
    const [modifiedData, setModifiedData] = useState({
        firstName: "",
        lastName: "",
        zip: "",
        partnerId: user?.partnerId?._id,
        address: "",
        city: "",
        country: "",
        phone1: "",
        phone2: "",
        email: "",
    });

    const [entity, setEntity] = useState([]);
    const [isOpen, setIsOpen] = React.useState(false);
    const [feeInput, setFeeInput] = useState({
        fee: 2.5,
        manual: false,
    });

    function openModal() {
        setIsOpen(true);
        setModifiedData({
            firstName: "",
            lastName: "",
            zip: "",
            partnerId: user?.partnerId?._id,
            address: "",
            city: "",
            country: "",
            phone1: "",
            phone2: "",
            email: "",
        });
    }

    function closeModal() {
        setIsOpen(false);
        setModifiedData({
            firstName: "",
            lastName: "",
            zip: "",
            partnerId: user?.partnerId?._id,
            address: "",
            city: "",
            country: "",
            phone1: "",
            phone2: "",
            email: "",
        });
    }

    const totalWithdrawPrice = (show = false) => {
        let totalInUSD = inputList.reduce(
            (partialSum, a) =>
                partialSum +
                (a.symbol === "USD"
                    ? Number(a.price) / a.rate
                    : Number(a.price) * (1 / a.rate)),
            0
        );

        let eurRate = inputList.find((il) => il.symbol === "EUR");

        if (!eurRate) {
            eurRate = currencyList.find((il) => il.symbol === "EUR");
        }

        if (eurRate) {
            return Number(Number(totalInUSD) / (1 / eurRate.rate));
        }
        return 0;
    };

    const generateConversationFee = () => {
        const EUR = currencyList.find(cl => cl.symbol === 'EUR');

        return EUR ? 1 / EUR.rate : 0;
    }

    const postExchange = async () => {
        let postExchangeObj = {
            client_id: client?._id,
            value_fee: feeInput,
            fiat_payment: (await totalWithdrawPrice()).toFixed(2),
            conversion_fee: generateConversationFee(),
            exchange_rate: inputList,
            status: 4,
        };

        if (
            client === "" ||
            client === null ||
            client === undefined
        ) {
            setClientError(true);
            return;
        } else {
            Swal.fire({
                title: `Are you sure you want to approve this transaction?`,
                icon: 'question',
                showDenyButton: true,
                confirmButtonText: "Yes",
                denyButtonText: `Cancel`,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    setLoading(true);
                    setClientError(false);

                    const response = await publicRequest.put(
                        "/transactions/partner/approval/" + approveSliderInfo._id,
                        postExchangeObj
                    );

                    if (response.status === 200) {
                        setClient(null)
                        setApproveTransaction(approveSliderInfo);
                        await cryptoPrint(response.data);
                        setSuccess(true);
                        setFail(false);
                        setTimeout(() => {
                            setSlide(0);
                            setApproveSliderInfoType(false);
                            setApproveSliderInfo([]);
                            setAfterClick(-1);
                            setSuccess(false);
                        }, 2000);
                    } else {
                        setFail(true);
                        setSuccess(false);
                        setTimeout(() => {
                            setSlide(0);
                            setAfterClick(-1);
                            setFail(false);
                        }, 2000);
                    }
                    setLoading(false);
                }
            })
        }
    };

    const dummySliderData = [
        {
            id: 1,
            symbol: `${approveSliderInfo?.symbol}`,
            icon: approveSliderInfo?.symbol?.split("/")[0],
            value: approveSliderInfo?.symbol?.split("/")[0] === 'USDT' ? parseFloat(approveSliderInfo?.quantity).toFixed(2) : approveSliderInfo?.quote_quantity_filled_total,
        },
        {
            id: 2,
            symbol: "USDT",
            icon: "USDT",
            value: parseFloat(approveSliderInfo?.quantity * approveSliderInfo.last_price_filled).toFixed(2),
        },
    ];

    const handleSelected = (e) => {
        if (e === null) {
            setClient(null);
        } else {
            setClient(e);
            setClientError(false);
        }
    };

    useEffect(() => {
        generateFee();
    }, [approveSliderInfo]);

    const generateFee = () => {
        if (approveSliderInfo) {
            const partnerPercentage = percentage ?? [];

            if (!partnerPercentage.length && !feeInput.manual) {
                return 0;
            }


            const sumOfAmount = parseFloat(dummySliderData[1].value);
            let currentFee = percentage?.find(
                (prc) =>
                    sumOfAmount >= prc.fromValue &&
                    sumOfAmount <= prc.toValue &&
                    prc.percentageSide === "BUY"
            );

            if (currentFee === undefined) {
                currentFee = percentage?.find(
                    (prc) => prc.percentageSide === "BUY" && prc.fromValue <= sumOfAmount
                );
            }

            if (!feeInput.manual && currentFee) {
                setFeeInput({
                    fee: Number(currentFee?.percentageValue),
                    manual: false,
                });
            } else {
                setFeeInput({
                    fee: 0,
                    manual: false,
                });
            }
        }
    };

    useEffect(() => {
        calculatePrice();
    }, [feeInput, inputList, approveSliderInfo]);

    const totalPriceWithFee = () => {
        const price =
            approveSliderInfo.quantity * approveSliderInfo.last_price_filled;
        const fee = (Number(feeInput.fee) / 100) * Number(price);
        return Number(price - fee).toFixed(2);
    };

    const calculatePrice = () => {
        if (approveSliderInfo.quantity) {
            let priceWithFee = totalPriceWithFee();

            const totalManualChanged = inputList.filter((cur) => cur.manualPrice);
            const sumOfManualChanged = totalManualChanged.reduce(
                (partialSum, a) => partialSum + Number(a.price) / a.rate,
                0
            );

            let totalCurrency =
                (priceWithFee - sumOfManualChanged) /
                (inputList.length - totalManualChanged.length);

            inputList.map((cur) => {
                if (!cur.manualRate) {
                    let enb = approveSliderInfo.exchange_rate.find(
                        (asi) => asi.symbol === cur.symbol
                    );

                    if (enb) {
                        cur.rate = enb.rate;
                    }
                }

                if (!cur.manualPrice) {
                    cur.price = Number(totalCurrency * cur.rate).toFixed(2);
                }
            });
        }
    };

    const handleFeeChange = (event) => {
        setFeeInput({fee: event.target.value, manual: true});

        inputList.map((cur) => {
            cur.manualPrice = false;
            cur.manualRate = false;
        });
    };

    const handleFocus = (event) => {
        setTimeout(() => {
            event.target.select();
        }, 100);
    };

    return (
        <div
            className="dashboardSliderFirst"
            style={{padding: "0 10px 10px 10px"}}
        >
            <div
                className="dashboardSliderFirstWrapper"
                style={{
                    justifyContent: !approveSliderInfoType ? "flex-start" : "center",
                }}
            >
                {!approveSliderInfoType && (
                    <ReusableTitle
                        title="Pending Transactions"
                        style={{
                            width: "100%",
                            marginTop: "-10px",
                            marginBottom: "38px",
                        }}
                    />
                )}
                {loading ? (
                    <div className="grid h-full w-full place-center">
                        <ClipLoader size={30} color="orange"/>
                    </div>
                ) : success ? (
                    <div
                        className="flex flex-column items-center justify-center w-full"
                        style={{position: "absolute", top: "-6%", left: "0", height: "fit-content"}}
                    >
                        <FaCheck
                            size={30}
                            color="white"
                            style={{
                                background: "#25da25",
                                padding: "10px",
                                borderRadius: "50%",
                            }}
                        />
                        <div
                            style={{fontWeight: "300", fontSize: "14px", margin: "5px 0"}}
                        >
                            The transaction was successful!
                        </div>
                    </div>
                ) : fail ? (
                    <div
                        className="flex flex-column items-center justify-center w-full h-full"
                        style={{position: "absolute", top: "-6%", left: "0"}}
                    >
                        <FaTimes
                            size={30}
                            color="white"
                            style={{
                                background: "red",
                                padding: "10px",
                                borderRadius: "50%",
                            }}
                        />
                        <div
                            style={{fontWeight: "300", fontSize: "14px", margin: "5px 0"}}
                        >
                            There was an error with the transaction!
                        </div>
                    </div>
                ) : (
                    <>
                        {approveSliderInfoType ? (
                            <>
                                <MainModal
                                    closable={true}
                                    isOpen={isOpen}
                                    onRequestClose={closeModal}
                                    closeModal={closeModal}
                                    footer={true}
                                    refetch={() => {
                                    }}
                                    setEntity={setEntity}
                                    successEdit={successEdit}
                                    success={successAdd}
                                    entity={entity}
                                    user={user}
                                    endpoint="client/register"
                                    modifiedData={modifiedData}
                                    setModifiedData={setModifiedData}
                                    submitOrEdit="submit"
                                    setIsOpen={setIsOpen}
                                    content={
                                        <div className="flex justify-between gap-10 flex-wrap">
                                            <h3 className="usersTableRegisterClientFormTitle">
                                                Register New Client
                                            </h3>
                                            <ClientRegisterFields
                                                modifiedData={modifiedData}
                                                setModifiedData={setModifiedData}
                                                success={successAdd}
                                                message={message}
                                            />
                                        </div>
                                    }
                                />
                                <>
                                    <div className="dashboardSliderFirstRightWrapper">
                                        {!user.isAdmin && !user.isMainOffice && (
                                            <div className="dashboardSliderFirstRightCurrency">
                                                {accounts.map((account, i) => (
                                                    <>
                                                        <div className="dashboardSliderFirstRightCurrencyWrapper">
                                                            <span className="dashboardSliderFirstRightCurrencyTopSpan">
                                                                <img
                                                                    src={iconGenerator(
                                                                        128,
                                                                        "icon",
                                                                        account.currency.symbol,
                                                                        true
                                                                    )}
                                                                    alt=""
                                                                    className="dashboardChartHeaderIcon"
                                                                />
                                                            </span>
                                                            <span
                                                                className="dashboardSliderFirstRightCurrencyBottomSpan">
                                                                <NumberFormatter
                                                                    value={account.balance.toFixed(2)}
                                                                    style={{fontWeight: "bold"}}
                                                                />{" "}
                                                                {account.currency.symbol}
                                                            </span>
                                                        </div>
                                                        {i < accounts.length - 1 && (
                                                            <div className="dashboardSliderFirstRightCurrencyLine"/>
                                                        )}
                                                    </>
                                                ))}
                                            </div>
                                        )}
                                        <div className="dashboardSliderFirstRightExchange">
                                            <div
                                                className="dashboardSliderFirstRightExchangeSelectWrapper"
                                                style={{marginBottom: "20px", position: "relative"}}
                                            >
                                                <CustomAriaLive
                                                    handleSelected={(e) => handleSelected(e)}
                                                    client={client}
                                                    fullWidth={true}
                                                    error={clientError}
                                                />
                                                <RiUserAddLine
                                                    size={30}
                                                    color="#fff"
                                                    onClick={() => openModal()}
                                                    className="addUserIcon"
                                                />
                                                {clientError && (
                                                    <span
                                                        style={{
                                                            position: "absolute",
                                                            top: "-20px",
                                                            color: "red",
                                                        }}
                                                    >
                                                        Choose a client first!
                                                    </span>
                                                )}
                                            </div>
                                            <div className="dashboardSliderFirstRightExchangeCurrency">
                                                <div className="dashboardSliderFirstRightExchangeCurrencyLeftwrapper">
                                                    <div
                                                        className="dashboardSliderFirstRightExchangeCurrencyInfoContainer">
                                                        <p
                                                            className="dashboardSliderFirstRightExchangeCurrencyInfoContainerP"
                                                            style={{marginLeft: "40px"}}
                                                        >
                                                            You Receive
                                                        </p>
                                                        <>
                                                            <img
                                                                className="secondSlderFirstSelectCoinIcon"
                                                                style={{
                                                                    left: "unset",
                                                                    top: "unset",
                                                                    transform: "translateY(-5%)",
                                                                }}
                                                                src={iconGenerator(
                                                                    128,
                                                                    "icon",
                                                                    `${dummySliderData[0].icon}`
                                                                )}
                                                                alt=""
                                                            />
                                                            <span
                                                                className="dashboardSliderFirstRightExchangeCurrencyInfoContainerSpan"
                                                                style={{marginLeft: "40px"}}
                                                            >
                                                                {dummySliderData[0].symbol.split("/")[0]}
                                                            </span>
                                                        </>
                                                    </div>
                                                </div>
                                                <div className="dashboardSliderFirstRightExchangeCurrencyValue">
                                                    <span
                                                        className="dashboardSliderFirstRightExchangeCurrencyTransferQtyValueSpan">
                                                        {dummySliderData[0].value}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="dashboardSliderFirstRightExchangeCurrencyTransferContainer">
                                                <div className="dashboardSliderFirstRightExchangeCurrencyTransferQty">
                                                    <span
                                                        className="dashboardSliderFirstRightExchangeCurrencyTransferQtySpan">
                                                        Price:
                                                    </span>
                                                    <div
                                                        className="dashboardSliderFirstRightExchangeCurrencyTransferQtyValue">
                                                        <span
                                                            className="dashboardSliderFirstRightExchangeCurrencyTransferQtyValueSpan">
                                                            {approveSliderInfo.last_price_filled}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="dashboardSliderFirstRightExchangeCurrencyTransferFee">
                                                    <span
                                                        className="dashboardSliderFirstRightExchangeCurrencyTransferFeeSpan">
                                                        Fee:
                                                    </span>
                                                    <div
                                                        className="dashboardSliderSecondRightExchangeCurrencyTransferFeeValue">
                                                        <input
                                                            type="text"
                                                            className="dashboardSliderSecondRightExchangeCurrencyValueSpan"
                                                            value={feeInput.fee}
                                                            onChange={(event) => handleFeeChange(event)}
                                                            onFocus={(event) => handleFocus(event)}
                                                        />
                                                        %
                                                    </div>
                                                </div>
                                            </div>
                                            {dummySliderData[0].symbol.split("/")[0] !== "USDT" && (
                                                <div className="dashboardSliderFirstRightExchangeCurrency">
                                                    <div
                                                        className="dashboardSliderFirstRightExchangeCurrencyLeftwrapper">
                                                        <div
                                                            className="dashboardSliderFirstRightExchangeCurrencyInfoContainer">
                                                            <p
                                                                className="dashboardSliderFirstRightExchangeCurrencyInfoContainerP"
                                                                style={{
                                                                    marginLeft:
                                                                        "40psecondSlderFirstSelectCoinIconx",
                                                                }}
                                                            ></p>
                                                            <img
                                                                className="secondSlderFirstSelectCoinIcon"
                                                                style={{
                                                                    left: "unset",
                                                                    top: "unset",
                                                                    transform: "translateY(-20%)",
                                                                }}
                                                                src={iconGenerator(
                                                                    128,
                                                                    "icon",
                                                                    dummySliderData[1].symbol.split("/")[0]
                                                                )}
                                                                alt=""
                                                            />
                                                            <span
                                                                className="dashboardSliderFirstRightExchangeCurrencyInfoContainerSpan"
                                                                style={{marginLeft: "40px"}}
                                                            >
                                                                {dummySliderData[1].symbol.split("/")[0]}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="dashboardSliderFirstRightExchangeCurrencyValue">
                                                        <input
                                                            className="dashboardSliderFirstRightExchangeCurrencyValueSpan"
                                                            value={dummySliderData[1].value}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            <div style={{padding: "0 0 20px 0", width: "100%"}}>
                                                <CurrencyOption
                                                    currencyList={inputList}
                                                    setCurrencyList={setInputList}
                                                    availableCurrencyList={currencyList}
                                                    setAvailableCurrencyList={setCurrencyList}
                                                    exchangeRate={approveSliderInfo?.exchange_rate}
                                                    reverseCurrency={false}
                                                    autoTrade={true}
                                                />
                                                <div className="printerIconWrapperr">
                                                    <div>
                                                        <span>
                                                            ≈ {Number(totalWithdrawPrice()).toFixed(2)} €
                                                        </span>
                                                    </div>
                                                    <BsFillPrinterFill
                                                        color="#b8b8b8"
                                                        size={20}
                                                        className="printerIcon"
                                                        onClick={() => postExchange()}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            </>
                        ) : (
                            <>
                                {loading ? (
                                    <div className="grid h-full w-full place-center">
                                        <ClipLoader size={30} color="orange"/>
                                    </div>
                                ) : (
                                    <>
                                        {transactions?.map((data, index) =>
                                            more ? (
                                                <div
                                                    key={`trans${index}`}
                                                    className="dashboardSliderFirstLeft"
                                                >
                                                    <div className="dashboardSliderFirstLeftItem">
                                                        <div
                                                            className="dashboardSliderFirstLeftItemInfoLeftIconContainer">
                                                            <img
                                                                className="dashboardSliderFirstLeftItemIcon"
                                                                src={iconGenerator(
                                                                    128,
                                                                    "icon",
                                                                    `${data.symbol.split("/")[0]}`
                                                                )}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="dashboardSliderFirstLeftItemInfo">
                                                            <div className="dashboardSliderFirstLeftItemInfoLeft">
                                                                <span
                                                                    className="dashboardSliderFirstLeftItemInfoLeftSpan">
                                                                    {data.symbol.split("/")[0]}
                                                                </span>
                                                                <p className="dashboardSliderFirstLeftItemInfoLeftP"
                                                                   style={{color: data.side === 'sell' ? 'red' : 'green'}}>
                                                                    {data.side.toUpperCase()}
                                                                </p>
                                                            </div>
                                                            {data.exchange === "Binance" ? (
                                                                <img
                                                                    className="dashboardSliderFirstLeftItemIconIcon"
                                                                    src={require("../../../assets/binance_favicon.png")}
                                                                    alt=""
                                                                />
                                                            ) : (
                                                                <img
                                                                    className="dashboardSliderFirstLeftItemIconIcon"
                                                                    src={require("../../../assets/dex.png")}
                                                                    alt=""
                                                                />
                                                            )}
                                                            <span className="dashboardSliderFirstLeftItemInfoLeftValue">
                                                                {data.symbol.split("/")[0] === "USDT" || data.symbol.split("/")[0] === "BUSD" ?
                                                                    <NumberFormatter
                                                                        value={data.quantity_sent || data.quantity}/>
                                                                    :
                                                                    <div className="flex-column gap-5">
                                                                        <p style={{textAlign: "right"}}>{data.quantity}</p>
                                                                        {(data.symbol.split("/")[0].toLowerCase() !== "usdt" && data.symbol.split("/")[0].toLowerCase() !== "busd") && (
                                                                            <p style={{
                                                                                marginBottom: 0,
                                                                                fontSize: "13px",
                                                                                color: "#989898",
                                                                                textAlign: "right"
                                                                            }}>≈ <NumberFormatter
                                                                                value={data.quantity * data.last_price_filled}/>
                                                                                <small>USD</small></p>
                                                                        )}
                                                                    </div>
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="dashboardSliderFirstLeftItemButtonContainer">
                                                        <div
                                                            className="dashboardSliderFirstLeftItemInfoLeftButtonContainerWrapper">
                                                            <ReusableStatusComponent status={data.status}/>
                                                            {data.status === 4 && (
                                                                <BsPrinterFill
                                                                    className="cursor-pointer"
                                                                    style={{transform: "translateX(10px)"}}
                                                                    onClick={() => cryptoPrint(data)}
                                                                    color="#8a8a8a"
                                                                    size={16}
                                                                />
                                                            )}
                                                        </div>
                                                        <div
                                                            className="dashboardSliderFirstLeftItemButtonContainerTime">
                                                            <span>🕓</span>
                                                            <span
                                                                style={{marginLeft: "5px", color: "gray"}}
                                                            >
                                                                {Moment(data.createdAt).format(
                                                                    "DD.MM.YYYY HH:mm"
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                index < 8 && (
                                                    <div
                                                        key={`dashboard${index}`}
                                                        className="dashboardSliderFirstLeft"
                                                    >
                                                        <div className="dashboardSliderFirstLeftItem">
                                                            <div
                                                                className="dashboardSliderFirstLeftItemInfoLeftIconContainer">
                                                                <img
                                                                    className="dashboardSliderFirstLeftItemIcon"
                                                                    src={iconGenerator(
                                                                        128,
                                                                        "icon",
                                                                        `${data.symbol.split("/")[0]}`
                                                                    )}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="dashboardSliderFirstLeftItemInfo">
                                                                <div className="dashboardSliderFirstLeftItemInfoLeft">
                                                                    <span
                                                                        className="dashboardSliderFirstLeftItemInfoLeftSpan">
                                                                        {data.symbol.split("/")[0]}
                                                                    </span>
                                                                    <p className="dashboardSliderFirstLeftItemInfoLeftP"
                                                                       style={{color: data.side === 'sell' ? 'red' : 'green'}}>
                                                                        {data.side.toUpperCase()}
                                                                    </p>
                                                                </div>
                                                                {data.exchange === "Binance" ? (
                                                                    <img
                                                                        className="dashboardSliderFirstLeftItemIconIcon"
                                                                        src={require("../../../assets/binance_favicon.png")}
                                                                        alt=""
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        className="dashboardSliderFirstLeftItemIconIcon"
                                                                        style={{width: "20px", height: "auto"}}
                                                                        src={require("../../../assets/dex.png")}
                                                                        alt=""
                                                                    />
                                                                )}
                                                                <span
                                                                    className="dashboardSliderFirstLeftItemInfoLeftValue">
                                                                    {data.symbol.split("/")[0] === "USDT" || data.symbol.split("/")[0] === "BUSD" ?
                                                                        <NumberFormatter
                                                                            value={data.quantity_sent || data.quantity}/>
                                                                        :
                                                                        <div className="flex-column gap-5">
                                                                            <p style={{textAlign: "right"}}>{data.quantity}</p>
                                                                            {(data.symbol.split("/")[0].toLowerCase() !== "usdt" && data.symbol.split("/")[0].toLowerCase() !== "busd") && (
                                                                                <p style={{
                                                                                    marginBottom: 0,
                                                                                    fontSize: "13px",
                                                                                    color: "#989898",
                                                                                    textAlign: "right"
                                                                                }}>≈ <NumberFormatter
                                                                                    value={data.quantity * data.last_price_filled}/>
                                                                                    <small>USD</small></p>
                                                                            )}
                                                                        </div>
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="dashboardSliderFirstLeftItemButtonContainer">
                                                            <div
                                                                className="dashboardSliderFirstLeftItemInfoLeftButtonContainerWrapper">
                                                                <ReusableStatusComponent status={data.status}/>
                                                                {data.status === 4 && (
                                                                    <BsPrinterFill
                                                                        className="cursor-pointer"
                                                                        style={{transform: "translateX(10px)"}}
                                                                        onClick={() => cryptoPrint(data)}
                                                                        color="#8a8a8a"
                                                                        size={20}
                                                                    />
                                                                )}
                                                            </div>
                                                            <div
                                                                className="dashboardSliderFirstLeftItemButtonContainerTime"
                                                                style={{marginTop: "5px"}}
                                                            >
                                                                <HiOutlineClock size={16} color="#828282"/>
                                                                <span
                                                                    style={{
                                                                        marginLeft: "5px",
                                                                        color: "#828282",
                                                                    }}
                                                                >
                                                                    {Moment(data.updatedAt).format(
                                                                        "DD.MM.YYYY HH:mm"
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            )
                                        )}
                                    </>
                                )}

                                {transactions.length < 1 && !loading ? (
                                    <span style={{margin: "0 auto"}}>
                                        No pending transactions.
                                    </span>
                                ) : null}

                                {transactions.length > 10 && (
                                    <span
                                        style={{cursor: "pointer", margin: "0 auto"}}
                                        onClick={() => setMore(!more)}
                                    >
                                        {more ? (
                                            <div className="flex items-center gap-5">
                                                Show Less
                                                <BiChevronUp size={25}/>
                                            </div>
                                        ) : (
                                            <div className="flex items-center gap-5">
                                                Show More
                                                <BiChevronDown size={25}/>
                                            </div>
                                        )}
                                    </span>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default DashboardSliderFirstRight;

