import {QRCodeSVG} from "qrcode.react";
import AuthCode from "react-auth-code-input";
import Button from "../../Button";
import React from "react";


const TwoFaDeactivate = ({handleOnChange, handleSubmit, code}) => {
    return (
        <>
            <span style={{
                display: "block",
                margin: "0 auto",
                textAlign: "center"
            }}>Two-Factor Authentication Activation</span>


            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 20,
                    padding: 20,
                    flexDirection: 'column'
                }}
            >
                <h3>Enter your six-digit code below to deactivate 2FA.</h3>
                <div style={{padding: 16}}>
                    <AuthCode
                        onChange={handleOnChange}
                        inputClassName={'two-fa-input'}
                        allowedCharacters={"numeric"}
                    />
                </div>
                <Button
                    title={"Deactivate"}
                    disabled={!code.length}
                    onClick={handleSubmit}
                    cls=""
                />
            </div>
        </>
    )
}

export default TwoFaDeactivate;