import React from "react";
import iconGenerator from "../../../utils/iconGenerator";
import {NumberFormatter} from "../NumberFormat";

function MRT_CurrencyFooter({tableData}) {
    let balance = [];
    let currencies = Object.entries(tableData).map((x) => x[1].original);

    currencies.map((currency) => {
        let index = balance.findIndex(
            (bb) => bb.currency === currency.currency.symbol
        );

        if (currency.status !== 4) {
            return
        }

        if (currency?.currency) {
            if (index !== -1) {
                balance[index].amount =
                    parseFloat(balance[index].amount) + parseFloat(currency.amount);
            } else {
                balance.push({
                    currency: currency.currency.symbol,
                    amount: parseFloat(currency.amount),
                });
            }
        }

    });

    return (
        <div
            className="flex items-center justify-around w-full"
            style={{padding: "10px", minHeight: "46px"}}
        >
            {balance.map((x) => (
                <div className="flex flex-column items-center justify-center">
          <span
              style={{
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "black",
              }}
          >
            <img
                src={iconGenerator(128, "icon", x.currency, true)}
                style={{height: "25px"}}
            />
          </span>
                    <span style={{fontSize: "14px"}}>
            <NumberFormatter suffix={` ${x.currency}`} value={x.amount}/>
          </span>
                </div>
            ))}
        </div>
    );
}

export default MRT_CurrencyFooter;

