import React from "react";

function ReusableTitle(props) {
  return (
    <p className="reusableP" style={{ color: props.color, ...props.style }}>
      {props.title}
    </p>
  );
}

export default ReusableTitle;

