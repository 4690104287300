import React, { useContext, useEffect, useState } from "react";
import CurrencyTable from "./CurrencyTable";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import PartnersCurrency from "./PartnersCurrency";
import PartnerPairs from "./PartnerPairs";
import { SocketContext } from "../../context/socket";
import { useSelector } from "react-redux";

function Currency() {
    const user = useSelector((state) => state.user.currentUser);
    const [fiat, setFiat] = useState(null);
    const socket = useContext(SocketContext);

    useEffect(() => {
        socket.fiatPrice((data) => {
            setFiat(data);
        });
    }, [socket]);

    return (
        <div className="users" style={{ padding: "0" }}>
            <Tabs
                style={{
                    maxWidth: "100%",
                    width: "100%",
                }}
            >
                <TabList
                    style={{
                        padding: "7px 20px 0 20px",
                        background: "white",
                        margin: "0",
                    }}
                >
                    {(user.isAdmin || user.isMainOffice) && <Tab>Currencies</Tab>}
                    <Tab>Currency Rates</Tab>
                    <Tab>Pairs</Tab>
                </TabList>

                {(user.isAdmin || user.isMainOffice) && (
                    <TabPanel>
                        <div className="users">
                            <div
                                className="usersUserListContainer"
                                style={{ paddingBottom: "" }}
                            >
                                <CurrencyTable fiat={fiat} />
                            </div>
                        </div>
                    </TabPanel>
                )}
                <TabPanel>
                    <div className="users">
                        <div
                            className="usersUserListContainer"
                            style={{ paddingBottom: "" }}
                        >
                            <PartnersCurrency fiat={fiat} />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="users">
                        <div
                            className="usersUserListContainer"
                            style={{ paddingBottom: "" }}
                        >
                            <PartnerPairs fiat={fiat} />
                        </div>
                    </div>
                </TabPanel>
            </Tabs>
        </div>
    );
}

export default Currency;

