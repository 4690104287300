import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import React, {useState} from "react";
import General from "./General";
import Security from "./Security";

const Profile = () => {
    const [data, setData] = useState({});
    const [saveLoading, setSaveLoading] = useState(false);

    const handleSubmit = () => {
    }

    const handleInputChange = () => {

    }

    return (
        <div className='users'>
            <Tabs style={{
                width: "100%",
            }}>
                <TabList
                    style={{
                        padding: "7px 20px 0 20px",
                        background: "white",
                        margin: "0",
                    }}
                >
                    {/*<Tab>General</Tab>*/}
                    <Tab>Security</Tab>
                </TabList>

                {/*<TabPanel>*/}
                {/*    <General*/}
                {/*        data={data}*/}
                {/*        handleSubmit={handleSubmit()}*/}
                {/*        saveLoading={saveLoading}*/}
                {/*    />*/}
                {/*</TabPanel>*/}

                <TabPanel>
                    <Security
                        data={data}
                        handleSubmit={handleSubmit()}
                        saveLoading={saveLoading}
                        handleInputChange={handleInputChange}
                    />
                </TabPanel>

            </Tabs>
        </div>
    );
}

export default Profile;
