import React from "react";
import "./exchangeDebit.css";

const ExchangeDebit = () => {
  const dummyLeftData = [
    {
      id: 1,
      title: "EUR",
      value: "5212.21",
    },
    {
      id: 2,
      title: "EUR",
      value: "5212.21",
    },
    {
      id: 3,
      title: "EUR",
      value: "5212.21",
    },
    {
      id: 4,
      title: "EUR",
      value: "5212.21",
    },
  ];

  return (
    <div className="exchangeDebit">
      <div className="exchangeDebitLeft">
        <div className="exchangeDebitLeftHeader">
          <span className="exchangeDebitLeftHedearSpan">Total Value</span>
        </div>
        {dummyLeftData.map((data) => (
          <div className="exchangeDebitLeftCurrencyContainer">
            <img
              src={require(`../../../assets/euro.png`)}
              alt=""
              className="exchangeDebitLeftCurrencyIcon"
            />
            <span className="exchangeDebitLeftCurrencSpan">EUR</span>
            <div className="exchangeDebitLeftCurrencyValueWrapper">
              <span className="exchangeDebitLeftCurrencyValueSpan">
                50.000.
              </span>
              <p className="exchangeDebitLeftCurrencyValueP">02</p>
            </div>
          </div>
        ))}
        <div className="exchangeDebitLeftBottomButtonWrapper">
          <button className="exchangeDebitLeftBottomButton">Withdraw</button>
        </div>
      </div>
      <div className="exchangeDebitLine"></div>
      <div className="exchangeDebitRight">
        <div className="exchangeDebitRightHeaderContainer">
          <span className="exchangeDebitRightHeaderSpan">Client</span>
          <span className="exchangeDebitRightHeaderSpan">Location</span>
          <span className="exchangeDebitRightHeaderSpan">Date</span>
          <span className="exchangeDebitRightHeaderSpan">Value</span>
        </div>
        {dummyLeftData.map((data) => (
          <div className="exchangeDebitRightContainer">
            <img
              src={require(`../../../assets/pound.png`)}
              alt=""
              className="exchangeDebitRightIcon"
            />
            <div className="exchangeDebitRightClientWrapper">
              <span className="exchangeDebitRightClientSpan">
                Besim Dalloshi
              </span>
              <p className="exchangeDebitRightClientP">Withdraw</p>
            </div>
            <div className="exchangeDebitRightLocationWrapper">
              <span className="exchangeDebitRightLocationSpan">Skopje</span>
              <p className="exchangeDebitRightLocationP">Teasor</p>
            </div>
            <span className="exchangeDebitRightDateSpan">31.10.2021</span>
            <div className="exchangeDebitRightValueWrapper">
              <span className="exchangeDebitRightValueSpan">31.000.</span>
              <p className="exchangeDebitRightValueP">00</p>
            </div>
          </div>
        ))}
        <div className="exchangeDebitRightBottomButtonWrapper">
          <button className="exchangeDebitLeftBottomButton">Excel</button>
        </div>
      </div>
    </div>
  );
};

export default ExchangeDebit;
