import {Paper} from "@material-ui/core";
import React from "react";
import {FaCheck, FaTimes} from "react-icons/fa";
import Moment from "moment";

function DestinationPartnerDetails({ data }) {
  return (
    <Paper
      variant="outlined"
      style={{ maxWidth: "100%", marginTop: "2em", paddingBottom: "0.5em" }}
    >
      <center>
        <div
          style={{
            borderBottom: "1px solid lightgrey",
            maxWidth: "95%",
            marginBottom: "2em",
            paddingTop: "0.5em",
            paddingBottom: "0.5em",
          }}
        >
          <span style={{ fontSize: "16px", fontWeight: "600" }}>
            Destination Partners Details
          </span>
        </div>
      </center>

      <div className="grid w-full">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0 2.5%",
            flexWrap: "wrap",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", rowGap: "" }}>
            <span
              style={{
                fontWeight: "400",
                color: "#666666",
                fontSize: "14px",
                textAlign: "center",
                lineHeight: "1.5",
              }}
            >
              Partner Name
            </span>
            <span
              style={{
                overflow: "hidden",
                fontWeight: "600",
                textOverflow: "ellipsis",
                color: "rgba(0, 0, 0, 0.87)",
                fontSize: "16px",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
              {data.destination_partner?.partnerName}
            </span>
          </div>
          <div style={{ display: "flex", flexDirection: "column", rowGap: "" }}>
            <span
              style={{
                fontWeight: "400",
                color: "#666666",
                fontSize: "14px",
                textAlign: "center",
                lineHeight: "1.5",
              }}
            >
              Partner City
            </span>
            <span
              style={{
                overflow: "hidden",
                fontWeight: "600",
                textOverflow: "ellipsis",
                color: "rgba(0, 0, 0, 0.87)",
                fontSize: "16px",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
              {data.destination_partner?.city}
            </span>
          </div>
          <div style={{ display: "flex", flexDirection: "column", rowGap: "" }}>
            <span
              style={{
                fontWeight: "400",
                color: "#666666",
                fontSize: "14px",
                textAlign: "center",
                lineHeight: "1.5",
              }}
            >
              Partner Country
            </span>
            <span
              style={{
                overflow: "hidden",
                fontWeight: "600",
                textOverflow: "ellipsis",
                color: "rgba(0, 0, 0, 0.87)",
                fontSize: "16px",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
              {data.destination_partner?.country}
            </span>
          </div>
        </div>

        <div
          className="flex items-center justify-center gap-30"
          style={{ marginTop: "30px" }}
        >
          <div style={{ display: "flex", flexDirection: "column", rowGap: "" }}>
            <span
              style={{
                fontWeight: "400",
                color: "#666666",
                fontSize: "14px",
                textAlign: "center",
                lineHeight: "1.5",
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              Partner Confirmation
              {data.partner_status ? (
                <FaCheck color="green" size={14} />
              ) : (
                <FaTimes color="red" size={14} />
              )}
            </span>
            {data.partner_status && (
              <span
                style={{
                  overflow: "hidden",
                  fontWeight: "600",
                  textOverflow: "ellipsis",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "16px",
                  lineHeight: "1.5",
                  textAlign: "center",
                  textTransform: "capitalize",
                }}
              >
                {data.partner_user_confirmation?.fullName}
              </span>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "column", rowGap: "" }}>
            <span
              style={{
                fontWeight: "400",
                color: "#666666",
                fontSize: "14px",
                textAlign: "center",
                lineHeight: "1.5",
                display: "flex",
                alignItems: "center",
              }}
            >
              Partner Confirmation Date
            </span>
            {data.partner_status && (
              <span
                style={{
                  overflow: "hidden",
                  fontWeight: "600",
                  textOverflow: "ellipsis",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "16px",
                  lineHeight: "1.5",
                  textAlign: "center",
                  textTransform: "capitalize",
                }}
              >
                {Moment(data.partner_user_confirmation_date).format("DD.MM.YYYY HH:mm")}
              </span>
            )}
          </div>
        </div>
      </div>
    </Paper>
  );
}

export default DestinationPartnerDetails;


