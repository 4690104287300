import { Paper } from "@material-ui/core";
import React from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import iconGenerator from "../../../../../utils/iconGenerator";
import ReusableStatusComponent from "../../../ReusableStatusComponent"
import Moment from "moment";
import { NumberFormatter } from "../../../NumberFormat";
import { capitalizeWords } from "../../../../../utils/utils";
import { useSelector } from "react-redux";

function Details({ data }) {
  const user = useSelector((state) => state.user.currentUser)

  let canSeeDetails = data?.transfer_type === "lite_transfer" && user?.isMainOffice || user?.isAdmin || data?.source_partner?._id === user?.partnerId?._id

  return (
    <Paper
      variant="outlined"
      style={{ maxWidth: "100%", marginTop: "2em", paddingBottom: "0.5em" }}
    >
      <center>
        <div
          style={{
            borderBottom: "1px solid lightgrey",
            maxWidth: "95%",
            marginBottom: "2em",
            paddingTop: "0.5em",
            paddingBottom: "0.5em",
          }}
        >
          <span style={{ fontSize: "16px", fontWeight: "600" }}>Details</span>
        </div>
      </center>

      <div
        style={{
          display: "grid",
          placeItems: "center",
          margin: "0 2.5%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: canSeeDetails ? "space-between" : "center",
            width: "100%",
            alignItems: "flex-start",
            gap: canSeeDetails ? "0" : "100px"
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                fontWeight: "400",
                color: "#666666",
                fontSize: "14px",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
              Order Number
            </span>
            <span
              style={{
                overflow: "hidden",
                fontWeight: "600",
                textOverflow: "ellipsis",
                color: "rgba(0, 0, 0, 0.87)",
                fontSize: "16px",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
              {data.order_number}
            </span>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                fontWeight: "400",
                color: "#666666",
                fontSize: "14px",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
              Symbol
            </span>
            <span
              style={{
                overflow: "hidden",
                fontWeight: "600",
                textOverflow: "ellipsis",
                color: "rgba(0, 0, 0, 0.87)",
                fontSize: "16px",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
              <div className="flex items-center gap-5">
                {data.transfer_type === "lite_transfer" ||
                  data.transfer_type === "transfer" ||
                  data.transfer_type === "internal_transfer" ? (
                  <img
                    src={iconGenerator(128, "icon", data.currency.symbol, true)}
                    alt=""
                    style={{ borderRadius: "12px" }}
                    className="dashboardChartHeaderIcon"
                  />
                ) : (
                  <img
                    src={iconGenerator(
                      128,
                      "icon",
                      data.symbol.split("/")[1],
                      false
                    )}
                    alt=""
                    style={{ borderRadius: "12px" }}
                    className="dashboardChartHeaderIcon"
                  />
                )}

                {data.currency ? data.currency.symbol : data.symbol}
                {data.transfer_type === "lite_transfer" ||
                  data.transfer_type === "transfer" ||
                  data.transfer_type === "internal_transfer" ? (
                  <></>
                ) : (
                  <img
                    src={iconGenerator(
                      128,
                      "icon",
                      data.symbol.split("/")[1],
                      false
                    )}
                    alt=""
                    style={{ borderRadius: "12px" }}
                    className="dashboardChartHeaderIcon"
                  />
                )}
              </div>
            </span>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                fontWeight: "400",
                color: "#666666",
                fontSize: "14px",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
              Amount
            </span>
            <span
              style={{
                overflow: "hidden",
                fontWeight: "600",
                textOverflow: "ellipsis",
                color: "rgba(0, 0, 0, 0.87)",
                fontSize: "16px",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
              <NumberFormatter value={data.amount?.toFixed(2)} />
            </span>
          </div>
          {canSeeDetails && (
            <>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    fontWeight: "400",
                    color: "#666666",
                    fontSize: "14px",
                    lineHeight: "1.5",
                    textAlign: "center",
                  }}
                >
                  Fee
                </span>
                <span
                  style={{
                    overflow: "hidden",
                    fontWeight: "600",
                    textOverflow: "ellipsis",
                    color: "rgba(0, 0, 0, 0.87)",
                    fontSize: "16px",
                    lineHeight: "1.5",
                    textAlign: "center",
                  }}
                >
                  <NumberFormatter value={data.fee?.toFixed(2)} />
                </span>
              </div>
              <div style={{ display: "flex", placeItems: "center" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span
                    style={{
                      fontWeight: "400",
                      color: "#666666",
                      fontSize: "14px",
                      lineHeight: "1.5",
                    }}
                  >
                    Summary
                  </span>
                  <span
                    style={{
                      overflow: "hidden",
                      fontWeight: "600",
                      textOverflow: "ellipsis",
                      color: "rgba(0, 0, 0, 0.87)",
                      fontSize: "16px",
                      lineHeight: "1.5",
                      textAlign: "center",
                    }}
                  >
                    <NumberFormatter value={data.summary?.toFixed(2)} />
                  </span>
                </div>
              </div>
            </>
          )}

        </div>
        <div
          className="w-full flex justify-between gap-30 items-start"
          style={{ marginTop: "20px" }}
        >
          <div className="grid place-items">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                style={{
                  fontWeight: "400",
                  color: "#666666",
                  fontSize: "14px",
                  lineHeight: "1.5",
                  textAlign: "center",
                }}
              >
                Date
              </span>
            </div>
            <span
              style={{
                overflow: "hidden",
                fontWeight: "600",
                textOverflow: "ellipsis",
                color: "rgba(0, 0, 0, 0.87)",
                fontSize: "16px",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
              {Moment(data.createdAt).format("DD.MM.YYYY HH:mm")}
            </span>
          </div>
          <div className="grid place-items">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                style={{
                  fontWeight: "400",
                  color: "#666666",
                  fontSize: "14px",
                  lineHeight: "1.5",
                }}
              >
                Comment
              </span>
            </div>
            <span
              style={{
                overflow: "hidden",
                fontWeight: "600",
                textOverflow: "ellipsis",
                color: "rgba(0, 0, 0, 0.87)",
                fontSize: "16px",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
              {data.comment}
            </span>
          </div>
          <div className="grid place-items">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                style={{
                  fontWeight: "400",
                  color: "#666666",
                  fontSize: "14px",
                  lineHeight: "1.5",
                }}
              >
                Office Status
              </span>
            </div>
            <span
              style={{
                overflow: "hidden",
                fontWeight: "600",
                textOverflow: "ellipsis",
                color: "rgba(0, 0, 0, 0.87)",
                fontSize: "16px",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
              {data.office_status ? (
                <FaCheck color="green" size={18} />
              ) : (
                <FaTimes color="red" size={18} />
              )}
            </span>
          </div>
          <div className="grid place-items">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                style={{
                  fontWeight: "400",
                  color: "#666666",
                  fontSize: "14px",
                  lineHeight: "1.5",
                }}
              >
                Partner Status
              </span>
            </div>
            <span
              style={{
                overflow: "hidden",
                fontWeight: "600",
                textOverflow: "ellipsis",
                color: "rgba(0, 0, 0, 0.87)",
                fontSize: "16px",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
              {data.partner_status ? (
                <FaCheck color="green" size={18} />
              ) : (
                <FaTimes color="red" size={18} />
              )}
            </span>
          </div>
          <div className="grid place-items">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                style={{
                  fontWeight: "400",
                  color: "#666666",
                  fontSize: "14px",
                  lineHeight: "1.5",
                  textAlign: "center",
                }}
              >
                Status
              </span>
            </div>
            <div className="w-full">
              <ReusableStatusComponent
                status={data.status}
                centered={true}
                smallFont={true}
              />
            </div>
          </div>
        </div>
        <div
          className="flex items-center justify-center w-full mt-10"
          style={{ gap: "100px" }}
        >
          <div className="grid place-items">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                style={{
                  fontWeight: "400",
                  color: "#666666",
                  fontSize: "14px",
                  lineHeight: "1.5",
                  textAlign: "center",
                }}
              >
                Transfer Type
              </span>
            </div>
            <div className="w-full" style={{ textAlign: "center" }}>
              {capitalizeWords(data?.transfer_type)}
            </div>
          </div>
          {data.transfer_type === "lite_transfer" && (
            <>
              <div className="grid place-items">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span
                    style={{
                      fontWeight: "400",
                      color: "#666666",
                      fontSize: "14px",
                      lineHeight: "1.5",
                      textAlign: "center",
                    }}
                  >
                    Sender
                  </span>
                </div>
                <div className="w-full" style={{ textAlign: "center" }}>
                  {`${data.sender?.firstName} ${data.sender?.lastName}`}
                </div>
              </div>
              <div className="grid place-items">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span
                    style={{
                      fontWeight: "400",
                      color: "#666666",
                      fontSize: "14px",
                      lineHeight: "1.5",
                      textAlign: "center",
                    }}
                  >
                    Receiver
                  </span>
                </div>
                <div className="w-full" style={{ textAlign: "center" }}>
                  {data?.receiver}
                </div>
              </div>
            </>
          )}

        </div>
      </div>
    </Paper>
  );
}

export default Details;

