import {getCoinMarketCapIcon} from './icons/list'

const iconGenerator = (type, color, symbol, currency = false) => {
    if (currency) {
        try {
            return require(`../assets/currency/${symbol.toLowerCase()}.png`);
        } catch {
            return require(`../assets/currency/universal.png`);
        }
    }

    try {
        return getCoinMarketCapIcon(symbol, type)
    } catch {
        return getCoinMarketCapIcon(symbol, type)
    }
};

export default iconGenerator;

