import React, {useCallback, useEffect, useState} from 'react'
import MainSelect from './Select';
import {components} from "react-select";
import {FaTimes} from 'react-icons/fa';

function ApiAddressSelect({entity, setEntity, selectData, setSelectData, fullWidth}) {
    const [group, setGroup] = useState([])

    const GroupHeading = (props) => <components.GroupHeading {...props} />;

    const updateList = async (entity) => {
        let updatedList = selectData.map((addresses) => {
            addresses.options.map((address) => {
                if (address.value === entity.value) {
                    address.used = !address.used;
                }
            });
            return addresses;
        });
        setSelectData([...updatedList]);
    }

    const handleListChange = async (e) => {
        if (entity.findIndex((obj) => obj.group === e.group) === -1) {
            setEntity([...entity, e]);
            await updateList(e)
        }
    };

    const removeCurrency = (id) => {
        setEntity(entity.filter((x) => x.value !== id));
        let arr = group.groupContent.filter((x) => x.value !== id)
        setGroup((prev) => ({
            groupNames: [...group.groupNames],
            groupContent: [...arr]
        }))
        updateList({value: id})
    };

    const customFilter = useCallback((candidate, input) => {
        if (input.length) {
            if (
                candidate.data.group.toLowerCase().includes(input.toLowerCase()) ||
                candidate.data.label.toLowerCase().includes(input.toLowerCase()) ||
                candidate.data.value.toLowerCase().includes(input.toLowerCase())
            ) {
                return !candidate.data.used;
            } else {
                return false;
            }
        }
        return !candidate.data.used;

    }, []);

    useEffect(() => {

        let groupList = [];
        entity.map((x) => {
            let index = groupList.findIndex((gl) => gl.group === x.group)
            if (index !== -1) {
                groupList[index].addresses.push(x);
            } else {
                groupList.push({
                    group: x.group,
                    name: x.networkName,
                    addresses: [x]
                })
            }
        });
        setGroup(groupList);

    }, [entity])

    return (
        <div style={{width: "100%", marginTop: "20px"}}>
            <h4
                style={{
                    fontWeight: "400",
                    borderBottom: "3px solid orange",
                    marginBottom: "20px",
                    width: "fit-content",
                }}
            >
                DEX
            </h4>
            <MainSelect
                isClearable={false}
                isSearchable={true}
                onChange={handleListChange}
                options={selectData}
                placeholder={"Select address"}
                defaultValue={"Select address"}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                filterOption={customFilter}
                components={{GroupHeading}}
            />
            <div
                style={{
                    minHeight: "250px",
                    border: "1px solid #d8d8d8",
                    broderRadius: "4px",
                    marginTop: "20px",
                    maxHeight: "250px",
                    overflowY: "scroll"
                }}
            >
                <div className="grid gap-10" style={{padding: "6px"}}>
                    {group.map((x) => (
                        <div style={{position: "relative"}}>
                            <div className="display-none-not-first">
                                <span style={{color: "#999999", fontSize: "12px", marginLeft: 5}}>{x.name}</span>
                            </div>
                            <div>
                                {x.addresses?.map((y) => (
                                    <div className="listOfCurrency">
                                        <span>{y.value}</span>
                                        <FaTimes
                                            onClick={() => removeCurrency(y.value)}
                                            color="white"
                                            size={14}
                                            className="remove-currency cursor-pointer"
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ApiAddressSelect
