import { Cut, Line, Printer, QRCode, Row, Text } from "react-thermal-printer";
import moment from "moment/moment";
import print from "../printer";

export const internalPrint = async (order) => {

    const transactionId = order._id;
    const TXCode = btoa((JSON.stringify({ id: transactionId, type: 'internal_transfer' })));

    await print(
        <Printer type="epson" width={46} characterSet="iso8859_2_latin2">
            <Text bold={true} align={'center'} size={{ width: 2, height: 2 }}>INTERNAL</Text>
            <Line />
            <Row left={'Date'} right={moment(order.createdAt).format('DD.MM.Y')} />
            <Row left={<Text bold={true}>Transfer ID</Text>} right={<Text bold={true}>{order.order_number}</Text>} />
            <Line />

            {order.transaction_type === 'send' ?
                <>
                    <Row left={'Sender'} right={`${order?.source_partner?.partnerName}`} />
                    <Row left={'Receiver'} right={`${order?.destination_partner?.partnerName}`} />
                </> :
                <>
                    <Row left={'Receiver'} right={`${order?.source_partner?.partnerName}`} />
                    <Row left={'Sender'} right={`${order?.destination_partner?.partnerName}`} />
                </>
            }

            <Row left={'Amount'} right={`${order?.amount} ${order.currency.symbol}`} />
            <Row left={'Fee'} right={`${order?.fee} %`} />
            <Row left={<Text bold={true}>Total</Text>} right={<Text bold={true}>{order?.summary} {order.currency.symbol}</Text>} />
            <Line />
            <QRCode cellSize={5} model={'model2'} align={"center"} content={TXCode} />
            <Line />
            <Text bold={true} align={'center'}>{order.comment}</Text>
            <Cut lineFeeds={4} />
        </Printer>
    );
}
