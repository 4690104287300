import { Paper } from "@material-ui/core";
import React from "react";

function SourcePartnerDetails({ data }) {
  return (
    <Paper
      variant="outlined"
      style={{ maxWidth: "100%", marginTop: "2em", paddingBottom: "0.5em" }}
    >
      <center>
        <div
          style={{
            borderBottom: "1px solid lightgrey",
            maxWidth: "95%",
            marginBottom: "2em",
            paddingTop: "0.5em",
            paddingBottom: "0.5em",
          }}
        >
          <span style={{ fontSize: "16px", fontWeight: "600" }}>
            Source Partners Details
          </span>
        </div>
      </center>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0 2.5%",
          flexWrap: "wrap",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", rowGap: "" }}>
          <span
            style={{
              fontWeight: "400",
              color: "#666666",
              fontSize: "14px",
              textAlign: "center",
              lineHeight: "1.5",
            }}
          >
            Initiator
          </span>
          <span
            style={{
              overflow: "hidden",
              fontWeight: "600",
              textOverflow: "ellipsis",
              color: "rgba(0, 0, 0, 0.87)",
              fontSize: "16px",
              lineHeight: "1.5",
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {data?.initiator_user?.fullName ? data.initiator_user.fullName : ""}
          </span>
        </div>
        <div style={{ display: "flex", flexDirection: "column", rowGap: "" }}>
          <span
            style={{
              fontWeight: "400",
              color: "#666666",
              fontSize: "14px",
              textAlign: "center",
              lineHeight: "1.5",
            }}
          >
            Partner Name
          </span>
          <span
            style={{
              overflow: "hidden",
              fontWeight: "600",
              textOverflow: "ellipsis",
              color: "rgba(0, 0, 0, 0.87)",
              fontSize: "16px",
              lineHeight: "1.5",
              textAlign: "center",
            }}
          >
            {data.source_partner?.partnerName}
          </span>
        </div>
        <div style={{ display: "flex", flexDirection: "column", rowGap: "" }}>
          <span
            style={{
              fontWeight: "400",
              color: "#666666",
              fontSize: "14px",
              textAlign: "center",
              lineHeight: "1.5",
            }}
          >
            Partner City
          </span>
          <span
            style={{
              overflow: "hidden",
              fontWeight: "600",
              textOverflow: "ellipsis",
              color: "rgba(0, 0, 0, 0.87)",
              fontSize: "16px",
              lineHeight: "1.5",
              textAlign: "center",
            }}
          >
            {data.source_partner?.city}
          </span>
        </div>
        <div style={{ display: "flex", flexDirection: "column", rowGap: "" }}>
          <span
            style={{
              fontWeight: "400",
              color: "#666666",
              fontSize: "14px",
              textAlign: "center",
              lineHeight: "1.5",
            }}
          >
            Partner Country
          </span>
          <span
            style={{
              overflow: "hidden",
              fontWeight: "600",
              textOverflow: "ellipsis",
              color: "rgba(0, 0, 0, 0.87)",
              fontSize: "16px",
              lineHeight: "1.5",
              textAlign: "center",
            }}
          >
            {data.source_partner?.country}
          </span>
        </div>
      </div>
    </Paper>
  );
}

export default SourcePartnerDetails;
