import React from "react";
import "./usersDetail.css";

const UsersDetail = ({ usersDetail, setDetails }) => {
  return (
    <div className="usersDetail">
      <div className="usersDetailWrapper">
        <span
          onClick={() => setDetails(false)}
          className="usersDetailCloseButton"
          
        >
          <i class="fa fa-times-circle"></i>

        </span>
        <h3 className="usersDetailh3">User Details</h3>
        <div className="usersDetailItem">
          <span className="usersDetailHeader">ID: </span>
          <span className="usersDetailSpanValue">{usersDetail?._id}</span>
        </div>
        <div className="usersDetailItem">
          <span className="usersDetailHeader">Username: </span>
          <span className="usersDetailSpanValue">{usersDetail?.username}</span>
        </div>
        <div className="usersDetailItem">
          <span className="usersDetailHeader">Full Name: </span>
          <span className="usersDetailSpanValue">{usersDetail?.fullName}</span>
        </div>
        <div className="usersDetailItem">
          <span className="usersDetailHeader">Phone 1: </span>
          <span className="usersDetailSpanValue">{usersDetail?.phone1}</span>
        </div>
        <div className="usersDetailItem">
          <span className="usersDetailHeader">Phone 2: </span>
          <span className="usersDetailSpanValue">{usersDetail?.phone2}</span>
        </div>
        <div className="usersDetailItem">
          <span className="usersDetailHeader">Email: </span>
          <span className="usersDetailSpanValue">{usersDetail?.email}</span>
        </div>
        <div className="usersDetailItem">
          <span className="usersDetailHeader">Partner ID: </span>
          <span className="usersDetailSpanValue">{usersDetail?.partnerId}</span>
        </div>
        <div className="usersDetailItem">
          <span className="usersDetailHeader">Role: </span>
          <span className="usersDetailSpanValue">{usersDetail?.roles}</span>
        </div>
        <div className="usersDetailItem">
          <span className="usersDetailHeader">Secret: </span>
          <span className="usersDetailSpanValue">{usersDetail?.secret}</span>
        </div>
        <div className="usersDetailItem">
          <span className="usersDetailHeader">Is Admin: </span>
          <span className="usersDetailSpanValue">
            {usersDetail?.isAdmin ? "True" : "False"}
          </span>
        </div>
        <div className="usersDetailItem">
          <span className="usersDetailHeader">Created By: </span>
          <span className="usersDetailSpanValue">
            {usersDetail?.whoCreateIt?._id}
          </span>
        </div>
      </div>
    </div>
  );
};

export default UsersDetail;
