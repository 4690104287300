import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import ClientRegisterFields from './ClientRegisterFields';
import MainModal from './MainModal';

function AddClientModal({ setLiteTransferData, setClient, isOpen, setIsOpen, setData }) {
    const user = useSelector((state) => state.user.currentUser);

    const [successAdd, setSuccessAdd] = useState(false);
    const [message, setMessage] = useState("");
    const [entity, setEntity] = useState([])
    const [modifiedData, setModifiedData] = useState({
        firstName: "",
        lastName: "",
        zip: "",
        partnerId: null,
        address: "",
        city: "",
        country: "",
        phone1: "",
        phone2: "",
        email: "",
    });

    const successEdit = (params, user) => {
        setSuccessAdd(true);
        setMessage(params);
        setClient(user);
        if (setData) {
            setData((prev) => ({
                ...prev,
                beneficiaryDetails: user._id,
            }));
        }
        if (setLiteTransferData) {
            setLiteTransferData((prev) => ({
                ...prev,
                sender: user._id,
            }));
        }
        setTimeout(() => {
            setSuccessAdd(false);
            setIsOpen(false);
        }, 2000);
    };

    function closeModal() {
        setIsOpen(false);
        clearModel();
    }

    useEffect(() => {
        resetData()
    }, [isOpen])

    const resetData = () => {
        setModifiedData({
            firstName: "",
            lastName: "",
            zip: "",
            partnerId: null,
            address: "",
            city: "",
            country: "",
            phone1: "",
            phone2: "",
            email: "",
        })
    }

    return (
        <MainModal
            closable={true}
            isOpen={isOpen}
            onRequestClose={closeModal}
            closeModal={closeModal}
            footer={true}
            entity={entity}
            setEntity={setEntity}
            refetch={() => { }}
            successEdit={successEdit}
            success={successAdd}
            user={user}
            endpoint="client/register"
            modifiedData={modifiedData}
            setModifiedData={setModifiedData}
            submitOrEdit="submit"
            setIsOpen={setIsOpen}
            content={
                <div className="flex justify-between gap-10 flex-wrap">
                    <h3 className="usersTableRegisterClientFormTitle">
                        Register New Client
                    </h3>
                    <ClientRegisterFields
                        modifiedData={modifiedData}
                        setModifiedData={setModifiedData}
                        success={successAdd}
                        message={message}
                    />
                </div>
            }
        />
    )
}

export default AddClientModal