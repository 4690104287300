import React from "react";
import "./exchangeDeposit.css";

const ExchangeDeposit = () => {
  const dummyLeftData = [
    {
      id: 1,
      title: "EUR",
      value: "5212.21",
    },
    {
      id: 2,
      title: "EUR",
      value: "5212.21",
    },
    {
      id: 3,
      title: "EUR",
      value: "5212.21",
    },
    {
      id: 4,
      title: "EUR",
      value: "5212.21",
    },
  ];

  return (
    <div className="exchangeDeposit">
      <div className="exchangeDepositLeft">
        <div className="exchangeDepositLeftInfoContainer">
          <span className="exchangeDepositLeftInfoSpanFirst">Currency</span>
          <span className="exchangeDepositLeftInfoSpanSecond">Value</span>
          <span className="exchangeDepositLeftInfoSpan">Count</span>
        </div>
        {dummyLeftData.map((data) => (
          <div className="exchangeDepositLeftContainer">
            <div className="exchangeDepositLeftContainerLeft">
              <img
                src={require(`../../../assets/euro.png`)}
                alt=""
                className="exchangeDepositLeftContainerIcon"
              />
              <span className="exchangeDepositLeftContainerSpan">EUR</span>
            </div>
            <div className="exchangeDepositLeftContainerValueWrapper">
              <span className="exchangeDepositLeftContainerValue">50.144.</span>
              <span className="exchangeDepositLeftContainerValueSmall">00</span>
            </div>
            <img
              src={require(`../../../assets/moneycounter.png`)}
              alt=""
              className="exchangeDepositLeftContainerCounterIcon"
            />
          </div>
        ))}
        <div className="exchangeDepositLeftBottomContainer">
          <img
            src={require(`../../../assets/addpage.png`)}
            alt=""
            className="exchangeDepositLeftBottomIcon"
          />
          <button className="exchangeDepositLeftBottomButton">Confirm</button>
        </div>
      </div>
      <div className="exchangeDepositLine"></div>
      <div className="exchangeDepositRight">
        <div className="exchangeDepositRightHeader">
          <span className="exchangeDepositRightHeaderSpan">Currency</span>
          <span className="exchangeDepositRightHeaderSpan">Value</span>
          <span className="exchangeDepositRightHeaderSpan">Swap</span>
          <span className="exchangeDepositRightHeaderSpan">Rate</span>
          <span className="exchangeDepositRightHeaderSpan">Value</span>
          <span className="exchangeDepositRightHeaderSpan">Status</span>
        </div>
        {dummyLeftData.map((data) => (
          <div className="exchangeDepositRightCurrency">
            <div className="exchangeDepositRightCurrencyIconContainer">
              <img
                src={require(`../../../assets/pound.png`)}
                alt=""
                className="exchangeDepositRightCurrencyIconBig"
              />
              <img
                src={require(`../../../assets/euro.png`)}
                alt=""
                className="exchangeDepositRightCurrencyIconSmall"
              />
            </div>
            <div className="exchangeDepositRightCurrencyInfoContainer">
              <span className="exchangeDepositRightCurrencyInfoSpan">
                MKD / EUR
              </span>
            </div>
            <div className="exchangeDepositRightCurrencyValueContainer">
              <span className="exchangeDepositRightCurrencyValueSpan">
                200.212.
              </span>
              <p className="exchangeDepositRightCurrencyValueP">00</p>
            </div>
            <img
              src={require(`../../../assets/edit.png`)}
              alt=""
              className="exchangeDepositRightCurrencySwapIcon"
            />
            <img
              src={require(`../../../assets/euro.png`)}
              alt=""
              className="exchangeDepositRightCurrencyInfoIcon"
            />
            <div className="exchangeDepositRightCurrencyRateContainer">
              <span className="exchangeDepositRightCurrencyRateSpan">53.</span>
              <p className="exchangeDepositRightCurrencyRateP">2132</p>
            </div>
            <div className="exchangeDepositRightCurrencyRateValueContainer">
              <span className="exchangeDepositRightCurrencyRateValueSpan">
                542,213.
              </span>
              <p className="exchangeDepositRightCurrencyRateValueP">05</p>
            </div>
            <img
              src={require(`../../../assets/edit.png`)}
              alt=""
              className="exchangeDepositRightCurrencyStatusIcon"
            />
          </div>
        ))}
        <div className="exchangeDepositRightBottomContainer">
          <img
            src={require(`../../../assets/addpage.png`)}
            alt=""
            className="exchangeDepositLeftBottomIcon"
          />
          <button className="exchangeDepositLeftBottomButton">Confirm</button>
        </div>
      </div>
    </div>
  );
};

export default ExchangeDeposit;
