import React from "react";
import { NumericFormat } from "react-number-format";

export function NumberFormatter({ value, suffix, prefix, style, customDecimal }) {
  return (
    <NumericFormat
      value={value}
      displayType={"text"}
      thousandSeparator=","
      decimalSeparator="."
      suffix={suffix}
      prefix={prefix}
      decimalScale={customDecimal ? customDecimal : 2}
      fixedDecimalScale={true}
      renderText={(formattedValue) => (
        <span style={style}>{formattedValue}</span>
      )}
    />
  );
}

