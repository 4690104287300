import React from "react";

function Reports() {
  return (
    <div
      className="accounting-history"
      style={{ maxWidth: "100%", marginTop: "1%" }}
    >
    </div>
  );
}

export default Reports;

