import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TimeFromTo = ({partnerData, setPartnerData}) => {
    const [fromTime, setFromTime] = useState(new Date());
    const [toTime, setToTime] = useState(new Date());

    const [timeFrom, setTimeFrom] = useState(null);
    const [timeTo, setTimeTo] = useState(null);

    const handleChange = (date) => {
        setPartnerData((prev) => ({
            ...prev,
            limits: {
                ...prev.limits,
                after_work: {
                    ...prev.limits.after_work,
                    open_time: date.toString(),
                },
            },
        }));
    };

    const handleEndDate = (date) => {
        setPartnerData((prev) => ({
            ...prev,
            limits: {
                ...prev.limits,
                after_work: {
                    ...prev.limits.after_work,
                    close_time: date.toString(),
                },
            },
        }))
    };
    useEffect(() => {

        try {
            setTimeFrom(new Date(partnerData.limits.after_work.open_time));
            setTimeTo(new Date(partnerData.limits.after_work.close_time));
        } catch (e) {
            setTimeFrom((new Date()).setHours(8, 0));
            setTimeTo((new Date()).setHours(20, 0));
        }
    }, [partnerData]);

    return (

        <div className={`flex items-center gap-10 no-date-picker`}>
            <DatePicker
                className={`datepicker`}
                onChange={handleChange}
                maxDate={toTime}
                dateFormat={"HH:mm"}
                showYearDropdown
                showTimeSelect={true}
                showMonthDropdown
                selected={timeFrom}
            />

            <DatePicker
                className={`datepicker`}
                onChange={handleEndDate}
                dateFormat={"HH:mm"}
                showYearDropdown
                showMonthDropdown
                showTimeSelect={true}
                minDate={fromTime}
                selected={timeTo}
            />
        </div>
    );
};

export default TimeFromTo;

