import React, {Fragment, useEffect, useState} from "react";
import TransportWebHID from "@ledgerhq/hw-transport-webhid";
import AppTRX from "@ledgerhq/hw-app-trx";
import ApiAddressSelect from "../../ReusableComponents/ApiAddressSelect";
import {publicRequest} from "../../../utils/requestMethods";

function PartnerApiFields({partnerData, setPartnerData}) {
    const [walletAddress, setWalletAddress] = useState("");
    const [connected, setConnected] = useState(false);
    const [loading, setLoading] = useState(false);

    const [selectedAddresses, setSelectedAddresses] = useState([]);
    const [selectData, setSelectData] = useState([]);

    const getAddressesList = async () => {
        return await publicRequest.get('/v2/dex/addresses');
    }

    useEffect(() => {
        getAddressesList().then((response) => {
            if (response.status === 200) {
                setSelectData(response.data.data);
            }
        })
    }, []);


    useEffect(() => {
        if (partnerData?.exchanges?.dex.length) {
            setSelectedAddresses(partnerData.exchanges.dex)
        }
    }, [])

    const [accounts, setAccounts] = useState([
        {
            path: "44'/195'/0'/0/0",
            address: "TFKuZdhKF1KiwnUMGP3UYoUbF2DSE8EmAK",
        },
    ]);

    const checkForConnection = async (confirm = false) => {
        return new Promise(async (resolve, reject) => {
            const transport = await TransportWebHID.create();
            try {
                setLoading(true);
                const trx = new AppTRX(transport);
                let {address} = await trx.getAddress("44'/195'/0'/0/0");
                resolve({
                    address,
                    connected: true,
                });
                setLoading(false);
            } catch (e) {
                resolve({
                    address: false,
                    connected: false,
                });
                setLoading(false);
            } finally {
                await transport.close();
                setLoading(false);
            }
        });
    };

    const connectLedger = async () => {
        const {address, connected} = await checkForConnection(true);
        setWalletAddress(address);

        if (connected === true) {
            setConnected(true);
            setLoading(false);
        } else {
            setConnected(false);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selectedAddresses.length) {
            setPartnerData((prev) => ({
                ...prev,
                exchanges: {
                    ...prev.exchanges,
                    dex: selectedAddresses
                }
            }))
        } else {
            setPartnerData((prev) => ({
                ...prev,
                exchanges: {
                    ...prev.exchanges,
                    dex: []
                }
            }))
        }
    }, [selectedAddresses])

    return (
        <Fragment>
            <div className="flex flex-column">
                <div className="flex gap-20 flex-wrap">
                    <div style={{width: "48%", marginTop: "20px"}}>
                        <h4
                            style={{
                                fontWeight: "400",
                                borderBottom: "3px solid orange",
                                width: "fit-content",
                            }}
                        >
                            Binance
                        </h4>
                        <div className="usersTableRegisterFormItem w-full">
                            <label className="usersTableRegisterFormLabel">API Key</label>
                            <input
                                name="apiKey"
                                onChange={(e) =>
                                    setPartnerData({
                                        ...partnerData,
                                        exchanges: {
                                            ...partnerData.exchanges,
                                            binance: {
                                                ...partnerData.exchanges.binance,
                                                apiKey: e.target.value,
                                            },
                                        },
                                    })
                                }
                                value={partnerData.exchanges?.binance?.apiKey}
                                type="text"
                                className="usersTableRegisterFormInput"
                            />
                        </div>
                        <div className="usersTableRegisterFormItem w-full">
                            <label className="usersTableRegisterFormLabel">Private Key</label>
                            <input
                                name="privateKey"
                                onChange={(e) =>
                                    setPartnerData({
                                        ...partnerData,
                                        exchanges: {
                                            ...partnerData.exchanges,
                                            binance: {
                                                ...partnerData.exchanges.binance,
                                                privateKey: e.target.value,
                                            },
                                        },
                                    })
                                }
                                value={partnerData.exchanges?.binance?.privateKey}
                                type="text"
                                className="usersTableRegisterFormInput"
                            />
                        </div>
                    </div>
                </div>
                <ApiAddressSelect fullWidth={true} entity={selectedAddresses} setEntity={setSelectedAddresses}
                                  selectData={selectData} setSelectData={setSelectData}/>
            </div>
        </Fragment>
    );
}

export default PartnerApiFields;

