import React, { useEffect, useRef, useState } from "react";
import Slider from "../Slider/Slider";
import { TfiArrowsHorizontal } from "react-icons/tfi";
import iconGenerator from "../../utils/iconGenerator";
import { BiSearch } from "react-icons/bi";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import DashboardTable from "../Dashboard/Table";
import "react-tabs/style/react-tabs.css";
import { SortableContainer, SortableElement, sortableHandle, } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { FaPlus, FaTimes } from "react-icons/fa";
import { sortingCoins } from "../../utils/sorting";
import { useDispatch, useSelector } from "react-redux";
import { updateSorting } from "../../redux/userRedux";
import ReusableHeader from "../ReusableComponents/ReusableHeader";
import { CryptoChart } from "../ReusableComponents/Charts/CryptoChart";
import { ClipLoader } from "react-spinners";

const DragHandle = sortableHandle(() => (
    <div title="Drag to change position." className="dragHandle">
        <TfiArrowsHorizontal
            style={{ transform: "translateX(5%)" }}
            size={15}
            color="#444"
        />
    </div>
));

const SortableItem = SortableElement(
    ({
        rowIndex,
        handleChartSellClick,
        handleChartBuyClick,
        item,
        index,
        handleAddCoins,
        handleRemoveCoins,
        showCoinOptions,
        setShowCoinOptions,
        userCoins,
        permissions,
    }) => (
        <div key={rowIndex} className="dashboard-charts">
            <div
                className=""
                style={{ display: showCoinOptions === item._id ? "flex" : "none" }}
            >
                <DragHandle />
                {!userCoins.includes(item.symbol) ? (
                    <FaPlus
                        size={15}
                        color="#444"
                        className="cursor-pointer showOnHover"
                        onClick={() => handleAddCoins(item)}
                    />
                ) : (
                    <FaTimes
                        size={15}
                        color="#444"
                        className="cursor-pointer showOnHover"
                        onClick={() => handleRemoveCoins(item)}
                    />
                )}
            </div>

            <div className="dashboardChartWrapper">
                <div key={`coin${index}`} className="dashboardChartItems">
                    <div
                        onDoubleClick={() => setShowCoinOptions(item._id)}
                        className="dashboardChartHeader"
                    >
                        <img
                            src={iconGenerator(128, "icon", item.symbol)}
                            alt=""
                            style={{ borderRadius: "12px" }}
                            className="dashboardChartHeaderIcon"
                        />
                        <div className="dashboardChartHeaderInfoWrapper">
                            <span className="dashboardChartHeaderTitle">{item.symbol}</span>
                            <p className="dashboardChartHeaderSubtitle">{item.name}</p>
                        </div>
                    </div>
                    <div className="dashboardChartHeaderChangeWrapper">
                        <span className="dashboardChartHeaderChangeTitle">
                            CHANGE ({item.symbol === "USDT" ? "1 Day" : "15 Minutes"})
                        </span>
                        {/* <p className="dashboardChartHeaderChangeCalc">
                  -{item.change}%
                </p> */}
                    </div>
                    <div className="dashboardChartGraph" style={{ position: "relative" }}>
                        <CryptoChart
                            fsym={item.symbol}
                            tsym={
                                ["USDT", "USDC", "BUSD", "DAI"].includes(item.symbol)
                                    ? "USD"
                                    : "USDT"
                            }
                        />
                        {/*<TradingViewWidget*/}
                        {/*  symbol={*/}
                        {/*    item.symbol === "USDT"*/}
                        {/*      ? `BINANCEUS:USDTUSD`*/}
                        {/*      : `BINANCE:${item.symbol}USDT`*/}
                        {/*  }*/}
                        {/*  locale="en"*/}
                        {/*  interval={item.symbol === "USDT" ? "D" : "15"}*/}
                        {/*  style="1"*/}
                        {/*  enable_publishing={false}*/}
                        {/*  hide_top_toolbar={true}*/}
                        {/*  hide_legend={true}*/}
                        {/*  timezone="Europe/Belgrade"*/}
                        {/*  autosize*/}
                        {/*/>*/}
                    </div>
                    <div className="dashboardChartBuySellWrapper">
                        <div
                            title={`${!permissions.buySell ? "No permissions!" : "Sell"}`}
                            className={`dashboardChartSell ${!permissions.buySell && "cursor-ban"
                                }`}
                            onClick={() =>
                                permissions.buySell
                                    ? handleChartSellClick(item.symbol, item)
                                    : null
                            }
                        >
                            <span
                                className="dashboardChartSellTitle"
                                style={{
                                    backgroundColor: !permissions.buySell && "#8a8a8a",
                                }}
                            >
                                SELL
                            </span>
                            <p className="dashboardChartSellPrice">
                                {item.symbol === "USDT"
                                    ? parseFloat(item.price).toFixed(3)
                                    : item.price}
                            </p>
                        </div>
                        <div
                            title={`${!permissions.buySell ? "No permissions!" : "Buy"}`}
                            className={`dashboardChartBuy ${!permissions.buySell && "cursor-ban"
                                }`}
                            onClick={() =>
                                permissions.buySell
                                    ? handleChartBuyClick(item.symbol, item)
                                    : null
                            }
                        >
                            <span
                                className="dashboardChartBuyTitle"
                                style={{
                                    backgroundColor: !permissions.buySell && "#8a8a8a",
                                }}
                            >
                                BUY
                            </span>
                            <p className="dashboardChartBuyPrice">
                                {item.symbol === "USDT"
                                    ? parseFloat(item.price).toFixed(3)
                                    : item.price}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
);

const SortableList = SortableContainer(
    ({
        items,
        handleChartBuyClick,
        handleChartSellClick,
        selectValue,
        filteredCoins,
        setFilteredCoins,
        isSearched,
        handleRemoveCoins,
        handleAddCoins,
        showCoinOptions,
        setShowCoinOptions,
        optionsRef,
        allCoins,
        userCoins,
        permissions,
    }) => {
        return (
            <div className="w-full flex flex-wrap-1250 justify-center items-start gap-10">
                {items?.map((item, index) => (
                    <SortableItem
                        key={index}
                        rowIndex={index}
                        index={index}
                        item={item}
                        handleChartBuyClick={handleChartBuyClick}
                        handleChartSellClick={handleChartSellClick}
                        selectValue={selectValue}
                        filteredCoins={filteredCoins}
                        setFilteredCoins={setFilteredCoins}
                        isSearched={isSearched}
                        handleRemoveCoins={handleRemoveCoins}
                        handleAddCoins={handleAddCoins}
                        showCoinOptions={showCoinOptions}
                        setShowCoinOptions={setShowCoinOptions}
                        optionsRef={optionsRef}
                        allCoins={allCoins}
                        userCoins={userCoins}
                        permissions={permissions}
                    />
                ))}
            </div>
        );
    }
);

function normalComponent({
    setSearchCoinVisible,
    searchCoinVisible,
    setSelectValue,
    filteredCoins,
    setFilteredCoins,
    filterCoins,
    approve,
    handleChartBuyClick,
    handleChartSellClick,
    setSearchPendingQuery,
    searchPendingQuery,
    setPendingQuery,
    coinSearch,
    selectValue,
    choosenCrypto,
    choosenOrderType,
    networks,
    networkLoading,
    loading,
    afterClick,
    setAfterClick,
    singleCryptoCurrencies,
    cryptoCurrencies,
    choosenNetwork,
    setNetworkLoading,
    pendingQuery,
    coinInfo,
    choosenCryptoBNBPrice,
    fiat,
    socket,
    setChoosenNetwork,
    isSearched,
    setCoinSearch,
    allCoins,
    setAllCoins,
    permissions,
}) {
    const searchRef = useRef();
    const searchRef2 = useRef();
    const optionsRef = useRef();

    const [availableOn, setAvailableOn] = useState("isBinanceAvailable");
    const [choosenCoinPrice, setChoosenCoinPrice] = useState(0);
    const [isOpenPopUp, setIsOpenPopUp] = useState(false);
    const [step, setStep] = useState(-1);
    const [localLoading, setLocalLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [cryptoPending, setCryptoPending] = useState(0);
    const [action, setAction] = useState({ type: "", id: null, data: null });

    const popUpData = useRef({});

    const user = useSelector((state) => state.user.currentUser);
    const userCoins = user?.options.dashboard.coins;
    const dispatch = useDispatch();

    const [showCoinOptions, setShowCoinOptions] = useState(false);

    useEffect(() => {
        sortingCoins(userCoins, filteredCoins);
    }, []);

    useEffect(() => {
        sortingCoins(userCoins, filteredCoins);
    }, []);

    useEffect(() => {
        function handleClickOutside(event) {
            if (optionsRef.current && !optionsRef.current.contains(event.target)) {
                setShowCoinOptions(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [optionsRef]);

    useEffect(() => {
        switch (selectValue) {
            case "BNB":
                setAvailableOn("isBinanceAvailable");
                break;

            case "DEX":
                setAvailableOn("isDexAvailable");
                break;
        }
    }, [filteredCoins, selectValue]);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        let arr = arrayMoveImmutable(filteredCoins, oldIndex, newIndex);
        for (let i = 0; i < arr.length; i++) {
            arr[i].ordering = i;
        }

        let newArr = [];
        arr.map((x) => newArr.push(x.symbol));
        sortingCoins(arr, filteredCoins);

        dispatch(updateSorting(newArr));
        setFilteredCoins(arr);
    };

    const handleAddCoins = (item) => {
        if (allCoins.length === 5) {
            alert("Cannot add new coin.");
        } else {
            let newCoins = [...allCoins, item];
            setAllCoins(newCoins);

            let newSort = [];
            newCoins.map((fc) => newSort.push(fc.symbol));

            dispatch(updateSorting(newSort));
        }
    };

    const handleRemoveCoins = (item) => {
        let allCoinsNew = allCoins.filter((ac) => ac.symbol !== item.symbol);
        setAllCoins(allCoinsNew);
        if (!isSearched) {
            let otherItems = filteredCoins.filter((fc) => fc.symbol !== item.symbol);
            setFilteredCoins(otherItems);
        }

        let newSort = [];
        allCoinsNew.map((fc) => newSort.push(fc.symbol));

        dispatch(updateSorting(newSort));
    };

    return (
        <div className="users">
            <Tabs
                style={{
                    maxWidth: "100%",
                    width: "100%",
                }}
            >
                <TabList
                    style={{
                        background: "white",
                        margin: 0,
                        padding: "7px 20px 0 20px",
                    }}
                >
                    <Tab style={{ marginLeft: "15px" }}>Trade</Tab>

                    <Tab style={{ marginLeft: "15px" }}>Transaction</Tab>
                </TabList>
                <TabPanel>
                    {loading ? (
                        <div
                            style={{
                                display: "grid",
                                placeItems: "center",
                                height: "100vh",
                            }}
                        >
                            <ClipLoader size={30} color="orange" />
                        </div>
                    ) : (
                        <div className="users" style={{ padding: "0", marginTop: "10px" }}>
                            <div
                                className="usersUserListContainer"
                                style={{ paddingBottom: "" }}
                            >
                                <ReusableHeader />
                                <div className="dasboardInputWrapperFirst">
                                    <div className="dasboardInputWrapperFirstWrapper">
                                        <div
                                            className="searchIcon"
                                            onClick={() => {
                                                setSearchCoinVisible(!searchCoinVisible);
                                                searchRef.current.focus();
                                            }}
                                        >
                                            <BiSearch color="#aeaeae" size={22} />
                                        </div>
                                        <input
                                            type="text"
                                            ref={searchRef}
                                            className={`dashboardChartInput ${searchCoinVisible ? "showSearchInput" : ""
                                                }`}
                                            placeholder="Search"
                                            onChange={(event) => filterCoins(event)}
                                            value={coinSearch}
                                        />
                                        <div
                                            className="cursor-pointer"
                                            style={{
                                                maxWidth: "fit-content",
                                                position: "relative",
                                                top: "-2px",
                                                right: "-10px",
                                                display:
                                                    coinSearch.length > 1 && searchCoinVisible
                                                        ? "block"
                                                        : "none",
                                            }}
                                            onClick={() => setCoinSearch("")}
                                        >
                                            <FaTimes size={14} color="#444" />
                                        </div>
                                    </div>
                                    <select
                                        className="dasboardInputWrapperFirstWrapperSelect"
                                        name=""
                                        id=""
                                        onChange={(event) => setSelectValue(event.target.value)}
                                        value={selectValue}
                                    >
                                        <option value="BNB">BNB</option>
                                        <option value="DEX">DEX</option>
                                    </select>
                                </div>
                                <div className="dashboardChart" ref={optionsRef}>
                                    <SortableList
                                        items={filteredCoins.filter(
                                            (coin) => coin?.details[availableOn]
                                        )}
                                        onSortEnd={onSortEnd}
                                        onSortStart={(_, event) => event.preventDefault()}
                                        axis={"x"}
                                        useDragHandle
                                        handleChartBuyClick={handleChartBuyClick}
                                        handleChartSellClick={handleChartSellClick}
                                        selectValue={selectValue}
                                        filteredCoins={filteredCoins}
                                        setFilteredCoins={setFilteredCoins}
                                        isSearched={isSearched}
                                        handleAddCoins={handleAddCoins}
                                        handleRemoveCoins={handleRemoveCoins}
                                        showCoinOptions={showCoinOptions}
                                        setShowCoinOptions={setShowCoinOptions}
                                        optionsRef={optionsRef}
                                        allCoins={allCoins}
                                        userCoins={userCoins}
                                        permissions={permissions}
                                    />
                                </div>
                                {approve && (
                                    <>
                                        <div className="dasboardInputWrapperFirst">
                                            <div className="dasboardInputWrapperFirstWrapper">
                                                <div
                                                    className="searchIcon"
                                                    onClick={() => {
                                                        setSearchPendingQuery(!searchPendingQuery);
                                                        searchRef2.current.focus();
                                                    }}
                                                >
                                                    <BiSearch color="#aeaeae" size={22} />
                                                </div>
                                                <input
                                                    ref={searchRef2}
                                                    type="text"
                                                    className={`dashboardChartInput ${searchPendingQuery ? "showSearchInput" : ""
                                                        }`}
                                                    placeholder="Search"
                                                    onChange={(event) =>
                                                        setPendingQuery(event.target.value)
                                                    }
                                                    value={pendingQuery}
                                                />
                                                <div
                                                    className="cursor-pointer"
                                                    style={{
                                                        maxWidth: "fit-content",
                                                        position: "relative",
                                                        top: "-2px",
                                                        right: "-10px",
                                                        display:
                                                            pendingQuery.length > 1 && searchPendingQuery
                                                                ? "block"
                                                                : "none",
                                                    }}
                                                    onClick={() => setPendingQuery("")}
                                                >
                                                    <FaTimes size={14} color="#444" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dashboardSlider" id="slider">
                                            <div className="dashboardSliderWrapper">
                                                <Slider
                                                    choosenCrypto={choosenCrypto}
                                                    choosenOrderType={choosenOrderType}
                                                    networks={networks}
                                                    networkLoading={networkLoading}
                                                    afterClick={afterClick}
                                                    setAfterClick={setAfterClick}
                                                    singleCryptoCurrencies={singleCryptoCurrencies}
                                                    cryptoCurrencies={cryptoCurrencies}
                                                    choosenNetwork={choosenNetwork}
                                                    setNetworkLoading={setNetworkLoading}
                                                    pendingQuery={pendingQuery}
                                                    coinInfo={coinInfo}
                                                    choosenCryptoBNBPrice={choosenCryptoBNBPrice}
                                                    fiat={fiat}
                                                    choosenCoinPrice={choosenCoinPrice}
                                                    setChoosenCoinPrice={setChoosenCoinPrice}
                                                    socket={socket}
                                                    setChoosenNetwork={setChoosenNetwork}
                                                    permissions={permissions}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </TabPanel>
                <TabPanel>
                    <div className="users" style={{ padding: "0", marginTop: "20px" }}>
                        <div
                            className="usersUserListContainer"
                            style={{ paddingBottom: "" }}
                        >
                            <DashboardTable
                                popUpData={popUpData}
                                tableLoading={tableLoading}
                                noMarginTop={true}
                                customHeight="calc(100vh - 187px)"
                            />
                        </div>
                    </div>
                </TabPanel>
            </Tabs>
        </div>
    );
}

export default normalComponent;

