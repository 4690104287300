import React, {useState} from "react";
import "./percentage.css";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import MainModal from "../ReusableComponents/MainModal";
import QrPin from "./PercetageTable/QrPin";
import TransferPercentages from "./PartnersPercentages/TransferPercentages";
import CryptoPercentages from "./PartnersPercentages/CryptoPercentages";

const Percentage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [percentages, setPercentages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [percentageType, setPercentageType] = useState([
    { label: "Crypto", value: "crypto" },
    { label: "Transfer", value: "transfer" },
    { label: "FX", value: "fx" },
  ]);
  const [isLoading, setIsLoading] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <div className="users">
        <MainModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onRequestClose={closeModal}
            closeModal={closeModal}
            closable={false}
            content={
                <div className="flex gap-10 flex-wrap justify-between h-full">
                    <h3 className="usersTableRegisterClientFormTitle">
                        Unlock Percentages
            </h3>

            <QrPin closeModal={closeModal} />
          </div>
        }
      />
      <Tabs
        style={{
          maxWidth: "100%",
          width: "100%",
        }}
      >
        <TabList
          style={{
            padding: "7px 20px 0 20px",
            background: "white",
            margin: "0",
          }}
        >
          <Tab>Crypto Percentages</Tab>
          <Tab>Transfer Percentages</Tab>
        </TabList>

        <TabPanel>
          <div className="users" style={{ padding: "0" }}>
            <div
              className="usersUserListContainer"
              style={{ paddingBottom: "" }}
            >
              <CryptoPercentages
                loading={loading}
                setLoading={setLoading}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="users" style={{ padding: "0" }}>
            <div
              className="usersUserListContainer"
              style={{ paddingBottom: "" }}
            >
              <TransferPercentages loading={loading} setLoading={setLoading} />
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Percentage;

