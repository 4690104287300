import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import AsyncSelect from "react-select/async";
import {publicRequest} from "../../utils/requestMethods";

export default function CustomAriaLive({
  handleSelected,
  fullWidth,
  client,
  error,
}) {
  const user = useSelector((state) => state.user.currentUser);
  const menu = useSelector((state) => state.user.menu);
    const [preventDefault, setPreventDefault] = useState(true)
    const [clients, setClients] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const viewAll = menu[7]?.viewAll === undefined ? false : menu[7]?.viewAll;
  const viewOwn = menu[7]?.viewOwn === undefined ? false : menu[7]?.viewOwn;
  const viewOther =
    menu[7]?.viewOther === undefined ? false : menu[7]?.viewOther;
  const partnerIdMain = user.partnerId;

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await publicRequest.get("client/");

        setClients(response.data);
      } catch (err) {
        console.log(err);
      }
    };

    const getOwnUsers = async () => {
      try {
        const response = await publicRequest.get(`client/getOwn/${user._id}`);

        setClients(response.data);
      } catch (err) {
        console.log(err);
      }
    };

    const getOtherUsers = async () => {
      try {
        const response = await publicRequest.get(
          `client/getOther/${partnerIdMain}`
        );

        setClients(response.data);
      } catch (err) {
        console.log(err);
      }
    };

    // if (viewAll === true) {
    getUsers();
    // } else if (viewOther === true) {
    //   getOtherUsers();
    // } else if (viewOwn === true) {
    //   getOwnUsers();
    // }
  }, []);

  const inputKeyDown = (e) => {
    if (e.keyCode === 13 && preventDefault) {
      e.preventDefault();
    }
  }
  const loadClients = async (inputValue) => {
      setPreventDefault(true)
    if (inputValue.length >= 3) {
        let response;
        try {
            response = await publicRequest.get(`/client/search/client?search=${inputValue}`)
        } catch (e) {
            console.log(e)
        }
        if (response.data.length > 0) {
            setPreventDefault(false)
        } else {
            setPreventDefault(true)
        }
        return response.data
    };
  }

  return (
    <form
      className="no-loading"
      style={{
        width: "100%",
        padding: fullWidth ? "0 20px 0 10px" : "",
      }}
    >
      <AsyncSelect
          classNamePrefix={`${error && "error-client"} custom-select`}
          isClearable={true}
          value={client}
          cacheOptions
          onKeyDown={inputKeyDown}
          defaultOptions
          getOptionLabel={(e) => e.firstName + " " + e.lastName}
          getOptionValue={(e) => e._id}
          loadOptions={(e) => loadClients(e)}
          onInputChange={(e) => setInputValue(e)}
          onChange={handleSelected}
          noOptionsMessage={() => "No clients found, keep searching..."}
          placeholder="Select a client"
      />
    </form>
  );
}

