import TransportWebHID from "@ledgerhq/hw-transport-webhid";
import AppTRX from "@ledgerhq/hw-app-trx";

export const checkForConnection = async (confirm = false, data) => {
    switch (data.network.toLowerCase()) {
        case 'trx':
            return await connectTRXApp();
    }

    return null
};


const connectTRXApp = async () => {
    return new Promise(async (resolve, reject) => {
        const transport = await TransportWebHID.create();
        try {
            const trx = new AppTRX(transport);
            let {address} = await trx.getAddress("44'/195'/0'/0/0");
            resolve({
                address,
                connected: true,
            });
        } catch (e) {
            resolve({
                address: false,
                connected: false,
            });
        } finally {
            await transport.close();
        }
    });
}
