import {render} from "react-thermal-printer";

const devicesFilter = [
    {
        //EPSON TM-T20III
        vendorId: 1208,
        productId: 3624,
        //serialNumber :"583741562060680000"
    },
    {
        //Tysoo PRP 300
        vendorId: 7358,
        productId: 2
    }
]

async function setup(usbDevice) {
    await usbDevice.open();

    await usbDevice.selectConfiguration(1);
    await usbDevice.claimInterface(0);

    return usbDevice;

}

const connectPrinter = async () => {
    let device = await (navigator.usb.getDevices()).then((devices) => {
        for (let device of devices) {
            let available = devicesFilter.find((df) => df.vendorId === device.vendorId && df.productId === device.productId);
            if (available) {
                return device;
            }
        }
    })

    if (device == null) {
        return navigator.usb.requestDevice({
            filters: devicesFilter
        })
            .then(async (selectedDevice) => {
                device = selectedDevice;
                return await setup(device);
            })
            .catch(error => {
                console.log(error);
            })
    } else {
        return await setup(device);
    }
}

const print = async (data) => {
    try {
        let device = await connectPrinter();

        let endpoint = device.configurations[0].interfaces[0].alternate.endpoints.find(dciae => dciae.direction === 'out')
        device.transferOut(endpoint?.endpointNumber, await render(data))
            .catch(error => {
                console.log(error);
            })
    } catch (e) {
        console.log(e)
    }
}

export default print;

