import React, {useEffect, useMemo, useRef, useState} from "react";
import Swal from "sweetalert2";
import {useSelector} from "react-redux";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";

import "./rulesTable.css";
import {publicRequest} from "../../../utils/requestMethods";
import {FaCheck, FaPlusCircle, FaTimes} from "react-icons/fa";
import MainModal from "../../ReusableComponents/MainModal";
import {ClipLoader} from "react-spinners";
import MainTable from "../../ReusableComponents/Tables/MRT_Main";

const RulesTable = () => {
  const user = useSelector((state) => state.user.currentUser);
  const [getRules, setGetRules] = useState([]);
  const [register, setRegister] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRule, setSelectedRule] = useState([]);
  const [activeItem, setActiveItem] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [submitOrEdit, setSubmitOrEdit] = useState("");
  const [message, setMessage] = useState("");

  const [id, setId] = useState("");
  const tableContainerRef = useRef();

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        size: 60,
      },
    ],
    []
  );

  const [rulesData, setRules] = useState({
    name: "",
    rules: [
      {
        id: 1,
        value: "dashboard",
        label: "Dashboard",
        permissions: [
          {
            buySell: false,
          },
          { approve: false },
        ],
      },
      {
        id: 2,
        value: "exchange",
        label: "Exchange",
        permissions: [
          {
            buySell: false,
          },
          { approve: false },
        ],
      },
      {
        id: 3,
        value: "transfer",
        label: "Transfer",
        permissions: [{ hasAccess: false }],
      },
      {
        id: 4,
        value: "transactions",
        label: "Transactions",
        permissions: [
          {
            viewAll: false,
          },
          { viewOwn: false },
          { viewOther: false },
        ],
      },
      {
        id: 5,
        value: "accounting",
        label: "Accounting",
        permissions: [{ hasAccess: false }],
      },
      {
        id: 6,
        value: "wallet",
        label: "Wallet",
        permissions: [{ hasAccess: false }],
      },
      {
        id: 7,
        value: "clients",
        label: "Clients",
        permissions: [
          {
            viewAll: false,
          },
          { viewOwn: false },
          { viewOther: false },
        ],
      },
      {
        id: 8,
        value: "users",
        label: "Users",
        permissions: [
          {
            viewAll: false,
          },
          { viewOwn: false },
          { viewOther: false },
        ],
      },
      {
        id: 9,
        value: "partners",
        label: "Partners",
        permissions: [{ hasAccess: false }],
      },
      {
        id: 10,
        value: "messages",
        label: "Messages",
        permissions: [{ hasAccess: false }],
      },
      {
        id: 11,
        value: "settings",
        label: "Settings",
        permissions: [{ hasAccess: false }],
      },
      {
        id: 12,
        value: "globalSettings",
        label: "Global Settings",
        parent: "settings",
        permissions: [{ hasAccess: false }],
      },
      {
        id: 13,
        value: "percentages",
        label: "Percentage",
        parent: "settings",
        permissions: [{ hasAccess: false }],
      },
      {
        id: 14,
        value: "currency",
        label: "Currency",
        parent: "settings",
        permissions: [{ hasAccess: false }],
      },
      {
        id: 15,
        value: "coins",
        label: "Coins",
        parent: "settings",
        permissions: [{ hasAccess: false }],
      },

      {
        id: 16,
        value: "rules",
        label: "Rules",
        parent: "settings",
        permissions: [{ hasAccess: false }],
      },
    ],
  });

  const handlePermissionChange = (e, permission) => {
    let arr = selectedRule;
    let newArr = [];

    let perm = Object.entries(permission).map((x) => x);

    perm[0][1] = e.target.checked;

    let label = perm[0][0];
    let value = perm[0][1];
    let id = perm[1][1];

    let newPerm = { [label]: value, id: id };

    arr.map((x, i) => {
      if (x === permission) {
        x = newPerm;
        arr[i] = newPerm;
      }
    });

    rulesData.rules.map((x, i) => {
      if (i === permission.id) {
        x.permissions = arr;
        rulesData.rules[i].permissions = arr;
      }
      newArr.push(x);
    });

    setRules({ ...rulesData, rules: newArr });
  };

  const openModal = () => {
    setIsOpen(true);
    setSelectedRule([]);
    setSubmitOrEdit("submit");
    setActiveItem(-1);
    setRules({
      name: "",
      rules: [
        {
          id: 1,
          value: "dashboard",
          label: "Dashboard",
          permissions: [
            {
              buySell: false,
            },
            { approve: false },
          ],
        },
        {
          id: 2,
          value: "exchange",
          label: "Exchange",
          permissions: [
            {
              buySell: false,
            },
            { approve: false },
          ],
        },
        {
          id: 3,
          value: "transfer",
          label: "Transfer",
          permissions: [{ hasAccess: false }],
        },
        {
          id: 4,
          value: "transactions",
          label: "Transactions",
          permissions: [
            {
              viewAll: false,
            },
            { viewOwn: false },
            { viewOther: false },
          ],
        },
        {
          id: 5,
          value: "accounting",
          label: "Accounting",
          permissions: [{ hasAccess: false }],
        },
        {
          id: 6,
          value: "wallet",
          label: "Wallet",
          permissions: [{ hasAccess: false }],
        },
        {
          id: 7,
          value: "clients",
          label: "Clients",
          permissions: [
            {
              viewAll: false,
            },
            { viewOwn: false },
            { viewOther: false },
          ],
        },
        {
          id: 8,
          value: "users",
          label: "Users",
          permissions: [
            {
              viewAll: false,
            },
            { viewOwn: false },
            { viewOther: false },
          ],
        },
        {
          id: 9,
          value: "partners",
          label: "Partners",
          permissions: [{ hasAccess: false }],
        },
        {
          id: 10,
          value: "messages",
          label: "Messages",
          permissions: [{ hasAccess: false }],
        },
        {
          id: 11,
          value: "settings",
          label: "Settings",
          permissions: [{ hasAccess: false }],
        },
        {
          id: 12,
          value: "percentages",
          label: "Percentage",
          parent: "settings",
          permissions: [{ hasAccess: false }],
        },
        {
          id: 13,
          value: "currency",
          label: "Currency",
          parent: "settings",
          permissions: [{ hasAccess: false }],
        },
        {
          id: 14,
          value: "coins",
          label: "Coins",
          parent: "settings",
          permissions: [{ hasAccess: false }],
        },
        {
          id: 16,
          value: "globalSettings",
          label: "Global Settings",
          parent: "settings",
          permissions: [{ hasAccess: false }],
        },
        {
          id: 15,
          value: "rules",
          label: "Rules",
          parent: "settings",
          permissions: [{ hasAccess: false }],
        },
      ],
    });
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedRule([]);
    setActiveItem(-1);
    setRules({
      name: "",
      rules: [
        {
          id: 1,
          value: "dashboard",
          label: "Dashboard",
          permissions: [
            {
              buySell: false,
            },
            { approve: false },
          ],
        },
        {
          id: 2,
          value: "exchange",
          label: "Exchange",
          permissions: [
            {
              buySell: false,
            },
            { approve: false },
          ],
        },
        {
          id: 3,
          value: "transfer",
          label: "Transfer",
          permissions: [{ hasAccess: false }],
        },
        {
          id: 4,
          value: "transactions",
          label: "Transactions",
          permissions: [
            {
              viewAll: false,
            },
            { viewOwn: false },
            { viewOther: false },
          ],
        },
        {
          id: 5,
          value: "accounting",
          label: "Accounting",
          permissions: [{ hasAccess: false }],
        },
        {
          id: 6,
          value: "wallet",
          label: "Wallet",
          permissions: [{ hasAccess: false }],
        },
        {
          id: 7,
          value: "clients",
          label: "Clients",
          permissions: [
            {
              viewAll: false,
            },
            { viewOwn: false },
            { viewOther: false },
          ],
        },
        {
          id: 8,
          value: "users",
          label: "Users",
          permissions: [
            {
              viewAll: false,
            },
            { viewOwn: false },
            { viewOther: false },
          ],
        },
        {
          id: 9,
          value: "partners",
          label: "Partners",
          permissions: [{ hasAccess: false }],
        },
        {
          id: 10,
          value: "messages",
          label: "Messages",
          permissions: [{ hasAccess: false }],
        },
        {
          id: 11,
          value: "settings",
          label: "Settings",
          permissions: [{ hasAccess: false }],
        },
        {
          id: 12,
          value: "percentages",
          label: "Percentage",
          parent: "settings",
          permissions: [{ hasAccess: false }],
        },
        {
          id: 13,
          value: "currency",
          label: "Currency",
          parent: "settings",
          permissions: [{ hasAccess: false }],
        },
        {
          id: 14,
          value: "coins",
          label: "Coins",
          parent: "settings",
          permissions: [{ hasAccess: false }],
        },
        {
          id: 16,
          value: "globalSettings",
          label: "Global Settings",
          parent: "settings",
          permissions: [{ hasAccess: false }],
        },
        {
          id: 15,
          value: "rules",
          label: "Rules",
          parent: "settings",
          permissions: [{ hasAccess: false }],
        },
      ],
    });
  };

  const handlePermissionAdd = (e, index) => {
    setActiveItem(index);
    if (e === null) {
      setSelectedRule(null);
    } else {
      let arr = e.map((x) => x);
      arr.map((x) => (x.id = index));

      setSelectedRule(arr);
    }
  };

  useEffect(() => {
    retrieveRules();
  }, []);

  const retrieveRules = async () => {
    try {
      setLoading(true);
      const response = await publicRequest.get("roles/");
      setGetRules(response.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleEdit = async (event) => {
    event.preventDefault();

    const editUser = {
      isAdmin: user.isAdmin,
      title,
      dashboard,
      exchange,
    };
    if (user.isAdmin === true) {
      try {
        const response = await publicRequest.patch(`roles/${id}`, editUser, {
          withCredentials: true,
        });

        Swal.fire(`${title} is Updated! Page will reload in 3 seconds!`);

        setTimeout(() => {
          location.reload();
        }, 3000);
      } catch (err) {
        console.log(err);
      }
    } else {
      console.log("Only Admin Can Update a User!");
      Swal.fire(`Not Allowed! Only Admin Can Update a User!`);
    }
  };

  const handleAddRule = async () => {
    try {
      setLocalLoading(true);
      if (submitOrEdit === "submit") {
        const response = await publicRequest.post("roles/", rulesData);
        setMessage("added");
      } else {
        const response = await publicRequest.patch(
          `roles/${rulesData.id}`,
          rulesData
        );
        setMessage("edited");
      }

      setLocalLoading(false);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        setFail(false);
        retrieveRules();
        closeModal();
      }, 2000);
    } catch (err) {
      setLocalLoading(false);
      setFail(true);
      setTimeout(() => {
        setSuccess(false);
        setFail(false);
        closeModal();
      }, 2000);
      console.log(err);
    }
  };

  const handleRuleName = (e) => {
    setRules((prev) => ({
      ...prev,
      name: e.target.value,
    }));
  };

  const handleUserEditInfo = (propsData) => {
    setIsOpen(true);
    setSubmitOrEdit("edit");
    setMessage("edited");
    setEdit(true);
    setRegister(false);
    setRules({
      id: propsData._id,
      name: propsData.name,
      rules: propsData.rules,
    });
  };

  const handleDelete = (e, propsData) => {
    setIsOpen(false);
    const admin = { isAdmin: user.isAdmin };

    const deleteUser = async () => {
      try {
        setLoading(true);
        const response = await publicRequest.delete(`roles/${propsData._id}`, {
          data: admin,
        });
        setGetRules(getRules.filter((x) => x._id !== propsData._id));
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    };

    Swal.fire({
      title: `Do you want to delete ?`,
      showDenyButton: true,
      confirmButtonText: "Accept",
      denyButtonText: `Refuse`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUser();
      }
    });
  };

  let coll = document.getElementsByClassName("collapsible");
  let i;

  for (i = 0; i < coll.length; i++) {
    coll[i].addEventListener("click", function () {
      this.classList.toggle("active");
      let content = this.nextElementSibling;
      if (content.style.display === "block") {
        content.style.display = "none";
      } else {
        content.style.display = "block";
      }
    });
  }

  const successEdit = (params) => {
    setSuccess(true);
    setMessage(params);
    setTimeout(() => {
      setRowClicked(false);
      setSuccess(false);
      setIsOpen(false);
    }, 2000);
  };

  return (
    <div className="users" style={{ padding: "0" }}>
      <Tabs
        style={{
          maxWidth: "100%",
          width: "100%",
        }}
      >
        <TabList
          style={{
            padding: "7px 20px 0 20px",
            background: "white",
            margin: "0",
          }}
        >
          <Tab>Rules</Tab>
        </TabList>

        <TabPanel>
          <div
            className="users"
            style={{
              padding: 0,
              marginTop: "20px",
            }}
          >
            <div
              className="usersUserListContainer"
              style={{ paddingBottom: "" }}
            >
              <div
                className="TitleAndButtonContainer"
                style={{
                  width: "100% ",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <MainModal
                  closable={true}
                  isOpen={isOpen}
                  closeModal={closeModal}
                  onRequestClose={closeModal}
                  customSubmit={handleAddRule}
                  footer={true}
                  success={success}
                  content={
                    <>
                      <h3
                        className="usersTableRegisterClientFormTitle"
                        style={{ marginBottom: "20px" }}
                      >
                        {submitOrEdit === "submit"
                          ? "Add New Rule"
                          : "Edit Rule"}
                      </h3>
                      {localLoading ? (
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%)",
                          }}
                        >
                          <ClipLoader size={30} color="orange" />{" "}
                        </div>
                      ) : success ? (
                        <div
                          className="grid place-center w-full h-full"
                          style={{ transform: "translateY(150%)" }}
                        >
                          <FaCheck
                            size={40}
                            color="white"
                            style={{
                              background: "#25da25",
                              padding: "10px",
                              borderRadius: "50%",
                            }}
                          />
                          <div
                            style={{
                              fontWeight: "300",
                              fontSize: "14px",
                              margin: "5px 0",
                            }}
                          >
                            Rule {message} Successfully!
                          </div>
                        </div>
                      ) : fail ? (
                        <div
                          className="grid place-center w-full h-full"
                          style={{ transform: "translateY(150%)" }}
                        >
                          <FaTimes
                            size={40}
                            color="white"
                            style={{
                              background: "red",
                              padding: "10px",
                              borderRadius: "50%",
                            }}
                          />
                          <div
                            style={{
                              fontWeight: "300",
                              fontSize: "14px",
                              margin: "5px 0",
                            }}
                          >
                            There was and error, please try again!
                          </div>
                        </div>
                      ) : (
                        <>
                          <div
                            className="usersTableRegisterFormItem"
                            style={{ width: "99%" }}
                          >
                            <label className="usersTableRegisterFormLabel">
                              Rule Name
                            </label>
                            <input
                              style={{ width: "98%" }}
                              name="name"
                              type="text"
                              className="usersTableRegisterFormInput"
                              onChange={(e) => {
                                handleRuleName(e);
                              }}
                              value={rulesData?.name}
                            />
                          </div>
                          <div
                            className="flex justify-start w-full gap-20"
                            style={{ marginTop: "30px" }}
                          >
                            <div
                              style={{
                                width: "46%",
                                marginTop: "5px",
                              }}
                            >
                              {rulesData?.rules?.map((rule, index) => (
                                <div className="grid gap-10 w-full">
                                  <span
                                    onClick={() =>
                                      handlePermissionAdd(
                                        rule.permissions,
                                        index
                                      )
                                    }
                                    className="hover-item"
                                    style={{
                                      background:
                                        activeItem === index
                                          ? "rgb(248, 230, 196)"
                                          : "",
                                      padding: "10px 3px",
                                      borderBottom: "1px solid #d7d7d7",
                                    }}
                                  >
                                    {rule.label}
                                  </span>
                                </div>
                              ))}
                            </div>
                            <div
                              style={{
                                width: "1px",
                                background: "#d7d7d7",
                              }}
                            ></div>
                            {selectedRule?.length > 0 ? (
                              <div style={{ width: "46%" }}>
                                {selectedRule?.map((x) => (
                                  <>
                                    <div className="grid gap-10 w-full">
                                      <span
                                        className="flex items-center justify-between w-full"
                                        style={{
                                          padding: "10px 3px",
                                          borderBottom: "1px solid #d7d7d7",
                                        }}
                                      >
                                        <span
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {Object.keys(x)[0]}
                                        </span>
                                        <label
                                          className="custom-checkbox-container"
                                          style={{ marginTop: 0 }}
                                        >
                                          <input
                                            name={Object.keys(x)}
                                            type="checkbox"
                                            checked={Object.values(x)[0]}
                                            onChange={(e) =>
                                              handlePermissionChange(e, x)
                                            }
                                            style={{
                                              width: "22px",
                                              height: "22px",
                                            }}
                                          />
                                          <span className="custom-checkmark"></span>
                                        </label>
                                      </span>
                                    </div>
                                  </>
                                ))}
                              </div>
                            ) : (
                              <div style={{ padding: "10px 3px" }}>
                                No permissions available.
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  }
                />
              </div>
              <div className="rulesTableContainer">
                <MainTable
                    columns={columns}
                    requestURL={"roles/"}
                    tableContainerRef={tableContainerRef}
                    onRowClick={handleUserEditInfo}
                    isSocketUpdatable={false}
                    tableHeight={"calc(100vh - 187px)"}
                    renderTopToolbarCustomActions={[
                        {
                            type: "add",
                            name: "Add",
                            icon: <FaPlusCircle size={20}/>,
                            onClick: () => {
                                openModal();
                            },
                        },
                    ]}
                    density={"comfortable"}
                    toolbarActions={{
                    search: true,
                    density: true,
                    fullScreen: true,
                  }}
                />
              </div>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default RulesTable;

