import React, { useEffect, useRef, useState } from "react";
import { FaTrash } from "react-icons/fa";
import PinInput from "react-pin-input";
import { ClipLoader } from "react-spinners";
import Button from "../../ReusableComponents/Button";
import { QRCodeSVG } from "qrcode.react";
import { useSelector } from "react-redux";

function QrPin({ closeModal }) {
  const user = useSelector((state) => state.user.currentUser);
  let ele = useRef();

  const [qrPin, setQrPin] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errorPin, setErrorPin] = useState(false);

  const checkPinSuccess = (value) => {
    if (value == "123456") {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setErrorPin(false);
        closeModal();
      }, 1000);
    } else {
      setErrorPin(true);
    }
  };

  return (
    <div
      className="w-full h-full flex flex-column items-center justify-center"
      style={{
        position: "absolute",
        top: "0",
        left: "0",
      }}
    >
      <h2>Scan or Enter PIN To Authorize</h2>
      <div
        className="w-full flex items-center justify-center"
        style={{ margin: "20px 0" }}
      >
        <QRCodeSVG
          value={`otpauth://totp/TresorX%20(${user.email})?secret=${user.secret}`}
        />
      </div>
      <PinInput
        length={6}
        initialValue=""
        secret={false}
        onChange={(value, index) => setQrPin(value)}
        type="numeric"
        inputMode="number"
        inputStyle={{ borderColor: errorPin ? "red" : "#d7d7d7" }}
        style={{ padding: "10px" }}
        inputFocusStyle={{ borderColor: errorPin ? "red" : "orange" }}
        onComplete={(value, index) => {
          checkPinSuccess(value);
        }}
        value={qrPin}
        autoSelect={true}
        regexCriteria={/^[ 0-9]*$/}
        ref={(n) => (ele = n)}
      />
      <div
        className="flex items-center justify-center"
        style={{ marginTop: "15px", width: "100%" }}
      >
        {loading ? (
          <ClipLoader size={24} color="orange" />
        ) : (
          <div style={{ maxWidth: "fit-content" }}>
            <Button
              onClick={() => {
                ele.clear();
                setErrorPin(false);
              }}
              title="Clear"
              icon={<FaTrash size={22} color="white" />}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default QrPin;

