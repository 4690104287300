import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import {FaArrowLeft, FaTimes} from "react-icons/fa";
import './Modals.css';
import CurrenciesList from './Default/CurrenciesList'
import DepositForm from "./DepositSteps/DepositForm";
import WithdrawalForm from './WithdrawalSteps/WithdrawalForm';
import TransferTypes from "./transfer/TransferTypes";
import SelectedTransferDetails from "../../Transfer/TransferButtons/SelectedTransferDetails";

const Currencies = ({isOpen, setIsOpen, onRequestClose, type, title, setAction, selectedPartner}) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [direction, setDirection] = useState(null);
    const [customClass, setCustomClass] = useState("")
    const [transferType, setTransferType] = useState({
        title: "",
        type: -1,
    });

    const handleTransferType = (title, type) => {
        let obj = {
            title: title,
            type: type,
        };
        setTransferType(obj);
        setDirection('forward')
        setCurrentStep(currentStep + 1);
    };

    useEffect(() => {
        if (transferType.type === 2) {
            setCustomClass("w-550")
        } else {
            setCustomClass("")
        }
    }, [transferType])

    const stepComponents = {
        deposit: [
            {
                component: CurrenciesList,
                props: {
                    currentStep: currentStep,
                    setCurrentStep: setCurrentStep,
                    setSelectedCurrency: setSelectedCurrency,
                    setDirection: setDirection,
                    style: {
                        width: 'auto'
                    }
                }
            },
            {
                component: DepositForm,
                props: {
                    setIsOpen: setIsOpen,
                    currency: selectedCurrency,
                    selectedPartner: selectedPartner,
                    style: {
                        width: 650
                    }
                }
            }
        ],
        withdrawal: [
            {
                component: CurrenciesList,
                props: {
                    currentStep: currentStep,
                    setCurrentStep: setCurrentStep,
                    setSelectedCurrency: setSelectedCurrency,
                    setDirection: setDirection,
                    style: {
                        width: 'auto'
                    }
                }
            },
            {
                component: WithdrawalForm,
                props: {
                    setIsOpen: setIsOpen,
                    currency: selectedCurrency,
                    selectedPartner: selectedPartner,
                    style: {
                        width: 650
                    }
                }
            }
        ],
        transfer: [
            {
                component: CurrenciesList,
                props: {}
            },
        ],
        send: [
            {
                component: CurrenciesList,
                props: {
                    currentStep: currentStep,
                    setCurrentStep: setCurrentStep,
                    setSelectedCurrency: setSelectedCurrency,
                    setDirection: setDirection,
                    style: {
                        width: 'auto'
                    }
                }
            },
            {
                component: TransferTypes,
                props: {
                    handleTransferType: handleTransferType,
                }
            },
            {
                component: SelectedTransferDetails,
                props: {
                    selectedCurrency: selectedCurrency,
                    transferType: transferType,
                    transactionType: title,
                    setIsOpen: setIsOpen,
                    setAction: setAction,
                    setCurrentStep: setCurrentStep,
                    setDirection: setDirection,
                }
            }
        ],
        receive: [
            {
                component: CurrenciesList,
                props: {
                    currentStep: currentStep,
                    setCurrentStep: setCurrentStep,
                    setSelectedCurrency: setSelectedCurrency,
                    setDirection: setDirection,
                    style: {
                        width: 'auto'
                    }
                }
            },
            {
                component: TransferTypes,
                props: {
                    handleTransferType: handleTransferType,
                }
            },
            {
                component: SelectedTransferDetails,
                props: {
                    selectedCurrency: selectedCurrency,
                    transferType: transferType,
                    transactionType: title,
                    setIsOpen: setIsOpen,
                    setAction: setAction,
                    setCurrentStep: setCurrentStep,
                    setDirection: setDirection,
                }
            }
        ],
    }

    const handleBackStep = () => {
        setDirection('backward');
        setTransferType({});
        setCurrentStep(currentStep - 1);
    }

    let CurrencyComponent = () => null;
    let props = {};
    if (type && stepComponents[type]) {
        const stepComponent = stepComponents[type][currentStep];
        if (stepComponent) {
            CurrencyComponent = stepComponent.component;
            props = stepComponent.props;
        }
    }

    const handleCloseModal = () => {
        setCurrentStep(0)
        setTransferType({})
        setDirection(null);
        onRequestClose();
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleCloseModal}
            className={`CurrenciesModel ${customClass}`}
            overlayClassName="CurrenciesModel-overlay"
            animationType="slide"
        >
            <div>
                <div className="CurrenciesModel-header">
                    {currentStep > 0 && (
                        <button className="CurrenciesModel-back" onClick={handleBackStep}>
                            <FaArrowLeft/>
                        </button>
                    )}
                    <h2 className="CurrenciesModel-title"><span>{title}</span></h2>
                    <button className="CurrenciesModel-close" onClick={handleCloseModal}>
                        <FaTimes/>
                    </button>
                </div>
                <div className={`CurrenciesModel-content ${direction}`}>
                    <CurrencyComponent {...props} />
                </div>
            </div>
        </Modal>
    )

}


export default Currencies;
