import {Paper} from "@material-ui/core";
import React from "react";
import {FaCheck, FaTimes} from "react-icons/fa";
import Button from "../../../Button";
import {NumberFormatter} from "../../../NumberFormat";
import {NumericFormat} from "react-number-format";

function TradingInfo({ data }) {
  return (
    <Paper
      variant="outlined"
      style={{ maxWidth: "100%", marginTop: "2em", paddingBottom: "0.5em" }}
    >
      <center>
        <div
          style={{
            borderBottom: "1px solid lightgrey",
            maxWidth: "95%",
            marginBottom: "2em",
            paddingTop: "0.5em",
            paddingBottom: "0.5em",
          }}
        >
          <span style={{ fontSize: "16px", fontWeight: "600" }}>
            Trade Info
          </span>
        </div>
      </center>

      <div
        style={{
          display: "grid",
          placeItems: "center",
          margin: "0 2.5%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "start",
            gap: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "25%",
              wordBreak: "break-all",
            }}
          >
            <span
              style={{
                fontWeight: "400",
                color: "#666666",
                fontSize: "14px",
                textAlign: "center",
                lineHeight: "1.5",
              }}
            >
              Side
            </span>
            <span
              style={{
                overflow: "hidden",
                fontWeight: "600",
                textOverflow: "ellipsis",
                color: "rgba(0, 0, 0, 0.87)",
                fontSize: "14px",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
              {data.side?.toUpperCase()}
            </span>
          </div>
          <div style={{ display: "grid", placeItems: "center", width: "25%" }}>
            <span
              style={{
                fontWeight: "400",
                color: "#666666",
                fontSize: "14px",
                textAlign: "center",
                lineHeight: "1.5",
              }}
            >
              Fee
            </span>
            {data.status !== 1 ? (
              <span
                style={{
                  overflow: "hidden",
                  fontWeight: "600",
                  textOverflow: "ellipsis",
                  color: data.value_fee?.manual ? "red" : "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                  lineHeight: "1.5",
                  textAlign: "center",
                }}
              >
                {data.value_fee?.fee}%
              </span>
            ) : (
              <div className="flex items-center gap-5">
                <input
                  name="phone1"
                  value={data.value_fee?.fee}
                  type="text"
                  className="usersTableRegisterFormInput"
                  style={{
                    marginTop: "0",
                    width: "40px",
                    padding: "5px 0",
                    textAlign: "center",
                  }}
                />
                %
              </div>
            )}
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", width: "25%" }}
          >
            <span
                style={{
                    fontWeight: "400",
                    color: "#666666",
                    fontSize: "14px",
                    textAlign: "center",
                    lineHeight: "1.5",
                }}
            >
              FX Rate x Paid
            </span>
              {(data.status === 1 || data.status === 5) && data.mode === "AUTO" ? (<></>) :
                  data.exchange_rate?.map((item) => (
                      <div
                          className={`${data.status === 1
                              ? "flex items-center gap-10 justify-between"
                              : ""
                          }`}
                          style={{
                              border: data.status !== 1 ? "0" : "1px solid #d7d7d7",
                              borderRadius: "4px",
                              padding: "2px 5px",
                              width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                {data.status === 1 && (
                  <span
                    style={{
                      position: "absolute",
                      left: "calc(100% + 5px)",
                      fontSize: "12px",
                    }}
                  >
                    {item.symbol}
                  </span>
                )}
                {data.status !== 1 ? (
                  <>
                    <span
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        color: "rgba(0, 0, 0, 0.87)",
                        fontSize: "14px",
                        lineHeight: "1.5",
                        flex: 1,
                        width: "55px",
                      }}
                    >
                      {item.rate}
                    </span>
                    <span
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        color: "rgba(0, 0, 0, 0.87)",
                        fontSize: "14px",
                        lineHeight: "1.5",
                        textAlign: "center",
                      }}
                    >
                      x
                    </span>
                  </>
                ) : (
                  <div style={{ position: "relative" }}>
                    <input
                      name="rate"
                      value={item.rate}
                      type="text"
                      style={{
                        flex: 1,
                        border: "0",
                        borderRight: "1px solid #d7d7d7",
                        paddingRight: "10px",
                        maxWidth: "50px",
                      }}
                    />
                  </div>
                )}
                <span
                  style={{
                    textOverflow: "ellipsis",
                    color: "rgba(0, 0, 0, 0.87)",
                    fontSize: "14px",
                    lineHeight: "1.5",
                    textAlign: "center",
                    flex: 1,
                    position: "relative",
                  }}
                >
                  {item.price}
                  {data.status !== 1 && (
                    <span
                      style={{
                        position: "absolute",
                        left: "100%",
                        fontSize: "12px",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                    >
                      {item.symbol}
                    </span>
                  )}
                </span>

                <span
                  style={{
                    fontWeight: "300",
                    fontSize: "13px",
                    width: "fit-content",
                  }}
                >
                  {item.currency}
                </span>
              </div>
            ))}
          </div>
          <div style={{ display: "grid", placeItems: "center", width: "25%" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                style={{
                  fontWeight: "400",
                  color: "#666666",
                  fontSize: "14px",
                  textAlign: "center",
                  lineHeight: "1.5",
                }}
              >
                Mode
              </span>
              <span
                style={{
                  overflow: "hidden",
                  fontWeight: "600",
                  textOverflow: "ellipsis",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px",
                  lineHeight: "1.5",
                  textAlign: "center",
                }}
              >
                {data.mode?.toUpperCase()}
              </span>
            </div>
          </div>
        </div>
        <div
          className="w-full flex justify-between gap-30 items-center"
          style={{ marginTop: "20px" }}
        >
          <div className="grid place-items" style={{ width: "25%" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                style={{
                  fontWeight: "400",
                  color: "#666666",
                  fontSize: "14px",
                  textAlign: "center",
                  lineHeight: "1.5",
                }}
              >
                Symbol
              </span>
            </div>
            <span
              style={{
                overflow: "hidden",
                fontWeight: "600",
                textOverflow: "ellipsis",
                color: "rgba(0, 0, 0, 0.87)",
                fontSize: "14px",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
              {data.symbol?.split("/")[0]}
            </span>
          </div>
          <div className="grid place-items" style={{ width: "25%" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                style={{
                  fontWeight: "400",
                  color: "#666666",
                  fontSize: "14px",
                  textAlign: "center",
                  lineHeight: "1.5",
                }}
              >
                Quantity
              </span>
            </div>
            <span
              style={{
                overflow: "hidden",
                fontWeight: "600",
                textOverflow: "ellipsis",
                color: "rgba(0, 0, 0, 0.87)",
                fontSize: "14px",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
            {data.quantity_sent || data.quantity}
            </span>
          </div>
          <div className="grid place-items" style={{ width: "25%" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                style={{
                  fontWeight: "400",
                  color: "#666666",
                  fontSize: "14px",
                  textAlign: "center",
                  lineHeight: "1.5",
                }}
              >
                Exchange
              </span>
            </div>
            <span
              style={{
                overflow: "hidden",
                fontWeight: "600",
                textOverflow: "ellipsis",
                color: "rgba(0, 0, 0, 0.87)",
                fontSize: "14px",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
              {data.exchange?.toUpperCase()}
            </span>
          </div>
          <div className="grid place-items" style={{ width: "25%" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                style={{
                  fontWeight: "400",
                  color: "#666666",
                  fontSize: "14px",
                  textAlign: "center",
                  lineHeight: "1.5",
                }}
              >
                Network
              </span>
            </div>
            <span
              style={{
                overflow: "hidden",
                fontWeight: "600",
                textOverflow: "ellipsis",
                color: "rgba(0, 0, 0, 0.87)",
                fontSize: "14px",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
              {data.network_name}
            </span>
          </div>
        </div>
        <div
          className="w-full flex justify-center items-center"
          style={{ marginTop: "20px", gap: "10px" }}
        >
          <div className="grid place-items" style={{ width: "25%" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                style={{
                  fontWeight: "400",
                  color: "#666666",
                  fontSize: "14px",
                  textAlign: "center",
                  lineHeight: "1.5",
                }}
              >
                Price
              </span>
            </div>
            <span
                style={{
                    overflow: "hidden",
                    fontWeight: "600",
                    textOverflow: "ellipsis",
                    color: "rgba(0, 0, 0, 0.87)",
                    fontSize: "14px",
                    lineHeight: "1.5",
                    textAlign: "center",
                }}
            >
                <NumericFormat
                    displayType={"text"}
                    value={data.last_price_filled}
                    suffix=" USD"
                    thousandSeparator=","
                    decimalSeparator="."
                />
            </span>
          </div>
            {data.side === 'sell' && (
                <div className="grid place-items" style={{width: "25%"}}>
                    <div style={{display: "flex", flexDirection: "column"}}>
              <span
                  style={{
                      fontWeight: "400",
                      color: "#666666",
                      fontSize: "14px",
                      textAlign: "center",
                      lineHeight: "1.5",
                  }}
              >
                Withdrawal Fee
              </span>
                    </div>
                    <span
                        style={{
                            overflow: "hidden",
                            fontWeight: "600",
                            textOverflow: "ellipsis",
                            color: "rgba(0, 0, 0, 0.87)",
                            fontSize: "14px",
                            lineHeight: "1.5",
                            textAlign: "center",
                        }}
                    >
              <NumberFormatter
                  value={(
                      (data.quantity - data.quantity_sent) *
                      data.last_price_filled
                  ).toFixed(3)}
              />
            </span>
                </div>
            )}

            {data.mode === "AUTO" && (
                <div className="grid place-items" style={{width: "25%"}}>
                    <div style={{display: "flex", flexDirection: "column"}}>
                <span
                    style={{
                        fontWeight: "400",
                        color: "#666666",
                        fontSize: "14px",
                        textAlign: "center",
                        lineHeight: "1.5",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                  Converted
                    {!data.is_converted && <FaTimes size={14} color="red"/>}
                </span>
                    </div>
                    <span
                        style={{
                            overflow: "hidden",
                            fontWeight: "600",
                            textOverflow: "ellipsis",
                            color: "rgba(0, 0, 0, 0.87)",
                            fontSize: "14px",
                            lineHeight: "1.5",
                            textAlign: "center",
                        }}
                    >
                {data.is_converted ? (
                    <FaCheck size={14} color="green"/>
                ) : (
                    <Button
                        onClick={() => console.log("convert")}
                        cls="small-padding"
                        title="Convert"
                    />
                )}
              </span>
                </div>
            )}
        </div>
      </div>
    </Paper>
  );
}

export default TradingInfo;

