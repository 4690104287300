import React, {useState, useEffect} from "react";
import {publicRequest} from "../../../utils/requestMethods";
import MainSelect from "../../ReusableComponents/Select";
import {FaTimes} from "react-icons/fa";
import {components} from "react-select";

function PartnerPercentage({partnerData, setPartnerData}) {
    const [otherPercentage, setOtherPercentage] = useState(0);


    useEffect(() => {
        if (partnerData.percentagesMethod === undefined) {
            partnerData.percentagesMethod = {
                type: 1,
                fields: {
                    partner: 0,
                    expenses: 0,
                }
            };
        }
    }, []);


    useEffect(() => {
        let partner = partnerData.percentagesMethod?.fields.partner;
        let expenses = partnerData.percentagesMethod?.fields.expenses;

        let other = 100;

        other -= partner;
        other -= expenses;

        setOtherPercentage(other);
    }, [partnerData]);

    return (
        <div>
            <div className="flex flex-column w-50 ">
                <h4
                    style={{
                        fontWeight: "400",
                        marginBottom: "5px",
                    }}
                >
                    Percentage Method
                </h4>
                <div class="switch-holder">
                    <div class="switch-toggle-yesno">
                        <input
                            type="checkbox"
                            id="published"
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setPartnerData((prev) => ({
                                        ...prev,
                                        percentagesMethod: {
                                            ...prev.percentagesMethod,
                                            type: 2,
                                        },
                                    }));
                                } else {
                                    setPartnerData((prev) => ({
                                        ...prev,
                                        percentagesMethod: {
                                            fields: {
                                                partner: 0,
                                                expenses: 0,
                                            },
                                            type: 1,
                                        },
                                    }));
                                }
                            }}
                            checked={partnerData?.percentagesMethod?.type === 2 ? true : false}
                        />
                        <label className="toggle-label" for="published"></label>
                    </div>
                </div>
            </div>
            {partnerData?.percentagesMethod?.type === 2 && (
                <div style={{maxWidth: "98%"}} className="flex gap-30">
                    <div className="usersTableRegisterFormItem" style={{flex: 1}}>
                        <label className="usersTableRegisterFormLabel">Partner</label>
                        <div
                            className="dashboardSliderSecondRightExchangeCurrencyTransferFeeValue"
                            style={{width: "100%", marginLeft: "0"}}
                        >
                            <input
                                type="text"
                                className="dashboardSliderSecondRightExchangeCurrencyValueSpan"
                                style={{
                                    width: "100%",
                                    textAlign: "left",
                                    paddingLeft: "10px",
                                }}
                                onFocus={(event) => {
                                    setTimeout(() => {
                                        event.target.select();
                                    }, 100);
                                }}
                                onChange={(e) =>
                                    setPartnerData((prev) => ({
                                        ...prev,
                                        percentagesMethod: {
                                            ...prev.percentagesMethod,
                                            fields: {
                                                ...prev.percentagesMethod.fields,
                                                partner: e.target.value,
                                            },
                                        },
                                    }))
                                }
                                value={partnerData.percentagesMethod?.fields.partner}
                            />
                            %
                        </div>
                    </div>
                    <div className="usersTableRegisterFormItem" style={{flex: 1}}>
                        <label className="usersTableRegisterFormLabel">Expenses</label>
                        <div
                            className="dashboardSliderSecondRightExchangeCurrencyTransferFeeValue"
                            style={{width: "100%", marginLeft: "0"}}
                        >
                            <input
                                type="text"
                                className="dashboardSliderSecondRightExchangeCurrencyValueSpan"
                                style={{
                                    width: "100%",
                                    textAlign: "left",
                                    paddingLeft: "10px",
                                }}
                                onFocus={(event) => event.target.select()}
                                onChange={(e) =>
                                    setPartnerData((prev) => ({
                                        ...prev,
                                        percentagesMethod: {
                                            ...prev.percentagesMethod,
                                            fields: {
                                                ...prev.percentagesMethod.fields,
                                                expenses: e.target.value,
                                            },
                                        },
                                    }))
                                }
                                value={partnerData.percentagesMethod?.fields.expenses}
                            />
                            %
                        </div>
                    </div>

                    <div className="usersTableRegisterFormItem" style={{flex: 1}}>
                        <label className="usersTableRegisterFormLabel">Other</label>
                        <div
                            className="dashboardSliderSecondRightExchangeCurrencyTransferFeeValue"
                            style={{
                                width: "100%",
                                marginLeft: "0",
                                backgroundColor: "#eee",
                            }}
                        >
                            <input
                                type="text"
                                className="dashboardSliderSecondRightExchangeCurrencyValueSpan"
                                style={{
                                    width: "100%",
                                    textAlign: "left",
                                    paddingLeft: "10px",
                                    backgroundColor: "#eee",
                                }}
                                disabled
                                value={otherPercentage}
                            />
                            %
                        </div>
                    </div>
                </div>
            )}
            {/* <MainSelect
        isClearable={true}
        isSearchable={true}
        onChange={(e) => handlePercentageChange(e)}
        options={percentages}
        getOptionLabel={(e) => e.label}
        getOptionValue={(e) => e.value}
        components={{ GroupHeading }}
      />
      <div
        style={{
          minHeight: "250px",
          border: "1px solid #d8d8d8",
          broderRadius: "4px",
          marginTop: "20px",
        }}
      >
        <div className="grid gap-10" style={{ padding: "6px" }}>
          {tempPercentages?.map((x) => (
            <>
              <span
                style={{
                  padding: "5px 5px 0 5px",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  color: "#989898",
                }}
              >
                {x.groupName}
              </span>
              <div className="listOfCurrency" style={{ marginTop: "-10px" }}>
                <span>{x.label}</span>
                <FaTimes
                  onClick={() => removePercentage(x.value)}
                  color="white"
                  size={14}
                  className="remove-currency cursor-pointer"
                />
              </div>
            </>
          ))}
        </div>
      </div> */}
        </div>
    );
}

export default PartnerPercentage;

