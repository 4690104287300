import React, {Fragment, useState} from "react";
import {FaCheck, FaTimes} from "react-icons/fa";
import MainSelect from "../../ReusableComponents/Select";
import {balkanCountries} from "../../ReusableComponents/Countries";
import {capitalizeWords, countryObject} from "../../../utils/utils";

function PercentageAddFields({
                                 percentageBuyData,
                                 percentageSellData,
                                 setPercentageBuyData,
                                 setPercentageSellData,
                                 setPercentageData,
                                 percentageData,
                                 success,
                                 message,
                                 isLoading,
                                 transferData,
                                 fail,
                             }) {
    const [value, setValue] = useState("");
    const [fromFocused, setFromFocused] = useState(false);
    const [toFocused, setToFocused] = useState(false);

    const handleBothChange = ({target: {name, value}}) => {
        setPercentageBuyData((prev) => ({
            ...prev,
            [name]: value,
        }));
        setPercentageSellData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleBuyChange = ({target: {name, value}}) => {
        if (name === "fromValue" && !fromFocused) {
            setPercentageSellData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }

        if (name === "toValue" && !toFocused) {
            setPercentageSellData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
        setPercentageBuyData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSellChange = ({target: {name, value}}) => {
        setPercentageSellData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleDataChange = ({target: {name, value}}) => {
        setPercentageData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleDetailsChange = (e) => {
        _.set(percentageData.percentageDetails, 'country', e.name);
        setPercentageData({...percentageData});
    };

    const handleTypeChange = (e) => {
        _.set(percentageData.percentageDetails, 'type', e.value);
        setPercentageData({...percentageData});
    };

    const handleFocus = (e) => {
        setTimeout(() => {
            e.target.select();
        }, 100);
    };

    return (
        <Fragment>
            <>
                {success ? (
                    <div
                        className="grid place-center w-full h-full"
                        style={{transform: "translateY(150%)"}}
                    >
                        <FaCheck
                            size={40}
                            color="white"
                            style={{
                                background: "#25da25",
                                padding: "10px",
                                borderRadius: "50%",
                            }}
                        />
                        <div
                            style={{
                                fontWeight: "300",
                                fontSize: "14px",
                                margin: "5px 0",
                            }}
                        >
                            Percentage {message} Successfully!
                        </div>
                    </div>
                ) : fail ? (
                    <div
                        className="grid place-center w-full h-full"
                        style={{transform: "translateY(150%)"}}
                    >
                        <FaTimes
                            size={40}
                            color="white"
                            style={{
                                background: "red",
                                padding: "10px",
                                borderRadius: "50%",
                            }}
                        />
                        <div
                            style={{
                                fontWeight: "300",
                                fontSize: "14px",
                                margin: "5px 0",
                            }}
                        >
                            There was and error, please try again!
                        </div>
                    </div>
                ) : (
                    <div className="flex items-start w-full flex-column justify-between w-full gap-20">
                        <div className="grid flex-1 w-full" style={{marginTop: "-10px"}}>
                            <div className="usersTableRegisterFormItem w-full">
                                <label className="usersTableRegisterFormLabel">Name</label>
                                <input
                                    name="percentageName"
                                    onChange={transferData ? handleDataChange : handleBothChange}
                                    value={percentageBuyData?.percentageName}
                                    type="text"
                                    className="usersTableRegisterFormInput"
                                />
                            </div>
                        </div>
                        <div className="flex flex-1 w-full gap-30">
                            {transferData ? (
                                <div className="flex-1" style={{marginTop: "-25px"}}>
                                    <div className="usersTableRegisterFormItem w-full">
                                        <label className="usersTableRegisterFormLabel">
                                            From Value
                                        </label>
                                        <input
                                            name="fromValue"
                                            onChange={handleDataChange}
                                            value={percentageData?.fromValue}
                                            type="text"
                                            onFocus={handleFocus}
                                            className="usersTableRegisterFormInput"
                                        />
                                    </div>
                                    <div className="usersTableRegisterFormItem w-full">
                                        <label className="usersTableRegisterFormLabel">
                                            To Value
                                        </label>
                                        <input
                                            name="toValue"
                                            onChange={handleDataChange}
                                            value={percentageData?.toValue}
                                            type="text"
                                            onFocus={handleFocus}
                                            className="usersTableRegisterFormInput"
                                        />
                                    </div>
                                    <div className="usersTableRegisterFormItem w-full">
                                        <label className="usersTableRegisterFormLabel">
                                            Percentage
                                        </label>
                                        <input
                                            name="percentageValue"
                                            onChange={handleDataChange}
                                            value={percentageData?.percentageValue}
                                            type="text"
                                            onFocus={handleFocus}
                                            className="usersTableRegisterFormInput"
                                        />
                                    </div>
                                    <div className="usersTableRegisterFormItem w-full">
                                        <label className="usersTableRegisterFormLabel">
                                            Partner
                                        </label>
                                        <input
                                            name="percentagePartner"
                                            onChange={handleDataChange}
                                            value={percentageData?.percentagePartner}
                                            type="text"
                                            onFocus={handleFocus}
                                            className="usersTableRegisterFormInput"
                                        />
                                    </div>
                                    <div className="usersTableRegisterFormItem w-full">
                                        <label className="usersTableRegisterFormLabel">
                                            Expenses
                                        </label>
                                        <input
                                            name="percentageExpenses"
                                            onChange={handleDataChange}
                                            value={percentageData?.percentageExpenses}
                                            type="text"
                                            className="usersTableRegisterFormInput"
                                        />
                                    </div>
                                    <div
                                        className="usersTableRegisterFormItem"
                                        style={{width: "101.5%", marginLeft: "-7px"}}
                                    >
                                        <label
                                            className="usersTableRegisterFormLabel"
                                            style={{marginBottom: "5px"}}
                                        >
                                            Country
                                        </label>
                                        <div className="w-full">
                                            <MainSelect
                                                placeholder="Select a Country"
                                                cls="top-450"
                                                isClearable={true}
                                                isSearchable={true}
                                                options={balkanCountries}
                                                name={'country'}
                                                onChange={(e) => handleDetailsChange(e)}
                                                getOptionLabel={(e) => e.name}
                                                getOptionValue={(e) => e.code}
                                                isLoading={isLoading}
                                                defaultValue={countryObject(percentageData.percentageDetails.country)}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="usersTableRegisterFormItem"
                                        style={{width: "101.5%", marginLeft: "-7px"}}
                                    >
                                        <label
                                            className="usersTableRegisterFormLabel"
                                            style={{marginBottom: "5px"}}
                                        >
                                            Type
                                        </label>
                                        <div className="w-full">
                                            <MainSelect
                                                placeholder="Select a Type"
                                                cls="top-450"
                                                isClearable={true}
                                                isSearchable={true}
                                                name={'type'}
                                                options={[
                                                    {label: "Lite Transfer", value: "lite_transfer"},
                                                    {
                                                        label: "Internal Transfer",
                                                        value: "internal_transfer",
                                                    },
                                                    {label: "Transfer", value: "transfer"},
                                                    {label: "Deposit", value: "deposit"},
                                                    {label: "Withdrawal", value: "withdrawal"},
                                                ]}
                                                onChange={(e) => handleTypeChange(e)}
                                                getOptionLabel={(e) => e.label}
                                                getOptionValue={(e) => e.value}
                                                isLoading={isLoading}
                                                defaultValue={{
                                                    label: capitalizeWords(percentageData?.percentageDetails?.type),
                                                    value: percentageData?.percentageDetails?.type,
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {" "}
                                    <div className="flex-1">
                                        <h4
                                            style={{
                                                fontWeight: "400",
                                                borderBottom: "3px solid orange",
                                                width: "fit-content",
                                                fontSize: "20px",
                                            }}
                                        >
                                            We Buy
                                        </h4>
                                        <div className="usersTableRegisterFormItem w-full">
                                            <label className="usersTableRegisterFormLabel">
                                                From Value
                                            </label>
                                            <input
                                                name="fromValue"
                                                onChange={handleBuyChange}
                                                value={percentageBuyData?.fromValue}
                                                type="text"
                                                onFocus={handleFocus}
                                                className="usersTableRegisterFormInput"
                                            />
                                        </div>
                                        <div className="usersTableRegisterFormItem w-full">
                                            <label className="usersTableRegisterFormLabel">
                                                To Value
                                            </label>
                                            <input
                                                name="toValue"
                                                onChange={handleBuyChange}
                                                value={percentageBuyData?.toValue}
                                                type="text"
                                                onFocus={handleFocus}
                                                className="usersTableRegisterFormInput"
                                            />
                                        </div>
                                        <div className="usersTableRegisterFormItem w-full">
                                            <label className="usersTableRegisterFormLabel">
                                                Percentage
                                            </label>
                                            <input
                                                name="percentageValue"
                                                onChange={handleBuyChange}
                                                value={percentageBuyData?.percentageValue}
                                                type="text"
                                                onFocus={handleFocus}
                                                className="usersTableRegisterFormInput"
                                            />
                                        </div>
                                        <div className="usersTableRegisterFormItem w-full">
                                            <label className="usersTableRegisterFormLabel">
                                                Partner
                                            </label>
                                            <input
                                                name="percentagePartner"
                                                onChange={handleBuyChange}
                                                value={percentageBuyData?.percentagePartner}
                                                type="text"
                                                onFocus={handleFocus}
                                                className="usersTableRegisterFormInput"
                                            />
                                        </div>
                                        <div className="usersTableRegisterFormItem w-full">
                                            <label className="usersTableRegisterFormLabel">
                                                Expenses
                                            </label>
                                            <input
                                                name="percentageExpenses"
                                                onChange={handleBuyChange}
                                                value={percentageBuyData?.percentageExpenses}
                                                type="text"
                                                className="usersTableRegisterFormInput"
                                            />
                                        </div>
                                    </div>
                                    <div className="sliderMiddleLine"></div>
                                    <div className="flex-1">
                                        <h4
                                            style={{
                                                fontWeight: "400",
                                                borderBottom: "3px solid orange",
                                                width: "fit-content",
                                                fontSize: "20px",
                                            }}
                                        >
                                            We Sell
                                        </h4>
                                        <div className="usersTableRegisterFormItem w-full">
                                            <label className="usersTableRegisterFormLabel">
                                                From Value
                                            </label>
                                            <input
                                                name="fromValue"
                                                onChange={handleSellChange}
                                                value={percentageSellData?.fromValue}
                                                onFocus={(e) => {
                                                    handleFocus(e);
                                                    setFromFocused(true);
                                                }}
                                                type="text"
                                                className="usersTableRegisterFormInput"
                                            />
                                        </div>
                                        <div className="usersTableRegisterFormItem w-full">
                                            <label className="usersTableRegisterFormLabel">
                                                To Value
                                            </label>
                                            <input
                                                name="toValue"
                                                onChange={handleSellChange}
                                                onFocus={(e) => {
                                                    handleFocus(e);
                                                    setToFocused(true);
                                                }}
                                                value={percentageSellData?.toValue}
                                                type="text"
                                                className="usersTableRegisterFormInput"
                                            />
                                        </div>
                                        <div className="usersTableRegisterFormItem w-full">
                                            <label className="usersTableRegisterFormLabel">
                                                Percentage
                                            </label>
                                            <input
                                                name="percentageValue"
                                                onChange={handleSellChange}
                                                value={percentageSellData?.percentageValue}
                                                type="text"
                                                onFocus={handleFocus}
                                                className="usersTableRegisterFormInput"
                                            />
                                        </div>
                                        <div className="usersTableRegisterFormItem w-full">
                                            <label className="usersTableRegisterFormLabel">
                                                Partner
                                            </label>
                                            <input
                                                name="percentagePartner"
                                                onChange={handleSellChange}
                                                value={percentageSellData?.percentagePartner}
                                                type="text"
                                                onFocus={handleFocus}
                                                className="usersTableRegisterFormInput"
                                            />
                                        </div>
                                        <div className="usersTableRegisterFormItem w-full">
                                            <label className="usersTableRegisterFormLabel">
                                                Expenses
                                            </label>
                                            <input
                                                name="percentageExpenses"
                                                onChange={handleSellChange}
                                                value={percentageSellData?.percentageExpenses}
                                                type="text"
                                                className="usersTableRegisterFormInput"
                                            />
                                        </div>
                                    </div>
                                    {" "}
                                </>
                            )}
                        </div>
                    </div>
                )}
            </>
        </Fragment>
    );
}

export default PercentageAddFields;

