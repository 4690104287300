import React, {useEffect, useMemo, useRef, useState} from "react";
import iconGenerator from "../../../utils/iconGenerator";
import {publicRequest} from "../../../utils/requestMethods";
import {AiOutlineGlobal, AiOutlineScan} from "react-icons/ai";
import MainModal from "../../ReusableComponents/MainModal";
import ReusableStatusComponent from "../../ReusableComponents/ReusableStatusComponent";
import Moment from "moment";
import {NumberFormatter} from "../../ReusableComponents/NumberFormat";
import MainTable from "../../ReusableComponents/Tables/MRT_Main";
import {useSelector} from "react-redux";
import {TransferStatus} from "../../ReusableComponents/Status/TransferStatus";
import {checkTransferAmount} from "../../../utils/calculation";
import TransferPopupInfo from "../../ReusableComponents/PopUpInfo/Transfer/TransferPopupInfo";
import Swal from "sweetalert2";
import Content from "../TransferButtons/Content";
import {BiExport} from "react-icons/bi";
import Currencies from "../../ReusableComponents/Modals/Currencies";

const TransactionsTable = ({
                               currencyId,
                               setCurrencyId,
                               scanData,
                               setScanData,
                               customHeight,
                               noButtons
                           }) => {
    const user = useSelector((state) => state.user.currentUser);
    const [isOpen, setIsOpen] = useState(false);
    const [transaction, setTransaction] = useState({});
    const [isOpenScan, setIsOpenScan] = useState(false);
    const [modalType, setModalType] = useState(null);

    const [step, setStep] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [rejected, setRejected] = useState(false);
    const [action, setAction] = useState({type: "", id: null, data: null});

    const [isTransferOpen, setIsTransferOpen] = useState(false);
    const [title, setTitle] = useState("");

    const popUpData = useRef({});
    const tableContainerRef = useRef(null);

    const params = useSelector((state) => state.user.params);


    const openTransferModal = (title, type) => {
        setIsTransferOpen(true);
        setModalType(type)
        setTitle(title);
    };

    const openScanModal = (title) => {
        setIsOpenScan(true);
        setTitle(title);
    }

    const closeTransferModal = () => {
        setIsTransferOpen(false);
        setIsOpenScan(false);
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: "order_number",
                header: "Order.Num",
                enableClickToCopy: true,
                size: 90,
                muiTableHeadCellProps: () => ({
                    sx: {
                        paddingLeft: "20px",
                    },
                }),
                muiTableBodyCellProps: () => ({
                    sx: {
                        paddingLeft: "20px",
                    },
                }),
                Cell: ({cell}) => {
                    return <span>{cell.getValue()}</span>;
                },
            },
            {
                accessorKey: "createdAt",
                header: "Date",
                muiTableBodyCellProps: {
                    align: "center",
                },
                size: 120,
                maxSize: 120,
                accessorFn: (e) => (
                    <div>{Moment(e.createdAt).format("DD.MM.YYYY HH:mm")}</div>
                ),
            },
            {
                accessorKey: "currency.symbol",
                header: "Symbol",
                filterable: false,
                muiTableBodyCellProps: {
                    align: "center",
                },
                size: 80,

                accessorFn: (e) => (
                    <div>
                        <div className="flex items-center gap-5">
                            <img
                                src={iconGenerator(
                                    128,
                                    "icon",
                                    e.currency.symbol,
                                    e.type === "crypto" ? false : true
                                )}
                                alt=""
                                style={{borderRadius: "12px"}}
                                className="dashboardChartHeaderIcon"
                            />
                            {e.currency.symbol}
                        </div>
                    </div>
                ),
                filterSelectOptions: params.filters.transactions.transfer.symbol.filterSelectOptions,
                filterVariant: params.filters.transactions.transfer.symbol.filterVariant,

            },
            {
                accessorKey: "transfer_type",
                header: "Transfer",
                filterable: false,
                muiTableBodyCellProps: {
                    align: "center",
                },
                size: 80,
                accessorFn: (e) => (
                    <div>
                        {e.transfer_type === "internal_transfer" ? (
                            <div className="flex items-center gap-5">
                                <AiOutlineGlobal size={18}/>
                                Internal
                            </div>
                        ) : e.transfer_type === "lite_transfer" ? (
                            <div className="flex items-center gap-5">
                                <AiOutlineGlobal size={18}/>
                                Lite
                            </div>
                        ) : (
                            <div className="flex items-center gap-5">
                                <AiOutlineGlobal size={18}/>
                                Transfer
                            </div>
                        )}
                    </div>
                ),
                filterSelectOptions: params.filters.transactions.transfer.type.filterSelectOptions,
                filterVariant: params.filters.transactions.transfer.type.filterVariant,
            },
            {
                accessorKey: "transaction_type",
                header: "Transaction",
                muiTableBodyCellProps: {
                    align: "center",
                },
                size: 80,
                accessorFn: (e) => (
                    <TransferStatus transaction={e} partner={user.partnerId}/>
                    // <div>
                    //     {(e.transaction_type === "send" && e.source_partner?._id !== user.partnerId._id) ? (
                    //         <div className="flex items-center gap-5">
                    //             <BiExport size={18}/>
                    //             Send
                    //         </div>
                    //     ) : (
                    //         <div className="flex items-center gap-5">
                    //             <BiImport size={18}/>
                    //             Receive
                    //         </div>
                    //     )}
                    // </div>
                ),
                filterSelectOptions: params.filters.transactions.transfer.side.filterSelectOptions,
                filterVariant: params.filters.transactions.transfer.side.filterVariant,
            },
            {

                id: 'initiator_user',
                header: "Initiator",
                filterable: false,
                muiTableBodyCellProps: {
                    align: "center",
                },
                size: 80,
                accessorFn: (e) => <div>{e?.initiator_user?.fullName ?? ''}</div>,
                filterSelectOptions: params.filters.global.users.filterSelectOptions,
                filterVariant: params.filters.global.users.filterVariant,
            },
            {
                accessorKey: "source_partner",
                header: "Source",
                filterable: false,
                muiTableBodyCellProps: {
                    align: "center",
                },
                size: 80,
                accessorFn: (e) => <div>{e.source_partner?.partnerName}</div>,
                filterSelectOptions: params.filters.global.partners.filterSelectOptions,
                filterVariant: params.filters.global.partners.filterVariant,
            },
            {
                accessorKey: "destination_partner",
                header: "Destination",
                filterable: false,
                muiTableBodyCellProps: {
                    align: "center",
                },
                size: 80,
                accessorFn: (e) =>
                    <div>{e.destination_partner?.partnerName || e?.receiver || (e?.clientId ? `${e?.clientId?.firstName} ${e?.clientId?.lastName}` : '')}</div>,
                filterSelectOptions: params.filters.global.partners.filterSelectOptions,
                filterVariant: params.filters.global.partners.filterVariant,
            },
            {
                accessorKey: "amount",
                header: "Amount",
                filterable: false,
                muiTableBodyCellProps: {
                    align: "center",
                },
                size: 100,
                accessorFn: (e) => <NumberFormatter
                    value={e.amount}
                    suffix={` ${e.currency?.symbol}`}
                    prefix={`${checkTransferAmount(e, user)}`}
                />,
            },
            {
                accessorKey: "fee",
                header: "Fee",
                filterable: false,
                muiTableBodyCellProps: {
                    align: "center",
                },
                size: 60,
                accessorFn: (e) => <NumberFormatter
                    value={e.fee}
                    suffix={` ${e.currency.symbol}`}
                />,
            },
            {
                accessorKey: "summary",
                header: "Summary",
                filterable: false,
                muiTableBodyCellProps: {
                    align: "center",
                },
                size: 100,
                accessorFn: (e) => <NumberFormatter
                    value={(e.transaction_type === 'deposit' || e.transaction_type === 'withdrawal') ? e.summary : e.destination_partner?._id === user.partnerId?._id ? parseFloat(e.summary - e.fee) : e.summary}
                    suffix={` ${e.currency?.symbol}`}
                    prefix={`${checkTransferAmount(e, user)}`}
                />,
            },
            {
                accessorKey: "country",
                header: "Country",
                filterable: false,
                muiTableBodyCellProps: {
                    align: "center",
                },
                size: 110,
                filterSelectOptions: params.filters.transactions.transfer.country.filterSelectOptions,
                filterVariant: params.filters.transactions.transfer.country.filterVariant,
            },
            {
                accessorKey: "status",
                header: "Status",
                size: 80,
                filterSelectOptions: [
                    {text: "Pending", value: "1"},
                    {text: "Approved", value: "2"},
                    {text: "In Progress", value: "3"},
                    {text: "Completed", value: "4"},
                    {text: "Declined", value: "5"},
                ],
                filterVariant: "multi-select",
                accessorFn: (e) => <ReusableStatusComponent status={e.status}/>,
            },
        ],
        []
    );

    const closeModal = () => {
        setLoading(false);
        setStep(-1);
        setIsOpen(false);
    };

    const handleInfo = (data) => {
        popUpData.current = data;
        setIsOpen(true);
        setStep(-1);
    };

    const handleOnSubmit = async (id) => {
        Swal.fire({
            title: `Are you sure you want to approve this transfer?`,
            icon: 'question',
            showDenyButton: true,
            confirmButtonText: "Yes",
            denyButtonText: `Cancel`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    setLoading(true);
                    const response = await publicRequest.patch("/transfer/approval/" + id);
                    setLoading(false);
                    setStep(4);
                    setAction({
                        type: "edited",
                        id: response.data._id,
                        data: response.data,
                    });
                    setTimeout(() => {
                        setIsOpen(false);
                        setLoading(false);
                    }, 2000);
                } catch (e) {
                    console.log(e)
                }
            }
        })

    };

    const denyRequest = async (id) => {
        Swal.fire({
            title: `Are you sure you want to deny this transfer?`,
            icon: 'question',
            showDenyButton: true,
            confirmButtonText: "Yes",
            denyButtonText: `Cancel`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);
                const response = await publicRequest.patch("/transfer/declined/" + id);
                setLoading(false);
                setStep(5);
                setAction({
                    type: "edited",
                    id: response.data._id,
                    data: response.data,
                });
                setTimeout(() => {
                    setIsOpen(false);
                    setLoading(false);
                }, 2000);
            }
        })
    };

    useEffect(() => {
        const getScanData = async () => {
            try {
                const response = await publicRequest.get(`/transfer/${scanData.id}`);
                if (response.status === 200) {
                    popUpData.current = response.data;
                    setIsOpen(true);
                    setScanData({});
                }
            } catch (e) {
                console.error(e);
            }
        };

        if (scanData?.type?.includes('transfer')) {
            getScanData();
        }
    }, [scanData]);

    return (
        <div
            className="clientsTable"
            style={{
                width: "100%",
                overflowX: "hidden",
                padding: 0,
                marginTop: 0,
            }}
        >
            <MainModal
                closeOnEsc={true}
                closable={true}
                isOpen={isOpenScan}
                onRequestClose={closeTransferModal}
                closeModal={closeTransferModal}
                content={
                    <Content
                        title={title}
                        accounts={user?.details?.accounts}
                        userAccounts={true}
                        setIsOpen={setIsOpenScan}
                        setAction={setAction}
                        currencyId={currencyId}
                    />
                }
            />

            <Currencies
                isOpen={isTransferOpen}
                setIsOpen={setIsTransferOpen}
                closable={true}
                onRequestClose={closeTransferModal}
                title={title}
                type={modalType}
                accounts={tableContainerRef.current}
                setAction={setAction}
            />

            <MainModal
                closable={true}
                closeOnEsc={true}
                isOpen={isOpen}
                onRequestClose={closeModal}
                closeModal={closeModal}
                content={
                    <>
                        <TransferPopupInfo
                            data={popUpData.current}
                            setIsOpenPopUp={setIsOpen}
                            connectLedger={handleOnSubmit}
                            step={step}
                            setStep={setStep}
                            loading={loading}
                            makeTransaction={{}}
                            walletAddress={{}}
                            rejected={rejected}
                            transaction={transaction}
                            setTransaction={setTransaction}
                            denyRequest={denyRequest}
                        />
                    </>
                }
            />
            <div
                className="clientsTableMaterialTableContainer"
                style={{width: "100%", overflowX: "hidden"}}
            >
                <MainTable
                    columns={columns}
                    customFilters={
                        _.isEmpty(currencyId)
                            ? []
                            : [{id: "currency", value: currencyId._id}]
                    }
                    requestURL="v2/transfers"
                    onRowClick={handleInfo}
                    tableHeight={customHeight ? customHeight : "78vh"}
                    renderCurrencyFooterToolbar={true}
                    renderFetchFooterToolbar={false}
                    tableContainerRef={tableContainerRef}
                    action={action}
                    density={'compact'}
                    setAction={setAction}
                    renderTopToolbarCustomActions={!noButtons ? [
                        {
                            type: "send",
                            name: "Send",
                            icon: <BiExport size={20}/>,
                            onClick: () => {
                                openTransferModal("Send", "send");
                            },
                        },
                        // {
                        //     type: "receive",
                        //     name: "Receive",
                        //     icon: <BiImport size={20}/>,
                        //     onClick: () => {
                        //         openTransferModal("Receive", "receive");
                        //     },
                        // },
                        {
                            type: "scan",
                            name: "Scan",
                            icon: <AiOutlineScan size={20}/>,
                            onClick: () => {
                                openScanModal("Scan");
                            },
                        },
                    ] : []}
                />
            </div>
        </div>
    );
};

export default TransactionsTable;

