export const sortingNetwork = (networks) => {
    const sortOrder = [
        "BTC",
        "TRX",
        "ETH",
        "BSC",
        "BEP20",
        "MATIC",
        "SOL",
        "XTZ",
        "AVAXC",
        "ARBITRUM",
        "OPTIMISM",
        "SEGWITBTC",
        "BNB",
    ];
    return networks.sort(
        (a, b) =>
            sortOrder.indexOf(a.network.toUpperCase()) -
            sortOrder.indexOf(b.network.toUpperCase())
    );
};

export const sortingCoins = (coinsArray, coins) => {
    return coins.sort(
        (a, b) =>
            coinsArray.indexOf(a.symbol.toUpperCase()) -
            coinsArray.indexOf(b.symbol.toUpperCase())
    );
};

export const sortingPairs = (pairArray, pairs) => {
    return pairs.sort(
        (a, b) =>
            pairArray.indexOf(a._id) -
            pairArray.indexOf(b._id)
    );
}


export const sortingTypes = (types) => {
    const orderTypes = [
        'crypto',
        'deposit',
        'withdrawal',
        'exchange',
        'transfer',
        'internal_transfer',
        'lite_transfer',
        'voucher',
    ];

    return types.sort(
        (a, b) =>
            orderTypes.indexOf(a) -
            orderTypes.indexOf(b)
    );
}
