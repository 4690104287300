import React, { useEffect, useState } from "react";
import Select from "../../ReusableComponents/Select";
import { TbArrowBarToRight, TbArrowNarrowLeft, TbChevronRight, } from "react-icons/tb";
import iconGenerator from "../../../utils/iconGenerator";
import SelectedTransferDetails from "./SelectedTransferDetails";
import scanner from "../../../utils/scanner";
import Button from "../../ReusableComponents/Button";
import { AiOutlineScan } from "react-icons/ai";
import { NumericFormat } from "react-number-format";
import { NumberFormatter } from "../../ReusableComponents/NumberFormat";
import { publicRequest } from "../../../utils/requestMethods";
import MainModal from "../../ReusableComponents/MainModal";
import TransferPopupInfo from "../../ReusableComponents/PopUpInfo/Transfer/TransferPopupInfo";

function Content({ title, accounts, setIsOpen, setAction, userAccounts, currencyId }) {
  const [currencies, setCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectLoading, setSelectLoading] = useState(false);
  const [transferType, setTransferType] = useState({
    title: "",
    type: -1,
  });

  useEffect(() => {
    setSelectLoading(true);
    if (!userAccounts && accounts) {
      setCurrencies(
        Object.entries(accounts.getRowModel().rowsById).map(
          (x) => x[1].original
        )
      );
      setSelectLoading(false);
    } else {
      setCurrencies(
        accounts
      )
    }
  }, [accounts]);

  useEffect(() => {
    if (currencyId?.balance && currencyId?.balance.length) {
      setSelectedCurrency({ currency: currencyId })
    } else {
      setSelectedCurrency(null)
    }
  }, [])

  const handleTransferType = (title, type) => {
    let obj = {
      title: title,
      type: type,
    };
    setTransferType(obj);
  };

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(false);
  const [popUpInfo, setPopUpInfo] = useState(false);
  const [scanData, setScanData] = useState({});
  const [errorScan, setErrorScan] = useState(false);
  const closeModal = () => {
    setLoading(false);
    setStep(-1);
    setPopUpInfo(false);
  };

  const getScanData = async (id) => {
    setErrorScan(false);
    try {
      const response = await publicRequest.get(`/transfer/${id}`);
      if (response?.status === 200) {
        if (response.data.status) {
          setScanData(response.data);
          setPopUpInfo(true);
          setStep(-1)
        }
      } else {
        setErrorScan(true);
        setPopUpInfo(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleChange = async (values, source) => {
    setErrorScan(false);
    if (values.floatValue > 99999) {
      await getScanData(values.floatValue);
    }
  };

  const openScanner = async () => {
    const scan = await scanner();
    scan.oninputreport = (event) => {
      const { data } = event;
      var enc = new TextDecoder("utf-8");
      let add = enc.decode(data).replace(/[^a-z0-9]/gi, "");
      let testData = btoa(
        JSON.stringify({
          id: "63ad92af5c94e8882da96d9b",
          type: "internal_transfer",
        })
      );
    };
  };

  const handleOnSubmit = async (id) => {
    try {
      setLoading(true);
      const response = await publicRequest.patch("/transfer/approval/" + id);
      setLoading(false);
      setStep(4);
      setTimeout(() => {
        setPopUpInfo(false);
        setLoading(false);
      }, 2000);
    } catch (e) { }
  };

  const denyRequest = async (id) => {
    setLoading(true);
    const response = await publicRequest.patch("/transfer/declined/" + id);
    setLoading(false);
    setStep(5);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  return (
    <>
      <MainModal
        closable={true}
        closeOnEsc={true}
        isOpen={popUpInfo}
        onRequestClose={closeModal}
        closeModal={closeModal}
        content={
          <>
            <TransferPopupInfo
              data={scanData}
              setIsOpenPopUp={setIsOpen}
              connectLedger={handleOnSubmit}
              step={step}
              setStep={setStep}
              loading={loading}
              makeTransaction={{}}
              walletAddress={{}}
              rejected={false}
              transaction={{}}
              setTransaction={{}}
              denyRequest={denyRequest}
            />
          </>
        }
      />

      <div
        className="dashboardSliderSecondAddressModalAddressWrapper"
        style={{ width: "98%", alignItems: "start" }}
      >
        {transferType.type !== -1 && (
          <div
            onClick={() => setTransferType({ ...transferType, type: -1 })}
            style={{ position: "absolute", top: "10px", left: "10px" }}
            className="cursor-pointer"
          >
            <TbArrowNarrowLeft size={24} color="777777" />
          </div>
        )}
        <h2
          style={{
            display: "block",
            margin: "0 auto",
          }}
        >
          {transferType.type !== -1
            ? transferType.title + ` (${title})`
            : title}
        </h2>
        {title !== "Scan" ? (
          <>
            {selectedCurrency !== null && transferType.type === -1 ? (
              <div className="flex items-center gap-10">
                <img
                  src={iconGenerator(
                    128,
                    "icon",
                    selectedCurrency.currency.symbol,
                    true
                  )}
                  alt=""
                  className="leftSidebarIcons"
                  style={{ width: 60, height: 60 }}
                />
                <div className="grid gap-10">
                  <h2 style={{ fontSize: "18px" }}>
                    Balance:
                    <span
                      style={{ color: "rgb(35, 182, 236)", fontSize: "16px" }}
                    >
                      {" "}
                      <NumberFormatter
                        value={selectedCurrency?.currency?.balance ? selectedCurrency?.currency?.balance : selectedCurrency?.balance}
                        suffix={` ${selectedCurrency?.currency?.symbol}`}
                      ></NumberFormatter>
                    </span>
                  </h2>
                </div>
              </div>
            ) : (
              <></>
            )}
            {transferType.type !== -1 ? (
              <div className="w-full">
                <SelectedTransferDetails
                  selectedCurrency={selectedCurrency}
                  transferType={transferType}
                  transactionType={title}
                  setIsOpen={setIsOpen}
                  setAction={setAction}
                />
              </div>
            ) : (
              <div className="w-full">
                <div
                  className="w-full"
                  style={{
                    margin: "20px 0",
                    minHeight: selectedCurrency !== null ? "" : "255px",
                  }}
                >
                  <Select
                    isSearchable={true}
                    isClearable={true}
                    options={currencies}
                    isLoading={selectLoading}
                    getOptionLabel={(coin) => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={iconGenerator(
                            128,
                            "icon",
                            coin.currency.symbol,
                            true
                          )}
                          style={{ height: "30px" }}
                        />
                        <span style={{ marginLeft: 5 }}>
                          {coin.currency.name} ({coin.currency.symbol})
                        </span>
                      </div>
                    )}
                    getOptionValue={(coin) => coin.currency.symbol}
                    defaultValue={selectedCurrency}
                    onChange={(e) => setSelectedCurrency(e)}
                    placeholder="Select currency"
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    borderTop:
                      selectedCurrency !== null ? "1px solid #d2d2d2" : "0",
                    paddingTop: "30px",
                    display: "grid",
                    gap: "10px",
                  }}
                >
                  {selectedCurrency !== null ? (
                    <>
                      <div
                        className="transfer-transfers"
                        onClick={() =>
                          handleTransferType("Internal Transfer", 3)
                        }
                      >
                        <div className="flex gap-10 items-center">
                          <TbArrowBarToRight
                            style={{
                              background: "#ededed",
                              padding: "8px",
                              borderRadius: "50%",
                            }}
                            color="#777777"
                          />
                          <p>Internal Transfer</p>
                        </div>

                        <TbChevronRight color="orange" />
                      </div>
                      <div
                        className="transfer-transfers"
                        onClick={() => handleTransferType("Lite Transfer", 2)}
                      >
                        <div className="flex gap-10 items-center">
                          <TbArrowBarToRight
                            style={{
                              background: "#ededed",
                              padding: "8px",
                              borderRadius: "50%",
                            }}
                            color="#777777"
                          />
                          <p>Lite Transfer</p>
                        </div>

                        <TbChevronRight color="orange" />
                      </div>
                      <div
                        className="transfer-transfers"
                        onClick={() => handleTransferType("Transfer", 1)}
                      >
                        <div className="flex gap-10 items-center">
                          <TbArrowBarToRight
                            style={{
                              background: "#ededed",
                              padding: "8px",
                              borderRadius: "50%",
                            }}
                            color="#777777"
                          />
                          <p>Transfer</p>
                        </div>

                        <TbChevronRight color="orange" />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            )}
          </>
        ) : (
          <div
            className="w-full grid place-center h-full"
            style={{ transform: "translateY(50%)" }}
          >
            <div
              className="usersTableRegisterFormItem"
              style={{ width: "98%", marginLeft: "-7px" }}
            >
              {/* <label
                style={{ fontSize: "12px" }}
                className="usersTableRegisterFormLabel"
              >
                From
              </label> */}
              <NumericFormat
                placeholder="Enter manual code ( MTCN )"
                className="usersTableRegisterFormInput"
                prefix={"#"}
                displayType={"input"}
                style={{
                  padding: "3px 6px",
                  marginTop: "5px",
                  height: 25,
                  borderRadius: "8px",
                  marginLeft: "10px",
                }}
                maxLength={7}
                onValueChange={(values, sourceInfo) =>
                  handleChange(values, sourceInfo)
                }
              />
              {errorScan && (
                <span style={{ marginTop: "5px", color: "red" }}>
                  Transaction no found.
                </span>
              )}
            </div>
            <div className="divider-or" style={{ margin: "30px 0" }}>
              or
            </div>
            <Button
              icon={<AiOutlineScan size={25} color="white" />}
              title="Scan a Code"
              onClick={() => openScanner()}
              cls="w-full"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default Content;

