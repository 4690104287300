import React, {useEffect, useState} from "react";
import iconGenerator from "../../../utils/iconGenerator";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";

import {TbChevronDown, TbChevronUp} from "react-icons/tb";
import {FaCloudUploadAlt} from "react-icons/fa";

import {balkanCountries} from "../../ReusableComponents/Countries";
import Select from "../../ReusableComponents/Select";
import MainSelect from "../../ReusableComponents/Select";
import {publicRequest} from "../../../utils/requestMethods";
import AsyncSelect from "react-select/async";
import {useSelector} from "react-redux";
import {internalPrint} from "../../../utils/templates/internalTransfer";
import {litePrint} from "../../../utils/templates/liteTransfer";
import {NumericFormat} from "react-number-format";
import {NumberFormatter} from "../../ReusableComponents/NumberFormat";
import Swal from "sweetalert2";
import AddClientModal from "../../ReusableComponents/AddClientModal";
import {RiUserAddLine} from "react-icons/ri";
import Loader from "../../ReusableComponents/Modals/Messages/loader";
import Success from "../../ReusableComponents/Modals/Messages/success";
import Failed from "../../ReusableComponents/Modals/Messages/failed";

function SelectedTransferDetails({
    selectedCurrency,
    transferType,
    transactionType,
    setIsOpen,
    setAction,
    setCurrentStep
}) {
    const user = useSelector((state) => state.user.currentUser);
    const percentages = useSelector((state) => state.user.percentages?.transfer);
    const { currency, balance } = selectedCurrency
    const { symbol } = currency;
    const [openExplanation, setOpenExplanation] = useState(false);
    const [openBeneficiaryBankDetails, setOpenBeneficiaryBankDetails] =
        useState(false);
    const [fee, setFee] = useState({ fee: 0, manual: false });
    const [total, setTotal] = useState(0);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [fail, setFail] = useState(false);
    const [clientAddOpen, setClientAddOpen] = useState(false);
    const [preventDefault, setPreventDefault] = useState(false)
    const [inputValue, setInputValue] = useState("");
    const [client, setClient] = useState(null)

    const [transferData, setTransferData] = useState({
        transfer_type: "transfer",
        transaction_type: transactionType.toLowerCase(),
        amount: 0,
        iban: 0,
        bicNumber: 0,
        transferDetails: 0,
        fullName: "",
        address: "",
        city: "",
        postCode: 0,
        country: "",
        bankName: "",
        bankAddress: "",
        bankCity: "",
        bankPostCode: 0,
        bankCountry: "",
        transferExplanation: null,
        summary: 0,
    });

    const [liteTransferData, setLiteTransferData] = useState({
        transfer_type: "lite_transfer",
        transaction_type: 'credit',
        amount: 0,
        sender: null,
        receiver: "",
        country: "",
        comment: "",
        summary: 0,
    });

    const [internalTransferData, setInternalTransferData] = useState({
        transfer_type: "internal_transfer",
        transaction_type: transactionType.toLowerCase(),
        amount: 0,
        beneficiaryDetails: "",
        comment: "",
        transferExplanation: null,
        summary: 0,
    });

    const getUploadParams = ({ meta }) => {
        return { url: "https://httpbin.org/post" };
    };

    const handleChangeStatus = ({ meta, file }, status) => {
        if (status === "done") {
            if (transferType.type === 1) {
                setTransferData({ ...transferData, transferExplanation: file });
            } else if (transferType.type === 3) {
                setInternalTransferData({ ...transferData, transferExplanation: file });
            }
        }
    };

    const clearModel = () => {
        setLiteTransferData({
            transfer_type: "lite_transfer",
            transaction_type: transactionType.toLowerCase(),
            amount: 0,
            sender: null,
            receiver: "",
            country: "",
            comment: "",
            summary: 0,
        })
    }

    const handleInputChange = (e, info = null) => {
        let name;
        let value;

        if (info) {
            name = info.event.target.name;
            value = e.floatValue;
        } else {
            name = e.target.name;
            value = e.target.value;
        }

        if (name === "amount" && value > 0) {
            delete errors.amount;
            setErrors({ ...errors });
        }

        if (transferType.type === 1) {
            setTransferData((prev) => ({
                ...prev,
                [name]: value,
            }));
        } else if (transferType.type === 2) {
            setLiteTransferData((prev) => ({
                ...prev,
                [name]: value,
            }));
        } else {
            setInternalTransferData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };

    const generateFee = (reset = false) => {
        const partnerPercentage = percentages;


        if (fee.manual && !reset) {
            return;
        }

        if (partnerPercentage === undefined) {
            return
        }

        const amount = parseFloat(liteTransferData?.amount);
        const country = liteTransferData?.country;

        if (!country.length) {
            setFee({ fee: 0, manual: false });
            return;
        }

        let currentFee = partnerPercentage.find(
            (prc) =>
                amount >= prc?.fromValue &&
                amount <= prc?.toValue &&
                prc.percentageDetails?.type === "lite_transfer" &&
                prc.percentageDetails?.country === country
        );

        if (currentFee === undefined) {
            currentFee = { percentageValue: 0 }
        }

        const totalFee = parseFloat(amount) * parseFloat(currentFee?.percentageValue / 100);
        setFee({ fee: totalFee, manual: false });
    }

    useEffect(() => {
        generateFee();
    }, [liteTransferData]);

    const handleSearchChange = (e) => {
        switch (transferType.type) {
            case 2:
                if (e === null) {
                    setLiteTransferData((prev) => ({
                        ...prev,
                        country: null,
                    }));
                } else {
                    setLiteTransferData((prev) => ({
                        ...prev,
                        country: e.name,
                    }));
                }

                setFee({ fee: 0, manual: false });
                break;
            case 3:
                const { name, value } = e.target;

                setInternalTransferData((prev) => ({
                    ...prev,
                    [name]: value,
                }));
                break;
        }
    };

    const inputKeyDown = (e) => {
        if (e.keyCode === 13 && preventDefault) {
            e.preventDefault();
        }
    }

    const loadClients = async (inputValue, clientOnly = false) => {
        if (inputValue.length >= 3) {
            let response;
            try {
                if (clientOnly) {
                    response = await publicRequest.get(`/client/search/client?search=${inputValue}`)
                } else {
                    if (user.isAdmin || user.isMainOffice) {
                        response = await publicRequest.get(`/partner/search/partner?search=${inputValue}`)
                    } else {
                        response = await publicRequest.get(`/client/search/client?search=${inputValue}`)
                    }
                }
            } catch (e) {
                console.log(e)
            }
            if (response.data.length === 0) {
                setPreventDefault(true)
            } else {
                setPreventDefault(false)
            }
            return response.data
        }
        ;
    }

    const loadOptions = async (inputValue) => {
        if (inputValue.length > 0) {
            let response;
            try {
                response = await publicRequest.get(`/partner/search/partner?search=${inputValue}`)
            } catch (e) {
                console.log(e)
            }
            if (response.data.length === 0) {
                setPreventDefault(true)
            } else {
                setPreventDefault(false)
            }
            return response.data
        }
    };

    const handleSetResult = (e) => {
        if (e === null) {
            setInternalTransferData((prev) => ({
                ...prev,
                beneficiaryDetails: null,
            }));
            setClient(null)
        } else {
            setInternalTransferData((prev) => ({
                ...prev,
                beneficiaryDetails: e._id,
            }));
            setLiteTransferData((prev) => ({
                ...prev,
                sender: e._id
            }))
            setClient(e)
            delete errors.beneficiary
            setErrors({ ...errors })
        }
    };

    const handleSubmit = async () => {
        if (transferType.type === 3) {
            if (internalTransferData.amount <= 0) {
                errors.amount = true
            } else {
                delete errors.amount
                setErrors({ ...errors })
            }
        } else {
            if (liteTransferData.amount <= 0) {
                errors.amount = true
            } else {
                delete errors.amount
                setErrors({ ...errors })
            }
        }

        if (!liteTransferData.sender || !internalTransferData.beneficiaryDetails) {
            errors.beneficiary = true
            setErrors({ ...errors })
        } else {
            delete errors.beneficiary
            setErrors({ ...errors })
        }

        if (Object.keys(errors).length === 0) {
            let data = {};
            switch (transferType.type) {
                case 1:
                    data = transferData;
                    break;
                case 2:
                    data = liteTransferData;
                    //We haven't receiver anymore.
                    // data.transaction_type = (data.transaction_type === 'send') ? 'credit' : 'debit';
                    break;
                case 3:
                    data = internalTransferData;
                    break;
            }

            if (data?.beneficiaryDetails) {
                data.destination_partner = data.beneficiaryDetails;
            }

            data.summary = (Number(total) + Number(fee.fee)).toFixed(2);
            data.fee = Number(fee.fee).toFixed(2);
            data.currency = currency["_id"];
            if (data) {
                Swal.fire({
                    title: `Are you sure you want to create this transfer?`,
                    icon: 'question',
                    showDenyButton: true,
                    confirmButtonText: "Yes",
                    denyButtonText: `Cancel`,
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        setLoading(true);
                        const response = await publicRequest.post("/transfer", data);

                        if (response.status === 200) {
                            setAction({
                                type: "refetch",
                                id: 1,
                                data: response.data,
                            });
                            switch (transferType.type) {
                                case 1:
                                    break;
                                case 2:
                                    await litePrint(response.data);
                                    break;
                                case 3:
                                    await internalPrint(response.data);
                                    break;
                            }
                            setLoading(false);
                            setSuccess(true);
                            setFail(false);
                        } else {
                            setLoading(false);
                            setSuccess(false);
                            setFail(true);
                        }
                        setTimeout(() => {
                            setSuccess(false);
                            setLoading(false);
                            setFail(false);
                            setIsOpen(false);
                            setCurrentStep(0)
                            setDirection(null)
                        }, 2000);
                    }
                })
            }
        }
    };

    useEffect(() => {
        switch (transferType.type) {
            case 1:
                setTotal(transferData.amount);
                break;
            case 2:
                setTotal(liteTransferData.amount);
                break;
            case 3:
                setTotal(internalTransferData.amount);
                break;
        }
    }, [transferData, liteTransferData, internalTransferData]);


    const openModal = () => {
        setClientAddOpen(true);
    }

    const customFee = (e, b) => {
        setFee({ fee: e.floatValue, manual: true });
    }

    const resetFee = async () => {
        setFee({ ...fee, ...{ manual: false } });
        await generateFee(true);
    }

    return (
        <>
            {loading ? (
                <Loader />
            ) : success && !loading ? (
                <Success type={'transfer'} />
            ) : fail && !loading ? (
                <Failed type={'transfer'} />
            ) : (
                <div style={{ marginTop: "20px" }}>
                    <div className="flex items-center justify-center w-full gap-30">
                        <div
                            className="usersTableRegisterFormItem"
                            style={{ width: "47%" }}
                        >
                            <label
                                style={{ fontSize: "12px" }}
                                className="usersTableRegisterFormLabel"
                            >
                                From
                            </label>
                            <NumericFormat
                                aria-disabled={true}
                                disabled={true}
                                displayType={"input"}
                                value={selectedCurrency?.currency?.balance ? selectedCurrency?.currency?.balance : selectedCurrency?.balance}
                                thousandSeparator=","
                                decimalSeparator="."
                                decimalScale={2}
                                prefix={`${symbol} `}
                                fixedDecimalScale={true}
                                className={"usersTableRegisterFormInput"}
                                style={{
                                    padding: "3px 6px",
                                    marginTop: "5px",
                                    height: 25,
                                    borderRadius: "4px",
                                    marginLeft: "10px",
                                }}
                            />
                            {/*<input*/}
                            {/*    disabled*/}
                            {/*    name="from"*/}
                            {/*    placeholder={symbol + ` (${balance})`}*/}
                            {/*    type="text"*/}
                            {/*    className="usersTableRegisterFormInput"*/}
                            {/*    style={{*/}
                            {/*        padding: "3px 6px",*/}
                            {/*        marginTop: "5px",*/}
                            {/*        height: 25,*/}
                            {/*        borderRadius: "4px",*/}
                            {/*        marginLeft: "10px",*/}
                            {/*    }}*/}
                            {/*/>*/}
                        </div>
                        <div
                            className="usersTableRegisterFormItem"
                            style={{ width: "47%" }}
                        >
                            <label
                                style={{ fontSize: "12px" }}
                                className="usersTableRegisterFormLabel"
                            >
                                Amount
                            </label>
                            <div
                                className="flex w-full items-center"
                                style={{
                                    border: errors?.amount
                                        ? "1px solid red"
                                        : "1px solid #d8d8d8",
                                    borderRadius: "4px",
                                    marginTop: "5px",
                                }}
                            >
                                <NumericFormat
                                    displayType={"input"}
                                    name={"amount"}
                                    className={"usersTableRegisterFormInput"}
                                    allowNegative={false}
                                    onValueChange={(e, sourceInfo) =>
                                        handleInputChange(e, sourceInfo)
                                    }
                                    onFocus={(e) => {
                                        setTimeout(() => {
                                            e.target.focus();
                                            e.target.select();
                                        }, 100);
                                    }}
                                    thousandSeparator=","
                                    decimalSeparator="."
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    isAllowed={(values) => {
                                        const { floatValue } = values;
                                        return floatValue < 100000000;
                                    }}
                                    placeholder={"0.00"}
                                    style={{
                                        border: "0px",
                                        marginTop: "0",
                                        padding: "0 10px",
                                    }}
                                    value={
                                        transferType.type === 2
                                            ? liteTransferData.amount
                                            : transferType.type === 3
                                                ? internalTransferData.amount
                                                : transferData.amount
                                    }
                                />
                                {/*<input*/}
                                {/*    name="amount"*/}
                                {/*    onChange={(e) => handleInputChange(e)}*/}
                                {/*    placeholder="Amount"*/}
                                {/*    type="text"*/}
                                {/*    className="usersTableRegisterFormInput"*/}
                                {/*    style={{*/}
                                {/*        border: "0px",*/}
                                {/*        marginTop: "0",*/}
                                {/*        padding: "0 10px",*/}
                                {/*    }}*/}
                                {/*    value={*/}
                                {/*        transferType.type === 2*/}
                                {/*            ? liteTransferData.amount*/}
                                {/*            : transferType.type === 3*/}
                                {/*                ? internalTransferData.amount*/}
                                {/*                : transferData.amount*/}
                                {/*    }*/}
                                {/*/>*/}
                                <button
                                    className={`${balance > 0 || parseFloat(selectedCurrency?.currency?.balance) > 0 ? "cursor-pointer" : ""} normal-btn`}
                                    disabled={balance <= 0 || parseFloat(selectedCurrency?.currency?.balance) <= 0 ? true : false}
                                    style={{
                                        borderRadius: "0",
                                        fontSize: "12px",
                                        color: balance <= 0 || parseFloat(selectedCurrency?.currency?.balance) <= 0 ? "#939393" : "orange",
                                        paddingRight: 10,
                                    }}
                                    onClick={() => {
                                        handleInputChange({
                                            target: { name: "amount", value: selectedCurrency?.currency?.balance ? parseFloat(selectedCurrency?.currency?.balance) : selectedCurrency?.balance },
                                        });
                                    }}
                                >
                                    Max
                                </button>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        borderLeft: "1px solid #d8d8d8",
                                        padding: "3px 10px",
                                    }}
                                >
                                    <img
                                        src={iconGenerator(128, "icon", symbol, true)}
                                        style={{ height: "25px" }}
                                    />
                                    <span
                                        style={{ marginLeft: 5, fontSize: "14px", fontWeight: 500 }}
                                    >
                                        {symbol}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {transferType.type === 1 && (
                        <div className="flex items-center justify-center w-full gap-30 flex-wrap">
                            <div
                                className="usersTableRegisterFormItem"
                                style={{ width: "47%" }}
                            >
                                <label
                                    style={{ fontSize: "12px" }}
                                    className="usersTableRegisterFormLabel"
                                >
                                    Bank account number / IBAN
                                </label>
                                <input
                                    name="iban"
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder="Enter IBAN"
                                    className="usersTableRegisterFormInput"
                                    style={{
                                        padding: "3px 6px",
                                        marginTop: "5px",
                                        height: 25,
                                        borderRadius: "4px",
                                        marginLeft: "10px",
                                    }}
                                />
                            </div>
                            <div
                                className="usersTableRegisterFormItem"
                                style={{ width: "47%" }}
                            >
                                <label
                                    style={{ fontSize: "12px" }}
                                    className="usersTableRegisterFormLabel"
                                >
                                    BIC / SWIFT / ABA / number
                                </label>
                                <input
                                    name="bicNumber"
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder="BIC / SWIFT / ABA"
                                    className="usersTableRegisterFormInput"
                                    style={{
                                        padding: "3px 6px",
                                        marginTop: "5px",
                                        height: 25,
                                        borderRadius: "4px",
                                        marginLeft: "10px",
                                    }}
                                />
                            </div>
                            <div
                                className="usersTableRegisterFormItem"
                                style={{ width: "99.5%", marginTop: "0" }}
                            >
                                <label
                                    style={{ fontSize: "12px" }}
                                    className="usersTableRegisterFormLabel"
                                >
                                    Transfer details / Reference / Comment
                                </label>
                                <input
                                    name="transferDetails"
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder="Details"
                                    className="usersTableRegisterFormInput"
                                    style={{
                                        padding: "3px 6px",
                                        marginTop: "5px",
                                        height: 25,
                                        borderRadius: "4px",
                                        marginLeft: "10px",
                                    }}
                                />
                            </div>
                        </div>
                    )}

                    {transferType.type == 1 ? (
                        <>
                            <div
                                className="flex items-center justify-between"
                                style={{ marginTop: "30px" }}
                            >
                                <div
                                    className="flex gap-20 items-center"
                                    onClick={() => setOpenExplanation(!openExplanation)}
                                >
                                    <p style={{ fontSize: "14px" }}>Beneficiary details</p>
                                </div>
                                <div
                                    style={{
                                        height: "1px",
                                        backgroundColor: "#d8d8d8",
                                        width: "75%",
                                    }}
                                ></div>
                            </div>
                            <div
                                className="usersTableRegisterFormItem"
                                style={{ width: "100%" }}
                            >
                                <label
                                    style={{ fontSize: "12px" }}
                                    className="usersTableRegisterFormLabel"
                                >
                                    Beneficiary full name
                                </label>
                                <input
                                    name="fullName"
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder="Beneficiary full name"
                                    type="text"
                                    className="usersTableRegisterFormInput"
                                    style={{
                                        padding: "3px 6px",
                                        marginTop: "5px",
                                        height: 25,
                                        borderRadius: "4px",
                                        marginLeft: "10px",
                                    }}
                                />
                            </div>
                            <div className="flex items-center justify-center w-full gap-30 flex-wrap">
                                <div
                                    className="usersTableRegisterFormItem"
                                    style={{ width: "47%" }}
                                >
                                    <label
                                        style={{ fontSize: "12px" }}
                                        className="usersTableRegisterFormLabel"
                                    >
                                        Address
                                    </label>
                                    <input
                                        name="address"
                                        onChange={(e) => handleInputChange(e)}
                                        placeholder="Address"
                                        className="usersTableRegisterFormInput"
                                        style={{
                                            padding: "3px 6px",
                                            marginTop: "5px",
                                            height: 25,
                                            borderRadius: "4px",
                                            marginLeft: "10px",
                                        }}
                                    />
                                </div>
                                <div
                                    className="usersTableRegisterFormItem"
                                    style={{ width: "47%" }}
                                >
                                    <label
                                        style={{ fontSize: "12px" }}
                                        className="usersTableRegisterFormLabel"
                                    >
                                        City
                                    </label>
                                    <input
                                        name="city"
                                        onChange={(e) => handleInputChange(e)}
                                        placeholder="City"
                                        className="usersTableRegisterFormInput"
                                        style={{
                                            padding: "3px 6px",
                                            marginTop: "5px",
                                            height: 25,
                                            borderRadius: "4px",
                                            marginLeft: "10px",
                                        }}
                                    />
                                </div>
                                <div className="flex items-center justify-between w-full">
                                    <div
                                        className="usersTableRegisterFormItem"
                                        style={{ width: "47%", marginTop: "0" }}
                                    >
                                        <label
                                            style={{ fontSize: "12px" }}
                                            className="usersTableRegisterFormLabel"
                                        >
                                            Post code
                                        </label>
                                        <input
                                            name="postCode"
                                            onChange={(e) => handleInputChange(e)}
                                            placeholder="Post code"
                                            className="usersTableRegisterFormInput"
                                            style={{
                                                padding: "3px 6px",
                                                marginTop: "5px",
                                                height: 25,
                                                borderRadius: "4px",
                                                marginLeft: "10px",
                                            }}
                                        />
                                    </div>
                                    <div
                                        className="usersTableRegisterFormItem"
                                        style={{
                                            width: "49%",
                                            marginTop: "0",
                                            position: "relative",
                                            left: "8px",
                                        }}
                                    >
                                        <label
                                            style={{ fontSize: "12px", marginBottom: "5px" }}
                                            className="usersTableRegisterFormLabel"
                                        >
                                            Country
                                        </label>
                                        <Select
                                            classNamePrefix="custom-select"
                                            options={balkanCountries}
                                            isSearchable={true}
                                            isClearable={true}
                                            getOptionLabel={(e) => e.name}
                                            getOptionValue={(e) => e.code}
                                            onChange={(e) => {
                                                if (e === null) {
                                                    setTransferData({
                                                        ...transferData,
                                                        country: null,
                                                    });
                                                } else {
                                                    setTransferData({
                                                        ...transferData,
                                                        country: e.code,
                                                    });
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            {transferType.type === 2 && (
                                <div
                                    className="flex items-center justify-between gap-30"
                                    style={{ marginTop: "30px" }}
                                >
                                    <div style={{width: "53%"}}>
                                        <label
                                            style={{fontSize: "12px"}}
                                            className="usersTableRegisterFormLabel"
                                        >
                                            Sender
                                        </label>
                                        <div className="w-full flex items-center gap-5 no-loading"
                                             style={{marginTop: "5px"}}>
                                            <div style={{flex: 1}}>
                                                <AsyncSelect
                                                    classNamePrefix={`${errors.beneficiary && "error-client"} custom-select`}
                                                    isClearable={true}
                                                    value={client}
                                                    cacheOptions
                                                    defaultOptions
                                                    getOptionLabel={(e) => e.firstName + " " + e.lastName}
                                                    getOptionValue={(e) => e._id}
                                                    loadOptions={() => loadClients(inputValue, true)}
                                                    onKeyDown={inputKeyDown}
                                                    onInputChange={(e) => setInputValue(e)}
                                                    onChange={(e) => handleSetResult(e)}
                                                    noOptionsMessage={() => "No clients found, keep searching..."}
                                                />
                                            </div>
                                            <RiUserAddLine
                                                size={20}
                                                color="#fff"
                                                onClick={() => openModal()}
                                                className="addUserIcon"
                                            />
                                            <AddClientModal setLiteTransferData={setLiteTransferData}
                                                            clearModel={clearModel} client={client}
                                                            setClient={setClient} isOpen={clientAddOpen}
                                                            setIsOpen={setClientAddOpen}/>
                                        </div>
                                    </div>
                                    <div style={{flex: 1}}>
                                        <label
                                            style={{fontSize: "12px", marginLeft: "-13px"}}
                                            className="usersTableRegisterFormLabel"
                                        >
                                            Receiver
                                        </label>
                                        <input
                                            name="receiver"
                                            onChange={(e) =>
                                                setLiteTransferData((prev) => ({
                                                    ...prev,
                                                    receiver: e.target.value,
                                                }))
                                            }
                                            placeholder="Enter the name of the receiver"
                                            className="usersTableRegisterFormInput"
                                            style={{
                                                padding: "3px 6px",
                                                marginTop: "5px",
                                                height: 25,
                                                borderRadius: "4px",
                                                marginLeft: "-15px",
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                            <div
                                className="usersTableRegisterFormItem"
                                style={{ width: "100%", marginTop: "30px" }}
                            >
                                <label
                                    style={{ fontSize: "12px" }}
                                    className="usersTableRegisterFormLabel"
                                >
                                    {transferType.type === 1
                                        ? "Beneficiery Details"
                                        : transferType.type === 2
                                            ? "Country"
                                            : "Beneficiary ID, email or phone"}
                                </label>
                                {transferType.type === 3 && (
                                    <div
                                        className="w-full no-loading"
                                        style={{ marginTop: "5px" }}
                                    >
                                        <AsyncSelect
                                            classNamePrefix={`${errors.beneficiary && "error-client"} custom-select`}
                                            isClearable={true}
                                            cacheOptions
                                            defaultOptions
                                            getOptionLabel={(e) => e.partnerName}
                                            getOptionValue={(e) => e._id}
                                            loadOptions={loadOptions}
                                            onKeyDown={inputKeyDown}
                                            filterOption={(option) =>
                                                option.value !== user.partnerId?._id
                                            }
                                            onInputChange={(e) => setInputValue(e)}
                                            onChange={(e) => handleSetResult(e)}
                                            noOptionsMessage={() => "No partners found, keep searching..."}
                                        />
                                    </div>
                                )}

                                {transferType.type === 2 && (
                                    <div className="w-full" style={{ marginTop: "5px" }}>
                                        <MainSelect
                                            name="country"
                                            placeholder="Select a country"
                                            isClearable={true}
                                            isSearchable={true}
                                            options={balkanCountries}
                                            getOptionLabel={(e) => e.name}
                                            getOptionValue={(e) => e.name}
                                            onChange={(e) => handleSearchChange(e)}
                                        />
                                    </div>
                                )}
                            </div>
                        </>
                    )}

                    {transferType.type === 1 ? (
                        <></>
                    ) : (
                        <div
                            className="usersTableRegisterFormItem"
                            style={{ width: "calc(98% - 1px)", marginLeft: "3px", marginTop: "30px" }}
                        >
                            <label
                                style={{ fontSize: "12px" }}
                                className="usersTableRegisterFormLabel"
                            >
                                Comment
                            </label>
                            <input
                                onChange={(e) => handleInputChange(e)}
                                name="comment"
                                placeholder="Comment"
                                type="text"
                                className="usersTableRegisterFormInput"
                                style={{
                                    padding: "3px 6px",
                                    marginTop: "5px",
                                    height: 25,
                                    borderRadius: "4px",
                                    marginLeft: "10px",
                                }}
                            />
                        </div>
                    )}

                    {transferType.type === 2 ? (
                        <></>
                    ) : transferType.type === 1 ? (
                        <>
                            <div
                                className="flex items-center justify-between"
                                style={{ marginTop: "30px" }}
                            >
                                <div
                                    className="flex gap-20 items-center"
                                    onClick={() =>
                                        setOpenBeneficiaryBankDetails(!openBeneficiaryBankDetails)
                                    }
                                >
                                    {openBeneficiaryBankDetails ? (
                                        <TbChevronUp
                                            size={24}
                                            color="orange"
                                            className="cursor-pointer"
                                            style={{ background: "#f9e7c6", borderRadius: "50%" }}
                                        />
                                    ) : (
                                        <TbChevronDown
                                            size={24}
                                            color="orange"
                                            className="cursor-pointer"
                                            style={{ background: "#f9e7c6", borderRadius: "50%" }}
                                        />
                                    )}
                                    <p style={{ fontSize: "14px" }}>
                                        Beneficiary bank details (Optional)
                                    </p>
                                </div>
                                <div
                                    style={{
                                        height: "1px",
                                        backgroundColor: "#d8d8d8",
                                        width: "45%",
                                    }}
                                ></div>
                            </div>
                            {openBeneficiaryBankDetails ? (
                                <>
                                    <div
                                        className="usersTableRegisterFormItem"
                                        style={{ width: "98%" }}
                                    >
                                        <label
                                            style={{ fontSize: "12px" }}
                                            className="usersTableRegisterFormLabel"
                                        >
                                            Bank name
                                        </label>
                                        <input
                                            name="bankName"
                                            onChange={(e) => handleInputChange(e)}
                                            placeholder="Bank name"
                                            type="text"
                                            className="usersTableRegisterFormInput"
                                            style={{
                                                padding: "3px 6px",
                                                marginTop: "5px",
                                                height: 25,
                                                borderRadius: "4px",
                                                marginLeft: "10px",
                                            }}
                                        />
                                    </div>
                                    <div className="flex items-center justify-center w-full gap-30 flex-wrap">
                                        <div
                                            className="usersTableRegisterFormItem"
                                            style={{ width: "47%" }}
                                        >
                                            <label
                                                style={{ fontSize: "12px" }}
                                                className="usersTableRegisterFormLabel"
                                            >
                                                Bank address
                                            </label>
                                            <input
                                                name="bankAddress"
                                                onChange={(e) => handleInputChange(e)}
                                                placeholder="Bank address"
                                                className="usersTableRegisterFormInput"
                                                style={{
                                                    padding: "3px 6px",
                                                    marginTop: "5px",
                                                    height: 25,
                                                    borderRadius: "4px",
                                                    marginLeft: "10px",
                                                }}
                                            />
                                        </div>
                                        <div
                                            className="usersTableRegisterFormItem"
                                            style={{ width: "47%" }}
                                        >
                                            <label
                                                style={{ fontSize: "12px" }}
                                                className="usersTableRegisterFormLabel"
                                            >
                                                Bank city
                                            </label>
                                            <input
                                                name="bankCity"
                                                onChange={(e) => handleInputChange(e)}
                                                placeholder="Bank city"
                                                className="usersTableRegisterFormInput"
                                                style={{
                                                    padding: "3px 6px",
                                                    marginTop: "5px",
                                                    height: 25,
                                                    borderRadius: "4px",
                                                    marginLeft: "10px",
                                                }}
                                            />
                                        </div>
                                        <div
                                            className="usersTableRegisterFormItem"
                                            style={{ width: "47%", marginTop: "0" }}
                                        >
                                            <label
                                                style={{ fontSize: "12px" }}
                                                className="usersTableRegisterFormLabel"
                                            >
                                                Bank Post code
                                            </label>
                                            <input
                                                name="bankPostCode"
                                                onChange={(e) => handleInputChange(e)}
                                                placeholder="Bank post code"
                                                className="usersTableRegisterFormInput"
                                                style={{
                                                    padding: "3px 6px",
                                                    marginTop: "5px",
                                                    height: 25,
                                                    borderRadius: "4px",
                                                    marginLeft: "10px",
                                                }}
                                            />
                                        </div>
                                        <div
                                            className="usersTableRegisterFormItem"
                                            style={{ width: "47%", marginTop: "0" }}
                                        >
                                            <label
                                                style={{ fontSize: "12px", marginBottom: "5px" }}
                                                className="usersTableRegisterFormLabel"
                                            >
                                                Bank Country
                                            </label>
                                            <Select
                                                options={balkanCountries}
                                                isSearchable={true}
                                                isClearable={true}
                                                getOptionLabel={(e) => e.name}
                                                getOptionValue={(e) => e.code}
                                                onChange={(e) =>
                                                    setTransferData({
                                                        ...transferData,
                                                        bankCountry: e.code,
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                            <div
                                className="flex items-center justify-between"
                                style={{ marginTop: "30px" }}
                            >
                                <div
                                    className="flex gap-20 items-center"
                                    onClick={() => setOpenExplanation(!openExplanation)}
                                >
                                    {openExplanation ? (
                                        <TbChevronUp
                                            size={24}
                                            color="orange"
                                            className="cursor-pointer"
                                            style={{ background: "#f9e7c6", borderRadius: "50%" }}
                                        />
                                    ) : (
                                        <TbChevronDown
                                            size={24}
                                            color="orange"
                                            className="cursor-pointer"
                                            style={{ background: "#f9e7c6", borderRadius: "50%" }}
                                        />
                                    )}
                                    <p style={{ fontSize: "14px" }}>
                                        Upload transfer explanation (Optional)
                                    </p>
                                </div>
                                <div
                                    style={{
                                        height: "1px",
                                        backgroundColor: "#d8d8d8",
                                        width: "45%",
                                    }}
                                ></div>
                            </div>
                            {openExplanation ? (
                                <div
                                    style={{
                                        background: "white",
                                        borderRadius: "8px",
                                        boxShadow: "0 0 10px rgb(0 0 0 / 10%)",
                                        padding: "12px",
                                        marginTop: "30px",
                                    }}
                                >
                                    <Dropzone
                                        getUploadParams={getUploadParams}
                                        onChangeStatus={handleChangeStatus}
                                        maxFiles={1}
                                        inputContent={(files, extra) =>
                                            extra.reject ? (
                                                "Drop here to upload."
                                            ) : (
                                                <div
                                                    style={{ gap: "10px" }}
                                                    className="grid place-center"
                                                >
                                                    <FaCloudUploadAlt color="#d8d8d8" size={40} />
                                                    <p style={{ fontSize: "12px", textAlign: "center" }}>
                                                        <span style={{ color: "#FE9A02" }}>
                                                            Upload a file{" "}
                                                        </span>
                                                        or drag and drop here.
                                                    </p>
                                                </div>
                                            )
                                        }
                                        styles={{
                                            dropzoneReject: {
                                                borderColor: "red",
                                                backgroundColor: "#DAA",
                                            },
                                            inputLabel: (files, extra) =>
                                                extra.reject ? { color: "red" } : {},
                                        }}
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                        </>
                    ) : (
                        <>
                            <div
                                className="flex items-center justify-between"
                                style={{ marginTop: "30px" }}
                            >
                                <div
                                    className="flex gap-20 items-center"
                                    onClick={() => setOpenExplanation(!openExplanation)}
                                >
                                    {openExplanation ? (
                                        <TbChevronUp
                                            size={24}
                                            color="orange"
                                            className="cursor-pointer"
                                            style={{ background: "#f9e7c6", borderRadius: "50%" }}
                                        />
                                    ) : (
                                        <TbChevronDown
                                            size={24}
                                            color="orange"
                                            className="cursor-pointer"
                                            style={{ background: "#f9e7c6", borderRadius: "50%" }}
                                        />
                                    )}
                                    <p style={{ fontSize: "14px" }}>
                                        Upload transfer explanation (Optional)
                                    </p>
                                </div>
                                <div
                                    style={{
                                        height: "1px",
                                        backgroundColor: "#d8d8d8",
                                        width: "45%",
                                    }}
                                ></div>
                            </div>
                            {openExplanation ? (
                                <div
                                    style={{
                                        background: "white",
                                        borderRadius: "8px",
                                        boxShadow: "0 0 10px rgb(0 0 0 / 10%)",
                                        padding: "12px",
                                        marginTop: "30px",
                                    }}
                                >
                                    <Dropzone
                                        getUploadParams={getUploadParams}
                                        onChangeStatus={handleChangeStatus}
                                        maxFiles={1}
                                        inputContent={(files, extra) =>
                                            extra.reject ? (
                                                "Drop here to upload."
                                            ) : (
                                                <div
                                                    style={{ gap: "10px" }}
                                                    className="grid place-center"
                                                >
                                                    <FaCloudUploadAlt color="#d8d8d8" size={40} />
                                                    <p style={{ fontSize: "12px", textAlign: "center" }}>
                                                        <span style={{ color: "#FE9A02" }}>
                                                            Upload a file{" "}
                                                        </span>
                                                        or drag and drop here.
                                                    </p>
                                                </div>
                                            )
                                        }
                                        styles={{
                                            dropzoneReject: {
                                                borderColor: "red",
                                                backgroundColor: "#DAA",
                                            },
                                            inputLabel: (files, extra) =>
                                                extra.reject ? { color: "red" } : {},
                                        }}
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                        </>
                    )}
                    <div
                        className="flex items-center justify-between"
                        style={{ marginTop: "30px" }}
                    >
                        <div
                            className="flex gap-20 items-center"
                            onClick={() => setOpenExplanation(!openExplanation)}
                        >
                            <p style={{ fontSize: "14px" }}>Summary</p>
                        </div>
                        <div
                            style={{
                                height: "1px",
                                backgroundColor: "#d8d8d8",
                                width: "85%",
                            }}
                        ></div>
                    </div>
                    <div className="flex items-center justify-between">
                        <div
                            className="flex items-center gap-10"
                            style={{ marginTop: "10px" }}
                        >
                            <img
                                src={iconGenerator(128, "icon", symbol, true)}
                                style={{ height: "40px" }}
                            />
                            <div className="grid">
                                <span style={{ fontSize: "12px", color: "#777777" }}>
                                    Fee:{" "}
                                    <NumericFormat
                                        style={{
                                            height: "10px",
                                            borderRadius: "5px",
                                            padding: "5px",
                                            fontSize: "12px",
                                            border: "0px solid white",
                                            outline: 'none',
                                            color: "rgb(119, 119, 119)"
                                        }}
                                        onValueChange={customFee}
                                        onDoubleClick={resetFee}
                                        onFocus={(e) => {
                                            setTimeout(() => {
                                                e.target.focus();
                                                e.target.select();
                                            }, 100);
                                        }}
                                        value={fee.fee}
                                        displayType={"input"}
                                        thousandSeparator=","
                                        decimalSeparator="."
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        suffix={` ${selectedCurrency.currency.symbol}`}
                                    />
                                    {/*<NumberFormatter*/}
                                    {/*    value={parseFloat(fee)}*/}
                                    {/*    suffix={` ${selectedCurrency.currency.symbol}`}*/}
                                    {/*/>*/}
                                </span>
                                <span style={{ fontSize: "12px", color: "#777777" }}>
                                    Total to spend:{" "}
                                    <NumberFormatter
                                        value={parseFloat(total)}
                                        suffix={` ${selectedCurrency.currency.symbol}`}
                                    />
                                </span>
                            </div>
                        </div>
                        <span
                            style={{
                                color: "orange",
                                fontSize: "16px",
                            }}
                        >
                            <NumberFormatter
                                value={parseFloat(total) + parseFloat(fee.fee)}
                                suffix={` ${selectedCurrency.currency.symbol}`}
                            />
                        </span>
                    </div>
                    <div
                        className="grid place-center w-full"
                        style={{ marginTop: "20px" }}
                    >
                        <button
                            style={{ background: errors.amount || errors.sender || user.isAdmin || user.isMainOffice ? "gray" : "orange" }}
                            className={`rounded-btn w-full ${errors.amount || user.isAdmin || user.isMainOffice ? "cursor-ban" : "cursor-pointer"}`}
                            onClick={handleSubmit}
                            disabled={errors.amount || user.isAdmin || user.isMainOffice ? true : false}
                        >
                            Transfer
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}

export default SelectedTransferDetails;

