import React, {Fragment} from "react";
import {FaCheck} from "react-icons/fa";
import {useSelector} from "react-redux";
import Select from "../../ReusableComponents/Select";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

function UserRegisterFields({
  modifiedData,
  setModifiedData,
  partners,
  getRoles,
  submitOrEdit,
  success,
  message,
}) {
  const user = useSelector((state) => state.user.currentUser);

  const handleChange = ({ target: { name, value } }) => {
    setModifiedData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Fragment>
      {success ? (
        <div
          className="grid place-center w-full h-full"
          style={{ transform: "translateY(150%)" }}
        >
          <FaCheck
            size={40}
            color="white"
            style={{
              background: "#25da25",
              padding: "10px",
              borderRadius: "50%",
            }}
          />
          <div
            style={{
              fontWeight: "300",
              fontSize: "14px",
              margin: "5px 0",
            }}
          >
            User {message} Successfully!
          </div>
        </div>
      ) : (
        <>
          {submitOrEdit === "submit" && (
            <>
              <div className="usersTableRegisterFormItem">
                <label className="usersTableRegisterFormLabel">Username</label>
                <input
                  name="username"
                  onChange={handleChange}
                  value={modifiedData?.username}
                  type="text"
                  className="usersTableRegisterFormInput"
                />
              </div>
              <div className="usersTableRegisterFormItem">
                <label className="usersTableRegisterFormLabel">Email</label>
                <input
                  name="email"
                  onChange={handleChange}
                  value={modifiedData?.email}
                  type="email"
                  className="usersTableRegisterFormInput"
                />
              </div>

              <div className="usersTableRegisterFormItem">
                <label className="usersTableRegisterFormLabel">Password</label>
                <input
                  name="password"
                  onChange={handleChange}
                  value={modifiedData?.password}
                  type="text"
                  className="usersTableRegisterFormInput"
                />
              </div>
            </>
          )}

          <div className="usersTableRegisterFormItem">
            <label className="usersTableRegisterFormLabel">Full Name</label>
            <input
              name="fullName"
              onChange={handleChange}
              value={modifiedData?.fullName}
              type="text"
              className="usersTableRegisterFormInput"
            />
          </div>
          <div className="usersTableRegisterFormItem">
            <label className="usersTableRegisterFormLabel">Phone 1</label>
            <PhoneInput
              defaultCountry="MK"
              placeholder="Enter phone number"
              name="phone1"
              onChange={(e) =>
                setModifiedData((prev) => ({
                  ...prev,
                  phone1: e,
                }))
              }
              value={modifiedData?.phone1}
              className="usersTableRegisterFormInput"
            />
          </div>
          <div className="usersTableRegisterFormItem">
            <label className="usersTableRegisterFormLabel">Phone 2</label>
            <PhoneInput
              defaultCountry="MK"
              placeholder="Enter phone number"
              name="phone2"
              onChange={(e) =>
                setModifiedData((prev) => ({
                  ...prev,
                  phone2: e,
                }))
              }
              value={modifiedData?.phone2}
              className="usersTableRegisterFormInput"
            />
          </div>
          <div
            className="usersTableRegisterFormItemS"
            style={{ width: "50%", marginLeft: "-6px" }}
          >
            <label
              className="usersTableRegisterFormLabel"
              style={{ marginBottom: "5px" }}
            >
              User Role
            </label>
            <Select
              isClearable={true}
              isSearchable={true}
              options={getRoles}
              name="roles"
              cls={submitOrEdit === "submit" ? "top-450" : ""}
              onChange={(e) => setModifiedData({ ...modifiedData, roles: e })}
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e._id}
              defaultValue={modifiedData?.roles}
            />
          </div>
          {(user.isAdmin || user.isMainOffice) && (
              <>
                  <div
                      className="usersTableRegisterFormItemS"
                      style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "48%",
                          transform: "translateY(10px)",
                      }}
              >
                <p style={{ fontWeight: "400" }}>Is main office user? :</p>
                <label
                  className="custom-checkbox-container"
                  style={{ marginTop: 0 }}
                >
                  <input
                    name="isMainOffice"
                    type="checkbox"
                    checked={modifiedData?.isMainOffice}
                    onChange={(e) => {
                      setModifiedData({
                          ...modifiedData,
                          ...{
                              isMainOffice: e.target.checked,
                              partnerId: null,
                          }
                      });
                    }}
                  />
                  <span className="custom-checkmark"></span>
                </label>
              </div>
              {!modifiedData?.isMainOffice && (
                <div
                  className="usersTableRegisterFormItemS"
                  style={{ width: "50%", marginLeft: "-6px" }}
                >
                  <label
                    className="usersTableRegisterFormLabel"
                    style={{ marginBottom: "5px" }}
                  >
                    Partner
                  </label>
                  <Select
                    isClearable={true}
                    isSearchable={true}
                    options={partners}
                    name="roles"
                    cls="top-450"
                    onChange={(e) => {
                      if (e === null) {
                        setModifiedData({
                          ...modifiedData,
                          partnerId: null,
                        });
                      } else {
                        setModifiedData({
                          ...modifiedData,
                          partnerId: e,
                        });
                      }
                    }}
                    getOptionLabel={(e) => e?.partnerName}
                    getOptionValue={(e) => e?._id}
                    defaultValue={modifiedData?.partnerId || null}
                  />
                </div>
              )}
            </>
          )}

          {/*{partners?.length > 1 && (*/}
          {/*  <div className="usersTableRegisterFormItem">*/}
          {/*    <label className="usersTableRegisterFormLabel">Partner</label>*/}
          {/*    <select*/}
          {/*      onChange={(event) => handleChoosenPartner(event.target.value)}*/}
          {/*    >*/}
          {/*      <option value="" selected disabled>*/}
          {/*        Choose One*/}
          {/*      </option>*/}
          {/*      {partners?.map((data) => (*/}
          {/*        <option value={data._id}>{data.partnerName}</option>*/}
          {/*      ))}*/}
          {/*    </select>*/}
          {/*  </div>*/}
          {/*)}*/}
        </>
      )}
    </Fragment>
  );
}

export default UserRegisterFields;

