import React, { Fragment } from "react";
import Select from "../../ReusableComponents/Select";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

function PartnerUserFields({
  partnerData,
  setPartnerData,
  partners,
  getRoles,
  submitOrEdit,
  onChange,
}) {
  const handleRoleChange = (e) => {
    if (e === null) {
      setPartnerData({
        ...partnerData,
        user: { ...partnerData.user, roles: null },
      });
    } else {
      setPartnerData({
        ...partnerData,
        user: { ...partnerData.user, roles: e },
      });
    }
  };

  return (
    <Fragment>
      {submitOrEdit === "partnerSubmit" && (
        <>
          <div className="usersTableRegisterFormItem">
            <label className="usersTableRegisterFormLabel">Username</label>
            <input
              name="username"
              onChange={onChange}
              type="text"
              className="usersTableRegisterFormInput"
              value={partnerData?.user?.username}
            />
          </div>
          <div className="usersTableRegisterFormItem">
            <label className="usersTableRegisterFormLabel">Email</label>
            <input
              name="email"
              onChange={onChange}
              type="email"
              className="usersTableRegisterFormInput"
              value={partnerData?.user?.email}
            />
          </div>
          <div className="usersTableRegisterFormItem">
            <label className="usersTableRegisterFormLabel">Password</label>
            <input
              name="password"
              onChange={onChange}
              type="text"
              className="usersTableRegisterFormInput"
              value={partnerData?.user?.password}
            />
          </div>
        </>
      )}
      <div className="usersTableRegisterFormItem">
        <label className="usersTableRegisterFormLabel">Full Name</label>
        <input
          name="fullName"
          onChange={onChange}
          value={partnerData?.user?.fullName}
          type="text"
          className="usersTableRegisterFormInput"
        />
      </div>
      <div className="usersTableRegisterFormItem">
        <label className="usersTableRegisterFormLabel">Phone 1</label>
        <PhoneInput
          placeholder="Enter phone number"
          name="phone1"
          defaultCountry="MK"
          onChange={(e) =>
            setPartnerData((prev) => ({
              ...prev,
              user: {
                ...partnerData.user,
                phone1: e,
              },
            }))
          }
          value={partnerData?.user.phone1}
          className="usersTableRegisterFormInput"
        />
      </div>
      <div className="usersTableRegisterFormItem">
        <label className="usersTableRegisterFormLabel">Phone 2</label>
        <PhoneInput
          placeholder="Enter phone number"
          name="phone2"
          defaultCountry="MK"
          onChange={(e) =>
            setPartnerData((prev) => ({
              ...prev,
              user: {
                ...partnerData.user,
                phone2: e,
              },
            }))
          }
          value={partnerData?.user.phone2}
          className="usersTableRegisterFormInput"
        />
      </div>
      <div
        className="usersTableRegisterFormItemS"
        style={{ width: "101.5%", marginLeft: "-7px" }}
      >
        <label
          className="usersTableRegisterFormLabel"
          style={{ marginBottom: "5px" }}
        >
          User Role
        </label>
        <Select
          isClearable={true}
          isSearchable={true}
          options={getRoles}
          defaultValue={partnerData?.user?.roles}
          name="roles"
          cls="top-450"
          onChange={(e) => handleRoleChange(e)}
          getOptionLabel={(e) => e.name}
          getOptionValue={(e) => e._id}
        />
      </div>
      {partners?.length > 1 && (
        <div className="usersTableRegisterFormItem">
          <label className="usersTableRegisterFormLabel">Partner</label>
          <select
            onChange={(event) => handleChoosenPartner(event.target.value)}
          >
            <option value="" selected disabled>
              Choose One
            </option>
            {partners?.map((data) => (
              <option value={data._id}>{data.partnerName}</option>
            ))}
          </select>
        </div>
      )}
    </Fragment>
  );
}

export default PartnerUserFields;

