import React, {useEffect, useRef, useState} from "react";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import "react-tabs/style/react-tabs.css";
import DashboardTable from "./Table";
import "./isMainOfficeComponent.css";

//Ledger connect
import {publicRequest} from "../../utils/requestMethods";
import TransactionsTable from "../Transfer/Transactions/TransactionsTable";
import ExchangeTable from "./ExchangeTable";
import {useSelector} from "react-redux";
import {ClipLoader} from "react-spinners";
import useSound from "use-sound";
import new_transaction from "../../assets/sounds/new_transaction.mp3";

function IsMainOfficeTable() {
    const accounts = useSelector((state) => state.user.accounting);

    const popUpData = useRef({});

    const [tableLoading, setTableLoading] = useState(false);
    const [pendingLoading, setPendingLoading] = useState(false);
    const [cryptoPending, setCryptoPending] = useState(0);
    const [transferPending, setTransferPending] = useState(0);
    const [exchangePending, setExchangePending] = useState(0);


    let intervalPending;

    const denyRequest = async (id) => {
        setLoading(true);

        const response = await publicRequest.post("/transactions/declined/" + id);
        setLoading(false);
        setStep(5);
        setAction({
            type: "edited",
            id: response.data._id,
            data: response.data,
        });
        setTimeout(() => {
            setTableLoading(false);
        }, 2000);
    };


    const getPendingCount = async () => {
        const result = await publicRequest.get('/v2/office/pending/transaction');
        if (result?.status === 200) {
            if (cryptoPending !== result.data.crypto_pending) {
                setCryptoPending(result.data.crypto_pending);
                play();
            }

            if (transferPending !== result.data.transfer_pending) {
                setTransferPending(result.data.transfer_pending);
                play();
            }
        }
    }

    const [play, {stop}] = useSound(new_transaction);

    useEffect(() => {
        play();
    }, [cryptoPending, transferPending])

    useEffect(() => {
        intervalPending = setInterval(() => {
            getPendingCount();
        }, 1000);

        return () => {
            clearInterval(intervalPending);
        }
    }, []);

    return (
        <div className="users">
            <Tabs
                style={{
                    maxWidth: "100%",
                    width: "100%",
                }}
            >
                <TabList
                    style={{
                        background: "white",
                        margin: 0,
                        padding: "7px 20px 0 20px",
                    }}
                >
                    <Tab>
                        Crypto{" "}
                        {cryptoPending > 0 && (
                            <span style={{padding: "5px"}} className="custom-tabs-numbers">
                                {pendingLoading ? (
                                    <ClipLoader size={6} color="white"/>
                                ) : (
                                    cryptoPending === 0 ? "" : cryptoPending
                                )}
                            </span>
                        )}
                    </Tab>
                    <Tab style={{marginLeft: "15px"}}>
                        Transfer{" "}
                        {transferPending > 0 && (
                            <span style={{padding: "5px"}} className="custom-tabs-numbers">
                                {pendingLoading ? (
                                    <ClipLoader size={6} color="white"/>
                                ) : (
                                    transferPending
                                )}
                            </span>
                        )}
                    </Tab>

                    <Tab style={{marginLeft: "15px"}}>
                        Exchange{" "}
                        {exchangePending > 0 && (
                            <span style={{padding: "5px"}} className="custom-tabs-numbers">
                                {pendingLoading ? (
                                    <ClipLoader size={6} color="white"/>
                                ) : (
                                    exchangePending
                                )}
                            </span>
                        )}
                    </Tab>
                </TabList>
                <TabPanel>
                    <div className="users" style={{padding: "0", marginTop: "20px"}}>
                        <div
                            className="usersUserListContainer"
                            style={{paddingBottom: ""}}
                        >
                            <DashboardTable
                                popUpData={popUpData}
                                setCryptoPending={setCryptoPending}
                                cryptoPending={cryptoPending}
                                setTransferPending={setTransferPending}
                                tableLoading={tableLoading}
                                noMarginTop={true}
                                customHeight="calc(100vh - 187px)"
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="users" style={{padding: "0", marginTop: "20px"}}>
                        <div
                            className="usersUserListContainer"
                            style={{paddingBottom: ""}}
                        >
                            <TransactionsTable
                                customHeight="calc(100vh - 213px)"
                                accounts={accounts}
                                noMarginTop={true}
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="users" style={{padding: "0", marginTop: "20px"}}>
                        <div
                            className="usersUserListContainer"
                            style={{paddingBottom: ""}}
                        >
                            <ExchangeTable
                                noMarginTop={true}
                                customHeight="calc(100vh - 187px)"
                            />
                        </div>
                    </div>
                </TabPanel>
            </Tabs>
        </div>
    );
}

export default IsMainOfficeTable;

