import {balkanCountries} from "../components/ReusableComponents/Countries";

export const firstDayOfMouth = () => {
    let date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), 1);
}


export const capitalizeWords = (str) => {
    if (!str) return;

    return str
        .toLowerCase()
        .split(/[\s_-]+/)
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

export const countryObject = (label) => {
    if (!label) return;

    return balkanCountries.find((bc) => bc.name === label);
}


export const networkValidationName = (name) => {
    switch (name) {
        case "BSC":
            return 'BNB';
        case "SEGWITBTC":
            return 'BTC';
        case "AVAXC":
            return 'AVAX';
        default:
            return name;
    }
}

export const getSessionStorage = (key, defaultValue) => {
    const stored = sessionStorage.getItem(key);
    if (!stored) {
        return defaultValue;
    }
    return JSON.parse(stored);
}

export const setSessionStorage = (key, value) => {
    if (value) {
        sessionStorage.setItem(key, JSON.stringify(value));
        return true;
    }

    return false;
}

export const removeSessionStorage = (key) => {
    if (key) {
        sessionStorage.removeItem(key);
        return true;
    }

    return false;
}

export const showOnlyStartAndEnd = (str) => {
    if (str.length <= 10) {
        return str; // Return the string as is if it has 10 or fewer characters
    }

    var visiblePart = str.slice(0, 5) + "*".repeat(str.length - 10) + str.slice(-5);

    return visiblePart;
}
