import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import React, {useEffect, useRef, useState} from "react";
import Collapse from '@mui/material/Collapse';
import {HiCalendarDays, HiOutlineCalendarDays} from "react-icons/hi2";
import {DateRangePicker, createStaticRanges} from 'react-date-range';
import {
    setHours,
    subYears,
    addDays,
    addMonths,
    endOfDay,
    endOfMonth,
    endOfWeek,
    isSameDay,
    startOfDay,
    startOfMonth,
    startOfWeek,
    startOfYear,
    endOfYear,
} from 'date-fns';

export const MRT_ToggleCalendarButton = ({table, ...rest}) => {
    const {
        getState,
        setColumnFilters
    } = table;

    const [showPicker, setShowPicket] = useState(false);
    const [state, setState] = useState([
        {
            startDate: setHours(new Date(), 0),
            endDate: new Date(),
            key: 'selection',
        }
    ]);

    const [focusedRange, setFocusedRange] = useState([0, 0]);
    const defineds = {
        startOfWeek: startOfWeek(new Date(), {weekStartsOn: 1}),
        endOfWeek: endOfWeek(new Date(), {weekStartsOn: 1}),
        startOfLastWeek: startOfWeek(addDays(new Date(), -7), {weekStartsOn: 1}),
        endOfLastWeek: endOfWeek(addDays(new Date(), -7), {weekStartsOn: 1}),
        startOfToday: startOfDay(new Date()),
        endOfToday: endOfDay(new Date()),
        startOfYesterday: startOfDay(addDays(new Date(), -1)),
        endOfYesterday: endOfDay(addDays(new Date(), -1)),
        startOfMonth: startOfMonth(new Date()),
        endOfMonth: endOfMonth(new Date()),
        startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
        endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
        startOfYear: startOfYear(new Date()),
        endOfYear: endOfYear(new Date()),
        startOfLastYear: startOfYear(subYears(new Date(), 1)),
        endOfLastYear: endOfYear(subYears(new Date(), 1))
    };

    const staticRanges = createStaticRanges(
        [
            {
                label: 'Today',
                hasCustomRendering: true,
                startDate: defineds.startOfToday,
                endDate: defineds.endOfToday,
                range: () => ({
                    startDate: defineds.startOfToday,
                    endDate: defineds.endOfToday,
                }),
            },
            {
                label: 'Yesterday',
                hasCustomRendering: true,
                range: () => ({
                    startDate: defineds.startOfYesterday,
                    endDate: defineds.endOfYesterday,
                }),
            },

            {
                label: 'This Week',
                hasCustomRendering: true,
                range: () => ({
                    startDate: defineds.startOfWeek,
                    endDate: defineds.endOfWeek,
                }),
            },
            {
                label: 'Last Week',
                hasCustomRendering: true,
                range: () => ({
                    startDate: defineds.startOfLastWeek,
                    endDate: defineds.endOfLastWeek,
                }),
            },
            {
                label: 'This Month',
                hasCustomRendering: true,
                range: () => ({
                    startDate: defineds.startOfMonth,
                    endDate: defineds.endOfMonth,
                }),
            },
            {
                label: 'Last Month',
                hasCustomRendering: true,
                range: () => ({
                    startDate: defineds.startOfLastMonth,
                    endDate: defineds.endOfLastMonth,
                }),
            },
            {
                label: 'This Year',
                hasCustomRendering: true,
                range: () => ({
                    startDate: defineds.startOfYear,
                    endDate: defineds.endOfYear,
                }),
            },
            {
                label: 'Last Year',
                hasCustomRendering: true,
                range: () => ({
                    startDate: defineds.startOfLastYear,
                    endDate: defineds.endOfLastYear,
                }),
            },
        ]);

    const handleDateChange = (dates) => {
        setState([dates.selection]);
        if (focusedRange[1]) {
            let selectedDates = [dates.selection.startDate, dates.selection.endDate]
            updateFilters(selectedDates);
            setShowPicket(!showPicker);
        }
    }
    const updateFilters = (filter) => {
        let filters = getState().columnFilters;
        let index = filters.findIndex((ff) => ff.id === 'range');
        if (index !== -1) {
            filters[index] = {id: "range", value: filter}
        } else {
            filters.push({id: "range", value: filter})
        }
        setColumnFilters([...filters]);
    }

    const handleStaticChange = (range) => {
        let selectedDates = [range.range().startDate, range.range().endDate];
        updateFilters(selectedDates);
        setShowPicket(!showPicker);
    }


    const handleToggleSearch = () => {
        setShowPicket(!showPicker);
    };

    useEffect(() => {
        sessionStorage.setItem('date_range', JSON.stringify([{
            id: "range",
            value: [state[0].startDate, addDays(state[0].endDate, 1)]
        }]));
    }, [state])

    return (
        <div>
            <Collapse
                in={showPicker}
                orientation="horizontal"
                unmountOnExit={true}
                mountOnEnter={true}
                timeout={100}
            >
                <div class={"date-range-picker"}>
                    <DateRangePicker
                        onChange={handleDateChange}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        editableDateInputs={true}
                        focusedRange={focusedRange}
                        onRangeFocusChange={setFocusedRange}
                        dateDisplayFormat={'dd.MM.yyyy'}
                        months={2}
                        ranges={state}
                        weekStartsOn={1}
                        direction="horizontal"
                        staticRanges={staticRanges}
                        inputRanges={[]}
                        renderStaticRangeLabel={(range) => (
                            <div
                                onClick={() => handleStaticChange(range)}
                            >
                                {range.label}
                            </div>
                        )}
                    />
                </div>
            </Collapse>
            <Tooltip arrow title={rest?.title ?? 'Date range'}>
                <IconButton
                    onClick={handleToggleSearch}
                    {...rest}
                    title={undefined}
                >
                    {showPicker ? <HiCalendarDays/> : <HiOutlineCalendarDays/>}
                </IconButton>
            </Tooltip>
        </div>
    );
}
