import React from "react";
import "./clientDetail.css";
import {capitalizeWords} from "../../../utils/utils";

const ClientDetail = ({ client, setDetails }) => {
  return (
    <div className="partnerDetails">
      <div className="partnerDetailsWrapper">
        <span
          onClick={() => setDetails(false)}
          className="partnerDetailsCloseButton"
        >
          <i class="fa fa-times-circle"></i>
        </span>
        <h3 className="partnerDetailsh3">Client Details</h3>
        <div className="partnerDetailsItem">
          <span className="partnerDetailsHeader">ID: </span>
          <span className="partnerDetailsSpanValue">{client._id}</span>
        </div>
        <div className="partnerDetailsItem">
          <span className="partnerDetailsHeader">First Name: </span>
          <span className="partnerDetailsSpanValue">{client.firstName}</span>
        </div>
        <div className="partnerDetailsItem">
          <span className="partnerDetailsHeader">Last Name: </span>
          <span className="partnerDetailsSpanValue">{client.lastName}</span>
        </div>
        <div className="partnerDetailsItem">
          <span className="partnerDetailsHeader">ZIP: </span>
          <span className="partnerDetailsSpanValue">{client.zip}</span>
        </div>
        <div className="partnerDetailsItem">
          <span className="partnerDetailsHeader">Partner ID: </span>
          <span className="partnerDetailsSpanValue">{client.partnerId}</span>
        </div>
        <div className="partnerDetailsItem">
          <span className="partnerDetailsHeader">Percentage Group </span>
          <span className="partnerDetailsSpanValue">
            {client.percentageGroup}
          </span>
        </div>
        <div className="partnerDetailsItem">
            <span className="partnerDetailsHeader">Address: </span>
            <span className="partnerDetailsSpanValue">{capitalizeWords(client.address)}</span>
        </div>
        <div className="partnerDetailsItem">
          <span className="partnerDetailsHeader">City: </span>
          <span className="partnerDetailsSpanValue">{client.city}</span>
        </div>
        <div className="partnerDetailsItem">
          <span className="partnerDetailsHeader">Country: </span>
          <span className="partnerDetailsSpanValue">{client.country}</span>
        </div>
        <div className="partnerDetailsItem">
          <span className="partnerDetailsHeader">Phone 1: </span>
          <span className="partnerDetailsSpanValue">{client.phone1}</span>
        </div>
        <div className="partnerDetailsItem">
          <span className="partnerDetailsHeader">Phone 2: </span>
          <span className="partnerDetailsSpanValue">{client.phone2}</span>
        </div>
        <div className="partnerDetailsItem">
          <span className="partnerDetailsHeader">Email: </span>
          <span className="partnerDetailsSpanValue">{client.email}</span>
        </div>
        <div className="partnerDetailsItem">
          <span className="partnerDetailsHeader">White Label: </span>
          <span className="partnerDetailsSpanValue">{client.whiteLabel}</span>
        </div>
        <div className="partnerDetailsItem">
          <span className="partnerDetailsHeader">Created By: </span>
          <span className="partnerDetailsSpanValue">
            {client.whoCreateIt._id}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ClientDetail;
