import React, { Fragment, useEffect, useState } from "react";
import TimeFromTo from "../../ReusableComponents/TimeFromTo";

function PartnerLimits({ partnerData, setPartnerData }) {
  return (
    <Fragment>
      <div
        className="flex flex-wrap justify-between flex-wrap"
        style={{ width: "98%" }}
      >
        <div className="flex items-start flex-wrap w-full justify-between gap-30 mt-20">
          <div className="flex flex-column w-full gap-20" style={{ flex: 1 }}>
            <h4
              style={{
                fontWeight: "400",
                borderBottom: "3px solid orange",
                width: "fit-content",
              }}
            >
              Options
            </h4>
            <div className="flex flex-column w-50 ">
              <h4
                style={{
                  fontWeight: "400",
                }}
              >
                Print List
              </h4>
              <div class="switch-holder">
                <div class="switch-toggle">
                  <input
                    type="checkbox"
                    id="published"
                    checked={
                      partnerData.limits?.options?.printList === "after"
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      setPartnerData((prev) => ({
                        ...prev,
                        limits: {
                          ...prev.limits,
                          options: {
                            printList: e.target.checked ? "after" : "before",
                          },
                        },
                      }));
                    }}
                  />
                  <label className="toggle-label" for="published"></label>
                </div>
              </div>
            </div>
          </div>
          <div className="grid w-full w-full" style={{ flex: 1 }}>
            <div className="flex items-center gap-30">
              <h4
                style={{
                  fontWeight: "400",
                  borderBottom: "3px solid orange",
                  width: "fit-content",
                }}
              >
                After Work
              </h4>
              <div
                class="switch-toggle-yes-no"
                style={{ transform: "translateY(3px)" }}
              >
                <input
                  type="checkbox"
                  id="afterwork"
                  checked={partnerData.limits?.after_work?.enabled}
                  onChange={(e) => {
                    setPartnerData((prev) => ({
                      ...prev,
                      limits: {
                        ...prev.limits,
                        after_work: {
                          ...prev.limits.after_work,
                          enabled: !prev.limits?.after_work?.enabled,
                        },
                      },
                    }));
                  }}
                />
                <label className="toggle-label" for="afterwork"></label>
              </div>
            </div>
            {partnerData.limits?.after_work?.enabled && (
              <>
                <div className="usersTableRegisterFormItem w-full">
                  <label
                    className="usersTableRegisterFormLabel"
                    style={{ transform: "translateY(-5px)" }}
                  >
                    Closed Hours
                  </label>
                  <TimeFromTo
                    partnerData={partnerData}
                    setPartnerData={setPartnerData}
                  />
                </div>
                <div className="usersTableRegisterFormItem w-full">
                  <label className="usersTableRegisterFormLabel">
                    Amount Limit
                  </label>
                  <input
                    name="apiKey"
                    type="number"
                    className="usersTableRegisterFormInput"
                    style={{ width: "97%" }}
                    value={partnerData.limits?.after_work.amountLimit}
                    onChange={(e) => {
                      setPartnerData((prev) => ({
                        ...prev,
                        limits: {
                          ...prev.limits,
                          after_work: {
                            ...prev.limits.after_work,
                            amountLimit: e.target.value,
                          },
                        },
                      }));
                    }}
                  />
                </div>
                <div
                  className="usersTableRegisterFormItem w-full"
                  style={{ alignItems: "flex-start" }}
                >
                  <label
                    className="custom-checkbox-container"
                    style={{
                      marginLeft: "0",
                      width: "93%",
                      transform: "translateY(-10px)",
                    }}
                  >
                    Percentage Read-Only
                    <input
                      type="checkbox"
                      checked={
                        partnerData.limits?.after_work?.percentageReadonly
                      }
                      onChange={(e) => {
                        setPartnerData((prev) => ({
                          ...prev,
                          limits: {
                            ...prev.limits,
                            after_work: {
                              ...prev.limits.after_work,
                              percentageReadonly: e.target.checked,
                            },
                          },
                        }));
                      }}
                    />
                    <span className="custom-checkmark"></span>
                  </label>
                </div>
              </>
            )}
          </div>
        </div>

        <div style={{ width: "100%" }}>
          <div className="flex items-start flex-wrap justify-between gap-30 mt-20">
            <div className="grid w-full w-full" style={{ flex: 1 }}>
              <h4
                style={{
                  fontWeight: "400",
                  borderBottom: "3px solid orange",
                  width: "fit-content",
                }}
              >
                Accounting
              </h4>
              <div className="usersTableRegisterFormItem w-full">
                <label className="usersTableRegisterFormLabel">Buy</label>
                <input
                  name="apiKey"
                  type="number"
                  className="usersTableRegisterFormInput"
                  style={{ width: "97%" }}
                  value={partnerData.limits?.accounting?.buy_limit}
                  onChange={(e) => {
                    setPartnerData((prev) => ({
                      ...prev,
                      limits: {
                        ...prev.limits,
                        accounting: {
                          ...prev.limits.accounting,
                          buy_limit: e.target.value,
                        },
                      },
                    }));
                  }}
                />
              </div>
              <div className="usersTableRegisterFormItem w-full">
                <label className="usersTableRegisterFormLabel">Sell</label>
                <input
                  name="privateKey"
                  type="number"
                  className="usersTableRegisterFormInput"
                  style={{ width: "97%" }}
                  value={partnerData.limits?.accounting?.sell_limit}
                  onChange={(e) => {
                    setPartnerData((prev) => ({
                      ...prev,
                      limits: {
                        ...prev.limits,
                        accounting: {
                          ...prev.limits.accounting,
                          sell_limit: e.target.value,
                        },
                      },
                    }));
                  }}
                />
              </div>
            </div>
            <div className="grid w-full w-full" style={{ flex: 1 }}>
              <h4
                style={{
                  fontWeight: "400",
                  borderBottom: "3px solid orange",
                  width: "fit-content",
                }}
              >
                Crypto
              </h4>
              <div className="usersTableRegisterFormItem w-full">
                <label className="usersTableRegisterFormLabel">Buy</label>
                <input
                  name="apiKey"
                  type="number"
                  className="usersTableRegisterFormInput"
                  style={{ width: "97%" }}
                  value={partnerData.limits?.crypto?.max_buy_limit}
                  onChange={(e) => {
                    setPartnerData((prev) => ({
                      ...prev,
                      limits: {
                        ...prev.limits,
                        crypto: {
                          ...prev.limits.crypto,
                          max_buy_limit: e.target.value,
                        },
                      },
                    }));
                  }}
                />
              </div>
              <div className="usersTableRegisterFormItem w-full">
                <label className="usersTableRegisterFormLabel">Sell</label>
                <input
                  name="privateKey"
                  type="number"
                  className="usersTableRegisterFormInput"
                  style={{ width: "97%" }}
                  value={partnerData.limits?.crypto?.max_sell_limit}
                  onChange={(e) => {
                    setPartnerData((prev) => ({
                      ...prev,
                      limits: {
                        ...prev.limits,
                        crypto: {
                          ...prev.limits.crypto,
                          max_sell_limit: e.target.value,
                        },
                      },
                    }));
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default PartnerLimits;

