import React, { useLayoutEffect } from "react";
import { FaBan, FaCheck, FaTimes } from "react-icons/fa";
import { ClipLoader } from "react-spinners";
import generateUrl from "../../../../utils/explorer";
import Moment from "moment";
import Content from "./Content";
import { IoMdDownload } from "react-icons/io";
import { BsPrinterFill } from "react-icons/bs";
import { NumberFormatter } from "../../NumberFormat";
import { exchangePrint } from "../../../../utils/templates/exchnage";
import { ExchangePdf } from "../../../../utils/templates/pdfs/exchange";
import { PDFDownloadLink } from "@react-pdf/renderer";

function ExchangePopupInfo({
    data,
    step,
    connectLedger,
    loading,
    walletAddress,
    transaction,
    denyRequest,
    noApprove,
}) {

    useLayoutEffect(() => {
        localStorage.setItem("orderId", btoa((JSON.stringify({ id: data._id, type: 'exchange' }))))
    }, [data])

    return (
        <div style={{ marginTop: "20px", paddingBottom: "40px" }}>
            {step === 4 ? (
                <>
                    <div
                        className="flex flex-column items-center justify-center w-full h-full"
                        style={{ position: "absolute", top: "-6%", left: "0" }}
                    >
                        <FaCheck
                            size={40}
                            color="white"
                            style={{
                                background: "#25da25",
                                padding: "10px",
                                borderRadius: "50%",
                            }}
                        />
                        <div
                            style={{ fontWeight: "300", fontSize: "14px", margin: "5px 0" }}
                        >
                            Success exchange
                        </div>
                        {data.amount && data.network && data.address_to && data.txId ? (
                            <>
                                {" "}
                                <div>
                                    <NumberFormatter value={data.amount} />{" "}
                                    {data.symbol.split("/")[0]}
                                </div>
                                <div
                                    style={{
                                        background: "#f7f8fa",
                                        padding: "20px 20px",
                                        marginTop: "20px",
                                        width: "60%",
                                        textAlign: "left",
                                        fontSize: "14px",
                                        display: "grid",
                                        color: "#9c9c9c",
                                        gap: "20px",
                                    }}
                                >
                                    <span style={{ display: "grid", gap: "5px" }}>
                                        Transfer account:{" "}
                                        <a
                                            style={{ color: "#4f8ae8", textDecoration: "none" }}
                                            href={generateUrl(data.network, "address", walletAddress)}
                                            target="_blank"
                                        >
                                            {walletAddress}
                                        </a>
                                    </span>
                                    <span style={{ display: "grid", gap: "5px" }}>
                                        Receiving account:{" "}
                                        <a
                                            style={{ color: "#4f8ae8", textDecoration: "none" }}
                                            href={generateUrl(
                                                data.network,
                                                "address",
                                                data.address_to?.address
                                            )}
                                            target="_blank"
                                        >
                                            {data.address_to?.address}
                                        </a>
                                    </span>
                                </div>
                                <div
                                    style={{
                                        padding: "20px 20px",
                                        marginTop: "20px",
                                        width: "60%",
                                        textAlign: "left",
                                        fontSize: "14px",
                                        display: "grid",
                                        color: "#9c9c9c",
                                        gap: "20px",
                                    }}
                                >
                                    <span>
                                        Transaciton ID:{" "}
                                        <a
                                            style={{
                                                color: "#4f8ae8",
                                                textDecoration: "none",
                                                wordBreak: "break-word",
                                            }}
                                            href={generateUrl(
                                                data.network,
                                                "transaction",
                                                transaction.txid
                                            )}
                                            target="_blank"
                                        >
                                            {transaction.txid}
                                        </a>
                                    </span>
                                    <span>
                                        Transaciton Time:{" "}
                                        {Moment(Date.now()).format("DD.MM.YYYY HH:mm")}
                                    </span>
                                </div>{" "}
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </>
            ) : step === 5 ? (
                <>
                    <div
                        className="flex flex-column items-center justify-center w-full h-full"
                        style={{ position: "absolute", top: "-6%", left: "0" }}
                    >
                        <FaTimes
                            size={30}
                            color="white"
                            style={{
                                background: "red",
                                padding: "10px",
                                borderRadius: "50%",
                            }}
                        />
                        <div
                            style={{ fontWeight: "300", fontSize: "14px", margin: "5px 0" }}
                        >
                            The request has been denied
                        </div>
                    </div>
                </>
            ) : loading ? (
                <div
                    className="grid h-full w-full place-center"
                    style={{ position: "absolute", top: "-20px", left: 0 }}
                >
                    <ClipLoader color="orange" size={30} />
                </div>
            ) : (
                <Content data={data} />
            )}

            {data.status === 4 && (
                <div
                    style={{
                        width: "100%",
                        position: "absolute",
                        bottom: "0em",
                        borderTop: "1px solid #d8d8d8",
                        left: "0",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            width: "97%",
                            borderBottom: "0",
                            background: "#fff",
                        }}
                    >
                        <div style={{
                            background: "#efefef",
                            padding: "10px",
                            borderRadius: "50%",
                            margin: "5px 5px 5px 20px",
                            width: "25px",
                            height: "25px",
                            fontSize: "25px",
                        }}
                            title="Decline"
                            onClick={() => console.log(data)}
                            className="cursor-pointer">
                            {data && (
                                <PDFDownloadLink document={<ExchangePdf data={data} />} fileName={`${data.order_number}.pdf`}>
                                    {({ blob, url, loading, error }) => (<IoMdDownload color="#8a8a8a" />)}
                                </PDFDownloadLink>
                            )}
                        </div>
                        <div
                            style={{
                                background: "#efefef",
                                padding: "10px",
                                borderRadius: "50%",
                                margin: "5px 5px 5px 10px",
                                width: "25px",
                                height: "25px",
                                fontSize: "25px",
                            }}
                            title="Print"
                            className="cursor-pointer"
                            onClick={async () => {
                                await exchangePrint(data)
                            }}
                        >
                            <BsPrinterFill color="#8a8a8a" />
                        </div>
                    </div>
                </div>
            )}

            {step === -1 && !loading && data.status === 1 && !noApprove && (
                <div
                    style={{
                        width: "100%",
                        position: "absolute",
                        bottom: "0em",
                        borderTop: "1px solid #d8d8d8",
                        left: "0",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "97%",
                            borderBottom: "0",
                            background: "#fff",
                        }}
                    >
                        <div
                            style={{
                                background: "#ff4646",
                                padding: "6px",
                                borderRadius: "50%",
                                margin: "5px 5px 5px 20px",
                                width: "30px",
                                height: "30px",
                                fontSize: "30px",
                            }}
                            title="Decline"
                            className="cursor-pointer"
                            onClick={denyRequest}
                        >
                            <FaBan color="white" />
                        </div>
                        <div
                            style={{
                                background: "#65d165",
                                padding: "6px",
                                borderRadius: "50%",
                                margin: "5px",
                                width: "30px",
                                height: "30px",
                                fontSize: "30px",
                            }}
                            title="Approve"
                            className="cursor-pointer"
                            onClick={connectLedger}
                        >
                            <FaCheck color="white" />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ExchangePopupInfo;

