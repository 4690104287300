const explorers = {
    trx: {
        transaction: "https://tronscan.org/#/transaction/{{transaction}}",
        address: "https://tronscan.org/#/address/{{address}}",
        contract: "https://tronscan.org/#/token20/{{contract}}",
    },
    erc20: {
        transaction: "https://etherscan.io/tx/{{transaction}}",
        address: "https://etherscan.io/address/{{address}}",
        contract: "https://etherscan.io/token/{{contract}}",
    },
    bep20: {
        transaction: "https://bscscan.com/tx/{{transaction}}",
        address: "https://bscscan.com/address/{{address}}",
        contract: "https://bscscan.com/token/{{contract}}",
    },
    bsc: {
        transaction: "https://bscscan.com/tx/{{transaction}}",
        address: "https://binance.mintscan.io/account/{{address}}",
        contract: "https://binance.mintscan.io/assets{{contract}}",
    },
    bnb: {
        transaction: "https://binance.mintscan.io/txs/{{transaction}}",
        address: "https://binance.mintscan.io/account/{{address}}",
        contract: "https://binance.mintscan.io/assets/{{contract}}",
    },
    eth: {
        transaction: "https://etherscan.io/tx/{{transaction}}",
        address: "https://etherscan.io/address/{{address}}",
        contract: "https://etherscan.io/token/{{contract}}",
    },
    btc: {
        transaction: "https://mempool.space/tx/{{transaction}}",
        address: "https://mempool.space/address/{{address}}",
        contract: "https://etherscan.io/token/{{contract}}",
    },
    segwitbtc: {
        transaction: "https://mempool.space/tx/{{transaction}}",
        address: "https://mempool.space/address/{{address}}",
        contract: "https://etherscan.io/token/{{contract}}",
    },

    xrp: {
        transaction: "https://xrpscan.com/tx/{{transaction}}",
        address: "https://xrpscan.com/account/{{address}}",
    },
    eos: {
        transaction: "https://bloks.io/transaction/{{transaction}}",
        address: "https://bloks.io/account/{{address}}",
    },
    dot: {
        transaction: "https://polkadot.subscan.io/extrinsic/{{transaction}}",
        address: "https://polkadot.subscan.io/account/{{address}}",
    },
    sol: {
        transaction: "https://explorer.solana.com/tx/{{transaction}}",
        address: "https://explorer.solana.com/address/{{address}}",
    },
    rune: {
        transaction: "https://thorchain.net/tx/{{transaction}}",
        address: "https://thorchain.net/address/{{address}}",
    },
    ada: {
        transaction: "https://cardanoscan.io/transaction/{{transaction}}",
        address: "https://cardanoscan.io/address/{{address}}",
    },
    ltc: {
        transaction: "https://litecoinblockexplorer.net/tx/{{transaction}}",
        address: "https://litecoinblockexplorer.net/address/{{address}}",
    }
};

const generateURL = (network, type, value) => {
    if (network && type && value) {
        if (explorers[network?.toLowerCase()]) {
            let explorer = explorers[network?.toLowerCase()][type?.toLowerCase()]
            return explorer.replace(
                `{{${type}}}`,
                value
            );
        }
        return '#'
    }
};

export default generateURL;

