import React, {useEffect, useRef, useState} from "react";
// React Calander
// CSS
import "./rightSidebar.css";
// Message Box
import "react-chat-widget/lib/styles.css";
import {useSelector} from "react-redux";
import {publicRequest} from "../../utils/requestMethods";
import {IoNotifications} from "react-icons/io5";
import Select from "react-select";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import Statistics from "./TabContent/Statistics";
import Balances from "./TabContent/Balances";
import {BsChevronDown} from "react-icons/bs";

import FeesPanel from "./Panels/FeesPanel";
import {FaTimes} from "react-icons/fa";
import Rates from "./Components/Rates";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const customStylesSelect = {
    control: (styles) => ({
        ...styles,
        borderRadius: "0px 0px 0px 0px",
        border: 0,
        boxShadow: "none",
    }),
};

const RightSidebar = ({tabsToggle}) => {
    const user = useSelector((state) => state.user.currentUser);
    const token = user?.token;
    const moreTabsRef = useRef(0);

    const [tabIndex, setTabIndex] = useState(0);
    const [showMore, setShowMore] = useState(false);
    const [date, setDate] = useState("");
    const [hour, setHour] = useState("");
    const [externalFees, setExternalFees] = useState({
        partnerName: null,
        fee: "",
        date: new Date(),
    });
    // Socket.io State

    const [notification, setNotification] = useState([]);
    const [notificationText, setNotificationText] = useState("");
    const [partners, setPartners] = useState([]);
    // const chosenPartners = useRef([])
    const [chosenPartners, setChosenPartners] = useState([]);
    const [types, setTypes] = useState([
        // {value:"0", label:"Type"},
        {value: "1", label: "Notification"},
        {value: "2", label: "Pin"},
        {value: "3", label: "Note"},
    ]);
    // const chosenType = useRef({})
    const [chosenType, setChosenType] = useState({value: null, label: "Type"});
    const [showSelectsPartnerType, setShowSelectsPartnerType] = useState(false);
    const clickedOnSelects = useRef(false);
    const focusedOnInput = useRef(false);
    // let chosenType ='';
    // useEffect(() => {
    //   if (!socket) {
    //     setSocket(
    //       io("ct.ultrasoft.mk:5050", {
    //         transports: ["websocket"],
    //         query: {
    //           token: token,
    //         },
    //       })
    //     );
    //   }

    //   // return () => {
    //   //   socket?.disconnect();
    //   // };
    // }, []);

    const getPartner = async () => {
        const response = await publicRequest.get("partner/");
        if (response.status === 200) {
            setPartners(response.data);
        }
    };
    useEffect(() => {
        getPartner();
    }, []);

    useEffect(() => {
        const getNotifications = async () => {
            const response = await publicRequest.get("notification");
            setNotification(response.data);
        };
        getNotifications();
    }, []);

    const handleCreateNotification = async () => {
        const notificationObj = {message: notificationText};
        try {
            const response = await publicRequest.post(
                "notification",
                notificationObj
            );
            setNotification([...notification, response.data]);
            setNotificationText("");
        } catch (err) {
            console.log(err);
        }
    };

    const handleDelete = async (notif) => {
        try {
            await publicRequest.delete(`notification/${notif._id}`);
            setNotification(notification.filter((x) => x._id !== notif._id));
        } catch (err) {
            console.log(err);
        }
    };

    const handleDate = () => {
        var days = new Array(
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
        );

        var months = new Array(
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        );

        var now = new Date();

        const minutes =
            now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes();

        const seconds =
            now.getSeconds() < 10 ? `0${now.getSeconds()}` : now.getSeconds();

        setDate(
            `${days[now.getDay()]}, ${now.getDate()} ${months[now.getMonth()]}`
        );

        setHour(`${now.getHours()}:${minutes}:${seconds}`);
    };

    setInterval(handleDate, 1000);

    useEffect(() => {
        function handleClickOutside(event) {
            if (moreTabsRef.current && !moreTabsRef.current.contains(event.target)) {
                setShowMore(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [moreTabsRef]);

    const restricted = user.isAdmin || user.isMainOffice ? false : true

    return (
        <div className={`rightSidebar hideRightBar`}
             style={{right: tabsToggle.right ? "0" : "-1000px", paddingTop: !user.isAdmin ? "15px" : "0"}}
        >
            {user.isAdmin ? (
                <Tabs
                    style={{width: "100%"}}
                    selectedIndex={tabIndex}
                    onSelect={(index) => setTabIndex(index)}
                >
                    <TabList
                        className="flex"
                        style={{
                            padding: "7px 20px 0 20px",
                            background: "white",
                            margin: "0",
                            position: "relative",
                            borderBottom: "1px solid #aaa",
                            minHeight: "35px",
                            maxHeight: "35px",
                        }}
                    >
                        <Tab>General</Tab>
                        <Tab>Statistics</Tab>
                        <Tab>Assets</Tab>
                        <Tab style={{display: "none"}}></Tab>
                        <Tab style={{display: "none"}}></Tab>
                        <div style={{position: "relative", top: "8px", left: "10px"}}>
                            <div
                                className="cursor-pointer"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                    borderBottom: 0,
                                    fontWeight: 500,
                                }}
                                onClick={() => {
                                    setShowMore(!showMore), setTabIndex(tabIndex);
                                }}
                            >
                                More
                                <BsChevronDown/>
                            </div>
                            {showMore && (
                                <div className="more-tabs" ref={moreTabsRef}>
                  <span
                      className="more-tabs-item"
                      onClick={() => {
                          setTabIndex(3);
                          setShowMore(false);
                      }}
                      style={{
                          borderBottom: tabIndex === 3 ? "1px solid orange" : "",
                      }}
                  >
                    Fees
                  </span>
                                    <span
                                        className="more-tabs-item"
                                        onClick={() => {
                                            setTabIndex(4);
                                            setShowMore(false);
                                        }}
                                        style={{
                                            borderBottom: tabIndex === 4 ? "1px solid orange" : "",
                                        }}
                                    >
                    Transactions
                  </span>
                                </div>
                            )}
                        </div>
                    </TabList>
                    <TabPanel>
                        <>
                            <div className="rightSidebarCalander mx-auto">
                                <div className="rightSidebarCalanderItem">
                                    <div className="rightSidebarCalanderItemWrapper">
                    <span className="rightSidebarCalanderItemDateTopSpan">
                      {date}
                    </span>
                                        <div className="rightSidebarCalanderItemDateLine"></div>
                                        <span className="rightSidebarCalanderItemDateBottomSpan">
                      {hour}
                    </span>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="rightSidebarNotificationsWrapper mx-auto"
                                style={{position: "relative", zIndex: 20}}
                            >
                                <p>Notifications</p>
                                <div
                                    className="rightSidebarNotifications"
                                    style={{
                                        maxHeight: "200px",
                                        overflowY: "scroll",
                                    }}
                                >
                                    <div className="notificationsCard">
                                        {notification.length ? (
                                            <>
                                                {notification
                                                    .map((notif, index) => (
                                                        <div
                                                            key={index}
                                                            className="rightSidebarNotificationsItems"
                                                            style={{position: "relative"}}
                                                        >
                                                            {user.isAdmin && (
                                                                <div
                                                                    className="cursor-pointer"
                                                                    style={{
                                                                        position: "absolute",
                                                                        left: "-20px",
                                                                        width: "fit-content",
                                                                    }}
                                                                    onClick={() => handleDelete(notif)}
                                                                >
                                                                    <FaTimes size={12} color="#e7e7e7"/>
                                                                </div>
                                                            )}
                                                            <div style={{width: "4%"}}>
                                                                <IoNotifications color="#fc522d"/>
                                                            </div>
                                                            <div style={{width: "96%"}}>
                                                                <span>{notif.message}</span>
                                                            </div>
                                                        </div>
                                                    ))
                                                    .reverse()}
                                            </>
                                        ) : (
                                            <div style={{padding: "15px 0 10px 0"}}>
                                                No notifications
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {user.isAdmin && (
                                    <>
                                        <div
                                            className="addNewNotificationButton"
                                            style={{zIndex: "9999"}}
                                        >
                                            <input
                                                type="text"
                                                onChange={(event) =>
                                                    setNotificationText(event.target.value)
                                                }
                                                value={notificationText}
                                                className="rightSidebarNotificationInput"
                                                placeholder="Add new notification..."
                                                onKeyPress={(e) =>
                                                    e.key === "Enter" && handleCreateNotification(0)
                                                }
                                                onFocus={() => {
                                                    focusedOnInput.current = true;
                                                    if (showSelectsPartnerType == false)
                                                        setShowSelectsPartnerType(true);
                                                }}
                                                onBlur={async () => {
                                                    focusedOnInput.current = false;
                                                    await delay(150);
                                                    if (clickedOnSelects.current === false) {
                                                        setShowSelectsPartnerType(false);
                                                        setChosenPartners([]);
                                                        setChosenType([]);
                                                    }
                                                }}
                                            />
                                            <button
                                                className="rightSidebarNotificationCreateButton"
                                                onClick={() => handleCreateNotification(0)}
                                            >
                                                Create
                                            </button>
                                        </div>

                                        <div
                                            style={{
                                                transform: showSelectsPartnerType
                                                    ? "translateY(0)"
                                                    : "translateY(-50px)",
                                                transition: "0.2s ease-in-out",
                                                zIndex: showSelectsPartnerType ? "9998" : "-1",
                                                position: "relative",
                                            }}
                                            className="notificationSelectPartnerAndTypeHeight"
                                            onClick={() => {
                                                clickedOnSelects.current = true;
                                            }}
                                            onBlur={async () => {
                                                clickedOnSelects.current = false;
                                                await delay(150);
                                                if (
                                                    clickedOnSelects.current === false &&
                                                    focusedOnInput.current == false
                                                ) {
                                                    setShowSelectsPartnerType(false);
                                                    setChosenPartners([]);
                                                    setChosenType([]);
                                                }
                                            }}
                                        >
                                            <div className="notificationSelectPartnerAndTypePosition">
                                                <div>
                                                    <Select
                                                        placeholder="Partner"
                                                        options={partners}
                                                        getOptionLabel={(e) => e.partnerName}
                                                        getOptionValue={(e) => e._id}
                                                        isMulti={true}
                                                        onChange={(event) => {
                                                            setChosenPartners(event);
                                                        }}
                                                        isClearable
                                                        closeMenuOnSelect={false}
                                                        styles={customStylesSelect}
                                                        value={
                                                            chosenPartners.length
                                                                ? chosenPartners
                                                                : () => {
                                                                    return;
                                                                }
                                                        }
                                                    />
                                                </div>

                                                <div>
                                                    <Select
                                                        placeholder="Type"
                                                        options={types}
                                                        onChange={(event) => {
                                                            setChosenType(event);
                                                        }}
                                                        value={
                                                            chosenType
                                                                ? chosenType
                                                                : () => {
                                                                    return;
                                                                }
                                                        }
                                                        isClearable
                                                        styles={customStylesSelect}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div
                                className="rightSidebarStatisticsWrapper mx-auto"
                                style={{
                                    height: "calc(90% - 60px)",
                                    transition: "0.2s ease-in-out",
                                    marginTop: showSelectsPartnerType ? "0" : "-37px",
                                    zIndex: 10,
                                }}
                            >
                                <p>Daily Statistics</p>
                                <Tabs
                                    style={{width: "fit-content", marginTop: "3%"}}
                                    className="custom-tabs-cont-side w-full h-full"
                                >
                                    <TabList
                                        style={{
                                            margin: 0,
                                            transform: "translateY(8px)",
                                            display: "flex",
                                            alignItems: "center",
                                            listStyle: "none",
                                            borderBottom: "0",
                                        }}
                                    >
                                        {!restricted && <Tab className="custom-tabs-small">Statistics</Tab>}
                                        <Tab className="custom-tabs-small">Balances</Tab>
                                    </TabList>
                                    {!restricted &&
                                        <TabPanel>
                                            <div
                                                className="rightSidebarStatistics"
                                                style={{position: "relative"}}
                                            >
                                                <Statistics/>
                                            </div>
                                        </TabPanel>
                                    }
                                    <TabPanel>
                                        <div
                                            className="rightSidebarStatistics"
                                            style={{position: "relative"}}
                                        >
                                            <Balances/>
                                        </div>
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </>
                    </TabPanel>
                    <TabPanel>Statistics</TabPanel>
                    <TabPanel>Assets</TabPanel>
                    <TabPanel
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "0 20px",
                        }}
                    >
                        <FeesPanel
                            externalFees={externalFees}
                            setExternalFees={setExternalFees}
                        />
                    </TabPanel>
                    <TabPanel>Transactions</TabPanel>
                </Tabs>
            ) : (
                <>
                    <div className="rightSidebarCalander">
                        <div className="rightSidebarCalanderItem">
                            <div className="rightSidebarCalanderItemWrapper">
                <span className="rightSidebarCalanderItemDateTopSpan">
                  {date}
                </span>
                                <div className="rightSidebarCalanderItemDateLine"></div>
                                <span className="rightSidebarCalanderItemDateBottomSpan">
                  {hour}
                </span>
                            </div>
                        </div>
                    </div>
                    <div className="rightSidebarNotificationsWrapper">
                        <p>Notifications</p>
                        <div
                            className="rightSidebarNotifications"
                            style={{
                                maxHeight: "200px",
                                overflowY: "scroll",
                            }}
                        >
                            <div className="notificationsCard">
                                {notification.length ? (
                                    <>
                                        {notification
                                            .map((notif, index) => (
                                                <div
                                                    key={index}
                                                    className="rightSidebarNotificationsItems"
                                                >
                                                    <div style={{width: "4%"}}>
                                                        <IoNotifications color="#fc522d"/>
                                                    </div>
                                                    <div style={{width: "96%"}}>
                                                        <span>{notif.message}</span>
                                                    </div>
                                                </div>
                                            ))
                                            .reverse()}
                                    </>
                                ) : (
                                    <div style={{padding: "15px 0 10px 0"}}>
                                        No notifications
                                    </div>
                                )}
                            </div>
                        </div>

                        {user.isAdmin && (
                            <>
                                <div className="addNewNotificationButton">
                                    <input
                                        type="text"
                                        onChange={(event) =>
                                            setNotificationText(event.target.value)
                                        }
                                        value={notificationText}
                                        className="rightSidebarNotificationInput"
                                        placeholder="Add new notification..."
                                        onKeyPress={(e) =>
                                            e.key === "Enter" && handleCreateNotification(0)
                                        }
                                        onFocus={() => {
                                            focusedOnInput.current = true;
                                            if (showSelectsPartnerType == false)
                                                setShowSelectsPartnerType(true);
                                        }}
                                        onBlur={async () => {
                                            focusedOnInput.current = false;
                                            await delay(150);
                                            if (clickedOnSelects.current === false) {
                                                setShowSelectsPartnerType(false);
                                                setChosenPartners([]);
                                                setChosenType([]);
                                            }
                                        }}
                                    />
                                    <button
                                        className="rightSidebarNotificationCreateButton"
                                        onClick={() => handleCreateNotification(0)}
                                    >
                                        Create
                                    </button>
                                </div>

                                <div
                                    style={{
                                        transform: showSelectsPartnerType
                                            ? "translateY(0)"
                                            : "translateY(-50px)",
                                        transition: "0.2s ease-in-out",
                                        zIndex: showSelectsPartnerType ? "10" : "-1",
                                    }}
                                    className="notificationSelectPartnerAndTypeHeight"
                                    onClick={() => {
                                        clickedOnSelects.current = true;
                                    }}
                                    onBlur={async () => {
                                        clickedOnSelects.current = false;
                                        await delay(150);
                                        if (
                                            clickedOnSelects.current === false &&
                                            focusedOnInput.current == false
                                        ) {
                                            setShowSelectsPartnerType(false);
                                            setChosenPartners([]);
                                            setChosenType([]);
                                        }
                                    }}
                                >
                                    <div className="notificationSelectPartnerAndTypePosition">
                                        <div>
                                            <Select
                                                placeholder="Partner"
                                                options={partners}
                                                isMulti={true}
                                                onChange={(event) => {
                                                    setChosenPartners(event);
                                                }}
                                                isClearable
                                                closeMenuOnSelect={false}
                                                styles={customStylesSelect}
                                                value={
                                                    chosenPartners.length
                                                        ? chosenPartners
                                                        : () => {
                                                            return;
                                                        }
                                                }
                                            />
                                        </div>

                                        <div>
                                            <Select
                                                placeholder="Type"
                                                options={types}
                                                onChange={(event) => {
                                                    setChosenType(event);
                                                }}
                                                value={
                                                    chosenType
                                                        ? chosenType
                                                        : () => {
                                                            return;
                                                        }
                                                }
                                                isClearable
                                                styles={customStylesSelect}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>

                    {(user.isAdmin || user.isMainOffice) &&
                        <div
                            className="rightSidebarRatesWrapper mx-auto"
                            style={{
                                transition: "0.2s ease-in-out",
                                zIndex: 10,
                            }}
                        >
                            <p>Rates</p>
                            <Rates/>
                        </div>
                    }
                    <div
                        className="rightSidebarStatisticsWrapper"
                        style={{height: "calc(57% - 60px)"}}
                    >
                        <p>Statistics</p>
                        <Tabs
                            style={{width: "fit-content", marginTop: "3%"}}
                            className="custom-tabs-cont-side w-full h-full"
                        >
                            <TabList
                                style={{
                                    margin: 0,
                                    transform: "translateY(8px)",
                                    display: "flex",
                                    alignItems: "center",
                                    listStyle: "none",
                                }}
                            >
                                {!restricted && <Tab className="custom-tabs-small">Statistics</Tab>}
                                <Tab className="custom-tabs-small">Balances</Tab>
                            </TabList>

                            {!restricted &&
                                <TabPanel>
                                    <div
                                        className="rightSidebarStatistics"
                                        style={{position: "relative"}}
                                    >
                                        <Statistics/>
                                    </div>
                                </TabPanel>
                            }

                            <TabPanel>
                                <div
                                    className="rightSidebarStatistics"
                                    style={{position: "relative"}}
                                >
                                    <Balances/>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </>
            )}
        </div>
    );
};

export default RightSidebar;

