import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import React, {useRef, useState} from "react";
import {MRT_ToggleAdvancedFilterMenu} from "./MRT_ToggleAdvancedFilterMenu";
import {TbFilter, TbFilterOff} from "react-icons/tb";

export const MRT_ToggleAdvancedFilterButton = ({table, ...rest}) => {
    const advancedFiltersRef = useRef();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    return (
        <>
            <Tooltip arrow title={rest?.title ?? 'Advanced Filters'} ref={advancedFiltersRef}>
                <IconButton
                    aria-label={'Advanced Filters'}
                    onClick={handleClick}
                    {...rest}
                    title={undefined}
                >
                    {anchorEl ? <TbFilterOff/> : <TbFilter/>}
                </IconButton>
            </Tooltip>
            <MRT_ToggleAdvancedFilterMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                table={table}
            />
        </>
    );
}
