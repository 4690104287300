import Button from "../../ReusableComponents/Button";
import {FaCheck, FaSave, FaTimes} from "react-icons/fa";
import {ClipLoader} from "react-spinners";
import ApiAddressSelect from "../../ReusableComponents/ApiAddressSelect";
import {Dex} from "../Wallet/dex";
import React, {useEffect, useState} from "react";
import {publicRequest} from "../../../utils/requestMethods";


const DEX = ({
                 settings,
                 setSettings,
                 saveGlobalSettings,
                 activeItem,
                 handleNetworkAdd,
                 loading,
                 setLoading,
                 checkPermissions,
                 successApi,
                 failApi,
                 handleInputChange,
                 saveSuccess,
                 saveLoading
             }) => {

    const [selectedAddresses, setSelectedAddresses] = useState([]);
    const [selectData, setSelectData] = useState([]);

    const getAddressesList = async () => {
        return await publicRequest.get('/v2/dex/addresses');
    }

    useEffect(() => {
        getAddressesList().then((response) => {
            if (response.status === 200) {
                setSelectData(response.data.data);
                setSelectedAddresses(settings.exchanges.dex)
            }
        })
    }, []);

    useEffect(() => {
        if (selectedAddresses.length) {
            setSettings((prev) => ({
                ...prev,
                exchanges: {
                    ...prev.exchanges,
                    dex: selectedAddresses
                }
            }))
        } else {
            setSettings((prev) => ({
                ...prev,
                exchanges: {
                    ...prev.exchanges,
                    dex: []
                }
            }))
        }
    }, [selectedAddresses])


    return (
        <div
            className="users"
            style={{
                margin: '0 auto',
                width: "98%",
            }}
        >
            {saveSuccess && (
                <div className="success-alert">
                    Settings Saved Successfully!
                </div>
            )}
            <div
                className="card"
                style={{
                    padding: "10px 20px 30px 20px",
                    marginTop: "20px",
                    width: "calc(100% - 40px)",
                }}
            >
                <div className="flex flex-column flex-wrap gap-20">
                    <div className="flex items-center justify-between">
                        <h3 className="custom-heading w-full">API Keys</h3>
                        <div style={{maxWidth: "fit-content", marginLeft: "auto"}}>
                            <Button
                                title={saveLoading ? "Saving..." : "Save"}
                                icon={<FaSave color="white" size={20}/>}
                                onClick={saveGlobalSettings}
                                cls=""
                            />
                        </div>
                    </div>
                    <div className="flex gap-20 justify-between w-full flex-nowrap">
                        <ApiAddressSelect entity={selectedAddresses} setEntity={setSelectedAddresses}
                                          selectData={selectData} setSelectData={setSelectData}/>
                    </div>
                </div>

                <Dex
                    activeItem={activeItem}
                    handleNetworkAdd={handleNetworkAdd}
                    loading={loading}
                    setLoading={setLoading}
                    selectData={selectData}
                    setSelectData={setSelectData}
                />
            </div>
        </div>
    );
}

export default DEX;