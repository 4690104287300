import Chart from 'react-apexcharts'
import { useEffect, useState } from "react";
import axios from "axios";

export function CryptoChart({ fsym, tsym }) {

    let [data, setData] = useState([])

    useEffect(() => {
        axios.get(`https://min-api.cryptocompare.com/data/histominute?fsym=${fsym}&tsym=${tsym}&limit=30`).then((response) => {
            let parsed_data = response.data.Data;
            let simpleData = []
            parsed_data.map((value, index) => {

                simpleData.push([value.time * 1000, value.high]);
            });

            setData(simpleData);
        });


    }, [])
    let options = {
        chart: {
            toolbar: {
                show: false,
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            height: '250px',
            width: "100%"
        },
        stroke: {
            width: 1
        },
        grid: {
            show: false
        },
        xaxis: {
            type: "datetime",
            labels: {
                show: false
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },
        colors: ['#ff9500'],
        yaxis: {
            labels: {
                show: false,
            }
        },
        tooltip: {
            x: {
                format: 'dd.MM.yy HH:mm'
            },
        },

    };

    let series = [{
        name: `${fsym}/${tsym}`,
        data: data
    },
    ];

    return (
        <Chart
            options={options}
            series={series}
            type={'line'}
        />
    )
}
