import React from "react";

export class Socket {
    constructor(socket) {
        this.socket = socket;
    }

    cryptoPrice(cb) {
        this.socket.on('crypto', (data) => {
            cb(data);
        })
    }

    fiatPrice(cb) {
        this.socket.on('fiat', (data) => {
            try {
                cb(JSON.parse(data));
            } catch (e) {
                console.error(e);
            }
        })
    }

    updatePendingTransactions(cb) {
        this.socket.on('pending_transaction', (data) => {
            try {
                cb(data);
            } catch (e) {
                console.error(e);
            }
        })
    }

    updateAccounts(cb) {
        this.socket.on('accounting', (data) => {
            cb(data);
        });
    }

    updatePercentage(cb) {
        this.socket.on('percentages', (data) => {
            cb(data);
        });
    }

    updateExFee(cb) {
        this.socket.on('exfee', (data) => {
            cb(data);
        });
    }

    updateRow(cb) {
        this.socket.on('update_row', (data) => {
            cb(data);
        });
    }
}

export const SocketContext = React.createContext()
