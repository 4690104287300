import React, { useEffect, useRef, useState } from "react";
import TransactionTable from "../Dashboard/Table";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ExchangeTable from "../Dashboard/ExchangeTable";

import TransactionsTable from "../Transfer/Transactions/TransactionsTable";

import { useSelector } from "react-redux";


const Transactions = ({ tabIndex, scanData, setScanData }) => {
  const accounts = useSelector((state) => state.user.accounting);
  const popUpData = useRef({});
  const [indexTab, setIndexTab] = useState(0);

  const [tableLoading, setTableLoading] = useState(false);


  useEffect(() => {
    setIndexTab(scanData ? tabIndex : 0);
  }, [tabIndex]);

  return (
    <div
      className="transactions"
      style={{ width: "100%", overflowX: "hidden" }}
    >
      <Tabs
        style={{
          maxWidth: "100%",
          width: "100%",
        }}
        selectedIndex={indexTab}
      >
        <TabList
          style={{
            padding: "7px 20px 0 20px",
            background: "white",
            margin: "0",
          }}
        >
          <Tab onClick={() => setIndexTab(0)}>Crypto</Tab>
          <Tab onClick={() => setIndexTab(1)}>Transfer</Tab>
          <Tab onClick={() => setIndexTab(2)}>Exchange</Tab>
        </TabList>
        <TabPanel>
          <div className="users" style={{ padding: "0", marginTop: "20px" }}>
            <div className="usersUserListContainer">
              {indexTab === 0 && (
                <TransactionTable
                  popUpData={popUpData}
                  tableLoading={tableLoading}
                  scanData={scanData}
                  setScanData={setScanData}
                  noMarginTop={true}
                  customHeight="calc(100vh - 187px)"
                />
              )}
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="users" style={{ padding: "0", marginTop: "20px" }}>
            <div
              className="usersUserListContainer"
              style={{ paddingBottom: "" }}
            >
              {indexTab === 1 && (
                <TransactionsTable
                  customHeight="calc(100vh - 213px)"
                  accounts={accounts}
                  scanData={scanData}
                  setScanData={setScanData}
                  noButtons={true}
                />
              )}
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="users" style={{ padding: "0", marginTop: "20px" }}>
            <div
              className="usersUserListContainer"
              style={{ paddingBottom: "" }}
            >
              {indexTab === 2 && (
                <ExchangeTable
                  noMarginTop={true}
                  customHeight="calc(100vh - 187px)"
                  scanData={scanData}
                  setScanData={setScanData}

                />
              )}
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Transactions;

