import React, {useEffect, useMemo, useRef, useState} from "react";
import MainTable from "../ReusableComponents/Tables/MRT_Main";
import {publicRequest} from "../../utils/requestMethods";
import iconGenerator from "../../utils/iconGenerator";

const CurrencyTable = ({fiat}) => {
    const [currencies, setCurrencies] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [addLoading, setAddLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [requestURL, setRequestURL] = useState("v2/currencies");
    const [action, setAction] = useState({type: "", id: 0, data: null});

    const logTableRef = useRef();

    const mainColumns = useMemo(
        () => [
            {
                accessorKey: "symbol",
                header: "Symbol",
                size: 80,
                accessorFn: (e) => (
                    <div
                        className="flex items-center gap-5"
                        style={{maxWidth: "fit-content"}}
                    >
                        <img
                            src={iconGenerator(64, null, e.symbol, true)}
                            alt={e.symbol}
                            width={32}
                        />
                        {e.symbol}
                    </div>
                ),
            },
            {
                accessorKey: "name",
                header: "Name",
                enableGrouping: false,
                size: 150,
            },
            {
                accessorKey: "rate",
                header: "Rate",
                size: 80,
            },
            {
                accessorKey: "buying_rate",
                header: "Buying Rate",
                size: 80,
            },
            {
                accessorKey: "selling_rate",
                header: "Selling Rate",
                size: 80,
            },
        ],
        []
    );

    useEffect(() => {
        if (fiat) {
            currencies.map((currency) => {
                if (currency.auto_rate && currency.status) {
                    let rate = fiat.find((f) => f.symbol === currency.symbol);
                    if (rate) {
                        currency.rate = rate.rate;
                        currency.buying_rate = rate.buying_rate;
                        currency.selling_rate = rate.selling_rate;
                    }
                }
                return currency;
            });
        }
    }, [fiat]);

    const handleSave = async () => {
        try {
            setAddLoading(true);
            const response = await publicRequest.put(
                "/currency/update/rate",
                currencies
            );

            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
            }, 2000);
            setAddLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    const handleSwitch = async (data) => {
        try {
            setLoading(data._id);

            const response2 = await publicRequest.patch(`currency/${data._id}`, {
                status: !data.status,
            });
            if (response2.status === 200) {
                setAction({
                    type: "edited",
                    id: data._id,
                    data: response2.data,
                });
                setLoading(-1);
            } else {
                setLoading(-1);
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div
            className="clientsTable"
            style={{
                width: "100%",
                padding: 0,
                marginTop: "10px",
            }}
        >
            {success && <div className="success-alert">Saved Successfully!</div>}
            <div
                className="clientsTableMaterialTableContainer"
                style={{width: "100%", maxWidth: "100%", marginTop: "10px"}}
            >
                <MainTable
                    columns={mainColumns}
                    requestURL={requestURL}
                    setAction={setAction}
                    action={action}
                    isSocketUpdatable={false}
                    tableContainerRef={logTableRef}
                    tableHeight={"calc(100vh - 130px)"}
                    enableGrouping={true}
                    renderFetchFooterToolbar={false}
                    fiat={fiat}
                    customFilters={[{onlyEnabled: false}]}
                    onRowClick={() => {
                    }}
                    toolbarActions={{
                        search: true,
                        columnsFilter: true,
                        columnsVisible: true,
                        density: true,
                        fullScreen: true,
                    }}
                    renderRowActions={[
                        {
                            type: "switch",
                            name: "Switch",
                            onClick: handleSwitch,
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export default CurrencyTable;

