import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./dashboard.css";

import NetworkModal from "../NetworkModal/NetworkModal";
import { publicRequest } from "../../utils/requestMethods";

import NormalComponent from "./normalComponent";

// Socket.io
import IsMainOfficeTable from "./isMainOfficeComponent";
import MainModal from "../ReusableComponents/MainModal";
import { ClipLoader } from "react-spinners";
import { SocketContext } from "../../context/socket";
import { sortingCoins } from "../../utils/sorting";

const Dashboard = () => {
  const user = useSelector((state) => state.user);
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const currentUser = user.currentUser;
  const userCoins = currentUser?.options.dashboard.coins;
  const menuRoles = user.menu;
  const permissions = menuRoles
    .find((x) => x.value === "dashboard")
    .permissions.reduce((obj, item) => Object.assign({ ...obj, ...item }), {});
  const approve = true;

  const [searchCoinVisible, setSearchCoinVisible] = useState(false);
  const [searchPendingQuery, setSearchPendingQuery] = useState(false);
  const [isSearched, setIsSearched] = useState(false);

  const [coinSearch, setCoinSearch] = useState("");
  const [filteredCoins, setFilteredCoins] = useState([]);
  const [allCoins, setAllCoins] = useState([]);
  const [choosenCrypto, setChoosenCrypto] = useState("BTC");
  const [choosenCryptoBNBPrice, setChoosenCryptoBNBPrice] = useState(1);
  const [choosenOrderType, setChoosenOrderType] = useState("BUY");
  const [networks, setNetworks] = useState([]);
  const [networkLoading, setNetworkLoading] = useState(false);
  const [afterClick, setAfterClick] = useState(-1);
  const [cryptoCurrencies, setCryptoCurrencies] = useState([]);
  const [singleCryptoCurrencies, setSingleCryptoCurrencies] = useState([]);
  const [coinInfo, setCoinInfo] = useState([]);
  const [pendingQuery, setPendingQuery] = useState("");
  const [selectValue, setSelectValue] = useState("BNB");
  const [loading, setLoading] = useState(false);

  // Socket IO
  const [cryptoPrice, setCryptoPrice] = useState([]);
  const [fiatPrice, setFiatPrice] = useState([]);

  useEffect(() => {
    if (!currentUser.isMainOffice) {
      socket.cryptoPrice((data) => {
        setCryptoPrice(data);
      });

      socket.fiatPrice((data) => {
        setFiatPrice(data);
      });
    }
  }, [socket]);

  // Network Modal States
  const [networkModal, setNetworkModal] = useState(false);
  const [choosenNetwork, setChoosenNetwork] = useState("");

  useEffect(() => {
    const getCoins = async () => {
      try {
        setLoading(true);
        const response = await publicRequest.get("/exchanges/cex/coins/all");

        let emptyArr = response.data.map((item) => {
          if (userCoins.includes(item.symbol)) {
            return item;
          }
        });

        let coins = sortingCoins(
          userCoins,
          emptyArr.filter((item) => item)
        );

        setAllCoins(coins);
        setFilteredCoins(coins);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    getCoins();
  }, []);

  const filterCoins = (event) => {
    setCoinSearch(event.target.value);
  };

  useEffect(() => {
    if (coinSearch.length < 3) {
      setFilteredCoins(sortingCoins(userCoins, allCoins));
      setIsSearched(false);
    } else {
      const getCoin = async () => {
        try {
          const body = {
            search: coinSearch,
          };

          const response = await publicRequest.post(
            "/exchanges/cex/coins",
            body
          );

          setFilteredCoins(response.data);
          setIsSearched(true);
        } catch (err) {
          console.log(err);
        }
      };

      getCoin();
    }
  }, [coinSearch]);

  useEffect(() => {
    const { binance } = cryptoPrice;
    switch (selectValue) {
      case "BNB":
        if (binance !== undefined) {
          binance.map((bp) => {
            if (bp?.p) {
              let myKey = bp?.s.split("USDT")[0];
              let index = filteredCoins.findIndex((fc) => fc.symbol === myKey);
              if (index !== -1) {
                let newPrice = filteredCoins[index];
                const buyBinance = Number(bp?.p);
                newPrice.price = buyBinance.toFixed(
                  filteredCoins[index].markets.bnb.price
                );
              }
            }
          });
        }
        break;
      case "DEX":
        break;
    }

    if (binance !== undefined) {
      binance.map((bp) => {
        const symbol = bp?.s.split("USDT")[0];
        if (symbol === choosenCrypto) {
          const coin = filteredCoins.find((coin) => coin.symbol === symbol);
          if (choosenCrypto !== "USDT") {
            const buyBinance = parseFloat(bp?.p).toFixed(
              coin?.markets?.bnb.price
            );
            setChoosenCryptoBNBPrice(buyBinance);
          } else {
            setChoosenCryptoBNBPrice(parseFloat(1).toFixed(3));
          }
        }
      });
    }
  }, [cryptoPrice]);

  const handleChartSellClick = async (pair, item) => {
    setNetworkModal(true);
    setChoosenCrypto(pair);
    setChoosenOrderType("SELL");
    setAfterClick(-1);
    setCoinInfo(item);
  };

  const handleChartBuyClick = async (pair, item) => {
    setNetworkModal(true);
    setChoosenCrypto(pair);
    setChoosenOrderType("BUY");
    setAfterClick(-1);
    setCoinInfo(item);
  };

  return (
    <div
      className="dashboard"
      style={{
        padding: "0",
        borderRadius: "0",
      }}
    >
      <>
        <MainModal
          isOpen={networkModal}
          closeModal={() => setNetworkModal(false)}
          onRequestClose={() => setNetworkModal(false)}
          closable={true}
          content={
            <NetworkModal
              setChoosenNetwork={setChoosenNetwork}
              choosenCrypto={choosenCrypto}
              choosenOrderType={choosenOrderType}
              setChoosenOrderType={setChoosenOrderType}
              setNetworkModal={setNetworkModal}
              networkLoading={networkLoading}
              coinInfo={coinInfo}
              choosenCryptoBNBPrice={choosenCryptoBNBPrice}
            />
          }
        />
        {currentUser.isMainOffice ? (
          <div className="dashboardHeader" style={{ margin: 0 }}>
            <IsMainOfficeTable />
          </div>
        ) : (
          <NormalComponent
            setSearchCoinVisible={setSearchCoinVisible}
            searchCoinVisible={searchCoinVisible}
            setSelectValue={setSelectValue}
            filteredCoins={filteredCoins}
            setFilteredCoins={setFilteredCoins}
            filterCoins={filterCoins}
            handleChartBuyClick={handleChartBuyClick}
            handleChartSellClick={handleChartSellClick}
            setSearchPendingQuery={setSearchPendingQuery}
            setPendingQuery={setPendingQuery}
            approve={approve}
            searchPendingQuery={searchPendingQuery}
            coinSearch={coinSearch}
            setCoinSearch={setCoinSearch}
            choosenCrypto={choosenCrypto}
            selectValue={selectValue}
            choosenOrderType={choosenOrderType}
            networks={networks}
            networkLoading={networkLoading}
            afterClick={afterClick}
            setAfterClick={setAfterClick}
            singleCryptoCurrencies={singleCryptoCurrencies}
            cryptoCurrencies={cryptoCurrencies}
            choosenNetwork={choosenNetwork}
            setNetworkLoading={setNetworkLoading}
            pendingQuery={pendingQuery}
            coinInfo={coinInfo}
            choosenCryptoBNBPrice={choosenCryptoBNBPrice}
            fiat={fiatPrice}
            setChoosenNetwork={setChoosenNetwork}
            isSearched={isSearched}
            allCoins={allCoins}
            setAllCoins={setAllCoins}
            permissions={permissions}
            loading={loading}
          />
        )}
      </>
    </div>
  );
};

export default Dashboard;

