import React, {Fragment, useEffect, useState} from "react";
import {FaCheck, FaEdit, FaTimes} from "react-icons/fa";
import Moment from "moment";

function PanelInfo({ modifiedData, handleUserEditInfo, success }) {
  const [staticData, setStaticData] = useState();

  useEffect(() => {
    setStaticData(modifiedData);
  }, [success]);

  return (
    <Fragment>
      <div className="grid w-full gap-20">
        <span
          className="cursor-pointer flex items-center gap-5 justify-end"
          style={{ marginBottom: "-20px" }}
          onClick={handleUserEditInfo}
        >
          <FaEdit color="orange" size={18} style={{ width: "25px" }} />
        </span>
        <div className="flex items-start justify-between w-full">
          <div className="userinfo-items">
            <span>Full Name</span>
            <p>{staticData?.fullName}</p>
          </div>
          <div className="userinfo-items">
            <span>Partner Name</span>
            <p>{staticData?.partnerId?.partnerName}</p>
          </div>
          <div className="userinfo-items">
            <span>Username</span>
            <p>{staticData?.username}</p>
          </div>
          <div className="userinfo-items">
            <span>Email</span>
            <p>{staticData?.email}</p>
          </div>
          <div className="userinfo-items">
            <span>Phone 1</span>
            <p>{staticData?.phone1}</p>
          </div>
          <div className="userinfo-items">
            <span>Phone 2</span>
            <p>{staticData?.phone2}</p>
          </div>
        </div>
        <div className="flex items-start justify-between w-full">
          <div className="userinfo-items">
            <span>Role</span>
            <p>{staticData?.roles?.name || ""}</p>
          </div>
          <div className="userinfo-items">
            <span>Is Admin</span>
            {staticData?.isAdmin === true ? (
              <FaCheck color="green" />
            ) : (
              <FaTimes color="red" />
            )}
          </div>
          <div className="userinfo-items">
            <span>Is Main Office</span>
            {staticData?.isMainOffice === true ? (
              <FaCheck color="green" />
            ) : (
              <FaTimes color="red" />
            )}
          </div>
          <div className="userinfo-items">
            <span>Is Partner</span>
            {staticData?.isPartner === true ? (
              <FaCheck color="green" />
            ) : (
              <FaTimes color="red" />
            )}
          </div>
          <div className="userinfo-items">
            <span>Date Created</span>
            {Moment(staticData?.createdAt).format("DD.MM.YYYY HH:mm")}
          </div>
          <div className="userinfo-items">
            <span>Created By</span>
            {staticData?.whoCreateIt?.fullName}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default PanelInfo;


