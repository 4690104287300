import Button from "../../ReusableComponents/Button";
import {ClipLoader} from "react-spinners";
import React, {useEffect, useState} from "react";
import TransportWebUSB from "@ledgerhq/hw-transport-webusb";
import AppTRX from "@ledgerhq/hw-app-trx";
import AppETH from "@ledgerhq/hw-app-eth";
import {listen} from "@ledgerhq/logs";
import AppBtc from "@ledgerhq/hw-app-btc";
import {publicRequest} from "../../../utils/requestMethods";

export const Dex = ({loading, selectData, setSelectData}) => {
    const [networks, setNetworks] = useState([
        {label: "Bitcoin", value: "btc"},
        {label: "ETH / BSC / Polygon / Fantom", value: "eth"},
        {label: "Tron", value: "trx"},
    ]);

    const [selectedNetwork, setSelectedNetwork] = useState(networks[0]);
    const [activeItem, setActiveItem] = useState(0);
    const [selectAll, setSelectAll] = useState(false);

    const [selectedAddresses, setSelectedAddresses] = useState([]);

    const [walletAddress, setWalletAddress] = useState({
        btc: [],
        eth: [],
        trx: []
    });

    const [isLedgerConnected, setIsLedgerConnected] = useState(false);
    const [addressLoader, setAddressLoader] = useState(0);

    const checkForConnection = async (confirm = false) => {
        return new Promise(async (resolve, reject) => {
            const transport = await TransportWebUSB.create();
            try {
                let app;
                switch (selectedNetwork.value) {
                    case "btc":
                        //When the Ledger device connected it is trying to display the bitcoin address
                        const appBtc = new AppBtc({transport});

                        const {bitcoinAddress} = await appBtc.getWalletPublicKey(
                            "44'/0'/0'/0/0",
                            {verify: false, format: "legacy"}
                        );

                        // for (let i = 0; i < 50; i++) {
                        //     let {address} = await app.getWalletPublicKey(
                        //         `49'/0'/${i}'/0/0`,
                        //         {
                        //             verify: false,
                        //             format: "legacy",
                        //         }
                        //     );
                        //     setIsLedgerConnected(true);
                        //     walletAddress[selectedNetwork.value].push(address);
                        //     setWalletAddress({...walletAddress});
                        //     setAddressLoader(i + 1);
                        // }
                        break;
                    case "eth":
                        app = new AppETH(transport);
                        for (let i = 0; i < 50; i++) {
                            let {address} = await app.getAddress(`44'/60'/${i}'/0/0`);
                            setIsLedgerConnected(true);
                            walletAddress[selectedNetwork.value].push(address);
                            setWalletAddress({...walletAddress});
                            setAddressLoader(i + 1);
                        }
                        break;
                    case "trx":
                        app = new AppTRX(transport);
                        for (let i = 0; i < 50; i++) {
                            let {address} = await app.getAddress(`44'/195'/${i}'/0/0`);
                            setIsLedgerConnected(true);
                            walletAddress[selectedNetwork.value].push(address);
                            setWalletAddress({...walletAddress});
                            setAddressLoader(i + 1);
                        }
                        break;

                }

                resolve({
                    address: walletAddress[selectedNetwork.value],
                    connected: true,
                });
            } catch (e) {
                console.log(e);
                resolve({
                    address: false,
                    connected: false,
                });
            } finally {
                await transport.close();
            }
        });
    };

    const handleNetworkAdd = (index) => {
        setActiveItem(index);
        setSelectedNetwork(networks[index]);
    };

    const connectLedger = async () => {
        listen(log => console.log(log));
        const {address, connected} = await checkForConnection(true);
        // await liveLedger()
    };

    let checker = (arr, target) => target.every((v) => arr.includes(v));

    const handleAddressCheck = (item, e) => {
        if (selectedAddresses.includes(item)) {
            setSelectedAddresses(selectedAddresses.filter((x) => x !== item));
        } else {
            setSelectedAddresses([...selectedAddresses, item]);
        }
    };

    const handleSelectAll = (e) => {
        setSelectAll(e.target.checked);
        if (checker(selectedAddresses, walletAddress[selectedNetwork.value])) {
            setSelectedAddresses([]);
        } else {
            walletAddress[selectedNetwork.value].map((x) => {
                selectedAddresses.push(x);
                setSelectedAddresses([...selectedAddresses]);
            });
        }
    };

    useEffect(() => {
        if (walletAddress[selectedNetwork.value].length) {
            if (checker(selectedAddresses, walletAddress[selectedNetwork.value])) {
                setSelectAll(true);
            } else {
                setSelectAll(false);
            }
        }
    }, [selectedAddresses]);


    const [importLoading, setImportLoading] = useState(false);
    const importAddresses = async () => {
        let response = await publicRequest.post('v2/dex/addresses', {
            addresses: selectedAddresses,
            networks: selectedNetwork.value
        });

        if (response.status === 200) {
            const {data: {data: addresses}} = response;
            let index = selectData.findIndex((sd) => sd.network === addresses.network);

            if (index !== -1) {
                selectData[index] = addresses;
            } else {
                selectData.push(addresses);
                setSelectData([...selectData]);
            }
        }
    }

    return (
        <div className="flex flex-column gap-20" style={{marginTop: "70px"}}>
            <h4
                style={{
                    fontWeight: "400",
                    borderBottom: "3px solid orange",
                    width: "fit-content",
                }}
            >
                Ledger addresses
            </h4>
            <div className="flex items-start justify-between">
                <div
                    style={{
                        flex: 1,
                        borderRight: "1px solid #d7d7d7",
                        padding: "0 20px 0 0",
                        height: "100%",
                    }}
                >
                    {networks?.map((network, index) => (
                        <div className="grid gap-10 w-full">
              <span
                  onClick={() => handleNetworkAdd(index)}
                  className="hover-item"
                  style={{
                      background: activeItem === index ? "rgb(248, 230, 196)" : "",
                      padding: "10px 3px",
                      borderBottom: "1px solid #d7d7d7",
                  }}
              >
                {network.label}
              </span>
                        </div>
                    ))}
                </div>
                <div style={{flex: 1, paddingLeft: "20px", maxHeight: 300, overflowY: "scroll", overflowX: "hidden"}}>
                    {walletAddress[selectedNetwork.value].length ? (
                        <>
                            <div className="grid gap-10 w-full">
                                <div
                                    className="flex items-center justify-between w-full"
                                    style={{
                                        padding: "10px 3px",
                                        borderBottom: "1px solid #d7d7d7",
                                    }}
                                >
                                    <span>Loaded {walletAddress[selectedNetwork.value].length} of 50</span>

                                    <div className="flex items-center gap-10">
                                        <Button
                                            small={true}
                                            title={
                                                importLoading ? (
                                                    <ClipLoader size={14} color="white"/>
                                                ) : (
                                                    "Import"
                                                )
                                            }
                                            onClick={importAddresses}
                                        />
                                        <span>Select All</span>
                                        <label
                                            className="custom-checkbox-container"
                                            style={{marginTop: "-10px"}}
                                        >
                                            <input
                                                name={`selectAll`}
                                                type="checkbox"
                                                onChange={(e) => handleSelectAll(e)}
                                                checked={selectAll}
                                                style={{
                                                    width: "22px",
                                                    height: "22px",
                                                }}
                                            />
                                            <span className="custom-checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {walletAddress[selectedNetwork.value]?.map((item, index) => (
                                <div className="grid gap-10 w-full">
                                    <div
                                        className="flex items-center justify-between w-full"
                                        style={{
                                            padding: "10px 3px",
                                            borderBottom: "1px solid #d7d7d7",
                                        }}
                                    >
                                        <span>{item}</span>
                                        <label
                                            className="custom-checkbox-container"
                                            style={{marginTop: 0}}
                                        >
                                            <input
                                                name={`address-${index}`}
                                                type="checkbox"
                                                onChange={(e) => handleAddressCheck(item, e)}
                                                checked={
                                                    selectedAddresses.includes(item)
                                                }
                                                style={{
                                                    width: "22px",
                                                    height: "22px",
                                                }}
                                            />
                                            <span className="custom-checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            ))}
                        </>
                    ) : (
                        <div style={{width: "100%", marginTop: "20px"}}>
                            <div className="flex flex-column items-start justify-start w-full h-full gap-30 mt-20"
                                 style={{alignItems: "center"}}>
                                <h4>Connect to Ledger and open {selectedNetwork.label} App</h4>
                                <div className="flex items-center justify-between gap-20">
                                    <img
                                        src={require("../../../assets/ledger.gif")}
                                        style={{maxWidth: "50%", margin: '0 auto'}}
                                    />
                                </div>
                                {loading ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <ClipLoader size={24} color="orange"/>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            width: "128.5px",
                                            display: "block",
                                            margin: "0 auto",
                                        }}
                                    >
                                        <Button title="Connect" onClick={connectLedger}/>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

