import React, {useMemo, useRef, useState} from "react";
import iconGenerator from "../../utils/iconGenerator";
import {NumberFormatter} from "../ReusableComponents/NumberFormat";
import MainTable from "../ReusableComponents/Tables/MRT_Main";
import {BiExport} from "react-icons/bi";
import {AiOutlineScan} from "react-icons/ai";
import Content from "./TransferButtons/Content";
import MainModal from "../ReusableComponents/MainModal";
import Currencies from "../ReusableComponents/Modals/Currencies";
import {useSelector} from "react-redux";

const TransferTable = ({ setIndex, setCurrencyId, customHeight }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenScan, setIsOpenScan] = useState(false);
    const [title, setTitle] = useState("");
    const [modalType, setModalType] = useState(null);
    const params = useSelector((state) => state.user.params);

    const openModal = (title, type) => {
        setModalType(type)
        setIsOpen(true);
        setTitle(title);
    };

    const openScanModal = (title) => {
        setIsOpenScan(true);
        setTitle(title);
    }

    const closeModal = () => {
        setIsOpen(false);
        setIsOpenScan(false);
    };

    const tableContainerRef = useRef(null);

    const columns = useMemo(() => [
        {
            accessorKey: "currency",
            muiTableHeadCellFilterTextFieldProps: {placeholder: 'Filter by Currency'},
            header: "Account",
            size: 120,
            accessorFn: (rowData) => (
                <div className="flex items-center gap-5">
                    {rowData.currency && (
                        <>
                            <img
                                src={iconGenerator(128, "icon", rowData.currency?.symbol, true)}
                                alt=""
                                className="dashboardChartHeaderIcon"
                            />
                            {rowData.currency?.name} ({rowData.currency?.symbol})
                        </>
                    )}
                </div>
            ),
            filterSelectOptions: params.filters.global.currencies.filterSelectOptions,
            filterVariant: params.filters.global.currencies.filterVariant,
        },
        {
            accessorKey: "pending_in",
            header: "Pending In",
            muiTableBodyCellProps: {
                align: "center",
            },
            size: 120,
            Footer: ({ column, footer, table }) => {
                let totalB = 0;
                if (table?.getRowModel()) {
                    let currencies = Object.entries(table?.getRowModel()?.rowsById).map(
                        (x) => x[1]?.original
                    );
                    if (currencies.length) {
                        totalB = currencies.reduce(
                            (partialSum, a) =>
                                partialSum +
                                (a?.currency?.symbol === "EUR"
                                    ? parseFloat(a?.pending_in)
                                    : parseFloat(a?.pending_in) / a?.currency?.rate),
                            0
                        );
                    }
                }
                return (
                    <div className="flex flex-column">
                        <span
                            style={{
                                fontWeight: "500",
                                fontSize: "14px",
                                color: "black",
                            }}
                        >
                            Total Pending In:
                        </span>
                        <NumberFormatter value={totalB} suffix={" EUR"} />
                    </div>
                );
            },
            accessorFn: (e) => (
                <NumberFormatter
                    value={e?.pending_in}
                    suffix={` ${e?.currency?.symbol}`}
                />
            ),
        },
        {
            accessorKey: "pending_out",
            header: "Pending Out",
            muiTableBodyCellProps: {
                align: "center",
            },

            size: 100,
            Footer: ({ column, footer, table }) => {
                let totalB = 0;
                if (table?.getRowModel()) {
                    let currencies = Object.entries(table?.getRowModel()?.rowsById).map(
                        (x) => x[1]?.original
                    );
                    if (currencies.length) {
                        totalB = currencies.reduce(
                            (partialSum, a) =>
                                partialSum +
                                (a?.currency?.symbol === "EUR"
                                    ? parseFloat(a?.pending_out)
                                    : parseFloat(a?.pending_out) / parseFloat(a?.currency?.rate)),
                            0
                        );
                    }
                }
                return (
                    <div className="flex flex-column">
                        <span
                            style={{
                                fontWeight: "500",
                                fontSize: "14px",
                                color: "black",
                            }}
                        >
                            Total Pending Out:
                        </span>
                        <NumberFormatter value={totalB} suffix={" EUR"} />
                    </div>
                );
            },
            accessorFn: (e) => (
                <NumberFormatter
                    value={e?.pending_out}
                    suffix={` ${e?.currency?.symbol}`}
                />
            ),
        },
        {
            accessorKey: "balance",
            header: "Balance",
            filterable: false,
            muiTableBodyCellProps: {
                align: "center",
            },
            Footer: ({ column, footer, table }) => {
                let totalB = 0;
                if (table?.getRowModel()) {
                    let currencies = Object.entries(table?.getRowModel()?.rowsById).map(
                        (x) => x[1]?.original
                    );
                    if (currencies.length) {
                        totalB = currencies.reduce(
                            (partialSum, a) =>
                                partialSum +
                                (a?.currency?.symbol === "EUR"
                                    ? parseFloat(a?.balance)
                                    : parseFloat(a?.balance) / a?.currency?.rate),
                            0
                        );
                    }
                }
                return (
                    <div className="flex flex-column">
                        <span
                            style={{
                                fontWeight: "500",
                                fontSize: "14px",
                                color: "black",
                            }}
                        >
                            Total Balance
                        </span>
                        <NumberFormatter value={totalB} suffix={" EUR"} />
                    </div>
                );
            },
            accessorFn: (e) => (
                <NumberFormatter value={e?.balance} suffix={` ${e.currency?.symbol}`} />
            ),
            size: 100,
        },
    ]);

    const selectAndChangeTab = (data) => {
        let newData = data.currency;
        newData.balance = JSON.stringify(data.balance)
        setCurrencyId(newData);
        setIndex(1);
    };

    const [action, setAction] = useState({ type: "", data: null, id: 1 });

    return (
        <div
            className="clientsTable"
            style={{
                width: "100%",
                overflowX: "hidden",
                padding: 0,
            }}
        >
            <div
                className="clientsTableMaterialTableContainer"
                style={{ width: "100%", overflowX: "hidden" }}
            >
                <MainModal
                    closeOnEsc={true}
                    closable={true}
                    isOpen={isOpenScan}
                    onRequestClose={closeModal}
                    closeModal={closeModal}
                    content={
                        <Content
                            title={title}
                            accounts={tableContainerRef.current}
                            setIsOpen={setIsOpen}
                            setAction={setAction}
                        />
                    }
                />

                <Currencies
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    closable={true}
                    onRequestClose={closeModal}
                    title={title}
                    type={modalType}
                    accounts={tableContainerRef.current}
                    setAction={setAction}
                />

                <MainTable
                    columns={columns}
                    isSocketUpdatable={false}
                    requestURL="v2/accounting/balances"
                    onRowClick={selectAndChangeTab}
                    setAction={setAction}
                    action={action}
                    tableContainerRef={tableContainerRef}
                    stickyFooter={true}
                    enableBottomToolbar={false}
                    customHeight={customHeight}
                    toolbarActions={{
                        search: true,
                        range: false,
                        advancedFilter: false,
                        columnsFilter: false,
                        columnsVisible: true,
                        density: true,
                        fullScreen: true,
                        partners: false,
                    }}
                    renderTopToolbarCustomActions={[
                        {
                            type: "send",
                            name: "Send",
                            icon: <BiExport size={20}/>,
                            onClick: () => {
                                openModal("Send", "send");
                            },
                        },
                        // {
                        //     type: "receive",
                        //     name: "Receive",
                        //     icon: <BiImport size={20} />,
                        //     onClick: () => {
                        //         openModal("Receive", "receive");
                        //     },
                        // },
                        {
                            type: "scan",
                            name: "Scan",
                            icon: <AiOutlineScan size={20}/>,
                            onClick: () => {
                                openScanModal("Scan");
                            },
                        },
                    ]}
                    renderCurrencyFooterToolbar={false}
                    renderFetchFooterToolbar={false}
                />
            </div>
        </div>
    );
};

export default TransferTable;

