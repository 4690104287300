import {Cut, Line, Printer, QRCode, Row, Text} from "react-thermal-printer";

import moment from "moment";
import React from "react";
import {NumericFormat} from "react-number-format";
import print from "../printer";

function FormatInput({value, suffix}) {
    return (
        <NumericFormat
            value={value}
            displayType={"text"}
            thousandSeparator=","
            decimalSeparator="."
            suffix={` ${suffix}`}
            decimalScale={2}
            thousandsGroupStyle={"none"}
            fixedDecimalScale={true}
            renderText={(formattedValue) => (
                formattedValue
            )}
        />)
}

export const exchangePrint = async (order) => {

    const transactionId = order._id;
    const TXCode = btoa((JSON.stringify({id: transactionId, type: 'exchange'})));

    let youSell = order.side === 'BUY' ?
        <FormatInput value={order.amount_from} suffix={order.currency_from.symbol}/> :
        <FormatInput value={order.amount_to} suffix={order.currency_to.symbol}/>
    let youBuy = order.side === 'BUY' ?
        <FormatInput value={order.amount_to} suffix={order.currency_to.symbol}/> :
        <FormatInput value={order.amount_from} suffix={order.currency_from.symbol}/>

    await print(
        <Printer type="epson" width={48} characterSet="iso8859_2_latin2">
            <Text bold={true} align={'center'} size={{width: 1, height: 1}}>EXCHANGE</Text>
            <Text align={'center'} size={{width: 2, height: 2}}>{order.symbol}</Text>
            <Line/>
            <Row left={'Date'} right={moment(order.createdAt).format('DD.MM.Y')}/>
            <Row left={'EID'} right={`#${order.order_number}`}/>
            <Line/>
            <Row left={'Description'} right={'Amount'}/>
            <Row left={'You sold'} right={<Text bold={true}>{youSell}</Text>}/>
            <Row left={'Rate'} right={`${order.conversion_fee}`}/>
            <Row left={'You bought'} right={<Text bold={true}>{youBuy}</Text>}/>
            <Line/>
            <QRCode cellSize={6} model={'model2'} align={"center"} content={TXCode}/>
            {/*<Barcode align="center" type="CODE39" content="25487459" />*/}
            <Line/>
            <Text align={"center"}>Thanks for using our service</Text>
            <Cut lineFeeds={4}/>
        </Printer>
    );
}
