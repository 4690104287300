import React, {useEffect, useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import iconGenerator from "../../../../utils/iconGenerator";
import Partners from "../../../Percentage/PartnersPercentages/Partners";
import Moment from "moment";
import MainModal from "../../../ReusableComponents/MainModal";
import {balkanCountries} from "../../../ReusableComponents/Countries";
import MainSelect from "../../../ReusableComponents/Select";
import {NumberFormatter} from "../../../ReusableComponents/NumberFormat";
import MainTable from "../../../ReusableComponents/Tables/MRT_Main";
import {BiExport, BiImport} from "react-icons/bi";
import {FaArrowLeft} from "react-icons/fa";
import qs from "qs";
import {capitalizeWords} from "../../../../utils/utils";
import {checkAccountingAmount, checkAccountingBalance} from "../../../../utils/calculation";
import AccountingContent from "../../../ReusableComponents/PopUpInfo/Accounting/AccountingContent";
import {TransferStatus} from "../../../ReusableComponents/Status/TransferStatus";

const TransferTable = ({
                           setSelectedPartner,
                           selectedPartner,
                           singleCurrencyData,
                           setSingleCurrencyData,
                           handleModal,
                           customHeight,
                           action,
                           setAction,
                       }) => {
    const user = useSelector((state) => state.user.currentUser);
    const [countryFilter, setCountryFilter] = useState(null);
    const [searchFilter, setSearchFilter] = useState("");
    const [tableLoading, setTableLoading] = useState(false);
    const [localLoading, setLocalLoading] = useState(false);
    const [currencyId, setCurrencyId] = useState("");
    const [column, setColumn] = useState("details");
    const [isOpenPopUp, setIsOpenPopUp] = useState(false);
    const popUpInformation = useRef("");
    const [requestURL, setRequestURL] = useState("v2/accounting/balances");

    const params = useSelector((state) => state.user.params);
    const tableContainerRef = useRef(null);

    const closeModal = () => {
        setIsOpenPopUp(false);
    };

    const secondColumns = useMemo(
        () => [
            {
                accessorKey: "ref_number",
                header: "ID",
                size: 30,
                muiTableHeadCellProps: () => ({
                    sx: {
                        paddingLeft: "25px",
                    },
                }),
                muiTableBodyCellProps: () => ({
                    sx: {
                        paddingLeft: "25px",
                    },
                }),
            },
            {
                accessorKey: "createdAt",
                header: "Time",
                size: 90,
                accessorFn: (e) => Moment(e.createdAt).format("DD.MM.YYYY HH:mm"),
            },
            {
                accessorKey: "clientId",
                header: "Client",
                muiTableBodyCellProps: {
                    align: "center",
                },
                size: 70,
                accessorFn: (e) => {
                    return `${capitalizeWords(e.clientId?.firstName) ?? ''} ${capitalizeWords(e.clientId?.lastName) ?? ''}`;
                },
                filterSelectOptions: params.filters.global.clients.filterSelectOptions,
                filterVariant: params.filters.global.clients.filterVariant,
            },
            {
                accessorKey: "type",
                header: "Type",
                size: 50,
                Cell: ({cell}) => {
                    return capitalizeWords(cell.getValue());
                },
                filterSelectOptions: params.filters.accounting.type.filterSelectOptions,
                filterVariant: params.filters.accounting.type.filterVariant,
            },
            {
                accessorKey: "side",
                header: "Side",
                size: 50,
                accessorFn: (e) => {
                    return e.side?.toUpperCase() === "BUY" || e.side?.toUpperCase() === "SELL" ?
                        <span
                            style={{
                                color: e.side?.toUpperCase() === "BUY" ? "lightgreen" : "red",
                                textTransform: "uppercase"
                            }}
                        >
                            {e.side}
                        </span>
                        :
                        <TransferStatus side={e.side} transaction={e} partner={user.partnerId}/>
                },
                filterSelectOptions: params.filters.accounting.side.filterSelectOptions,
                filterVariant: params.filters.accounting.side.filterVariant,
            },
            {
                accessorKey: "details",
                header: "Details",
                size: 50,
                accessorFn: (e) => (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "4px",
                        }}
                    >
                        <img
                            src={iconGenerator(128, "icon", e.details, e.type !== "crypto")}
                            style={{height: "20px"}}
                            alt={e.details}/>
                        {e.details}
                    </div>
                ),
                filterSelectOptions: params.filters.accounting.details.filterSelectOptions,
                filterVariant: params.filters.accounting.details.filterVariant,
            },
            {
                accessorKey: "note",
                header: "Note",
                size: 50,
            },
            {
                accessorKey: "amount",
                header: "Amount",
                size: 100,
                accessorFn: (e) => <NumberFormatter
                    value={e.amount}
                    suffix={` ${e.currency?.symbol}`}
                    prefix={`${checkAccountingAmount(e)}`}
                    style={{color: checkAccountingAmount(e) == "-" ? "red" : "green"}}
                />,
                filterVariant: 'range',
                filterFn: 'between',
            },
            {
                accessorKey: "balance",
                header: "Balance",
                size: 100,
                accessorFn: (e) => (<NumberFormatter
                    value={e.balance}
                    suffix={` ${e.currency?.symbol}`}
                    prefix={`${checkAccountingBalance(e)}`}
                />),
                filterVariant: 'range',
                filterFn: 'between',
            }
        ],
        [requestURL, currencyId, singleCurrencyData]
    );

    if (!secondColumns.some(item => item.header === "Partner")) {
        singleCurrencyData && (user.isAdmin || user.isMainOffice) && secondColumns.splice(2, 0, {
            accessorKey: "partnerId",
            header: "Partner",
            size: 60,
            accessorFn: (e) => (e.partnerId ? e.partnerId?.partnerName : ""),
            filterSelectOptions: params.filters.global.partners.filterSelectOptions,
            filterVariant: params.filters.global.partners.filterVariant,
        })
    }

    const mainColumns = useMemo(
        () => [
            {
                accessorKey: "currency.symbol",
                header: "Account",
                size: 120,
                // muiTableHeadCellProps: () => ({
                //   sx: {
                //     paddingLeft: "25px",
                //   },
                // }),
                // muiTableBodyCellProps: () => ({
                //   sx: {
                //     paddingLeft: "25px",
                //   },
                // }),
                accessorFn: (e) => (
                    <div className="flex items-center gap-5">
                        {e.currency && (
                            <>
                                <img
                                    src={iconGenerator(128, "icon", e.currency?.symbol, true)}
                                    alt=""
                                    className="dashboardChartHeaderIcon"
                                />
                                {e.currency?.name} ({e.currency?.symbol})
                            </>
                        )}
                    </div>
                ),
            },
            {
                accessorKey: "pending_in",
                header: "Pending In",
                muiTableBodyCellProps: {
                    align: "center",
                },
                size: 120,
                Footer: ({column, footer, table}) => {
                    let totalB = 0;
                    if (table?.getRowModel()) {
                        let currencies = Object.entries(table?.getRowModel()?.rowsById).map(
                            (x) => x[1]?.original
                        );
                        if (currencies.length) {
                            totalB = currencies.reduce(
                                (partialSum, a) =>
                                    partialSum +
                                    (a?.currency?.symbol === "EUR"
                                        ? parseFloat(a?.pending_in)
                                        : parseFloat(a?.pending_in) / a?.currency?.rate),
                                0
                            );
                        }
                    }
                    return (
                        <div className="flex flex-column">
                            <span
                                style={{
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    color: "black",
                                }}
                            >
                                Total Pending In:
                            </span>
                            <NumberFormatter value={totalB} suffix={" EUR"}/>
                        </div>
                    );
                },
                accessorFn: (e) => (
                    <div>
                        <NumberFormatter value={e.pending_in}/> {e.currency?.symbol}
                    </div>
                ),
            },
            {
                accessorKey: "pending_out",
                header: "Pending Out",
                muiTableBodyCellProps: {
                    align: "center",
                },

                size: 100,
                Footer: ({column, footer, table}) => {
                    let totalB = 0;
                    if (table?.getRowModel()) {
                        let currencies = Object.entries(table?.getRowModel()?.rowsById).map(
                            (x) => x[1]?.original
                        );
                        if (currencies.length) {
                            totalB = currencies.reduce(
                                (partialSum, a) =>
                                    partialSum +
                                    (a?.currency?.symbol === "EUR"
                                        ? parseFloat(a?.pending_out)
                                        : parseFloat(a?.pending_out) /
                                        parseFloat(a?.currency?.rate)),
                                0
                            );
                        }
                    }
                    return (
                        <div className="flex flex-column">
                            <span
                                style={{
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    color: "black",
                                }}
                            >
                                Total Pending Out:
                            </span>
                            <NumberFormatter value={totalB} suffix={" EUR"}/>
                        </div>
                    );
                },
                accessorFn: (e) => (
                    <div>
                        <NumberFormatter value={e.pending_out}/> {e.currency?.symbol}
                    </div>
                ),
            },
            {
                accessorKey: "balance",
                header: "Balance",
                filterable: false,
                muiTableBodyCellProps: {
                    align: "center",
                },
                Footer: ({column, footer, table}) => {
                    let totalB = 0;
                    if (table?.getRowModel()) {
                        let currencies = Object.entries(table?.getRowModel().rowsById).map(
                            (x) => x[1]?.original
                        );
                        if (currencies.length) {
                            totalB = currencies.reduce(
                                (partialSum, a) =>
                                    partialSum +
                                    (a?.currency?.symbol === "EUR"
                                        ? parseFloat(a?.balance)
                                        : parseFloat(a?.balance) / a?.currency?.rate),
                                0
                            );
                        }
                    }
                    return (
                        <div className="flex flex-column">
                            <span
                                style={{
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    color: "black",
                                }}
                            >
                                Total Balance
                            </span>
                            <NumberFormatter value={totalB} suffix={" EUR"}/>
                        </div>
                    );
                },
                size: 100,
                accessorFn: (e) => (
                    <div>
                        <NumberFormatter value={e.balance}/> {e.currency?.symbol}
                    </div>
                ),
            },
        ],
        [requestURL]
    );

    const handleCountryFilter = (e) => {
        if (e === null) {
            setCountryFilter(null);
        } else {
            setCountryFilter(e.name);
        }
    };

    const filterName = (e) => {
        setSearchFilter(e.target.value);
    };

    const handleInfo = (data) => {
        popUpInformation.current = data;
        setIsOpenPopUp(true);
    };

    const setSingleCurrencyTable = (data, columnId) => {
        if (!singleCurrencyData) {
            let params = {
                currencyId: data.currency._id,
                partnerId: selectedPartner,
                column: columnId
            };

            setRequestURL(`v2/accounting/balances/transactions/?${qs.stringify(params)}`);
            setTimeout(() => {
                setCurrencyId(data.currency._id);
                setSingleCurrencyData(data);
                setColumn(columnId);
            }, 500);
        }


    };

    useEffect(() => {
        let params = {
            partnerId: selectedPartner,
            currencyId: currencyId,
            column: column
        };

        if (requestURL.includes("transactions")) {
            setRequestURL(
                `v2/accounting/balances/transactions/?${qs.stringify(params)}`
            );
        } else {
            setRequestURL(`v2/accounting/balances/?${qs.stringify(params)}`);
        }
    }, [selectedPartner]);

    const handleBackButton = () => {
        setSingleCurrencyData(null);

        let params = {};
        if (selectedPartner.length) {
            params = {
                partnerId: selectedPartner,
            };
        }

        setRequestURL(`v2/accounting/balances/?${qs.stringify(params)}`);
    };

    return (
        <div className="flex items-start gap-10 h-full">
            {(user.isAdmin || user.isMainOffice) && (
                <div className="grid" style={{width: "15%", marginTop: "10px"}}>
                    <MainSelect
                        placeholder="Filter by Country"
                        isClearable={true}
                        isSearchable={true}
                        options={balkanCountries}
                        getOptionLabel={(e) => e.name}
                        getOptionValue={(e) => e.name}
                        onChange={(e) => handleCountryFilter(e)}
                    />

                    <input
                        name="username"
                        type="text"
                        placeholder="Search partner by name..."
                        className="some-input"
                        onChange={(e) => filterName(e)}
                    />
                    <div
                        className="card partners-card"
                    >
                        <Partners
                            setSelectedPartner={setSelectedPartner}
                            selectedPartner={selectedPartner}
                            countryFilter={countryFilter}
                            searchFilter={searchFilter}
                            tableLoading={tableLoading}
                            setTableLoading={setTableLoading}
                            multiSelect={true}
                            setRequestURL={setRequestURL}
                        />
                    </div>
                </div>
            )}
            <MainModal
                closeOnEsc={true}
                closable={true}
                isOpen={isOpenPopUp}
                onRequestClose={closeModal}
                closeModal={closeModal}
                content={
                    <>
                        <AccountingContent
                            data={popUpInformation.current}
                            setIsOpen={setIsOpenPopUp}
                            noPrint={true}
                        />
                    </>
                }
            />
            <div
                className="clientsTable"
                style={{
                    overflowX: "hidden",
                    padding: 0,
                    marginTop: "10px",
                    width: user.isAdmin ? "85%" : "100%",
                }}
            >
                <div
                    className="clientsTableMaterialTableContainer"
                    style={{width: "100%", overflowX: "hidden"}}
                >
                    <MainTable
                        columns={singleCurrencyData ? secondColumns : mainColumns}
                        requestURL={requestURL}
                        enableColumnOrdering={true}
                        setAction={setAction}
                        action={action}
                        customHeight={"calc(100vh - 130px)"}
                        columnOrder={["ref_number", "createdAt"]}
                        isSocketUpdatable={false}
                        tableContainerRef={tableContainerRef}
                        stickyFooter={true}
                        enableBottomToolbar={false}
                        tableHeight={customHeight}
                        onRowClick={
                            singleCurrencyData ? handleInfo : null
                        }
                        onCellClick={setSingleCurrencyTable}
                        toolbarActions={{
                            search: true,
                            range: false,
                            advancedFilter: false,
                            columnsFilter: singleCurrencyData,
                            columnsVisible: true,
                            density: true,
                            fullScreen: true,
                            partners: false,
                        }}
                        renderTopToolbarCustomActions={singleCurrencyData ? [

                                {
                                    type: "back",
                                    name: `Back`,
                                    icon: <FaArrowLeft size={20}/>,
                                    onClick: () => {
                                        handleBackButton();
                                    },
                                }
                            ]
                            : [{
                                type: "deposit",
                                name: "Deposit",
                                icon: <BiImport size={20}/>,
                                onClick: () => handleModal('Deposit', 'deposit'),
                            }, {
                                type: "withdrawal",
                                name: "Withdraw",
                                icon: <BiExport size={20}/>,
                                onClick: () => handleModal('Withdraw', 'withdrawal'),
                            }]
                        }
                        renderCurrencyFooterToolbar={false}
                        renderFetchFooterToolbar={false}
                    />
                </div>
            </div>
        </div>
    );
};

export default TransferTable;

