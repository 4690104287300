import React, {useContext, useEffect, useState} from "react";
import "./leftSidebar.css";
import {useDispatch, useSelector} from "react-redux";
import {logout, reFetch} from "../../redux/userRedux";
import iconGenerator from "../../utils/iconGenerator";
import MainModal from "../ReusableComponents/MainModal";
import {publicRequest} from "../../utils/requestMethods";
import Select from "react-select";
import {QRCodeSVG} from "qrcode.react";
import {ClipLoader} from "react-spinners";
import {SocketContext} from "../../context/socket";
import {cryptoAddress} from "../../utils/templates/cryptoAddress";
import {useCookies} from "react-cookie";
import {FaLockOpen, FaUser} from "react-icons/fa";
import {TbLogout} from "react-icons/tb";

const LeftSidebar = ({page, setPage, tabsToggle}) => {
    const user = useSelector((state) => state.user?.currentUser);
    const mainMenu = useSelector((state) => state.user.menu);
    const settingsMenu = useSelector((state) => state.user.settings);
    const dispatch = useDispatch();
    const socket = useContext(SocketContext);
    const [settings, setSettings] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [exchange, setExchange] = useState("Binance");
    const [currency, setCurrency] = useState();
    const [coins, setCoins] = useState(null);
    const [network, setNetwork] = useState({});
    const [networks, setNetworks] = useState([]);
    const [networksLoading, setNetworkLoading] = useState(false);
    const [accountingValues, setAccountingValues] = useState([]);
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    const updateReducer = async (data) => {
        if (data.type === 'update_details') {
            return await publicRequest.get(`v2/accounts/refresh`);
        }
    }

    useEffect(() => {
        if (socket) {
            socket.updateRow((data) => {
                updateReducer(data).then((result) => {
                    if (result?.status === 200) {
                        dispatch(reFetch(result.data))
                    }
                })
            })
        }
    }, []);

    const openModal = (icon) => {
        setIsOpen(true);
        setExchange("Binance");
        setNetwork({});
        setNetworks([]);
        setCurrency(icon);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        setAccountingValues(user.details.accounts);
    }, [user]);

    const handleExchangeChange = (e) => {
        setExchange(e?.value);
        setNetwork({});
        setNetworks([]);
    };

    const handleCurrencyChange = (e) => {
        setCurrency(e);
        setNetwork({});
        setNetworks([]);
    };

    const handleNetworkChange = (e) => {
        setNetwork(e);
    };

    const getCoins = async () => {
        try {
            const response = await publicRequest.get("/exchanges/cex/coins/all");
            setCoins(response.data);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        setNetwork({});
    }, [currency, networks, exchange]);

    useEffect(() => {
        const getNetworks = async () => {
            try {
                setNetworkLoading(true);
                const response = await publicRequest.get(
                    `/exchanges/cex/deposit/addresses/buy/${currency.symbol}`
                );

                setNetworks(response.data);

                if (!coins) {
                    await getCoins();
                }
                setNetworkLoading(false);
            } catch (err) {
                console.log(err);
            }
        };

        if (currency) {
            setNetwork({});
            getNetworks();
        }
    }, [currency, exchange]);

    const dummyIconsTop = [
        {
            id: 1,
            symbol: "usdt",
            name: "Tether",
        },
        {
            id: 2,
            symbol: "btc",
            name: "Bitcoin",
        },
        {
            id: 3,
            symbol: "eth",
            name: "Ethereum",
        },
        {
            id: 4,
            symbol: "bnb",
            name: "Binance Coin",
        },
    ];

    const handleLogout = () => {
        localStorage.clear();
        removeCookie('token');
        socket?.socket.close();
        dispatch(logout());
    };

    const menuFilter = () => {
        if (settings === false) {
            return mainMenu?.map((item, i) => (
                <div
                    key={`menu${i}`}
                    className="leftSidebarMenuItems"
                    onClick={() =>
                        item?.label === "Settings"
                            ? (setPage("Global Settings"), setSettings(true))
                            : item?.label === "Back"
                                ? (setPage("Dashboard"), setSettings(false))
                                : setPage(item?.label)
                    }
                    style={
                        page === item?.label
                            ? {
                                backgroundColor: "#e9e9e9",
                                color: "black",
                                borderRadius: "8px",
                            }
                            : {backgroundColor: "white", color: "black"}
                    }
                >
                    <img
                        className="leftSidebarMenuIcons"
                        src={item?.value ? require(`../../assets/${item?.value}.png`) : ""}
                        alt=""
                    />
                    <span className="leftSidebarMenuSpan">{item?.label}</span>
                </div>
            ));
        } else if (settings === true) {
            return settingsMenu?.map((item, i) => (
                <div
                    key={`menuSettings${i}`}
                    className="leftSidebarMenuItems"
                    onClick={() =>
                        item?.label === "Settings"
                            ? (setPage("gloablSettings"), setSettings(true))
                            : item?.label === "Back"
                                ? (setPage("Dashboard"), setSettings(false))
                                : setPage(item?.label)
                    }
                    style={
                        page === item?.label
                            ? {
                                backgroundColor: "#e9e9e9",
                                color: "black",
                                borderRadius: "8px",
                            }
                            : {backgroundColor: "white", color: "black"}
                    }
                >
                    <img
                        className="leftSidebarMenuIcons"
                        src={require(`../../assets/${item?.value}.png`)}
                        alt=""
                    />
                    <span className="leftSidebarMenuSpan">{item?.label}</span>
                </div>
            ));
        }
    };

    return (
        <div className={`leftSidebar hideLeftBar`} style={{left: tabsToggle.left ? "0" : "-1000px"}}>
            <div className="leftSidebarItem">
                <img
                    className="avatar"
                    src={require("../../assets/avatar.png")}
                    alt=""
                />
                <div className="userInfoContainer">
                    <span className="userSpan">{user && user?.username}</span>
                    <p className="userP">{user?.isAdmin ? "Admin" : user?.isMainOffice ? "Office" : user?.partnerId?.partnerName}</p>
                    <div className='user-action-panel'>
                        <span onClick={(() => {
                            setPage('Profile')
                        })} className="userProfile">
                        <FaUser></FaUser>
                    </span>
                        <span onClick={() => handleLogout()} className="userLogout">
                        <TbLogout></TbLogout>
                    </span>
                    </div>
                </div>
            </div>
            <div className="leftSidebarItem">
                <div className="leftSidebarWrapper">{menuFilter(page)}</div>
            </div>
            <div className="leftSidebarItem">
                <div className="leftSidebarIconWrapper">
                    <div className="iconsTop">
                        {dummyIconsTop.map((icon, i) => (
                            <img
                                onClick={() => openModal(icon)}
                                key={`icon${i}`}
                                src={iconGenerator(128, "icon", `${icon.symbol}`)}
                                alt={icon.name}
                                className="leftSidebarIcons"
                            />
                        ))}
                    </div>

                    {currency && exchange && networks && coins ? (
                        <MainModal
                            closable={true}
                            isOpen={isOpen}
                            onRequestClose={closeModal}
                            closeModal={closeModal}
                            content={
                                <>
                                    <div
                                        className="dashboardSliderSecondAddressModalAddressWrapper"
                                        style={{width: "100%", alignItems: "start"}}
                                    >
                                        <h2
                                            style={{
                                                display: "block",
                                                margin: "0 auto",
                                            }}
                                        >
                                            {currency ? currency.name : "Coin name"}
                                        </h2>
                                        <div className="flex items-center gap-10">
                                            <img
                                                src={iconGenerator(128, "icon", currency.symbol)}
                                                alt=""
                                                className="leftSidebarIcons"
                                                style={{width: 100, height: 100}}
                                            />
                                            <div className="grid gap-10">
                                                <h2>
                                                    Balance:
                                                    <span style={{color: "rgb(35, 182, 236)"}}>
                                                        {" "}
                                                        {Number(0).toPrecision(6)}{" "}
                                                        {currency.symbol?.toUpperCase()}
                                                    </span>
                                                </h2>
                                                <h4 style={{fontWeight: "600"}}>
                                                    Equivalent:{" "}
                                                    <span style={{color: "#d8d8d8"}}>0.00 EUR</span>
                                                </h4>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                width: "100%",
                                                borderTop: "1px solid #d2d2d2",
                                                paddingTop: "30px",
                                                display: "grid",
                                                gap: "10px",
                                            }}
                                        >
                                            <label>Exchange:</label>
                                            <Select
                                                isSearchable={true}
                                                name={"Exchange"}
                                                onChange={(e) => handleExchangeChange(e)}
                                                options={[
                                                    {value: "Binance", label: "Binance"},
                                                    {value: "Dex", label: "DEX"},
                                                ]}
                                                defaultValue={{value: "Binance", label: "Binance"}}
                                            />
                                            <label>Currency:</label>
                                            <Select
                                                isSearchable={true}
                                                options={coins}
                                                getOptionLabel={(coin) => (
                                                    <div
                                                        style={{display: "flex", alignItems: "center"}}
                                                    >
                                                        <img
                                                            width={25}
                                                            height={25}
                                                            src={iconGenerator(
                                                                "128",
                                                                "color",
                                                                coin.symbol ?? "BTC"
                                                            )}
                                                            alt={coin.symbol}
                                                        />
                                                        <span style={{marginLeft: 5}}>
                                                            {coin.name} ({coin.symbol})
                                                        </span>
                                                    </div>
                                                )}
                                                getOptionValue={(coin) => coin.symbol}
                                                filterOption={(coin, input) => {
                                                    const reg = new RegExp(input, "i");
                                                    if (input) {
                                                        if (
                                                            (reg.test(coin.data.name) ||
                                                                reg.test(coin.data.symbol)) &&
                                                            coin.data.details[`is${exchange}Available`]
                                                        ) {
                                                            return coin;
                                                        }
                                                    } else if (
                                                        coin.data.details[`is${exchange}Available`]
                                                    ) {
                                                        return coin;
                                                    }
                                                }}
                                                defaultValue={() => {
                                                    const current = coins.filter(
                                                        (coin) =>
                                                            coin.symbol === currency.symbol.toUpperCase()
                                                    );
                                                    return current.length ? current[0] : {};
                                                }}
                                                onChange={(e) => handleCurrencyChange(e)}
                                            />
                                            <label>Blockchain network:</label>
                                            <Select
                                                isClearable={false}
                                                isLoading={networksLoading}
                                                classNamePrefix="custom-select"
                                                isSearchable={true}
                                                options={networks[exchange === 'Dex' ? 'DEX' : exchange]}
                                                getOptionLabel={(option) => option.networkName}
                                                getOptionValue={(option) => option.network}
                                                filterOption={(option) =>
                                                    option.data.info.success === true
                                                }
                                                onChange={(e) => handleNetworkChange(e)}
                                                value={network || null}
                                            />
                                        </div>
                                        {network?.address ? (
                                            <>
                                                <div
                                                    style={{
                                                        border: " 1px solid #23B6EC",
                                                        padding: "15px",
                                                        borderRadius: "4px",
                                                        backgroundColor: "#EEFAFE",
                                                        margin: "30px 0",
                                                    }}
                                                >
                                                    <ul style={{paddingLeft: "10px"}}>
                                                        <li>
                                                            This wallet address only accepts tokens transfered
                                                            by
                                                            <span style={{color: "#23B6EC"}}>
                                                                {" "}
                                                                {network.networkName}
                                                            </span>{" "}
                                                            network. Tokens transferred through any other
                                                            network will be lost.
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding: "10px",
                                                        border: "1px solid #d2d2d2",
                                                        borderRadius: "4px",
                                                        width: "97%",
                                                        justifyContent: "space-between",
                                                        marginBottom: "30px",
                                                    }}
                                                >
                                                    <div className="grid">
                                                        <p style={{fontWeigth: "400"}}>Wallet address</p>
                                                        <p
                                                            style={{
                                                                wordBreak: "break-word",
                                                                paddingRight: "15px",
                                                            }}
                                                        >
                                                            {network.address}
                                                        </p>
                                                    </div>
                                                    <div className="flex items-center gap-10">
                                                        <span
                                                            style={{color: "#23B6EC", cursor: "pointer"}}
                                                            onClick={() => {
                                                                navigator.clipboard.writeText(network.address);
                                                            }}
                                                        >
                                                            Copy
                                                        </span>
                                                        <span>|</span>
                                                        <span
                                                            style={{color: "#23B6EC", cursor: "pointer"}}
                                                            onClick={async () => {
                                                                await cryptoAddress(currency, network);
                                                            }}
                                                        >
                                                            Print
                                                        </span>
                                                    </div>
                                                </div>
                                                {network.tag && (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            padding: "10px",
                                                            border: "1px solid #d2d2d2",
                                                            borderRadius: "4px",
                                                            width: "97%",
                                                            justifyContent: "space-between",
                                                            marginBottom: "30px",
                                                        }}
                                                    >
                                                        <div className="grid">
                                                            <p style={{fontWeigth: "400"}}>MEMO</p>
                                                            <p
                                                                style={{
                                                                    wordBreak: "break-word",
                                                                    paddingRight: "15px",
                                                                }}
                                                            >
                                                                {network.tag}
                                                            </p>
                                                        </div>
                                                        <div className="flex items-center gap-10">
                                                            <span
                                                                style={{color: "#23B6EC", cursor: "pointer"}}
                                                                onClick={() => {
                                                                    navigator.clipboard.writeText(network.tag);
                                                                }}
                                                            >
                                                                Copy
                                                            </span>
                                                            <span>|</span>
                                                            <span
                                                                style={{color: "#23B6EC", cursor: "pointer"}}
                                                                onClick={async () => {
                                                                    await cryptoAddress(currency, network);
                                                                }}
                                                            >
                                                                Print
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        borderRadius: "4px",
                                                        gap: 20,
                                                        width: "100%",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <QRCodeSVG value={network.address}/>
                                                    <div className="grid">
                                                        <h3
                                                            style={{
                                                                fontWeigth: "600",
                                                                width: 400,
                                                            }}
                                                        >
                                                            Your {currency.name} (
                                                            {currency.symbol.toUpperCase()}) address in{" "}
                                                            {network.networkName} blockchain network
                                                        </h3>
                                                        <p style={{color: "#999"}}>
                                                            Share this with anyone so that they can send you
                                                            payments.
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    style={{
                                                        margin: "0 auto",
                                                        fontSize: "25px",
                                                        height: "36vh",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <span>No network selected...</span>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </>
                            }
                        />
                    ) : (
                        <></>
                    )}
                    <div className="leftSidebarIconsLine"/>
                    <div className="iconsBottom">
                        {accountingValues.slice(0, 4).map((icon, i) => (
                            <img
                                // onClick={() => openModal(icon)}
                                key={i}
                                src={iconGenerator(
                                    128,
                                    "icon",
                                    `${icon.currency.symbol}`,
                                    true
                                )}
                                alt=""
                                className="leftSidebarIcons"
                            />
                        ))}
                    </div>
                    <MainModal
                        closable={true}
                        isOpen={isOpen}
                        onRequestClose={closeModal}
                        closeModal={closeModal}
                        content={
                            <div
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%)",
                                }}
                            >
                                <ClipLoader color="orange" size={24}/>
                            </div>
                        }
                    />
                </div>
            </div>
            <div className="leftSidebarItem">
                <div className="leftSidebarCopyrightWrapper">
                    <span className="copyrightSpan">
                        <b>TresorX</b> Version 0.1
                    </span>
                </div>
            </div>
        </div>
    );
};

export default LeftSidebar;

