import axios from "axios";

export let cancelTokenSource;

export const publicRequest = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL || process.env.REACT_APP_DEV_URL,
});

publicRequest.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');

    cancelTokenSource = axios.CancelToken.source();
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    config.cancelToken = cancelTokenSource.token;
    return config;
});

publicRequest.interceptors.response.use((response) => response, (error) => {
    if (error.toString().includes("403") || error.toString().includes("401")) {
        localStorage.clear();
        // window.location.reload();
    }
    throw error
});

