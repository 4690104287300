import {Paper} from "@material-ui/core";
import React from "react";
import iconGenerator from "../../../../../utils/iconGenerator";
import ReusableStatusComponent from "../../../ReusableStatusComponent";
import Moment from "moment";
import {NumberFormatter} from "../../../NumberFormat";

function Details({ data }) {
  return (
    <Paper
      variant="outlined"
      style={{ maxWidth: "100%", marginTop: "2em", paddingBottom: "0.5em" }}
    >
      <center>
        <div
          style={{
            borderBottom: "1px solid lightgrey",
            maxWidth: "95%",
            marginBottom: "2em",
            paddingTop: "0.5em",
            paddingBottom: "0.5em",
          }}
        >
          <span style={{ fontSize: "16px", fontWeight: "600" }}>Details</span>
        </div>
      </center>

      <div
        style={{
          display: "grid",
          placeItems: "center",
          margin: "0 2.5%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "flex-start",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                fontWeight: "400",
                color: "#666666",
                fontSize: "14px",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
              Order Number
            </span>
            <span
              style={{
                overflow: "hidden",
                fontWeight: "600",
                textOverflow: "ellipsis",
                color: "rgba(0, 0, 0, 0.87)",
                fontSize: "16px",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
              {data.order_number}
            </span>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              style={{
                fontWeight: "400",
                color: "#666666",
                fontSize: "14px",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
              Symbol
            </span>
            <span
              style={{
                overflow: "hidden",
                fontWeight: "600",
                textOverflow: "ellipsis",
                color: "rgba(0, 0, 0, 0.87)",
                fontSize: "16px",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
              <div className="flex items-center gap-5">
                <img
                  src={iconGenerator(
                    128,
                    "icon",
                    data.currency_from.symbol,
                    true
                  )}
                  alt=""
                  style={{ borderRadius: "12px" }}
                  className="dashboardChartHeaderIcon"
                />
                {data.currency_from.symbol + "/" + data.currency_to.symbol}
                <img
                  src={iconGenerator(
                    128,
                    "icon",
                    data.currency_to.symbol,
                    true
                  )}
                  alt=""
                  style={{ borderRadius: "12px" }}
                  className="dashboardChartHeaderIcon"
                />
              </div>
            </span>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
                style={{
                    fontWeight: "400",
                    color: "#666666",
                    fontSize: "14px",
                    lineHeight: "1.5",
                    textAlign: "center",
                }}
            >
              Base Currency
            </span>
              <span
                  style={{
                      overflow: "hidden",
                      fontWeight: "600",
                      textOverflow: "ellipsis",
                      color: "rgba(0, 0, 0, 0.87)",
                      fontSize: "16px",
                      lineHeight: "1.5",
                      textAlign: "center",
              }}
            >
              <NumberFormatter value={data.amount_from} suffix={` ${data.currency_from?.symbol}`}/>
            </span>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
                style={{
                    fontWeight: "400",
                    color: "#666666",
                    fontSize: "14px",
                    lineHeight: "1.5",
                    textAlign: "center",
                }}
            >
             Quote Currency
            </span>
              <span
                  style={{
                      overflow: "hidden",
                      fontWeight: "600",
                      textOverflow: "ellipsis",
                      color: "rgba(0, 0, 0, 0.87)",
                      fontSize: "16px",
                      lineHeight: "1.5",
                      textAlign: "center",
              }}
            >
              <NumberFormatter value={data.amount_to} suffix={` ${data.currency_to?.symbol}`}/>
            </span>
            <span
              style={{
                overflow: "hidden",
                fontWeight: "600",
                textOverflow: "ellipsis",
                color: "rgba(0, 0, 0, 0.87)",
                fontSize: "16px",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
              <NumberFormatter value={data.fee} />
            </span>
          </div>
        </div>
        <div
          className="w-full flex justify-between gap-30 items-start"
          style={{ marginTop: "20px" }}
        >
          <div className="grid place-items">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                style={{
                  fontWeight: "400",
                  color: "#666666",
                  fontSize: "14px",
                  lineHeight: "1.5",
                  textAlign: "center",
                }}
              >
                Date
              </span>
            </div>
            <span
              style={{
                overflow: "hidden",
                fontWeight: "600",
                  textOverflow: "ellipsis",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "16px",
                  lineHeight: "1.5",
                  textAlign: "center",
              }}
            >
              {Moment(data.createdAt).format("DD.MM.YYYY HH:mm")}
            </span>
          </div>
            <div className="grid place-items">
                <div style={{display: "flex", flexDirection: "column"}}>
              <span
                  style={{
                      fontWeight: "400",
                      color: "#666666",
                      fontSize: "14px",
                      lineHeight: "1.5",
                  }}
              >
                Type
              </span>
                </div>
                <span
                    style={{
                        overflow: "hidden",
                        fontWeight: "600",
                        textOverflow: "ellipsis",
                        color: data.side === 'BUY' ? "green" : 'red',
                        fontSize: "16px",
                        lineHeight: "1.5",
                        textAlign: "center",
                    }}
                >
              {data.side}
            </span>
            </div>
            <div className="grid place-items">
                <div style={{display: "flex", flexDirection: "column"}}>
              <span
                  style={{
                      fontWeight: "400",
                      color: "#666666",
                      fontSize: "14px",
                      lineHeight: "1.5",
                  }}
              >
                Conversion Fee
              </span>
                </div>
                <span
                    style={{
                        overflow: "hidden",
                        fontWeight: "600",
                        textOverflow: "ellipsis",
                        color: "rgba(0, 0, 0, 0.87)",
                        fontSize: "16px",
                        lineHeight: "1.5",
                        textAlign: "center",
                    }}
                >
              {data.conversion_fee}
            </span>
            </div>
          <div className="grid place-center">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                style={{
                  fontWeight: "400",
                  color: "#666666",
                  fontSize: "14px",
                  lineHeight: "1.5",
                  textAlign: "center",
                }}
              >
                Status
              </span>
            </div>
            <div className="w-full">
              <ReusableStatusComponent
                status={data.status}
                centered={true}
                smallFont={true}
              />
            </div>
          </div>
        </div>
      </div>
    </Paper>
  );
}

export default Details;


