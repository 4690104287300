import React from "react";
import "./exchangeConvert.css";

const ExchangeConvert = () => {
  const dummyLeftData = [
    {
      id: 1,
      title: "EUR",
      value: "5212.21",
    },
    {
      id: 2,
      title: "EUR",
      value: "5212.21",
    },
    {
      id: 3,
      title: "EUR",
      value: "5212.21",
    },
    {
      id: 4,
      title: "EUR",
      value: "5212.21",
    },
  ];

  return (
    <div className="exchangeConvert">
      <div className="exchangeConvertLeft">
        <div className="exchangeConvertLeftHeader">
          <span className="exchangeConvertLeftHeaderSpan">Currency</span>
          <span className="exchangeConvertLeftHeaderSpan">Value</span>
          <span className="exchangeConvertLeftHeaderSpan">Swap</span>
          <span className="exchangeConvertLeftHeaderSpan">Rate</span>
          <span className="exchangeConvertLeftHeaderSpan">Value</span>
          <span className="exchangeConvertLeftHeaderSpan">Status</span>
        </div>
        {dummyLeftData.map((data) => (
          <div className="exchangeConvertLeftCurrency">
            <div className="exchangeConvertLeftCurrencyIconContainer">
              <img
                src={require(`../../../assets/pound.png`)}
                alt=""
                className="exchangeConvertLeftCurrencyIconBig"
              />
              <img
                src={require(`../../../assets/euro.png`)}
                alt=""
                className="exchangeConvertLeftCurrencyIconSmall"
              />
            </div>
            <div className="exchangeConvertLeftCurrencyInfoContainer">
              <span className="exchangeConvertLeftCurrencyInfoSpan">
                MKD / EUR
              </span>
            </div>
            <div className="exchangeConvertLeftCurrencyValueContainer">
              <span className="exchangeConvertLeftCurrencyValueSpan">
                200.212.
              </span>
              <p className="exchangeConvertLeftCurrencyValueP">00</p>
            </div>
            <img
              src={require(`../../../assets/edit.png`)}
              alt=""
              className="exchangeConvertLeftCurrencySwapIcon"
            />
            <img
              src={require(`../../../assets/euro.png`)}
              alt=""
              className="exchangeConvertLeftCurrencyInfoIcon"
            />
            <div className="exchangeConvertLeftCurrencyRateContainer">
              <span className="exchangeConvertLeftCurrencyRateSpan">53.</span>
              <p className="exchangeConvertLeftCurrencyRateP">2132</p>
            </div>
            <div className="exchangeConvertLeftCurrencyRateValueContainer">
              <span className="exchangeConvertLeftCurrencyRateValueSpan">
                542,213.
              </span>
              <p className="exchangeConvertLeftCurrencyRateValueP">05</p>
            </div>
            <img
              src={require(`../../../assets/edit.png`)}
              alt=""
              className="exchangeConvertLeftCurrencyStatusIcon"
            />
          </div>
        ))}
        <div className="exchangeConvertLeftBottomContainer">
          <img
            src={require(`../../../assets/addpage.png`)}
            alt=""
            className="exchangeDepositLeftBottomIcon"
          />
          <button className="exchangeDepositLeftBottomButton">Confirm</button>
        </div>
      </div>
      <div className="exchangeConvertLine"></div>
      <div className="exchangeConvertRight">
        <div className="exchangeConvertRightHeader">
          <span className="exchangeConvertRightHeaderSpan">Total Value</span>
        </div>
        {dummyLeftData.map((data) => (
          <div className="exchangeConvertRightContainer">
            <img
              src={require(`../../../assets/euro.png`)}
              alt=""
              className="exchangeConvertRightCurrencyIcon"
            />
            <div className="exchangeConvertRightCurrencyValueWrapper">
              <span className="exchangeConvertRightCurrencyValueSpan">
                42.213.
              </span>
              <p className="exchangeConvertRightCurrencyValueP">02</p>
            </div>
          </div>
        ))}
        <button className="exchangeConvertRightBottomButton">Confirm</button>
      </div>
    </div>
  );
};

export default ExchangeConvert;
