const vendorId = 1504, productId = 6400;

const devicesFilter = [
    {
        // Zebra scanner
        vendorId: 1504,
        productId: 6400
    }
]
const scanner = async () => {
    let device = await (navigator.hid.getDevices()).then((devices) => {
        for (let filter of devicesFilter) {
            return devices.find((dvc) => dvc.vendorId === filter.vendorId && dvc.productId === filter.productId);
        }
    });

    if (device) {
        if (!device.opened) {
            await device.open();
        }
    } else {
        try {
            const devices = await navigator.hid.requestDevice({
                filters: devicesFilter
            });
            device = devices[0];
        } catch (error) {
            console.log("An error occurred.");
        }

        if (!device) {
            console.log("No device was selected.");
        } else {
            if (!device.opened) {
                device.open();
            }
        }
    }

    return device;
}

export default scanner;
