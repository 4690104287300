import React, { useLayoutEffect } from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { ClipLoader } from "react-spinners";
import Content from "./Content";
import { ImCancelCircle } from "react-icons/im";
import { IoMdDownload } from "react-icons/io";
import { BsPrinterFill } from "react-icons/bs";
import { internalPrint } from "../../../../utils/templates/internalTransfer";
import { InternalPdf } from "../../../../utils/templates/pdfs/internal"
import { LitePdf } from "../../../../utils/templates/pdfs/lite"
import { ApproveButton } from "../../Status/ApproveButton";
import { PDFDownloadLink } from "@react-pdf/renderer";

function TransferPopupInfo({
    noPrint,
    data,
    step,
    connectLedger,
    loading,
    denyRequest,
}) {

    useLayoutEffect(() => {
        if (data.transfer_type === "internal_transfer") {
            localStorage.setItem("orderId", btoa((JSON.stringify({ id: data._id, type: 'internal_transfer' }))))
        } else {
            localStorage.setItem("orderId", btoa((JSON.stringify({ id: data._id, type: 'lite_transfer' }))))
        }
    }, [data])

    const print = async (data) => {
        switch (data.transfer_type) {
            case "internal_transfer":
                await internalPrint(data);
                break;
            case "lite_transfer":
                await litePrint(data);
                break;
            default:
                break;
        }
    };
    
    return (
        <div style={{ marginTop: "20px", maxHeight: "80vh", overflowY: "scroll" }}>
            {step === 4 ? (
                <>
                    <div
                        className="flex flex-column items-center justify-center w-full h-full"
                        style={{ position: "absolute", top: "-6%", left: "0" }}
                    >
                        <FaCheck
                            size={40}
                            color="white"
                            style={{
                                background: "#25da25",
                                padding: "10px",
                                borderRadius: "50%",
                            }}
                        />
                        <div
                            style={{ fontWeight: "300", fontSize: "14px", margin: "5px 0" }}
                        >
                            Success transfer
                        </div>
                    </div>
                </>
            ) : step === 5 ? (
                <>
                    <div
                        className="flex flex-column items-center justify-center w-full h-full"
                        style={{ position: "absolute", top: "-6%", left: "0" }}
                    >
                        <FaTimes
                            size={30}
                            color="white"
                            style={{
                                background: "red",
                                padding: "10px",
                                borderRadius: "50%",
                            }}
                        />
                        <div
                            style={{ fontWeight: "300", fontSize: "14px", margin: "5px 0" }}
                        >
                            The request has been denied
                        </div>
                    </div>
                </>
            ) : loading ? (
                <div
                    className="grid h-full w-full place-center"
                    style={{ position: "absolute", top: "-20px", left: 0 }}
                >
                    <ClipLoader color="orange" size={30} />
                </div>
            ) : (
                <Content data={data} />
            )}

            {data.status === 4 && !noPrint && (
                <div
                    style={{
                        width: "100%",
                        position: "sticky",
                        bottom: "-5px",
                        borderTop: "1px solid #d8d8d8",
                        left: "0",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            width: "100%",
                            borderBottom: "0",
                            background: "#fff",
                        }}
                    >
                        <div style={{
                            background: "#efefef",
                            padding: "10px",
                            borderRadius: "50%",
                            margin: "5px 5px 5px 20px",
                            width: "25px",
                            height: "25px",
                            fontSize: "25px",
                        }}
                            title="Decline"
                            onClick={() => console.log(data)}
                            className="cursor-pointer">
                            {data && (
                                <PDFDownloadLink document={data.transfer_type === "internal_transfer" ? <InternalPdf data={data} /> : <LitePdf data={data} />} fileName={`${data.order_number}.pdf`}>
                                    {({ blob, url, loading, error }) => (<IoMdDownload color="#8a8a8a" />)}
                                </PDFDownloadLink>
                            )}
                        </div>
                        <div
                            style={{
                                background: "#efefef",
                                padding: "10px",
                                borderRadius: "50%",
                                margin: "5px 5px 5px 10px",
                                width: "25px",
                                height: "25px",
                                fontSize: "25px",
                            }}
                            title="Decline"
                            className="cursor-pointer"
                            onClick={() => print(data)}
                        >
                            <BsPrinterFill color="#8a8a8a" />
                        </div>
                    </div>
                </div>
            )}
            {step === -1 && !loading && data.status === 1 && (
                <div
                    style={{
                        width: "100%",
                        position: "absolute",
                        bottom: "0em",
                        borderTop: "1px solid #d8d8d8",
                        left: "0",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            width: "100%",
                            borderBottom: "0",
                            background: "#fff",
                        }}
                    >
                        <div
                            style={{
                                background: "#efefef",
                                padding: "10px",
                                borderRadius: "50%",
                                margin: "5px 5px 5px 20px",
                                width: "25px",
                                height: "25px",
                                fontSize: "25px",
                            }}
                            title="Decline"
                            className="cursor-pointer"
                            onClick={() => denyRequest(data._id)}
                        >
                            <ImCancelCircle color="red" />
                        </div>
                        <ApproveButton
                            data={data}
                            checkVisibility={true}
                            handleOnClick={() => connectLedger(data._id)}
                            type={'transfer'}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default TransferPopupInfo;

