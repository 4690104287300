import React from "react";
import Select from "react-select";

function MainSelect({
  isClearable,
  isSearchable,
  defaultValue,
  isDisabled,
  options,
  placeholder,
  getOptionLabel,
  getOptionValue,
  onChange,
  cls,
  components,
  name,
  customPrefix,
  error,
  isMulti,
  filterOption,
}) {
  return (
    <div className={`w-full ${cls}`}>
      <Select
        isClearable={isClearable}
        isSearchable={isSearchable}
        classNamePrefix={
          error ? "error-client" : `${customPrefix} custom-select`
        }
        defaultValue={defaultValue && defaultValue}
        value={defaultValue && defaultValue}
        isDisabled={isDisabled}
        options={options}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        onChange={onChange}
        placeholder={placeholder ? placeholder : "Select..."}
        components={components}
        name={name}
        isMulti={isMulti}
        filterOption={filterOption}
      />
    </div>
  );
}

export default MainSelect;

