import React, {useEffect, useState} from "react";
import "./App.css";
// React Router
import {BrowserRouter, Route, Routes} from "react-router-dom";
// Pages
import MainPage from "./pages/MainPage/MainPage";
import LoginPage from "./pages/LoginPage/LoginPage";
// Redux
import {useDispatch, useSelector} from "react-redux";
import {Socket, SocketContext} from "./context/socket";
import io from "socket.io-client";
import {useCookies} from "react-cookie";
import {logout} from "./redux/userRedux";

const App = () => {
    const [flag, setFlag] = useState(false);
    const [socket, setSocket] = useState(null);
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    const dispatch = useDispatch();

    useEffect(() => {
        if (cookies.token) {
            if (!socket?.socket?.connected) {
                let ws = io.connect(process.env.REACT_APP_SOCKET_URL, {
                    transports: ["websocket"],
                    query: {
                        token: cookies.token,
                    },
                });
                setSocket(new Socket(ws));
            }
            setFlag(true);
        } else {
            localStorage.clear();
            dispatch(logout())
            socket?.socket.close();
            new Socket({});
            setFlag(false);
        }
    }, [cookies]);

    return (
        <SocketContext.Provider value={socket}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={flag ? <MainPage/> : <LoginPage/>}/>
                    <Route path="/login" element={flag ? <MainPage/> : <LoginPage/>}/>
                </Routes>
            </BrowserRouter>
        </SocketContext.Provider>
    );
};

export default App;

