import {BsCheck2Circle} from "react-icons/bs";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

export function ApproveButton({ data, handleOnClick, checkVisibility, type }) {
    const user = useSelector((state) => state.user.currentUser)
    const [visibility, setVisibility] = useState(false);
    useEffect(() => {
        if (checkVisibility) {
            switch (type) {
                case 'transfer':
                    if (data.status === 1) {
                        if ((user?.isAdmin || user?.isMainOffice) && !data?.office_status) {
                            setVisibility(true)
                            return;
                        }

                        if ((user?.partnerId?._id === data?.destination_partner?._id) && !data?.partner_status) {
                            setVisibility(true)
                            return;
                        }

                        if (data.transfer_type === 'lite_transfer' && data.status === 1 && (user?.partnerId?._id !== data?.source_partner?._id)) {
                            setVisibility(true)
                            return;
                        }
                    }
            }
        } else {
            setVisibility(true);
        }
    }, []);

    return (
        <>
            {visibility &&
                <div
                    style={{
                        background: "#efefef",
                        padding: "10px",
                        borderRadius: "50%",
                        margin: "5px 5px 5px 10px",
                        width: "25px",
                        height: "25px",
                        fontSize: "25px",
                    }}
                    title="Approve"
                    className="cursor-pointer"
                    onClick={handleOnClick}
                >
                    <BsCheck2Circle color="green" />
                </div>
            }
        </>
    )
}
