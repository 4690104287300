import Button from "../../ReusableComponents/Button";
import {FaSave} from "react-icons/fa";
import React, {useState} from "react";
import TwoFAModal from "../../ReusableComponents/Modals/2FA/2FAModal";
import {useSelector} from "react-redux";
import PasswordChange from "./PasswordChange";

const Security = ({data, saveLoading, handleSubmit, handleInputChange}) => {


    const user = useSelector((state) => state.user.currentUser);
    const [checked, setChecked] = useState(user.secret.code ?? false);
    const [isOpen, setIsOpen] = useState(false);


    const handleTwoFaChange = () => {
        setIsOpen(true);
    }

    const handleCloseModal = () => {
        setIsOpen(false);
    }


    return (
        <div className='users' style={{margin: '0 auto', width: "98%"}}>
            <div className='card' style={{
                padding: "10px 20px 30px 20px",
                marginTop: "20px",
                width: "calc(100% - 40px)",
            }}>
                <TwoFAModal isOpen={isOpen} handleCloseModal={handleCloseModal} isActive={checked}
                            setIsActive={setChecked}/>
                <div className="flex flex-column flex-wrap">
                    <div className="flex items-center jusitfy-between">
                        <h3 className="custom-heading w-full">PASSWORD MANAGEMENT</h3>
                    </div>

                    <div className="flex" style={{flexDirection: "column", width: '33%'}}>
                        <PasswordChange/>
                    </div>
                    <div className="flex items-center mt-30">
                        <h3 className="custom-heading">TWO-FACTOR AUTHENTICATION </h3>
                        <div style={{marginLeft: 20}}>
                            <label className="custom-switch" style={{width: 40, height: 25}}>
                                <input
                                    onChange={() => handleTwoFaChange()}
                                    type="checkbox"
                                    checked={checked}
                                />
                                <span className="custom-slider-bigger custom-round"></span>
                            </label>
                        </div>
                    </div>
                    <div className="flex items-center">
                        <span>
                            Two-factor Authentication ("2FA") is a system of double authentication. The first one is done with your password and the second one with a code you get from a dedicated mobile app. Popular ones include Authy, Google Authenticator or the Microsoft Authenticator.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Security;
