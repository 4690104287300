import {ClipLoader} from "react-spinners";
import React from "react";

const Loader = ({}) => {
    return (
        <div className={"content-center-element"}>
            <ClipLoader size={30} color="orange"/>
        </div>
    )
}

export default Loader
