import React, {useContext, useEffect, useState} from "react";
// Components
import ExchangeChart from "./ExchangeChart/ExchangeChart";
// CSS
import "./exchange.css";
import ExchangeMain from "./ExcangeMain/ExchangeMain";
import {useSelector} from "react-redux";
import {SocketContext} from "../../context/socket";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import "react-tabs/style/react-tabs.css";

const Exchange = ({}) => {
  const [clicked, setClicked] = useState(false);
  const user = useSelector((state) => state.user);
  const menu = user.menu;
  const approve = menu[1]?.approve === undefined ? false : menu[1]?.approve;

  const [searchCoinVisible, setSearchCoinVisible] = useState(false);
  const [secondSearch, setSecondSearch] = useState(false);
  const [headerSearch, setHeaderSearch] = useState(false);
  const [fiat, setFiat] = useState(null);

  const socket = useContext(SocketContext);

  useEffect(() => {
    socket.fiatPrice((data) => {
      setFiat(data);
    });
  }, [socket]);

  return (
    <div className="exchange">
      {clicked ? (
        <ExchangeMain />
      ) : (
        <Tabs
          style={{
            maxWidth: "100%",
            width: "100%",
          }}
        >
          <TabList
            style={{
              background: "white",
              margin: 0,
              padding: "7px 20px 0 20px",
            }}
          >
            <Tab>Pairs</Tab>
          </TabList>

          <TabPanel>
            <div className="users" style={{ padding: "0" }}>
              <div
                className="usersUserListContainer"
                style={{ paddingBottom: "" }}
              >
                <ExchangeChart setClicked={setClicked} fiat={fiat} />
              </div>
            </div>
          </TabPanel>
        </Tabs>
      )}
    </div>
  );
};

export default Exchange;

