import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import React, {useEffect, useState} from "react";
import Collapse from "@mui/material/Collapse";
import {HiOutlineUsers, HiUsers} from "react-icons/hi";
import MainSelect from "../Select";
import {publicRequest} from "../../../utils/requestMethods";
import {getSessionStorage, removeSessionStorage, setSessionStorage} from "../../../utils/utils";

export const MRT_TogglePartnerSelect = ({table, ...rest}) => {
    const {setColumnFilters, getColumnFilters, getState} = table;
    const [showPicker, setShowPicket] = useState(false);
    const [partners, setPartners] = useState([]);
    const [selectedPartners, setSelectedPartners] = useState([]);

    useEffect(() => {
        if (selectedPartners.length) {
            const selectedPartner = selectedPartners.map(partner => {
                return partner._id;
            })
            setSessionStorage('partner_id', selectedPartner);
        } else {
            removeSessionStorage('partner_id')
        }
    }, [selectedPartners])

    const handlePartnerChange = (partners) => {
        let filters = getState().columnFilters;
        let index = filters.findIndex((ff) => ff.id === 'partner_id');
        if (index !== -1) {
            filters[index] = {id: "partner_id", value: partners.map((x) => x._id)}
        } else {
            filters.push({id: "partner_id", value: partners.map((x) => x._id)})
        }
        setColumnFilters([...filters]);
        setSelectedPartners(partners);
    };


    const handleToggleSearch = () => {
        setShowPicket(!showPicker);
    };

    useEffect(() => {
        retrievePartners();

    }, []);

    const retrievePartners = async () => {
        try {
            const response = await publicRequest.get("v2/partners");
            setPartners(response.data.data);
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            <Collapse
                in={showPicker}
                orientation="horizontal"
                unmountOnExit
                mountOnEnter
            >
                <div style={{width: "300px"}}>
                    <MainSelect
                        options={partners}
                        isMulti
                        placeholder={"Filter by partners"}
                        getOptionLabel={(e) => e.partnerName}
                        getOptionValue={(e) => e._id}
                        onChange={(e) => handlePartnerChange(e)}
                    />
                </div>
            </Collapse>
            <Tooltip arrow title={rest?.title ?? "Filter by partner"}>
                <IconButton onClick={handleToggleSearch} {...rest} title={undefined}>
                    {showPicker ? <HiUsers/> : <HiOutlineUsers/>}
                </IconButton>
            </Tooltip>
        </>
    );
};

