import React, {useCallback, useContext, useEffect, useMemo, useRef, useState,} from "react";
import MaterialReactTable, {
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table";

import {useInfiniteQuery} from "@tanstack/react-query";
import {publicRequest} from "../../../utils/requestMethods";
import {MRT_ToggleCalendarButton} from "./MRT_ToggleCalendarButton";
import {MRT_ToggleAdvancedFilterButton} from "./MRT_ToggleAdvancedFilterButton";
import {Box, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MRT_CurrencyFooter from "./MRT_CurrencyFooter";
import {MRT_TogglePartnerSelect} from "./MRT_TogglePartnerSelect";
import {SocketContext} from "../../../context/socket";
import {useDispatch, useSelector} from "react-redux";

import useSound from "use-sound";
import new_transaction from "../../../assets/sounds/new_transaction.mp3";

const fetchSize = process.env.REACT_APP_PAGE_SIZE;

const MainTable = ({
                       requestURL,
                       enableGrouping,
                       selectedPartner,
                       columnOrder,
                       letLoading,
                       grouping,
                       action,
                       noBorderRadius,
                       customHeight,
                       translateNoData,
                       noData,
                       isSocketUpdatable = true,
                       setAction,
                       columns,
                       tableContainerRef,
                       customFilters = [],
                       tableHeight = "83vh",
                       onRowClick,
                       onCellClick,
                       toolbarActions = {
                           search: false,
                           range: true,
                           advancedFilter: false,
                           columnsFilter: true,
                           columnsVisible: true,
                           density: true,
                           fullScreen: true,
                           partners: true,
                       },
                       renderRowActions = [],
                       density = "comfortable",
                       renderTopToolbarCustomActions = [],
                       renderCurrencyFooterToolbar = false,
                       renderFetchFooterToolbar = true,
                       fiat,
                       partnerCurrencies,
                       pairs = false
                   }) => {
        const user = useSelector((state) => state.user.currentUser);
        const virtualizedInstanceRef = useRef(null);

        var yesterday = new Date();
        yesterday.setHours(0, 0, 0, 0);

        const [columnFilters, setColumnFilters] = useState([
            ...[{id: "range", value: [yesterday, new Date()]}],
            ...customFilters,
        ]);

        const [globalFilter, setGlobalFilter] = useState();
        const [sorting, setSorting] = useState([]);
        const [tableData, setTableData] = useState([]);
        const [totalRowCount, setTotalRowCount] = useState(0);
        const socket = useContext(SocketContext);

        const dispatch = useDispatch();
        const [play, {stop}] = useSound(new_transaction);
        const [playSound, setPlaySound] = useState(false);
        const [localLoading, setLocalLoading] = useState(false);

        const {data, fetchNextPage, isError, isFetching, refetch} =
            useInfiniteQuery(
                ["table-data", columnFilters, globalFilter, sorting, requestURL],
                async ({pageParam = 0}) => {
                    if (letLoading) {
                        setLocalLoading(true);
                    }
                    const url = new URL(`${requestURL}`, process.env.REACT_APP_DEV_URL);
                    url.searchParams.set("start", `${pageParam * fetchSize}`);
                    url.searchParams.set("size", `${fetchSize}`);
                    url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
                    url.searchParams.set("search", globalFilter ?? "");
                    url.searchParams.set("sorting", JSON.stringify(sorting ?? []));

                    const response = await publicRequest(url.href);
                    setTotalRowCount(response.data.meta.totalRowCount);
                    setLocalLoading(false);
                    return response.data;
                },
                {
                    keepPreviousData: true,
                    refetchOnWindowFocus: false,
                    getNextPageParam: (_lastGroup, groups) => groups?.length,
                }
            );

        const flatData = useMemo(
            () => data?.pages.flatMap((page) => page.data) ?? [],
            [data]
        );

        useEffect(() => {
            setTableData(flatData);
        }, [flatData]);

        useEffect(() => {
                if (fiat) {
                    let currencies = Object.entries(
                        tableContainerRef.current.getRowModel().rowsById
                    ).map((x) => x[1].original);
                    if (pairs) {
                        let fiatFlatData = currencies.map((pair) => {
                                let symbol1 = fiat.find((ff) => ff.symbol === pair.currency_from?.symbol);
                                let symbol2 = fiat.find((ff) => ff.symbol === pair.currency_to?.symbol);

                                if (!symbol1 || !symbol2) {
                                    return pair;
                                }
                                let pId, pCc;
                                if (selectedPartner.length) {
                                    pId = selectedPartner[0];
                                    let pCcp = partnerCurrencies.find(pc => pc._id === pId);
                                    if (pCcp) {
                                        pCc = pCcp.currencies;
                                    }
                                }

                                if (!symbol1.auto_rate) {
                                    let manualRate = pCc.find(pc => pc.symbol === symbol1.symbol);
                                    if (manualRate) {
                                        symbol1.rate = manualRate.rate;
                                    }
                                }

                                if (!symbol2.auto_rate) {
                                    let manualRate = pCc.find(pc => pc.symbol === symbol2.symbol);
                                    if (manualRate) {
                                        symbol2.rate = manualRate.rate;
                                    }
                                }

                                let buyingPip = pair.buying_rate_fee ? pair.buying_rate_fee / 100 : 0;
                                let sellingPip = pair.selling_rate_fee ? pair.selling_rate_fee / 100 : 0;

                                let rate = parseFloat(symbol2?.rate / symbol1?.rate)

                                let buyingRateWithFee = parseFloat(rate - (rate * buyingPip));
                                let sellingRateWithFee = parseFloat(rate + (rate * sellingPip));

                                return {
                                    ...pair,
                                    ...{
                                        rate: parseFloat(symbol2?.rate / symbol1?.rate).toFixed(5),
                                        buying_rate: parseFloat(buyingRateWithFee).toFixed(5),
                                        selling_rate: parseFloat(sellingRateWithFee).toFixed(5),
                                    },
                                };
                            }
                        )
                        setTableData(fiatFlatData);
                    } else {
                        let fiatFlatData = currencies.map((row) => {
                            if (row.auto_rate && row.status) {
                                let currency = fiat.find((ff) => ff.symbol === row.symbol);
                                let buyingFee = parseFloat(row?.buying_rate_fee / 100) ?? 0;
                                let sellingFee = parseFloat(row?.selling_rate_fee / 100) ?? 0;

                                if (selectedPartner && selectedPartner.length) {
                                    buyingFee += parseFloat(currency.buying_rate_fee / 100);
                                    sellingFee += parseFloat(currency.selling_rate_fee / 100);
                                }

                                return {
                                    ...row,
                                    ...{
                                        rate: currency?.rate ?? 0,
                                        buying_rate: (parseFloat(currency?.rate) - parseFloat(currency?.rate * (buyingFee))).toFixed(4),
                                        selling_rate: (parseFloat(currency?.rate) + parseFloat(currency?.rate * (sellingFee))).toFixed(4),

                                    },
                                };
                            }
                            return row;
                        });
                        setTableData(fiatFlatData);
                    }
                }
            }, [fiat]
        );

        const totalDBRowCount = totalRowCount ?? 0;
        const totalFetched = tableData.length;

        const fetchMoreOnBottomReached = useCallback(
            (containerRefElement) => {
                if (containerRefElement) {
                    const {scrollHeight, scrollTop, clientHeight} = containerRefElement;
                    if (
                        scrollHeight - scrollTop - clientHeight < 400 &&
                        !isFetching &&
                        totalFetched < totalDBRowCount
                    ) {
                        fetchNextPage();
                    }
                }
            },
            [fetchNextPage, isFetching, totalFetched, totalDBRowCount]
        );

    // useEffect(() => {
    //     if (virtualizedInstanceRef.current) {
    //         virtualizedInstanceRef.current.scrollToIndex(0);
    //     }
    // }, [sorting, columnFilters, globalFilter]);

    useEffect(() => {
        if (socket) {
            socket.updateRow((data) => {
                if (isSocketUpdatable || data.type === 'refetch') {
                    // setPlaySound(data?.notification)
                    updateTable({
                        type: data.type,
                        data: data.data,
                        id: data.data._id,
                        }).then((r) => {
                            // if (data?.notification) {
                            //     play();
                            // }
                        })
                    }
                });
            }

            return () => {
                socket.socket.removeAllListeners("update_row");
            };
        }, []);

        const updateTable = async (item) => {
            if (!item || _.isEmpty(item)) {
                return;
            }

            switch (item?.type) {
                case 'added':
                    setTableData([item.data, ...tableData]);
                    if (typeof setAction === "function") {
                        setAction({type: "", id: null, data: null});
                    }
                    break;
                case 'editedRow':
                    tableData.map((x, i) => {
                        if (x._id === item.id) {
                            tableData[i][item.name] = item.value;
                        }
                    });
                    if (typeof setAction === "function") {
                        setAction({type: "", id: null, data: null, name: "", value: null});
                    }
                    setTableData([...tableData]);
                    break;
                case 'edited':
                    tableData.map((x, i) => {
                        if (x._id === item.id) {
                            tableData[i] = item.data;
                        }
                    });
                    if (typeof setAction === "function") {
                        setAction({type: "", id: null, data: null});
                    }
                    setTableData([...tableData]);
                    break;
                case 'deleted':
                    setTableData(tableData.filter((x) => x._id !== item.id));
                    if (typeof setAction === "function") {
                        setAction({type: "", id: null, data: null});
                    }
                    break;
                case 'refetch':
                    const reFetchTable = async () => {
                        await refetch({refetchPage: (page, index) => index === 0});
                    };
                    await reFetchTable();
                    break;
                default:
                    break;
            }
            return item;
        }

        useEffect(() => {
            updateTable(action).then(r => {
                // if (playSound) {
                //     play();
                // }
            });
        }, [action]);

        return (
            <div style={{overflowX: "hidden"}} className="mrt-main-table">
                <MaterialReactTable
                    tableInstanceRef={tableContainerRef}
                    columns={columns}
                    data={noData || localLoading ? [] : tableData}
                    enablePagination={false}
                    enableFullScreenToggle={true}
                    enableDensityToggle={true}
                    enableHiding={true}
                    enableGlobalFilter={false}
                    initialState={{
                        density: density,
                        grouping: grouping ? grouping : "",
                        columnOrder: columnOrder,
                    }}
                    enableGrouping={enableGrouping ?? false}
                    enableColumnFilters={true}
                    enableColumnActions={false}
                    enableRowNumbers={false}
                    enableColumnDragging={false}
                    enableRowVirtualization
                    manualFiltering={true}
                    manualSorting={true}
                    enableRowActions={!!renderRowActions.length}
                    renderRowActions={({row}, index) => {
                        return (
                            <div className="flex items-center gap-5">
                                {renderRowActions.map((item, i) => {
                                    if (item.type === "switch") {
                                        return (
                                            <div style={{paddingRight: "10px"}}>
                                                <label class="custom-switch">
                                                    <input
                                                        onChange={() => item.onClick(row.original)}
                                                        type="checkbox"
                                                        checked={
                                                            row.original?.is_enabled
                                                                ? row.original?.is_enabled
                                                                : row.original?.status
                                                        }
                                                    />
                                                    <span class="custom-slider custom-round"></span>
                                                </label>
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <IconButton
                                                key={i}
                                                onClick={() => {
                                                    item.onClick(row.original, selectedPartner);
                                                }}
                                            >
                                                {item.icon}
                                            </IconButton>
                                        );
                                    }
                                })}
                            </div>
                        );
                    }}
                    renderBottomToolbarCustomActions={({table}) => (
                        <div className="flex flex-column items-center justify-center w-full gap-10">
                            {renderCurrencyFooterToolbar && (
                                <MRT_CurrencyFooter tableData={table.getRowModel().rowsById}/>
                            )}
                            {renderFetchFooterToolbar && (
                                <Typography
                                    style={{
                                        borderTop: renderCurrencyFooterToolbar
                                            ? "1px solid #d7d7d7"
                                            : "",
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        paddingTop: "5px",
                                    }}
                                >
                                    Fetched {totalFetched} of {totalDBRowCount} total rows.
                                </Typography>
                            )}
                        </div>
                    )}
                    muiTopToolbarProps={{
                        sx: {
                            borderRadius: noBorderRadius ? "0" : "8px 8px 0 0",
                        },
                    }}
                    muiBottomToolbarProps={{
                        sx: {
                            borderRadius: "0 0 8px 8px",
                        },
                    }}
                    muiTableBodyRowProps={({row}) => ({
                        onClick: () => {
                            if (typeof onRowClick === 'function') {
                                onRowClick(row.original);
                            }
                        },
                        sx: {
                            cursor: "pointer",
                            borderBottom:
                                row.original?.percentageSide === "BUY"
                                    ? "1px solid green"
                                    : row.original?.percentageSide === "SELL"
                                        ? "1px solid red"
                                        : "",
                        },
                    })}
                    muiTableHeadCellProps={{
                        sx: {
                            padding: "1rem 0.4rem 1rem 0.7rem",
                        },
                    }}
                    muiTableBodyCellProps={({cell: {row, column}, table}) => ({
                        onClick: () => {
                            if (typeof onCellClick === 'function') {
                                onCellClick(row.original, column.id);
                            }
                        },
                        sx: {
                            padding: "1rem 0.4rem 1rem 0.7rem",
                            fontWeight: "500",
                            textAlign: "left",
                            alignItems: 'center',
                            justifyContent: 'left',
                            fontSize: "12px",
                            color: "black",
                            fontFamily: "SF Pro Display, sans-serif",
                        },
                    })}
                    muiTableContainerProps={{
                        sx: {
                            maxHeight: customHeight ?? tableHeight,
                            minHeight: customHeight ?? tableHeight,
                            position: "relative",
                            borderRadius: "8px",
                        },
                        onScroll: (event) => fetchMoreOnBottomReached(event.target),
                    }}
                    muiToolbarAlertBannerProps={
                        isError
                            ? {
                                color: "error",
                                children: "Error loading data",
                            }
                            : undefined
                    }
                    muiTableFooterProps={{
                        sx: {
                            position: tableData.length > 10 ? "sticky" : "absolute",
                            bottom: 0,
                            width: "100%",
                            borderTop: "1px solid #e0e0e0",
                            borderRadius: "0 0 8px 8px",
                        },
                    }}
                    onColumnFiltersChange={setColumnFilters}
                    onGlobalFilterChange={setGlobalFilter}
                    onSortingChange={setSorting}
                    positionActionsColumn="last"
                    enableBottomToolbar={
                        renderCurrencyFooterToolbar || renderFetchFooterToolbar
                    }
                    enableStickyHeader
                    enableStickyFooter={tableData.length > 10 ? true : false}
                    renderTopToolbarCustomActions={({table}) => {
                        return (
                            <Box>
                                {renderTopToolbarCustomActions.map((item) => {
                                    return (
                                        <IconButton onClick={item.onClick}>
                                            {item.icon}{" "}
                                            {item.name ? (
                                                <span
                                                    style={{
                                                        fontSize: "16px",
                                                        paddingLeft: "5px",
                                                    }}
                                                >{`${item.name}`}</span>
                                            ) : (
                                                ""
                                            )}
                                        </IconButton>
                                    );
                                })}
                            </Box>
                        );
                    }}
                    renderToolbarInternalActions={({table}) => (
                        <>
                            {/*{toolbarActions?.search && (*/}
                            {/*    <MRT_ToggleGlobalFilterButton table={table}/>*/}
                            {/*)}*/}
                            {toolbarActions?.partners &&
                                (user.isAdmin || user.isMainOffice) && (
                                    <MRT_TogglePartnerSelect table={table}/>
                                )}
                            {toolbarActions?.range && (
                                <MRT_ToggleCalendarButton table={table}/>
                            )}
                            {toolbarActions?.advancedFilter && (
                                <MRT_ToggleAdvancedFilterButton table={table}/>
                            )}
                            {toolbarActions?.columnsFilter && (
                                <MRT_ToggleFiltersButton table={table}/>
                            )}
                            {toolbarActions?.columnsVisible && (
                                <MRT_ShowHideColumnsButton table={table}/>
                            )}
                            {toolbarActions?.density && (
                                <MRT_ToggleDensePaddingButton table={table}/>
                            )}
                            {toolbarActions?.fullScreen && (
                                <MRT_FullScreenToggleButton table={table}/>
                            )}
                        </>
                    )}
                    muiLinearProgressProps={({isTopToolbar}) => ({
                        sx: {
                            backgroundColor: "orange",
                            display: isTopToolbar ? "block" : "none", //hide bottom progress bar
                        },
                    })}
                    localization={{
                        noResultsFound: translateNoData || "No results found",
                    }}
                    state={{
                        columnFilters,
                        globalFilter,
                        // isLoading: isFetching,
                        showAlertBanner: isError,
                        showProgressBars: isFetching,
                        sorting,
                    }}
                    rowVirtualizerInstanceRef={virtualizedInstanceRef}
                />
            </div>
        );
    }
;
export default MainTable;

