import React from "react";
import "./users.css";
import UsersTable from "./UsersTable/UsersTable";

const Users = () => {
  return (
    <div className="users">
        <UsersTable />
    </div>
  );
};

export default Users;

