import React, {useState} from "react";
import {useSelector} from "react-redux";
import Partners from "../Percentage/PartnersPercentages/Partners";
import MainSelect from "../ReusableComponents/Select";
import {balkanCountries} from "../ReusableComponents/Countries";
import PartnersPairsTable from "./PartnerPairsTable";

const PartnerPairs = ({ fiat }) => {
  const user = useSelector((state) => state.user.currentUser);

  const [loading, setLoading] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState([]);
    const [partnerCurrency, setPartnerCurrency] = useState([]);
    const [countryFilter, setCountryFilter] = useState(null);
  const [searchFilter, setSearchFilter] = useState("");
  const [exchangeRates, setExchangeRates] = useState([]);

  const [currencies, setCurrencies] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [localCurrencies, setLocalCurrencies] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [message, setMessage] = useState("false");
  const [addLoading, setAddLoading] = useState(false);

  const [modifiedData, setModifiedData] = useState({
    currency_from: "",
    currency_to: "",

    buying_rate_fee: 0,
    selling_rate_fee: 0,
  });

  const handleCountryFilter = (e) => {
    if (e === null) {
      setCountryFilter(null);
    } else {
      setCountryFilter(e.name);
    }
  };

  const filterName = (e) => {
    setSearchFilter(e.target.value);
  };

  return (
    <div className="flex flex-column items-start gap-10 mt-10 h-full">
      <div className="w-full">
        <div
          style={{
            marginLeft: "auto",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {" "}
          {success && <div className="success-alert">Saved Successfully!</div>}
        </div>
      </div>
      <div className="flex items-start gap-10 h-full w-full">
        {(user.isAdmin || user.isMainOffice) && (
          <div className="grid" style={{ width: "20%" }}>
            <MainSelect
              placeholder="Filter by Country"
              isClearable={true}
              isSearchable={true}
              options={balkanCountries}
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.name}
              onChange={(e) => handleCountryFilter(e)}
            />
            <input
              name="username"
              type="text"
              placeholder="Search partner by name..."
              className="some-input"
              onChange={(e) => filterName(e)}
            />
            <div
              className="card partners-card"
            >
              <Partners
                  setSelectedPartner={setSelectedPartner}
                  selectedPartner={selectedPartner}
                  loadingProp={loading}
                  searchFilter={searchFilter}
                  countryFilter={countryFilter}
                  entity={currencies}
                  setEntity={setLocalCurrencies}
                  tableLoading={tableLoading}
                  setTableLoading={setTableLoading}
                  setPartnerCurrency={setPartnerCurrency}
                  multiSelect={true}
              />
            </div>
          </div>
        )}

        <div
          className="clientsTable"
          style={{
            overflowX: "hidden",
            padding: 0,
            width: user.isAdmin || user.isMainOffice ? "85%" : "100%",
          }}
        >
          <div
            className="clientsTableMaterialTableContainer"
            style={{ width: "100%", overflowX: "hidden" }}
          >
            <div
              className={`card h-full ${loading ? "flex-center" : ""}`}
              style={{
                width: "calc(100% - 23px)",
                minHeight: "550px",
                margin: "0",
              }}
            >
              <PartnersPairsTable
                  setCurrencies={setCurrencies}
                  tableLoading={tableLoading}
                  setTableLoading={setTableLoading}
                  localCurrencies={localCurrencies}
                  fiat={fiat}
                  selectedPartner={selectedPartner}
                  setSelectedPartner={setSelectedPartner}
                  exchangeRates={exchangeRates}
                  setExchangeRates={setExchangeRates}
                  addLoading={addLoading}
                  setAddLoading={setAddLoading}
                  setSuccess={setSuccess}
                  partnerCurrencies={partnerCurrency}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerPairs;

