import React, { useState } from "react";
// Components
import ExchangeDeposit from "../ExchangeDeposit/ExchangeDeposit";
import ExchangeConvert from "../ExchangeConvert/ExchangeConvert";
import ExchangeDebit from "../ExchangeDebit/ExchangeDebit";
import "./exchangeMain.css";

const ExchangeMain = () => {
  const [clicked, setClicked] = useState("Deposit");

  return (
    <div className="exchangeMain">
      <div className="exchangeMainTopContainer">
        <div className="exchangeMainTopContainerHeader">
          <img
            className="exchangeMainTopContainerHeaderLeftIcon"
            src={require(`../../../assets/qr.png`)}
            alt=""
          />
          <span className="exchangeMainTopContainerHeaderSpan">
            Agron Buxhaku
          </span>
          <img
            src={require(`../../../assets/downarrow.png`)}
            alt=""
            className="exchangeMainTopContainerHeaderRightIcon"
          />
        </div>
        <div className="exchangeMainTopContainerButtonWrapper">
          <button
            onClick={() => setClicked("Deposit")}
            style={
              clicked === "Deposit"
                ? { color: "white", backgroundColor: "crimson" }
                : { color: "black", backgroundColor: "rgb(240,240,240)" }
            }
            className="exchangeMainTopContainerButtonWrapperButton"
          >
            Deposit
          </button>
          <button
            onClick={() => setClicked("Convert")}
            style={
              clicked === "Convert"
                ? { color: "white", backgroundColor: "crimson" }
                : { color: "black", backgroundColor: "rgb(240,240,240)" }
            }
            className="exchangeMainTopContainerButtonWrapperButton"
          >
            Convert
          </button>
          <button
            onClick={() => setClicked("Debit")}
            style={
              clicked === "Debit"
                ? { color: "white", backgroundColor: "crimson" }
                : { color: "black", backgroundColor: "rgb(240,240,240)" }
            }
            className="exchangeMainTopContainerButtonWrapperButton"
          >
            Debit
          </button>
        </div>
      </div>
      {clicked === "Deposit" ? (
        <ExchangeDeposit />
      ) : clicked === "Convert" ? (
        <ExchangeConvert />
      ) : (
        <ExchangeDebit />
      )}
    </div>
  );
};

export default ExchangeMain;
