import React, { Fragment, useState } from "react";
import { publicRequest } from "../../../../utils/requestMethods";
import Details from "./Details";
import AccountingPopupInfo from "./Content/AccountingPopupInfo";
import CryptoPopupInfo from "../Crypto/CryptoPopupInfo";
import ExchangePopupInfo from "../Exchange/ExchangePopupInfo"

function AccountingContent({ data, noPrint }) {
    const [details, setDetails] = useState(null);
    const [step, setStep] = useState(6);
    const [type, setType] = useState(null);
    const [loading, setLoading] = useState(false);

    const getDetails = async (type, id) => {
        setLoading(true);
        if (type === "crypto") {
            try {
                const response = await publicRequest.get(`transactions/${id}`);
                setDetails(response.data);
                setStep(7);
                setType('crypto')
                setLoading(false);
            } catch (err) {
                setStep(6);
                console.log(err);
                setType(null)
                setLoading(false);
            }
        } else if (type === "exchange") {
            try {
                const response = await publicRequest.get(`exchanges/${id}`);
                setDetails(response.data);
                setStep(8);
                setType('exchange')
                setLoading(false);
            } catch (err) {
                setStep(6);
                console.log(err);
                setType(null)
                setLoading(false);
            }
        } else {
            try {
                const response = await publicRequest.get(`transfer/${id}`);
                setDetails(response.data);
                setStep(7);
                setType('transfer')
                setLoading(false);
            } catch (err) {
                setStep(6);
                console.log(err);
                setType(null)
                setLoading(false);
            }
        }
    };

    return (
        <Fragment>
            {step === 6 ? (
                <Details data={data} getDetails={getDetails} noPrint={noPrint} />
            ) : (type === 'transfer') ? (
                <AccountingPopupInfo
                    data={details}
                    step={step}
                    setStep={setStep}
                    loading={loading}
                    noPring={noPrint}
                />
            ) : step === 8 ? (
                <ExchangePopupInfo
                    data={details}
                    step={step}
                    setStep={setStep}
                    loading={loading}
                    noPring={noPrint}
                />
            ) : (
                <CryptoPopupInfo
                    data={details}
                    step={step}
                    setStep={setStep}
                    loading={loading}
                    noPring={noPrint}
                />
            )}
        </Fragment>
    );
}

export default AccountingContent;
