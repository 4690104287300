import React, {useEffect, useState} from "react";
import iconGenerator from "../../../utils/iconGenerator";
import Modal from "react-modal";
import {QRCodeSVG} from "qrcode.react";
import "./DashboardSliderSecondLeft.css";
import {publicRequest} from "../../../utils/requestMethods";
import {ClipLoader} from "react-spinners";
import scanner from "../../../utils/scanner";
import {sortingNetwork} from "../../../utils/sorting";
import {BsFillPrinterFill} from "react-icons/bs";
import {cryptoAddress} from "../../../utils/templates/cryptoAddress";
import useScanDetection from "use-scan-detection";
import WAValidator from 'multicoin-address-validator';
import {networkValidationName} from "../../../utils/utils";

const DashboardSliderSecondLeft = ({
                                       choosenNetwork,
                                       choosenCrypto,
                                       afterClick,
                                       setAfterClick,
                                       choosenCryptoBNBPrice,
                                       choosenCoinPrice,
                                       setChoosenCoinPrice,
                                       setChoosenNetworkFee,
                                       choosenOrderType,
                                       coinInfo,
                                       choosenNetworkAddress,
                                       setChoosenNetworkAddress,
                                       addressError,
                                       setAddressError,
                                       setManualAddress,
                                       selectedNetworkChain,
                                       setSelectedNetworkChain
                                   }) => {
    const [loader, setLoader] = useState(false);
    const [subNetwork, setSubNetwork] = useState([]);
    const [networkLoading, setNetworkLoading] = useState(false);
    const [printerData, setPrinterData] = useState(null)

    useEffect(() => {
        setSubNetwork([]);
        const getNetworks = async () => {
            setNetworkLoading(true);
            try {
                const response = await publicRequest.get(
                    `/exchanges/cex/deposit/addresses/${choosenOrderType.toLowerCase()}/${choosenCrypto}`
                );

                const {data} = response;
                setNetworkLoading(false);
                window.scrollTo(0, document.body.scrollHeight);

                if (choosenNetwork === "BNB") {
                    let addArr = [];
                    let deletedArr = [];
                    let lastArr = [];

                    data.Binance?.map((coin) => {
                        if (coin.info.success === false) {
                            deletedArr.push(coin);
                        } else {
                            addArr.push(coin);
                        }
                    });

                    addArr?.map((coin) => lastArr.push(coin));
                    deletedArr?.map((coin) => lastArr.push(coin));

                    setSubNetwork(sortingNetwork(lastArr));
                } else if (choosenNetwork === "DEX") {
                    let addArr = [];
                    let deletedArr = [];
                    let lastArr = [];

                    data.DEX?.map((coin) => {
                        if (coin.info.success === false) {
                            deletedArr.push(coin);
                        } else {
                            addArr.push(coin);
                        }
                    });

                    addArr?.map((coin) => lastArr.push(coin));
                    deletedArr?.map((coin) => lastArr.push(coin));

                    setSubNetwork(sortingNetwork(lastArr));
                } else {
                    setSubNetwork([]);
                }
            } catch (err) {
                console.log(err);
            }
        };
        getNetworks();
    }, [choosenCrypto, choosenNetwork, choosenOrderType]);

    const handleClick = async (data, index, success, fee) => {

        document.getElementById("slider").scrollIntoView();
        if (success) {
            setLoader(true);
            setAfterClick(index);
            setLoader(false);
            setChoosenNetworkFee(data);
            setSelectedNetworkChain(data);
            if (choosenOrderType === "SELL") {
                setChoosenNetworkAddress("");
            }
        }

        if (success && choosenOrderType === "BUY") {
            setChoosenNetworkAddress(data.address);
            setPrinterData(data)
        } else {
            setChoosenNetworkAddress("");
        }
    };

    const customStyles = {
        content: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "500px",
            height: "350px",
        },
    };

    const [modalIsOpen, setIsOpen] = React.useState(false);

    const [hidAddress, setHidAddress] = useState([]);

    async function openModal() {
        if (choosenOrderType === "BUY") {
            setIsOpen(true);
        }
    }

    const openScanner = async () => {
        const scan = await scanner();
        setHidAddress([]);
        scan.oninputreport = (event) => {
            const {data, device, reportId} = event;
            var enc = new TextDecoder("utf-8");
            let add = enc.decode(data).replace(/[^a-z0-9]/gi, "");
            let tmp = hidAddress;
            tmp.push(add);
            setChoosenNetworkAddress(tmp.join(""));
        };
    };

    function closeModal() {
        setIsOpen(false);
    }

    useEffect(() => {
        if (choosenCrypto !== "USDT") {
            setChoosenCoinPrice(choosenCryptoBNBPrice);
        } else {
            setChoosenCoinPrice(Number(1).toFixed(3));
        }
    }, [choosenCryptoBNBPrice]);


    const validateAddress = (address) => {
        let valid = true;
        if (selectedNetworkChain.network) {
            try {
                valid = WAValidator.validate(address, networkValidationName(selectedNetworkChain?.network));
            } catch (e) {
                console.log(e);
            }
        }
        return valid;
    }

    const handleChoosenNetworkAddress = (e) => {
        if (e === "" || !validateAddress(e.target.value)) {
            setAddressError(true);
        } else {
            setAddressError(false);
        }
        setManualAddress(true);
        setChoosenNetworkAddress(e.target.value);
    };

    useEffect(() => {
        if (choosenNetworkAddress === "" || !validateAddress(choosenNetworkAddress)) {
            setAddressError(true);
        } else {
            setAddressError(false);
        }
    }, [choosenNetworkAddress]);

    const onScanCompleted = async (code) => {
        const replacedCode = code.replaceAll('Shift', '');
        setChoosenNetworkAddress(replacedCode);
        setManualAddress(false);
    }

    const onScanError = async (error) => {
        console.error(error)
    }

    useScanDetection({
        onComplete: onScanCompleted,
        onError: onScanError,
        minLength: 15
    });

    return (
        <div className="dashboardSliderSecond">
            <div className="dashboardSliderSecondWrapper" style={{maxWidth: "89%"}}>
                <div
                    className="dashboardSliderSecondHeaderOne"
                    style={{
                        border: addressError ? "2px solid red" : "",
                        minHeight: "20px",
                    }}
                >
                    {choosenOrderType === "SELL" && afterClick !== -1 ? (
                        <input
                            style={{cursor: "unset"}}
                            className="address-input"
                            type="text"
                            onChange={(e) => handleChoosenNetworkAddress(e)}
                            value={choosenNetworkAddress || ""}
                            onClick={(e) => {
                                e.target.select();
                            }}
                        />
                    ) : choosenOrderType === "BUY" && afterClick !== -1 ? (
                        loader === true ? (
                            "Address Loading..."
                        ) : (
                            <div className="flex items-center justify-between w-full">
                                <div style={{padding: "0 10px"}}>{choosenNetworkAddress}</div>
                                <BsFillPrinterFill className="cursor-pointer" color="#000000"
                                                   onClick={async () => await cryptoAddress(null, null, printerData, coinInfo)}/>
                            </div>
                        )
                    ) : (
                        <></>
                    )}
                    {choosenOrderType === "SELL" && (
                        <img
                            src={require("../../../assets/qrcodeicon.png")}
                            className="fa fa-qrcode cursor-pointer"
                            aria-hidden="true"
                            style={{display: "block", marginLeft: "auto"}}
                            width="20"
                            height="20"
                            onClick={openModal}
                        />
                    )}

                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Address"
                    >
                        <div className="dashboardSliderSecondAddressModalAddressWrapper">
                            <span className="dashboardSliderSecondAddressModalAddressSpan">
                                Address: {choosenNetworkAddress}
                            </span>
                            <QRCodeSVG value={choosenNetworkAddress}/>
                            <button
                                onClick={closeModal}
                                className="dashboardSliderSecondAddressModalCloseButton"
                            >
                                Close
                            </button>
                        </div>
                    </Modal>
                </div>
                <div className="dashboardSliderSecondLeftItem">
                    <div className="dashboardSliderSecondLeftItemCoinContainer">
                        <div className="dashboardSliderSecondLeftItemCoinWrapper">
                            <img
                                src={iconGenerator(128, "icon", `${choosenCrypto}`)}
                                alt=""
                                className="dashboardSliderSecondLeftItemCoinSymbol"
                            />
                            <span className="dashboardSliderSecondLeftItemCoinSpan">
                                {choosenCrypto} - {coinInfo?.name}
                            </span>
                            <span style={{display: "contents"}}>
                                {choosenNetwork === "BNB" ? (
                                    <img
                                        className="dashboardSliderSecondLeftItemIconIcon"
                                        src={require("../../../assets/binance_favicon.png")}
                                        alt=""
                                    />
                                ) : (
                                    <img
                                        className="dashboardSliderSecondLeftItemIconIcon"
                                        src={require("../../../assets/dex.png")}
                                        alt=""
                                    />
                                )}
                            </span>
                        </div>
                        <span className="dashboardSliderSecondLeftItemCoinPrice">
                            ${choosenCoinPrice}
                        </span>
                    </div>
                </div>
                <div className="dashboardSliderSecondHeaderTwo">Transfer</div>
                {networkLoading ? (
                    <div
                        style={{
                            display: "grid",
                            placeItems: "center",
                            width: "100%",
                            padding: "50px 0",
                        }}
                    >
                        <ClipLoader color="#fdb619" size={48}/>
                        <span style={{marginTop: "10px"}}>Waiting for networks...</span>
                    </div>
                ) : (
                    <>
                        {subNetwork.map((data, index) => (
                            <div
                                style={
                                    afterClick === index
                                        ? {cursor: "normal", border: "1px solid orange"}
                                        : {cursor: "pointer"}
                                }
                                onClick={() =>
                                    handleClick(data, index, data.info.success, data.withdrawFee)
                                }
                                key={index}
                                className="dashboardSliderSecondLeftItems"
                            >
                                <div
                                    className="dashboardNetworkSpanCircle"
                                    style={
                                        afterClick === index
                                            ? {cursor: "normal", backgroundColor: "orange"}
                                            : {cursor: "pointer"}
                                    }
                                ></div>
                                <div className="dashboardNetwork">
                                    <p>{data.network}</p>
                                    <p>{data.networkName}</p>
                                    <p style={{fontSize: "12px", color: "crimson"}}>
                                        {!data.info.success && data.info.error}
                                    </p>
                                </div>
                                <div className="dashboardleftsidefee">
                                    {choosenOrderType === "SELL" ? (
                                        <>
                                            <p>Fee: {data.withdrawFee}</p>
                                            <p>
                                                ≈$
                                                {(data.withdrawFee * choosenCoinPrice).toFixed(4)}
                                            </p>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        ))}
                    </>
                )}
            </div>
        </div>
    );
};

export default DashboardSliderSecondLeft;

