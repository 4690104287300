import { Document, Page, Text, View, StyleSheet, Svg, Path, Image } from '@react-pdf/renderer';
import moment from 'moment';
import QRCode from 'qrcode.react';
import { renderToStaticMarkup } from 'react-dom/server';
import ReactHtmlParser from 'react-html-parser';
import iconGenerator from '../../iconGenerator';


const styles = StyleSheet.create({
    document: {
        width: 300,
        paddingTop: 30,
        flexDirection: "row",
        flexGrow: 1
    },
    page: {
        paddingVertical: 20,
        display: "flex",
        flexDirection: "column",
    },
    title: {
        fontWeight: "bold",
        textAlign: "center",
        fontSize: 16,
        paddingBottom: 10,
        borderBottomWidth: 1,
        borderBottomStyle: "dashed",
        marginBottom: 10,
    },
    divider: {
        borderBottomWidth: 1,
        borderBottomStyle: "dashed",
        marginBottom: 10,
    },
    view: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 5,
        paddingHorizontal: 10
    },
    text: {
        fontSize: 12,
        marginBottom: 5
    },
    boldText: {
        fontWeight: "bold",
        fontSize: 14
    },
    image: {
        width: 30,
        height: 30
    }
});

const parseQrCodeMarkup = (markup) => {
    let parsedQrCodeSvg = null;

    ReactHtmlParser(markup).forEach(el => {
        const { type } = el;
        if (type === 'svg') {
            parsedQrCodeSvg = el;
        }
    });

    return parsedQrCodeSvg;
};

const qrCodeComponent = (
    <QRCode
        value={localStorage.getItem("orderId")}
        renderAs="svg"
        size={80}
    />
);

const qrCodeComponentStaticMarkup = renderToStaticMarkup(qrCodeComponent);

const parsedQrCodeSvg = parseQrCodeMarkup(qrCodeComponentStaticMarkup);
if (!parsedQrCodeSvg) {

}

// let data = JSON.parse(localStorage.getItem("order"))



export const CryptoPdf = ({ data }) => (
    <Document style={styles.document}>
        <Page style={styles.page} size="A5" dpi={82}>
            <View style={styles.divider}>
                <View style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: 5 }}>
                    <Text style={styles.title}>YOU {data?.side?.toUpperCase() === 'SELL' ? 'BOUGHT' : 'SOLD'}</Text>
                    <Text style={styles.text}>{data?.symbol?.split('/')[0]}</Text>
                </View>
                <View style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: 10 }}>
                    <View style={styles.image}>
                        <Image src={iconGenerator(64, null, data?.symbol?.split('/')[0])} />
                    </View>
                </View>
            </View>
            <View style={styles.divider}>
                <View style={styles.view}>
                    <Text style={styles.text}>Date</Text>
                    <Text style={styles.text}>{moment(data?.createdAt).format('DD.MM.Y')}</Text>
                </View>

                <View style={styles.view}>
                    <Text style={styles.boldText}>Order ID</Text>
                    <Text style={styles.boldText}>{data?.order_number}</Text>
                </View>
            </View>
            <View style={styles.divider}>
                <View style={styles.view}>
                    <Text style={styles.text}>Amount</Text>
                    <Text style={styles.text}>{data?.quantity_sent ?? data?.quantity} {data?.symbol?.split("/")[0]}</Text>
                </View>

                <View style={styles.view}>
                    <Text style={styles.text}>Equivalent To</Text>
                    <Text style={styles.text}>{parseFloat(data?.fiat_payment).toFixed(2)} EUR</Text>
                </View>
            </View>
            <View style={styles.divider}>
                <View style={styles.view}>
                    <Text style={{ fontSize: 12, fontWeight: "bold" }}>Description</Text>
                    <Text style={{ fontSize: 12, fontWeight: "bold" }}>Price</Text>
                </View>
                <View style={styles.view}>
                    <Text style={styles.text}>Last Price</Text>
                    <Text style={styles.text}>{data?.last_price_filled} USD</Text>
                </View>
                <View style={styles.view}>
                    <Text style={styles.text}>Chain Fee {data?.network_fee} {data?.symbol?.split('/')[0]}</Text>
                    <Text style={styles.text}>{(data?.network_fee * data?.last_price_filled).toFixed(2)} USD</Text>
                </View>
                <View style={styles.view}>
                    <Text style={styles.text}>Chain Network</Text>
                    <Text style={styles.text}>{data?.network}</Text>
                </View>
                <View style={styles.view}>
                    <Text style={styles.text}>Fee %</Text>
                    <Text style={styles.text}>{data?.value_fee?.fee} %</Text>
                </View>
            </View>
            <View style={styles.divider}>
                <View style={styles.view}>
                    <Text style={styles.boldText}>Currency</Text>
                    <Text style={styles.boldText}>Value</Text>
                </View>
                {data?.exchange_rate?.map((rates) => (
                    <View style={styles.view}>
                        <Text style={styles.text}>{rates.symbol} {rates.rate}</Text>
                        <Text style={styles.text}>{parseFloat(rates.price).toFixed(2)} {rates.symbol}</Text>
                    </View>
                ))
                }
                <View style={styles.view}>
                    <Text style={styles.boldText}>Total</Text>
                    <Text style={styles.boldText}>{parseFloat(data?.fiat_payment).toFixed(2)} EUR</Text>
                </View>
            </View>
            <View style={styles.divider}>
                <View style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: 5, paddingBottom: 15 }}>
                    <Svg
                        style={{ width: 100, height: 100 }}
                        viewBox="0 0 29 29"
                    >
                        {parsedQrCodeSvg.props.children.filter(c => c.type === 'path').map((child, index) => (
                            <Path
                                key={index}
                                d={child.props.d}
                                fill={child.props.fill}
                            />
                        ))}
                    </Svg>
                    <Text style={styles.boldText}>Receiving address</Text>
                    <Text style={styles.text}>{data?.address_to?.address}</Text>
                </View>
            </View>
            <View style={styles.divider}>
                <View style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: 5, paddingBottom: 15 }}>
                    <Text style={styles.text}>Thanks for using our service</Text>
                </View>
            </View>
        </Page>
    </Document>

)
