import React, { useEffect, useState } from "react";
// Components
import Dashboard from "../../components/Dashboard/Dashboard";
import Exchange from "../../components/Exchange/Exchange";
import Accounting from "../../components/Accounting/Accounting";
import Clients from "../../components/Clients/Clients";
import LeftSidebar from "../../components/LeftSidebar/LeftSidebar";
import RightSidebar from "../../components/RightSidebar/RightSidebar";
import Transactions from "../../components/Transactions/Transactions";
import Transfer from "../../components/Transfer/Transfer";
import Wallet from "../../components/Wallet/Wallet";
import Settings from "../../components/Settings/Settings";
import Coins from "../../components/Coins/Coins";
import Currency from "../../components/Currency/Currency";
import Messages from "../../components/Messages/Messages";
import Users from "../../components/Users/Users";
import Percentage from "../../components/Percentage/Percentage";
import Partners from "../../components/Partners/Partners";
import GlobalSettings from "../../components/GlobalSettings/GlobalSettings";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// CSS
import "./mainpage.css";

// Redux
import { useSelector } from "react-redux";
import Rules from "../../components/Rules/Rules";
import useScanDetection from "use-scan-detection";
import Header from "../../components/Header/Header";
import Profile from "../../components/Users/UsersProfile/Profile";

const encoder = new TextDecoder("utf-8");
const MainPage = () => {
    const [page, setPage] = useState("Dashboard");
    const [tabIndex, setTabIndex] = useState(0);
    const [scanData, setScanData] = useState('');
    const [dataScan, setDataScan] = useState({});
    const [tabsToggle, setTabsToggle] = useState({
        leftBar: false,
        rightBar: false
    })
    const redux = useSelector((state) => state.user);
    const user = redux.currentUser;

    const completeScan = (code) => {
        setScanData(code.replaceAll('Shift', ''));
    }

    useScanDetection({
        onComplete: completeScan,
        minLength: 15,
    })

    useEffect(() => {
        try {
            if (scanData.length) {
                let data = JSON.parse(atob(scanData));
                switch (data.type) {
                    case 'crypto':
                        setTabIndex(0);
                        break;
                    case 'exchange':
                        setTabIndex(2);
                        break;
                    case 'internal_transfer':
                    case 'lite_transfer':
                        setTabIndex(1);
                        break;
                    case 'voucher':
                        break;
                }

                setDataScan(data);
                setScanData('');
                setPage('Transactions')
            }
        } catch (e) {
            console.error(e)
        }
    }, [scanData])

    useEffect(() => {
        setTabsToggle({ left: false, right: false });
    }, [page])

    const queryClient = new QueryClient();

    return (
        user && (
            <div className="container">
                <Header tabsToggle={tabsToggle} setTabsToggle={setTabsToggle} />
                <div className="wrapper">
                    <QueryClientProvider client={queryClient}>
                        <LeftSidebar setPage={setPage} page={page} tabsToggle={tabsToggle} />
                        {page === "Accounting" && <Accounting />}
                        {page === "Partners" && <Partners />}
                        {page === "Users" && <Users />}
                        {page === "Clients" && <Clients />}
                        {page === "Exchange" && <Exchange />}
                        {page === "Transactions" &&
                            <Transactions tabIndex={tabIndex} scanData={dataScan} setScanData={setDataScan} />}
                        {page === "Transfer" && <Transfer />}
                        {page === "Wallet" && <Wallet />}
                        {page === "Dashboard" && <Dashboard />}
                        {page === "Settings" && <GlobalSettings />}
                        {page === "Global Settings" && <GlobalSettings />}
                        {page === "Messages" && <Messages />}
                        {page === "Coins" && <Coins />}
                        {page === "Currency" && <Currency />}
                        {page === "Percentage" && <Percentage />}
                        {page === "Rules" && <Rules/>}
                        {page === "Profile" && <Profile/>}
                        <RightSidebar tabsToggle={tabsToggle} />
                    </QueryClientProvider>
                </div>
            </div>
        )
    );
};

export default MainPage;

