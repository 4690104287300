import {NumericFormat} from "react-number-format";
import iconGenerator from "../../../../utils/iconGenerator";
import AsyncSelect from "react-select/async";
import {RiUserAddLine} from "react-icons/ri";
import AddClientModal from "../../AddClientModal";
import {NumberFormatter} from "../../NumberFormat";
import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {publicRequest} from "../../../../utils/requestMethods";
import Swal from "sweetalert2";
import Loader from "../Messages/loader";
import Success from "../Messages/success";
import Failed from "../Messages/failed";
import {withdrawalPrint} from "../../../../utils/templates/withdrawal";

const WithdrawalFrom = ({currency, setIsOpen, selectedPartner}) => {
    const user = useSelector((state) => state.user.currentUser);
    const percentages = useSelector((state) => state.user.percentages);
    const [preventDefault, setPreventDefault] = useState(false)
    const [client, setClient] = useState(null)
    const [clientAddOpen, setClientAddOpen] = useState(false)
    const [success, setSuccess] = useState(false);
    const [fail, setFail] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const resultsRef = useRef(null);
    const [fee, setFee] = useState({fee: 0, manual: false});
    const [total, setTotal] = useState(0);
    const [data, setData] = useState({
        comment: "",
        details: null,
        type: "withdrawal",
        transaction_type: "withdrawal",
        transfer_type: "transfer",
        side: "Withdrawal",
        status: 1,
        currency: null,
        partnerId: {},
        clientId: null,
        amount: 0,
    });

    const handleInputChange = (values, source) => {
        const value = values.value;
        const name = source.event.target.name;

        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const generateFee = async (amount) => {
        const percentage = percentages.transfer.filter((tt) => tt.percentageDetails.type === 'withdrawal');

        if (!percentage.length) {
            return 0;
        }
        percentage.sort((a, b) => a.fromValue - b.fromValue);


        let currentFee = percentage.find(
            (prc) =>
                parseFloat(amount) >= prc.fromValue &&
                parseFloat(amount) <= prc.toValue
        );

        if (currentFee === undefined) {
            currentFee = percentage[0];
        }

        return currentFee.percentageValue;
    }

    useEffect(() => {
        let totalWithFee = parseFloat(data.amount) - parseFloat(data.amount * (fee.fee / 100));
        setTotal(totalWithFee)
    }, [fee, data]);


    const calculateFee = () => {
        if (!fee.manual && parseFloat(data.amount) > 0) {
            generateFee(data.amount).then((feeValue) => {
                setFee({...fee, ...{fee: feeValue}});
            });
        }
    }

    useEffect(() => {
        calculateFee();
    }, [data]);

    const inputKeyDown = (e) => {
        if (e.keyCode === 13 && preventDefault) {
            e.preventDefault();
        }
    }

    const handleSetResult = (e) => {
        if (user.isAdmin || user.isMainOffice) {
            if (e === null) {
                setData((prev) => ({
                    ...prev,
                    beneficiaryDetails: null,
                }));
            } else {
                setData((prev) => ({
                    ...prev,
                    beneficiaryDetails: e._id,
                }));
                setData((prev) => ({
                    ...prev,
                    partnerId: e._id,
                }));
            }
        } else {
            if (e === null) {
                setData((prev) => ({
                    ...prev,
                    beneficiaryDetails: null,
                }));
                setClient(null)
            } else {
                setData((prev) => ({
                    ...prev,
                    beneficiaryDetails: e._id,
                }));
                setData((prev) => ({
                    ...prev,
                    partnerId: user?.partnerId?._id,
                }));

            }
        }
        setClient(e)
    };

    const loadOptions = async (inputValue) => new Promise(async (resolve) => {
        if (inputValue.length > 2) {
            let response;
            if (user.isAdmin || user.isMainOffice) {
                response = await publicRequest.get(`/partner/search/partner?search=${inputValue}`)
            } else {
                response = await publicRequest.get(`/client/search/client?search=${inputValue}`)
            }
            if (response.status === 200) {
                resolve(response.data);
            }
        } else {
            if (selectedPartner !== '' && selectedPartner.length > 0) {
                let response;
                if (user.isAdmin || user.isMainOffice) {
                    response = await publicRequest.get(`/partner/${selectedPartner[0]}`)
                }

                if (response.status === 200) {
                    setClient(response.data);
                    resolve(response.data);
                }
            }
        }
    });

    const handleSubmit = async () => {
        if (user.isAdmin || user.isMainOffice) {
            if (data?.beneficiaryDetails || selectedPartner.length) {
                data.partnerId = data?.beneficiaryDetails || selectedPartner[0]
                data.status = 4
            }
        } else {
            data.partnerId = user.partnerId._id;
            data.clientId = data?.beneficiaryDetails
        }

        data.transaction_type = 'withdrawal'
        data.currency = currency?.currency._id;
        data.details = currency?.currency.symbol;
        data.note = data.comment;
        data.summary = parseFloat(total).toFixed(2);
        data.fee = parseFloat(fee.fee).toFixed(2);
        data.isFeeManual = fee.manual;

        if (data) {
            Swal.fire({
                title: `Are you sure you want to create this withdraw?`,
                icon: 'question',
                showDenyButton: true,
                confirmButtonText: "Yes",
                denyButtonText: `Cancel`,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        setLoading(true);
                        let response;
                        if (user.isAdmin || user.isMainOffice) {
                            response = await publicRequest.post(
                                "/accounting/account/deposit",
                                data
                            );
                        } else {
                            response = await publicRequest.post(
                                "/v2/clients/transfer",
                                data
                            );
                        }

                        if (response.status === 201) {
                            setLoading(false);
                            setSuccess(true);
                            setFail(false);
                            await withdrawalPrint(response.data);
                        } else {
                            setLoading(false);
                            setSuccess(false);
                            setFail(true);
                        }

                        setTimeout(() => {
                            setSuccess(false);
                            setLoading(false);
                            setFail(false);
                            setIsOpen(false);
                        }, 1000);
                    } catch (e) {
                        const {response} = e;
                        if (response.status === 500) {
                            setLoading(false);
                            setSuccess(false);
                            setFail(true);
                            setMessage(response.data.error)
                        }

                        setTimeout(() => {
                            setSuccess(false);
                            setLoading(false);
                            setFail(false);
                            setIsOpen(false);
                        }, 2000);
                    }
                }
            })
        }
    };

    const customFee = (e, b) => {
        setFee({fee: e.floatValue, manual: true});
    }

    const resetFee = async () => {
        setFee({...fee, ...{manual: false}});
        await calculateFee();
    }

    return (
        <>
            {loading ? (
                <Loader/>
            ) : success && !loading ? (
                <Success type={'withdraw'}/>
            ) : fail && !loading ? (
                <Failed type={'withdraw'} message={message}/>
            ) : (
                <>
                    <div ref={resultsRef}>
                        <div
                            className="usersTableRegisterFormItem"
                            style={{width: "100%", marginBottom: '10px'}}
                        >
                            <label
                                style={{fontSize: "12px"}}
                                className="usersTableRegisterFormLabel"
                            >
                                {user.isAdmin || user.isMainOffice
                                    ? "Partner (Name / ID / Email / Phone)"
                                    : "Client (Name / ID / Email / Phone)"}
                            </label>
                            <div className="w-full flex items-center gap-5 no-loading"
                                 style={{marginTop: "5px"}}>
                                <div style={{flex: 1}}>
                                    <AsyncSelect
                                        classNamePrefix="custom-select"
                                        isClearable={true}
                                        cacheOptions
                                        value={client}
                                        defaultOptions
                                        getOptionLabel={(e) =>
                                            user.isAdmin || user.isMainOffice
                                                ? e.partnerName
                                                : e.firstName + " " + e.lastName
                                        }
                                        getOptionValue={(e) => e._id}
                                        loadOptions={loadOptions}
                                        onKeyDown={inputKeyDown}
                                        onChange={(e) => handleSetResult(e)}
                                        noOptionsMessage={() => "No clients found, keep searching..."}
                                    />
                                </div>
                                {!user.isAdmin && !user.isMainOffice && (
                                    <>
                                        <RiUserAddLine
                                            size={20}
                                            color="#fff"
                                            onClick={() => {
                                                setClientAddOpen(true)
                                            }}
                                            className="addUserIcon"
                                        />
                                        <AddClientModal client={client} setClient={setClient} setData={setData}
                                                        isOpen={clientAddOpen} setIsOpen={setClientAddOpen}/>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full">
                        <div className="w-full" style={{marginBottom: '10px'}}>
                            <label
                                style={{fontSize: "12px"}}
                                className="usersTableRegisterFormLabel"
                            >
                                Amount
                            </label>
                            <div
                                className="flex w-full items-center"
                                style={{
                                    border: "1px solid #d8d8d8",
                                    borderRadius: "4px",
                                    marginTop: "5px",
                                }}
                            >
                                <NumericFormat
                                    value={parseFloat(data.amount)}
                                    type={"text"}
                                    placeholder="Amount"
                                    name='amount'
                                    displayType={"input"}
                                    allowNegative={false}
                                    thousandSeparator=","
                                    decimalSeparator="."
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    onFocus={(e) =>
                                        setTimeout(() => {
                                            e.target.select();
                                        }, 100)
                                    }
                                    className="usersTableRegisterFormInput"
                                    style={{
                                        border: "0px",
                                        marginTop: "0",
                                        padding: "0 10px",
                                    }}
                                    onValueChange={(values, sourceInfo) => handleInputChange(values, sourceInfo)}
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        borderLeft: "1px solid #d8d8d8",
                                        padding: "3px 10px",
                                    }}
                                >
                                    <img
                                        src={iconGenerator(
                                            128,
                                            "icon",
                                            currency.currency.symbol,
                                            true
                                        )}
                                        style={{height: "25px"}}
                                    />
                                    <span
                                        style={{
                                            marginLeft: 5,
                                            fontSize: "14px",
                                            fontWeight: 500,
                                        }}
                                    >
                                                {currency.currency.symbol}
                                            </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex w-full">
                        <div className="w-full">
                            <label
                                style={{fontSize: "12px"}}
                                className="usersTableRegisterFormLabel"
                            >
                                Comment
                            </label>
                            <div
                                className="flex w-full items-center"
                            >
                                <input
                                    onChange={(e) => setData((prev) => ({
                                        ...prev,
                                        comment: e.target.value
                                    }))}
                                    name="comment"
                                    placeholder="Comment"
                                    type="text"
                                    className="usersTableRegisterFormInput"
                                    style={{
                                        height: 25,
                                        borderRadius: "4px",
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        className="flex items-center justify-between"
                        style={{marginTop: "30px"}}
                    >
                        <div className="flex gap-10 items-center">
                            <p style={{fontSize: "14px"}}>Summary</p>
                        </div>
                        <div
                            style={{
                                height: "1px",
                                backgroundColor: "#d8d8d8",
                                width: "85%",
                            }}
                        ></div>
                    </div>
                    <div className="flex items-center justify-between">
                        <div
                            className="flex items-center gap-10"
                            style={{marginTop: "10px"}}
                        >
                            <img
                                src={iconGenerator(
                                    128,
                                    "icon",
                                    currency.currency.symbol,
                                    true
                                )}
                                style={{height: "40px"}}
                            />
                            <div className="grid">
                                        <span style={{fontSize: "12px", color: "#777777"}}>
                                            Fee:
                                            <NumericFormat
                                                style={{
                                                    height: "10px",
                                                    borderRadius: "5px",
                                                    padding: "5px",
                                                    fontSize: "12px",
                                                    border: "0px solid white",
                                                    outline: 'none',
                                                    color: "rgb(119, 119, 119)"
                                                }}
                                                onValueChange={customFee}
                                                onDoubleClick={resetFee}
                                                onFocus={(e) => {
                                                    setTimeout(() => {
                                                        e.target.focus();
                                                        e.target.select();
                                                    }, 100);
                                                }}
                                                value={fee.fee}
                                                displayType={"input"}
                                                thousandSeparator=","
                                                decimalSeparator="."
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                suffix={` %`}
                                            />
                                            {/*<NumberFormatter value={parseFloat(fee.fee)}*/}
                                            {/*                      suffix={` ${currency.currency.symbol}`}/>*/}
                                        </span>
                                <span style={{fontSize: "12px", color: "#777777"}}>
                                            Total to spend: <NumberFormatter value={parseFloat(data.amount)}
                                                                             suffix={` ${currency.currency.symbol}`}/>
                                        </span>
                            </div>
                        </div>
                        <span
                            style={{
                                color: "orange",
                                fontSize: "16px",
                            }}
                        >
                                    <NumberFormatter value={parseFloat(total)}
                                                     suffix={` ${currency.currency.symbol}`}/>
                                </span>
                    </div>
                    <div
                        className="grid place-center w-full"
                        style={{marginTop: "20px"}}
                    >
                        <button className="rounded-btn w-full" onClick={handleSubmit}>
                            {"Withdraw"}
                        </button>
                    </div>
                </>
            )}
        </>
    );
}


export default WithdrawalFrom;
