import React, {useEffect, useMemo, useRef, useState} from "react";
import "./clientsTable.css";
import {useSelector} from "react-redux";
import Swal from "sweetalert2";
import ClientDetail from "../ClientsDetail/ClientDetail";
import {publicRequest} from "../../../utils/requestMethods";
import MainModal from "../../../components/ReusableComponents/MainModal";
import ClientRegisterFields from "../../ReusableComponents/ClientRegisterFields";
import PanelInfo from "./PanelInfo";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import "react-tabs/style/react-tabs.css";
import MainTable from "../../ReusableComponents/Tables/MRT_Main";
import {FaArrowLeft, FaPlusCircle} from "react-icons/fa";
import {capitalizeWords} from "../../../utils/utils";
import Moment from "moment/moment";

const ClientsTable = () => {
    const user = useSelector((state) => state.user.currentUser);
    const menu = useSelector((state) => state.user.menu);

    const [users, setUsers] = useState([]);
    const [partners, setPartners] = useState([]);
    const [percentage, setPercentage] = useState([]);
    const [edit, setEdit] = useState(false);
    const [register, setRegister] = useState(false);
    const [selectedRow, setSelectedRow] = useState([]);
    const [id, setId] = useState([]);
    const [client, setClient] = useState(null);
    const [details, setDetails] = useState(false);
    const [submitOrEdit, setSubmitOrEdit] = useState("");
    const [modalType, setModalType] = useState("");
    const [infoData, setInfoData] = useState(null);
    const [partnerId, setPartnerId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [rowClicked, setRowClicked] = useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState("");
    const [requestURL, setRequestURL] = useState("v2/clients");
    const [action, setAction] = useState({type: "", id: null, data: null});

    const partnerIdMain = user.partnerId;

    // Input States
    const [isOpen, setIsOpen] = useState(false);

    const [modifiedData, setModifiedData] = useState({
        firstName: "",
        lastName: "",
        zip: "",
        address: "",
        city: "",
        country: {name: "", code: ""},
        phone1: "",
        phone2: "",
        email: "",
    });

    const openModal = (params) => {
        setIsOpen(true);
        setSubmitOrEdit(params);
    };

    const closeModal = () => {
        setIsOpen(false);
        setModalType("");
    };

    const mainTableRef = useRef();
    const logTableRef = useRef();

    const mainColumns = useMemo(
        () => [
            {
                accessorKey: "firstName",
                header: "First Name",
                Cell: ({cell}) => {
                    return capitalizeWords(cell.getValue());
                },
                size: 50,
            },
            {
                accessorKey: "lastName",
                header: "Last Name",
                Cell: ({cell}) => {
                    return capitalizeWords(cell.getValue());
                },
                size: 50,
            },
            {
                accessorKey: "email",
                header: "Email",
                size: 50,
            },
            {
                accessorKey: "partnerId.partnerName",
                header: "Partner",
                size: 50,
                accessorFn: (e) => capitalizeWords(e.partnerId?.partnerName) || capitalizeWords(e.whoCreateIt?.fullName),
            },
            {
                accessorKey: "city",
                header: "City",
                size: 50,
                Cell: ({cell}) => {
                    return capitalizeWords(cell.getValue());
                },
            },
            {
                accessorKey: "country",
                header: "Country",
                size: 50,
                Cell: ({cell}) => {
                    return capitalizeWords(cell.getValue());
                },
            },
            {
                accessorKey: "phone1",
                header: "Phone",
                size: 50,
            },
        ],
        []
    );

    const logColumns = useMemo(
        () => [
            {
                accessorKey: "type",
                header: "Log Name",
                Cell: ({cell}) => {
                    return capitalizeWords(cell.getValue());
                },
            },
            {
                accessorKey: "side",
                header: "Action",
                Cell: ({cell}) => {
                    return capitalizeWords(cell.getValue());
                },
            },
            {
                accessorKey: "createdAt",
                header: "Date",
                accessorFn: (e) => (
                    <div>{Moment(e.createdAt).format("DD.MM.YYYY HH:mm")}</div>
                ),
            },
        ],
        []
    );

    const [viewAll, setViewAll] = useState(false);
    const [viewOther, setViewOther] = useState(false);
    const [viewOwn, setViewOwn] = useState(false);

    useEffect(() => {
        if (user.isAdmin) {
            setViewAll(true);
            setViewOther(true);
            setViewOwn(true);
        } else {
            let userRules = menu.find((mm) => mm.value === "clients");
            if (userRules) {
                userRules.permissions.map((up) => {
                    let permission = Object.entries(up)[0];
                    switch (permission[0]) {
                        case "viewAll":
                            setViewAll(permission[1]);
                            break;
                        case "viewOther":
                            setViewOther(permission[1]);
                            break;
                        case "viewOwn":
                            setViewOwn(permission[1]);
                            break;
                    }
                });
            }
        }
    }, []);

    useEffect(() => {
        const getPartners = async () => {
            try {
                const response = await publicRequest.get("partner/");

                const filteredPartner = response.data.filter(
                    (item) => item._id === partnerId
                );

                partnerIdMain
                    ? setPartners(filteredPartner)
                    : setPartners(response.data);
            } catch (err) {
                console.log(err);
            }
        };

        getPartners();
    }, []);

    const getUsers = async () => {
        try {
            const response = await publicRequest.get("client/");

            setUsers(response.data);
            setPartnerId(null);
        } catch (err) {
            console.log(err);
        }
    };

    const getOwnUsers = async () => {
        try {
            const response = await publicRequest.get(`client/getOwn`);

            setUsers(response.data);
            setPartnerId(partnerIdMain);
        } catch (err) {
            console.log(err);
        }
    };

    const getOtherUsers = async () => {
        try {
            const response = await publicRequest.get(`client/getOther`);

            setUsers(response.data);
            setPartnerId(partnerIdMain);
        } catch (err) {
            console.log(err);
        }
    };

    const handleUserEditInfo = (event, propsData) => {

        setSubmitOrEdit("edit");
        setIsOpen(true);
        setRegister(false);
    };

    const handleDelete = () => {
        setIsOpen(false);
        const deleteUser = async () => {
            try {
                await publicRequest.delete(`client/${modifiedData._id}`);
                setAction({
                    type: "deleted",
                    id: modifiedData._id,
                    data: {},
                });
            } catch (err) {
                console.log(err);
            }
        };

        Swal.fire({
            title: `Do you want to delete ${
                modifiedData.firstName + " " + modifiedData.lastName
            }?`,
            showDenyButton: true,
            confirmButtonText: "Accept",
            denyButtonText: `Refuse`,
        }).then((result) => {
            if (result.isConfirmed) {
                rowBackClicked();
                deleteUser();
            }
        });
    };

    const handleChoosenPartner = (id) => {
        const choosen = partners.find((x) => x._id === id);

        setPartnerId(choosen._id);
    };

    const handleInfo = (event, propsData) => {
        setIsOpen(true);
        setModalType("info");
        setInfoData(propsData);
    };

    const handleRowClick = (data) => {
        setModifiedData(data);
        setRowClicked(true);
        setRequestURL(`v2/clients/log?clientId=${data._id}`);
    };

    const rowBackClicked = () => {
        setRequestURL(`v2/clients`);
        setRowClicked(false);
        setModifiedData({
            firstName: "",
            lastName: "",
            zip: "",
            address: "",
            city: "",
            country: "",
            phone1: "",
            phone2: "",
            email: "",
        });
    };

    const successEdit = (params, data, navigate) => {
        setSuccess(true);
        setMessage(params);
        setTimeout(() => {
            if (navigate === "backToTable") {
                rowBackClicked();
            } else {
                handleRowClick(modifiedData);
            }
            setSuccess(false);
            setIsOpen(false);
        }, 2000);
    };

    return (
        <div
            className="clientsTable"
            style={{width: "100%", padding: 0, marginTop: "20px"}}
        >
            <div className="users">
                <div className="usersUserListContainer">
                    <MainModal
                        closable={true}
                        setIsOpen={setIsOpen}
                        setModifiedData={setModifiedData}
                        setEntity={setUsers}
                        setAction={setAction}
                        entity={users}
                        refetch={null}
                        deleteFunc={handleDelete}
                        deleteButton={true}
                        successEdit={successEdit}
                        success={success}
                        isOpen={isOpen}
                        submitOrEdit={submitOrEdit}
                        onRequestClose={closeModal}
                        closeModal={closeModal}
                        footer={true}
                        setRowClicked={setRowClicked}
                        user={user}
                        endpoint="client/register"
                        editEndpoint="client"
                        modifiedData={modifiedData}
                        content={
                            <div className="flex justify-between gap-10 flex-wrap">
                                <h3 className="usersTableRegisterClientFormTitle">
                                    {submitOrEdit === "submit"
                                        ? "Register New Client"
                                        : "Edit Client"}
                                </h3>

                                <Tabs className="w-full">
                                    <TabList style={{display: "flex"}}>
                                        <Tab style={{flex: 1}}>Level 1</Tab>
                                        <Tab style={{flex: 1}}>Level 2</Tab>
                                    </TabList>
                                    <TabPanel
                                        style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            gap: "0 25px",
                                        }}
                                    >
                                        <ClientRegisterFields
                                            onChange={(e) => handleChange(e)}
                                            partnerChange={handleChoosenPartner}
                                            modifiedData={modifiedData}
                                            partners={partners}
                                            setModifiedData={setModifiedData}
                                            user={user}
                                            success={success}
                                            message={message}
                                            submitOrEdit={submitOrEdit}
                                        />
                                    </TabPanel>
                                    <TabPanel
                                        style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            gap: "0 25px",
                                        }}
                                    >
                                        Level 2
                                    </TabPanel>
                                </Tabs>
                            </div>
                        }
                    />
                    {rowClicked && (
                        <div
                            className="flex items-center justify-between"
                            style={{
                                width: "calc(100% - 20px)",
                                overflowY: "scroll",
                                background: "white",
                                padding: "10px",
                                borderRadius: "8px 8px 0 0",
                                marginRight: "auto",
                            }}
                        >
                            <PanelInfo
                                modifiedData={modifiedData}
                                handleUserEditInfo={handleUserEditInfo}
                                success={success}
                            />
                        </div>
                    )}

                    <div
                        className="clientsTableMaterialTableContainer"
                        style={{width: "100%"}}
                    >
                        <MainTable
                            columns={rowClicked ? logColumns : mainColumns}
                            requestURL={requestURL}
                            noBorderRadius={rowClicked ? true : false}
                            setAction={setAction}
                            isSocketUpdatable={false}
                            action={action}
                            tableContainerRef={logTableRef}
                            tableHeight={
                                rowClicked ? "calc(100vh - 330px)" : "calc(100vh - 186px)"
                            }
                            onRowClick={handleRowClick}
                            renderTopToolbarCustomActions={
                                rowClicked
                                    ? [
                                        {
                                            type: "back",
                                            name: "Back",
                                            icon: <FaArrowLeft size={20}/>,
                                            onClick: () => {
                                                rowBackClicked();
                                            },
                                        },
                                    ]
                                    : [
                                        {
                                            type: "add",
                                            name: "Add",
                                            icon: <FaPlusCircle size={20}/>,
                                            onClick: () => {
                                                openModal("submit");
                                            },
                                        },
                                    ]
                            }
                            toolbarActions={{
                                search: true,
                                range: false,
                                advancedFilter: false,
                                columnsFilter: true,
                                columnsVisible: true,
                                density: true,
                                fullScreen: true,
                            }}
                        />
                    </div>

                    {details && <ClientDetail client={client} setDetails={setDetails}/>}
                </div>
            </div>
        </div>
    );
};

export default ClientsTable;

