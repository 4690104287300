import React, {useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import "./ReusableHeader.css";
import {SocketContext} from "../../context/socket";
import {NumberFormatter} from "./NumberFormat";

function ReusableHeader() {
    const user = useSelector((state) => state.user);
    const accounting = user.accounting
    const socket = useContext(SocketContext);

    const [cryptoBalance, setCryptoBalance] = useState(0);
    const [fiatBalance, setFiatBalance] = useState(0);
    const [rates, setRates] = useState([]);

    const details = user?.currentUser.details;

    const calculateFiatBalance = async () => {
        if (rates.length) {
            let balance = 0;
            await Promise.all(accounting.map((account) => {
                const rate = rates.find((rr) => rr.symbol === account.currency.symbol);
                balance = parseFloat(balance) + (parseFloat(account.balance) / parseFloat(rate.rate))
            }))
            setFiatBalance(balance.toFixed(2));
        }
    }

    const calculateCryptoBalance = () => {
        let total = details?.crypto_balance?.filter(
            (balance) => balance.name === "total"
        );
        const rate = rates.find((rr) => rr.symbol === 'USD');
        let balance = parseFloat(total[0]?.balance["USDT"] / rate.rate).toFixed(2);

        if (!isNaN(balance)) {
            setCryptoBalance(balance);
        }
    };

    useEffect(() => {
        if (rates.length) {
            calculateFiatBalance()
            calculateCryptoBalance();
        }
    }, [rates, user])

    useEffect(() => {
        if (socket) {
            socket.fiatPrice((fiat) => {
                setRates(fiat);
            });
        }
    }, [socket]);

    const data = [
        {
            id: 1,
            title: "AVAILABLE",
            subtitle: "USD Tether",
            formatter: true,
            info: `${cryptoBalance} €`,
        },
        {
            id: 2,
            title: "Exchanges",
            subtitle: "Today",
            formatter: false,
            info: details?.total_transaction,
        },
        {
            id: 3,
            title: "Profit",
            subtitle: "Today",
            formatter: true,
            info: `${details?.profit} €`,
        },
        {
            id: 4,
            title: "Clients",
            subtitle: "Today",
            formatter: false,
            info: details?.total_clients,
        },
        {
            id: 5,
            title: "Balance",
            subtitle: "Estimate Balance",
            formatter: true,
            info: `${fiatBalance} €`,
        },
    ];

    return (
        <div className="Header">
            {data.map((header) => (
                <div key={header.id} className="HeaderItems">
                    <h4 className="HeaderTitle">{header.title}</h4>
                    <p className="HeaderSubtitle">{header.subtitle}</p>
                    <div className="HeaderLine"></div>
                    <span className="HeaderInfo">
            {header.formatter ? (
                <NumberFormatter value={header.info} suffix=" €"/>
            ) : (
                header.info
            )}
          </span>
                </div>
            ))}
        </div>
    );
}

export default ReusableHeader;

