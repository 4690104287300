import { Paper } from "@material-ui/core";
import React from "react";
import ReusableStatusComponent from "../../..//ReusableStatusComponent";
import generateURL from "../../../../../utils/explorer";

function TransactionInfo({ data }) {
  return (
    <Paper
      variant="outlined"
      style={{ maxWidth: "100%", marginTop: "2em", paddingBottom: "0.5em", marginBottom: "10px" }}
    >
      <center>
        <div
          style={{
            borderBottom: "1px solid lightgrey",
            maxWidth: "95%",
            marginBottom: "2em",
            paddingTop: "0.5em",
            paddingBottom: "0.5em",
          }}
        >
          <span style={{ fontSize: "16px", fontWeight: "600" }}>
            Transaction Info
          </span>
        </div>
      </center>

      <div
        style={{
          display: "grid",
          placeItems: "center",
          margin: "0 2.5%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
            alignItems: "start",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "50%",
            }}
          >
            <span
              style={{
                fontWeight: "400",
                color: "#666666",
                fontSize: "14px",
                textAlign: "center",
                lineHeight: "1.5",
              }}
            >
              Address To
            </span>
            <span
              style={{
                overflow: "hidden",
                fontWeight: "600",
                textOverflow: "ellipsis",
                // color: "rgba(0, 0, 0, 0.87)",
                fontSize: "14px",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
              <a
                style={{
                  color: data?.address_to?.is_manual
                    ? "red"
                    : "rgb(57, 140, 249)",
                }}
                className="custom-link"
                href={generateURL(
                  data?.network,
                  "address",
                  data?.address_to?.address || ""
                )}
                target="_blank"
              >
                {data?.address_to?.address || ""}
              </a>
            </span>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <span
              style={{
                fontWeight: "400",
                color: "#666666",
                fontSize: "14px",
                textAlign: "center",
                lineHeight: "1.5",
              }}
            >
              Address From
            </span>

            <span
              style={{
                overflow: "hidden",
                fontWeight: "600",
                textOverflow: "ellipsis",
                color: "rgba(0, 0, 0, 0.87)",
                fontSize: "14px",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
              <a
                className="custom-link"
                href={generateURL(
                  data?.network,
                  "address",
                  data?.address_from?.address || ""
                )}
                target="_blank"
              >
                {data?.address_from?.address || ""}
              </a>
            </span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
            alignItems: "start",
            marginTop: "30px",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <span
              style={{
                fontWeight: "400",
                color: "#666666",
                fontSize: "14px",
                textAlign: "center",
                lineHeight: "1.5",
              }}
            >
              Tx ID
            </span>
            <span
              style={{
                overflow: "hidden",
                fontWeight: "600",
                textOverflow: "ellipsis",
                color: "rgba(0, 0, 0, 0.87)",
                fontSize: "14px",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
              <a
                className="custom-link"
                href={generateURL(data.network, "transaction", data.txId)}
                target="_blank"
              >
                {data.txId}
              </a>
            </span>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <span
              style={{
                fontWeight: "400",
                color: "#666666",
                fontSize: "14px",
                textAlign: "center",
                lineHeight: "1.5",
              }}
            >
              Status
            </span>
            <span
              style={{
                overflow: "hidden",
                fontWeight: "600",
                textOverflow: "ellipsis",
                color: "rgba(0, 0, 0, 0.87)",
                fontSize: "14px",
                lineHeight: "1.5",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ReusableStatusComponent status={data.status} />
            </span>
          </div>
        </div>
      </div>
    </Paper>
  );
}

export default TransactionInfo;

