import print from '../printer';
import moment from "moment";
import {Cut, Image, Line, Printer, QRCode, Row, Text} from 'react-thermal-printer';
import iconGenerator from "../iconGenerator";


export const cryptoPrint = async (order) => {

    const transactionId = order._id;
    const symbols = order.symbol.split('/');

    const TXCode = btoa((JSON.stringify({id: transactionId, type: 'crypto'})));

    await print(
        <Printer type="epson" width={46} characterSet="iso8859_2_latin2">
            <Text bold={true} align={'center'}
                  size={{width: 1, height: 1}}>YOU {order.side.toUpperCase() === 'SELL' ? 'BOUGHT' : 'SOLD'}</Text>
            <Text bold={true} align={'center'} size={{width: 1, height: 1}}>{symbols[0]}</Text>
            <Image src={iconGenerator(64, null, symbols[0])} align={"center"}/>
            <Line/>
            <Row left={'Date'} right={moment(order.createdAt).format('DD.MM.Y')}/>
            <Row left={<Text bold={true}>Order ID</Text>} right={<Text>{order.order_number}</Text>}/>
            <Line/>
            <Row left={"Amount"} right={`${order.quantity_sent ?? order.quantity} ${symbols[0]}`}/>
            <Row left={"Equvalent To"}
                 right={`${parseFloat(order.quantity_sent * order.last_price_filled).toFixed(2)} USD`}/>
            <Line/>
            <Row left={<Text bold={true}>Description</Text>} right={<Text bold={true}>Price</Text>}/>
            <Row left={`Last Price ${symbols[0]}`} right={`${order.last_price_filled} USD`}/>
            {order.side.toUpperCase() === 'SELL' && (<Row left={`Chain Fee ${order.network_fee} ${symbols[0]}`}
                                                          right={`${(order.network_fee * order.last_price_filled).toFixed(2)} USD`}/>)}
            <Row left={'Chain Network'} right={`${order.network}`}/>
            <Row left={<Text bold={true}>Fee %</Text>} right={<Text>{order.value_fee.fee} %</Text>}/>
            <Line/>
            <Row left={<Text bold={true}>Currency</Text>} right={<Text bold={true}>Value</Text>}/>
            {order.exchange_rate.map((rates) => (
                <Row left={`${rates.symbol} ${rates.rate}`}
                     right={`${parseFloat(rates.price).toFixed(2)} ${rates.symbol}`}/>
            ))
            }
            <Row left={<Text bold={true}>Total</Text>}
                 right={<Text bold={true}>{parseFloat(order.fiat_payment).toFixed(2)} EUR</Text>}/>
            <Line/>
            <QRCode cellSize={6} model={'model2'} align={"center"} content={TXCode}/>
            <Text bold={true} align={'center'}>Receiving address</Text>
            <Text align={'center'}>{order.address_to.address}</Text>
            <Line/>
            <Text align={"center"}>Thanks for using our service</Text>
            <Cut lineFeeds={4}/>
        </Printer>
    );
}
