import React, { Fragment, useEffect, useState } from "react";
import { publicRequest } from "../../../utils/requestMethods";
import { ClipLoader } from "react-spinners";

// const balanceDemo = [
//   {
//     partnerName: "Tresor 1",
//     balances: [
//       {
//         exchange: "Binance",
//         balance: {
//           BUSD: 0.00012282,
//           dasdw: 0.74731586,
//           das: 0.74731586,
//           wdad: 0.74731586,
//           LDBdssadcUSD: 0.74731586,
//           csa: 0.74731586,
//           xasx: 0.74731586,
//           asx: 0.74731586,
//         },
//       },
//       {
//         exchange: "Huobi",
//         balance: {
//           USDT: 0.10012282,
//           BTC: 0.74731586,
//         },
//       },
//       {
//         exchange: "DEX",
//         balance: {
//           USDT: 200.2,
//           TRX: 128.9,
//         },
//       },
//     ],
//   },
//   {
//     partnerName: "TresorX Nastel",
//     balances: [
//       {
//         exchange: "Binance",
//         balance: {
//           BUSD: 0.00012282,
//           LDBUSD: 0.74731586,
//         },
//       },
//     ],
//   },
//   {
//     partnerName: "TresorX",
//     balances: [
//       {
//         exchange: "Binance",
//         balance: {
//           BUSD: 0.00012282,
//           LDBUSD: 0.74731586,
//         },
//       },
//     ],
//   },
// ];

function Balances() {
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getPartners = async () => {
      try {
        setLoading(true);
        const response = await publicRequest.get("exchanges/partner/balances/");

        setPartners(response.data);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    getPartners();
  }, []);
  return (
    <Fragment>
      <div
        className="flex flex-column justify-start align-start w-full"
        style={{ padding: "10px", maxHeight: 400, overflowY: "scroll" }}
      >
        {loading ? (
          <div
            className="grid h-full w-full place-center"
            style={{ padding: "20px 0" }}
          >
            <ClipLoader color="orange" size={24} />
          </div>
        ) : (
          <>
            {partners.length < 1 ? (
              <div
                className="grid h-full w-full place-center"
                style={{ padding: "20px 0" }}
              >
                There are no partners available!
              </div>
            ) : (
              <>
                {partners?.map((item, i) => (
                  <>
                    <div
                      className="flex flex-column w-full gap-5"
                      style={{ marginBottom: "20px" }}
                    >
                      <span
                        style={{
                          color: "orange",
                          fontWeight: "600",
                          fontSize: "16px",
                          padding: "0 10px",
                        }}
                      >
                        {item.partnerName}
                      </span>
                      <div
                        className="grid"
                        style={{
                          width: "100%",
                          maxWidth: "85%",
                          paddingBottom: "10px",
                          margin: "0 auto",
                          borderBottom: "1px solid #d7d7d7",
                        }}
                      >
                        {item.balances.length > 0 ? (
                          <>
                            {item.balances?.map((exch) => (
                              <>
                                <span
                                  className="border-not-first"
                                  style={{
                                    fontSize: "14px",
                                    marginBottom: "5px",
                                    marginTop: "10px",
                                    textAlign: "center",
                                  }}
                                >
                                  {exch?.exchange || ""}
                                </span>
                                {Object.entries(exch.balance).map((x) => (
                                  <div
                                    className="flex items-center justify-between"
                                    style={{ padding: "0 0 0 10px" }}
                                  >
                                    <span style={{ fontSize: "12px" }}>
                                      {x[0]}
                                    </span>
                                    <span style={{ fontSize: "12px" }}>
                                      {x[1]}
                                    </span>
                                  </div>
                                ))}
                              </>
                            ))}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </>
                ))}
              </>
            )}
          </>
        )}
      </div>
    </Fragment>
  );
}

export default Balances;

