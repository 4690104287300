import React, {useEffect, useRef, useState} from "react";
import iconGenerator from "../../utils/iconGenerator";
import {AiOutlineMinusSquare} from "react-icons/ai";
import {MdLibraryAdd} from "react-icons/md";
import {useSelector} from "react-redux";
import {debounce} from "lodash";

function CurrencyOption({
                            currencyList,
                            availableCurrencyList,
                            setCurrencyList,
                            setAvailableCurrencyList,
                            exchangeRate,
                            reverseCurrency = false,
                            choosenOrderType = 'buy',
                            autoTrade = false
                        }) {
    const user = useSelector((state) => state.user);
    const currenciesRates = user.currencies;
    const [rateError, setRateError] = useState(null);

    useEffect(() => {
        setTimeout(() => {
            currenciesList();
        }, 300);
    }, [user.currencies]);

    const currenciesList = () => {
        let currencies = [
            {
                symbol: "EUR",
                name: "Euro",
                rate: 0,
                price: 0,
                buying_rate: 0,
                selling_rate: 0,
                buying_rate_fee: 0,
                selling_rate_fee: 0,
                status: true,
                manualPrice: false,
                manualRate: false,
                default: true,
                changeRate: false,
                changePrice: false,
                attached: false,
            },
        ];

        if (exchangeRate) {
            currencies = exchangeRate;
        } else if (currenciesRates.length) {
            currencies = currenciesRates;
        }
        let availableCurrencies = currencies.map((currency) => {
            return {
                symbol: currency.symbol || currency.currency.symbol,
                rate: parseFloat(currency.rate).toFixed(4) || parseFloat(currency.currency.rate).toFixed(4),
                buying_rate: currency?.buying_rate || currency?.currency.buying_rate,
                selling_rate: currency?.selling_rate || currency?.currency.selling_rate,
                buying_rate_fee: currency?.buying_rate_fee || (currency?.currency?.buying_rate_fee ?? 0),
                selling_rate_fee: currency?.selling_rate_fee || (currency?.currency?.selling_rate_fee ?? 0),
                price: 0,
                manualPrice: false,
                manualRate: false,
                changeRate: false,
                changePrice: false,
                attached: false,
            };
        });
        setAvailableCurrencyList(availableCurrencies);
    };

    const handlePriceChange = (event) => {
        const list = currencyList.map((currency) => {
            if (currency.symbol === event.target.name) {
                currency.price = event.target.value;
                currency.manualPrice = true;
            }
            return currency;
        });
        setCurrencyList(list);
    };


    const checkRateLimit = useRef(debounce(({currencyList, choosenOrderType, lastRatePriceBySymbol, setRateError}) => {
        const newCurrency = currencyList.map((currency) => {
            if (choosenOrderType.toLowerCase() === 'sell') {
                let lastRate = lastRatePriceBySymbol(currency.symbol, 'min');
                if (currency.rate < lastRate) {
                    setRateError({
                        [currency.symbol]: {
                            message: `${currency.symbol} rate cannot be under ${lastRate}`
                        }
                    });

                    setTimeout(() => {
                        currency.rate = lastRate;
                        currency.manualRate = false;
                        setRateError({
                            ...rateError, ...{
                                [currency.symbol]: {
                                    message: null,
                                }
                            }
                        })
                    }, 1000);
                }
            } else {
                let lastRate = lastRatePriceBySymbol(currency.symbol, 'max');
                if (currency.rate > lastRate) {
                    setRateError({
                        [currency.symbol]: {
                            message: `Rate cannot be over ${lastRate}`
                        }
                    });

                    setTimeout(() => {
                        currency.rate = lastRate;
                        currency.manualRate = false;
                        setRateError({
                            ...rateError, ...{
                                [currency.symbol]: {
                                    message: null,
                                }
                            }
                        });
                    }, 1000);
                }
            }
            return currency;
        });
        setCurrencyList(newCurrency);
    }, 1000)).current;

    useEffect(() => {
        checkAvailable();
    }, [currencyList]);

    const handleRateChange = (event) => {
        const list = currencyList.map((currency) => {
            if (currency.symbol === event.target.name) {
                currency.rate = event.target.value;
                currency.manualRate = true;
            }
            return currency;
        });
        setCurrencyList(list);
        checkRateLimit({currencyList, choosenOrderType, lastRatePriceBySymbol, setRateError, setCurrencyList})
    };

    const handleCurrencySelect = async (event, index) => {
        const symbol = event.target.value;
        const currency = availableCurrencyList.find((cur) => cur.symbol === symbol);

        if (currency) {
            const newValue = {
                symbol: currency.symbol,
                rate: parseFloat(currency?.rate).toFixed(4),
                buying_rate: currency?.buying_rate || currency?.currency.buying_rate,
                selling_rate: currency?.selling_rate || currency?.currency.selling_rate,
                buying_rate_fee: (currency?.buying_rate_fee) ? currency?.buying_rate_fee ?? 0 : currency?.currency?.buying_rate_fee ?? 0,
                selling_rate_fee: (currency?.selling_rate_fee) ? currency?.selling_rate_fee ?? 0 : currency?.currency?.selling_rate_fee ?? 0,
                price: 0,
                manualPrice: false,
                manualRate: false,
                changeRate: false,
                changePrice: false,
                attached: false,
            };
            const list = [...currencyList, newValue];
            list.splice(index, 1);
            setCurrencyList(list);
        }
    };

    const handleRemoveClick = (index) => {
        const list = [...currencyList];
        list.splice(index, 1);
        list.map((ll) => {
            ll.manualPrice = false
        })
        setCurrencyList(list);
    };

    const handleAddClick = () => {
        const availableCurrency = availableCurrencyList.filter(
            (cur) => cur.attached === false
        );
        if (availableCurrency.length) {
            setCurrencyList([
                ...currencyList,
                {
                    symbol: availableCurrency[0].symbol,
                    rate: Number(availableCurrency[0].rate).toFixed(4),
                    price: 0,
                    manualPrice: false,
                    manualRate: false,
                    changeRate: false,
                    changePrice: false,
                    attached: false,
                },
            ]);
        }
    };

    const checkAvailable = () => {
        const available = availableCurrencyList.map((cur) => {
            let isAvailable = currencyList.filter(
                (list) => list.symbol === cur.symbol
            );
            cur.attached = !!isAvailable.length;
            return cur;
        });
        setAvailableCurrencyList(available);
    };

    const getFreeCurrencyList = () => {
        return availableCurrencyList.filter(
            (currency) => currency.attached === false
        );
    };

    const handleRateFocus = (event, write = true) => {
        currencyList.map((currency) => {
            if (currency.symbol === event.target.name) {
                currency.changeRate = write;
            }
        });
        if (write) {
            event.target.select();
        }
    };

    const handlePriceFocus = (event, write = true) => {
        currencyList.map((currency) => {
            if (currency.symbol === event.target.name) {
                currency.changePrice = write;
            }
        });
        if (write) {
            setTimeout(() => {
                event.target.select();
            }, 100);
        }
    };

    const handleAutoRate = (event) => {
        currencyList.map((currency) => {
            if (currency.symbol === event.target.name) {
                currency.changeRate = false;
                currency.manualRate = false;
            }
        });
    };

    const lastRatePriceBySymbol = (symbol, type) => {
        let currency = availableCurrencyList.find(acl => acl.symbol === symbol);

        if (typeof currency === 'undefined') {
            return type === 'min' ? 0 : 10000;
        }

        if (type === 'min') {
            return choosenOrderType.toLowerCase() === 'sell' ? parseFloat(currency.rate) : 0;
        } else {
            return choosenOrderType.toLowerCase() === 'sell' ? 10000 : parseFloat(currency.rate);
        }
    }

    return (
        <>
            {currencyList.map((x, i) => (
                <>
                    <div className={`secondSliderRightConTwoFlex`} key={i}>

                        <div className="dashboardSliderFirstRightExchangeCurrencyLeftwrapper">
                            <img
                                className="secondSlderFirstSelectCoinIcon"
                                style={{left: "5px"}}
                                src={iconGenerator(128, "icon", x.symbol, true)}
                                alt=""
                            />
                            <span
                                className="secondSlderFirstSelectCoinSpan"
                                style={{position: "absolute", left: "45px"}}
                            >
                {i === currencyList.length - 1 &&
                currencyList.length < availableCurrencyList.length ? (
                    <select
                        style={{
                            height: "100%",
                            borderColor: "#d8d8d8",
                            marginBottom: "0",
                            width: "70px",
                        }}
                        onChange={(event) => handleCurrencySelect(event, i)}
                    >
                        <option value={x.symbol}>{x.symbol}</option>
                        {getFreeCurrencyList().map((currency) =>
                            currency.symbol !== x.symbol ? (
                                <option value={currency.symbol}>
                                    {currency.symbol}
                                </option>
                            ) : (
                                <></>
                            )
                        )}
                    </select>
                ) : (
                    x.symbol
                )}
              </span>
                        </div>
                        {(rateError && rateError[x.symbol]) && (
                            <span style={{
                                left: autoTrade ? "150px" : "155px",
                                fontSize: 12,
                                color: 'red',
                                position: "absolute",
                                top: '90%',
                            }}>{rateError[x.symbol]?.message}</span>)}
                        <div className="secondSliderFirstInputWrapper">
                            <input
                                type="text"
                                name={x.symbol}
                                value={x.rate}
                                onChange={(event) => handleRateChange(event)}
                                onFocus={(event) => {
                                    setRateError(null)
                                    handleRateFocus(event, true);
                                    setTimeout(() => {
                                        event.target.select();
                                    }, 100);
                                }}
                                onBlur={(event) => handleRateFocus(event, false)}
                                onDoubleClick={(event) => handleAutoRate(event)}
                                className="dashboardSliderSecondRightExchangeCurrencyValueSpanFirst"
                            />
                            {/*<span style={{fontSize:12}}>{parseFloat(1 / x.rate).toFixed(4)}</span>*/}
                            <input
                                className="dashboardSliderSecondRightExchangeCurrencyValueSpan"
                                type="text"
                                name={x.symbol}
                                value={x.price}
                                readOnly={((currencyList.length === 1 || i === currencyList.length - 1) && !reverseCurrency)}
                                onChange={(event) => {
                                    handlePriceChange(event);
                                }}
                                onFocus={(event) => handlePriceFocus(event, true)}
                                onBlur={(event) => handlePriceFocus(event, false)}
                            />
                        </div>
                        <div
                            className="btn-box"
                            style={{position: "absolute", right: "8px", top: "5px"}}
                        >
                            {currencyList.length !== 1 && (
                                <button
                                    className="mr10 normal-btn cursor-pointer"
                                    onClick={() => handleRemoveClick(i)}
                                >
                                    <AiOutlineMinusSquare
                                        color="#8a8a8a"
                                        size={16}
                                        style={{background: "white"}}
                                    />
                                </button>
                            )}
                        </div>
                        <div style={{position: "absolute", top: "70px", left: "110px"}}>
                            {currencyList.length - 1 === i &&
                                currencyList.length < availableCurrencyList.length && (
                                    <button
                                        className="cursor-pointer add-button normal-btn"
                                        onClick={handleAddClick}
                                    >
                                        <MdLibraryAdd color="#8a8a8a" size={18}/>
                                    </button>
                                )}
                        </div>
                    </div>


                </>
            ))}
        </>
    );
}

export default CurrencyOption;

