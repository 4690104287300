import {FaCheck} from "react-icons/fa";
import React from "react";

const Success = ({type}) => {
    return (
        <div className="flex flex-column items-center justify-center content-center-element">
            <FaCheck
                size={40}
                color="white"
                style={{
                    background: "#25da25",
                    padding: "10px",
                    borderRadius: "50%",
                }}
            />
            <div style={{fontWeight: "300", fontSize: "14px", margin: "5px 0"}}>
                Success {type}
            </div>
        </div>
    )
}


export default Success;
