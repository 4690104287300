import React from "react";
import Details from "./Content/Details";
import TradingInfo from "./Content/TradingInfo";
import TransactionInfo from "./Content/TransactionInfo";

function Content({ data }) {
    return (
        <div className="big-modal">
            <Details data={data} />
            <TradingInfo data={data} />
            <TransactionInfo data={data} />
        </div>
    );
}

export default Content;

