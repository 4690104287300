import React, {useEffect, useMemo, useRef, useState} from "react";
import MainTable from "../../../ReusableComponents/Tables/MRT_Main";
import moment from "moment/moment";
import {capitalizeWords} from "../../../../utils/utils";
import iconGenerator from "../../../../utils/iconGenerator";
import {NumberFormatter} from "../../../ReusableComponents/NumberFormat";
import ReusableStatusComponent from "../../../ReusableComponents/ReusableStatusComponent";
import MainModal from "../../../ReusableComponents/MainModal";
import {useSelector} from "react-redux";
import {checkAccountingAmount, checkAccountingBalance} from "../../../../utils/calculation";
import AccountingContent from "../../../ReusableComponents/PopUpInfo/Accounting/AccountingContent";
import {TransferStatus} from "../../../ReusableComponents/Status/TransferStatus";
import {MenuItem, TextField} from "@mui/material";
import {sortingTypes} from "../../../../utils/sorting";
import {BiExport} from "react-icons/bi";
import {publicRequest} from "../../../../utils/requestMethods";
import qs from "qs";
import Moment from "moment/moment";

function Transactions({fiat, customHeight, onlyTable}) {
    const [isOpenPopUp, setIsOpenPopUp] = useState(false);
    const [tableButtons, setTableButtons] = useState([]);
    const popUpInformation = useRef("");
    const user = useSelector((state) => state.user.currentUser);
    const params = useSelector((state) => state.user.params);

    const columns = useMemo(
        () => [
            {
                accessorKey: "ref_number",
                header: "ID",
                size: 50,
                muiTableHeadCellProps: () => ({
                    sx: {
                        paddingLeft: "25px",
                    },
                }),
                muiTableBodyCellProps: () => ({
                    sx: {
                        paddingLeft: "25px",
                    },
                }),
                Cell: ({cell}) => {
                    return <span>{cell.getValue()}</span>;
                },
            },
            {
                accessorKey: "createdAt",
                header: "Time",
                muiTableBodyCellProps: {
                    align: "center",
                },
                size: 100,
                Cell: ({cell}) => {
                    return (
                        <span>{moment(cell.getValue()).format("DD.MM.YYYY HH:mm")}</span>
                    );
                },
            },
            {
                accessorKey: "clientId",
                header: "Client",
                muiTableBodyCellProps: {
                    align: "center",
                },
                size: 70,
                accessorFn: (e) => {
                    return `${capitalizeWords(e.clientId?.firstName) ?? ''} ${capitalizeWords(e.clientId?.lastName) ?? ''}`;
                },
                filterSelectOptions: params.filters.global.clients.filterSelectOptions,
                filterVariant: params.filters.global.clients.filterVariant,
            },
            {
                accessorKey: "type",
                header: "Type",
                size: 85,
                Cell: ({cell}) => {
                    return capitalizeWords(cell.getValue());
                },
                filterSelectOptions: params.filters.accounting.type.filterSelectOptions,
                filterVariant: params.filters.accounting.type.filterVariant,
            },
            {
                accessorKey: "side",
                header: "Side",
                size: 80,
                filterSelectOptions: params.filters.accounting.side.filterSelectOptions,
                filterVariant: params.filters.accounting.side.filterVariant,
                accessorFn: (e) => {
                    return e.side?.toUpperCase() === "BUY" || e.side?.toUpperCase() === "SELL" ?
                        <span
                            style={{
                                color: e.side?.toUpperCase() === "BUY" ? "lightgreen" : "red",
                                textTransform: "uppercase"
                            }}
                        >
                            {e.side}
                        </span>
                        :
                        <TransferStatus side={e.side} transaction={e} partner={user.partnerId}/>
                },
            },
            {
                accessorKey: "details",
                header: "Details",
                size: 70,
                accessorFn: (e) => (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "4px",
                        }}
                    >
                        <img
                            src={iconGenerator(128, "icon", e.details, e.type !== "crypto")}
                            style={{height: "20px"}}
                            alt={e.details}/>
                        {e.details}
                    </div>
                ),
                filterSelectOptions: params.filters.accounting.details.filterSelectOptions,
                filterVariant: params.filters.accounting.details.filterVariant,
            },
            {
                accessorKey: "amount",
                header: "Amount",
                size: 150,
                accessorFn: (e) => <NumberFormatter
                    value={e.amount}
                    suffix={` ${e.currency?.symbol}`}
                    prefix={`${checkAccountingAmount(e)}`}
                    style={{color: checkAccountingAmount(e) == "-" ? "red" : "green"}}
                />,
                filterVariant: 'range',
                filterFn: 'between',
            },
            {
                accessorKey: "balance",
                header: "Balance",
                size: 150,
                accessorFn: (e) => <NumberFormatter
                    value={e.balance}
                    suffix={` ${e.currency?.symbol}`}
                    prefix={`${checkAccountingBalance(e)}`}
                />,
            },
            {
                accessorKey: "status",
                header: "Status",
                size: 80,
                filterSelectOptions: [
                    {text: "Pending", value: "1"},
                    {text: "Approved", value: "2"},
                    {text: "In Progress", value: "3"},
                    {text: "Completed", value: "4"},
                    {text: "Declined", value: "5"},
                ],
                filterVariant: "multi-select",
                accessorFn: (e) => <ReusableStatusComponent status={e.status}/>,
            },
        ],
        []
    );

    if (!columns.some(item => item.header === "Partner")) {
        (user.isAdmin || user.isMainOffice) && columns.splice(2, 0, {
            accessorKey: "partnerId",
            header: "Partner",
            size: 60,
            accessorFn: (e) =>
                e.partnerId ? e.partnerId?.partnerName : ""
            ,
            filterSelectOptions: params.filters.global.partners.filterSelectOptions,
            filterVariant: params.filters.global.partners.filterVariant,
        })
    }

    const handleInfo = (data) => {
        popUpInformation.current = data;
        setIsOpenPopUp(true);
    };

    useEffect(() => {
        if (user.isAdmin || user.isMainOffice) {
            setTableButtons([{
                type: "export",
                name: "Export",
                icon: <BiExport size={20}/>,
                onClick: () => handleExport(),
            }])
        }
    }, []);

    const handleExport = async () => {
        const filters = tableContainerRef.current.getState().columnFilters;
        console.log(filters);
        const response = await publicRequest.get(`/v2/reports/accounting?${qs.stringify(filters)}`, {responseType: 'blob'});
        const url = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        const date = new Date();
        link.download = `Accounting_Report_${Moment(date).format('DD_MM_Y_HH_mm')}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }

    const tableContainerRef = useRef();
    return (
        <div style={{marginTop: 20}}>
            <MainModal
                closeOnEsc={true}
                closable={true}
                isOpen={isOpenPopUp}
                onRequestClose={() => setIsOpenPopUp(false)}
                closeModal={() => setIsOpenPopUp(false)}
                content={
                    <>
                        <AccountingContent
                            data={popUpInformation.current}
                            setIsOpen={setIsOpenPopUp}
                            noPrint={true}
                        />
                    </>
                }
            />
            <MainTable
                requestURL={"accounting/transaction"}
                columns={columns}
                tableHeight="85vh"
                density={'compact'}
                onRowClick={handleInfo}
                tableContainerRef={tableContainerRef}
                customHeight={'calc(100vh - 186px)'}
                renderTopToolbarCustomActions={tableButtons}
            />
        </div>
    );
}

export default Transactions;

