import {QRCodeSVG} from "qrcode.react";
import AuthCode from "react-auth-code-input";
import Button from "../../Button";
import React from "react";


const TwoFaActive = ({qrCode, handleOnChange, handleSubmit, code}) => {
    return (
        <>
            <span style={{
                display: "block",
                margin: "0 auto",
                textAlign: "center"
            }}>Two-Factor Authentication Activation</span>


            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 20,
                    padding: 20,
                    flexDirection: 'column'
                }}
            >
                <QRCodeSVG value={qrCode.otpauth_url} size={200}></QRCodeSVG>
                <span>Cannot scan it?</span>

                <h3>Enter your six-digit code below</h3>
                <div style={{padding: 16}}>
                    <AuthCode
                        onChange={handleOnChange}
                        inputClassName={'two-fa-input'}
                        allowedCharacters={"numeric"}
                    />
                </div>

                <Button
                    title={"Activate"}
                    disabled={!code.length}
                    onClick={handleSubmit}
                    cls=""
                />
            </div>
        </>
    )
}

export default TwoFaActive;