import Modal from "react-modal";
import {FaSave, FaTimes} from "react-icons/fa";
import React, {useEffect, useState} from "react";
import {QRCodeSVG} from "qrcode.react";
import AuthCode from 'react-auth-code-input';
import Button from "../../Button";
import {publicRequest} from "../../../../utils/requestMethods";
import Success from "../Messages/success";
import Failed from "../Messages/failed";
import TwoFaActive from "./Active";
import TwoFaDeactivate from "./Deactive";
import {useDispatch} from "react-redux";
import {update2FAAuth} from "../../../../redux/userRedux";

const TwoFAModal = ({isOpen, handleCloseModal, isActive, setIsActive}) => {
    const [qrCode, setQrCode] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [code, setCode] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        setQrCode("");
        setSuccess(false);
        setError(false);
        setCode("");
    }, []);

    const handleOnChange = (res) => {
        if (res.length === 6) {
            setCode(res);
        }
    };

    const getAuthOTP = () => new Promise(async (resolve) => {
        await publicRequest.get('/user/activate/2fa').then(response => {
            if (response.status === 200) {
                resolve(response.data);
            }
        })
    });


    const submitTwoFaActivation = () => new Promise(async (resolve, reject) => {
        await publicRequest.post('/user/activate/2fa', {...qrCode, ...{code: code}}).then(response => {
            if (response.status === 200) {
                setSuccess(true);
                dispatch(update2FAAuth(response.data.secret));
                setIsActive(!isActive);
                resolve(response.data);
            }
        })
    });

    const submitTwoFaDeactivation = () => new Promise(async (resolve, reject) => {
        await publicRequest.post('/user/deactivate/2fa', {code: code}).then(response => {
            if (response.status === 200) {
                setSuccess(true);
                dispatch(update2FAAuth(false));
                setIsActive(!isActive);
                resolve(response.data);
            }
        })
    });


    const handleSubmitActivate = () => {
        submitTwoFaActivation().then((result) => {
            setError(false);

            setTimeout(() => {
                handleCloseModal();
            }, 2000)
        }).catch((reason) => {
            setError(true);
        })
    }

    const handleSubmitDeactivate = () => {
        submitTwoFaDeactivation().then((result) => {
            setError(false);
            setTimeout(() => {
                handleCloseModal();
            }, 2000)
        }).catch((reason) => {
            setError(true);
        })
    }

    useEffect(() => {
        if (isOpen && !isActive) {
            getAuthOTP().then(result => setQrCode(result));
        }
    }, [isOpen]);
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleCloseModal}
            className={`CurrenciesModel`}
            overlayClassName="CurrenciesModel-overlay"
            animationType="slide"
        >
            <div className="modalClose" onClick={handleCloseModal}>
                <FaTimes size={20}/>
            </div>
            {success ? (<>
                    {!error ?
                        (<Success type={"2FA Activation"}/>) :
                        (<Failed type={"2FA Activation"} message={""}/>)
                    }
                </>) :
                (
                    <>
                        {!isActive ? <TwoFaActive
                            code={code} qrCode={qrCode}
                            handleOnChange={handleOnChange}
                            handleSubmit={handleSubmitActivate}
                        /> : <TwoFaDeactivate
                            code={code}
                            handleOnChange={handleOnChange}
                            handleSubmit={handleSubmitDeactivate}/>}
                    </>
                )

            }
        </Modal>
    )
}


export default TwoFAModal;