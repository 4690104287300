import React, {Fragment, useEffect, useRef, useState} from "react";
import {publicRequest} from "../../../utils/requestMethods";
import {NumberFormatter} from "../../ReusableComponents/NumberFormat";
import {getSessionStorage} from "../../../utils/utils";

function Statistics() {
    let interval = null;
    const [stats, setStats] = useState({});

    const getShortStats = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const url = new URL(`v2/reports/short_statistic`, process.env.REACT_APP_DEV_URL);
                url.searchParams.set("filters", JSON.stringify(getSessionStorage('date_range', [])));
                url.searchParams.set("partners", JSON.stringify(getSessionStorage('partner_id', [])))
                const {status, data} = await publicRequest.get(url.href);
                if (status === 200) {
                    resolve(data);
                }
            } catch (e) {
                console.log(e);
            }
        })
    }

    useEffect(() => {
        if (_.isEmpty(stats)) {
            getShortStats().then((result) => {
                setStats(result);
            })
        }

        if (!interval) {
            interval = setInterval(() => {
                getShortStats().then((result) => {
                    setStats(result);
                });
            }, 5000)
        }

        return () => {
            clearInterval(interval);
        }
    }, []);
    return (
        <Fragment>
            <div
                className="flex flex-column justify-start align-start w-full"
                style={{padding: "10px", fontSize: "14px"}}
            >
                <h4
                    style={{
                        textAlign: "center",
                        fontWeight: "600",
                        marginBottom: "10px",
                        width: "100%",
                    }}
                >
                    Crypto
                </h4>
                <div
                    className="grid w-full h-full gap-10"
                    style={{padding: "0 10px", maxWidth: "94%"}}
                >
                    <div className="flex items-center gap-10 justify-between">
                        <span>Buy:</span>
                        <NumberFormatter
                            value={parseFloat(stats?.crypto_buy)}
                            suffix={' USDT'}
                        />
                    </div>
                    <div className="flex items-center gap-10 justify-between">
                        <span>Sell:</span>
                        <NumberFormatter
                            value={parseFloat(stats?.crypto_sell)}
                            suffix={' USDT'}
                        />
                    </div>
                    <div className="flex items-center gap-10 justify-between">
                        <span>Total:</span>
                        <NumberFormatter
                            value={parseFloat(stats?.crypto_total)}
                            suffix={' USDT'}
                        />
                    </div>
                </div>
            </div>
            <div
                className="flex flex-column justify-start align-start w-full"
                style={{padding: "10px", fontSize: "14px"}}
            >
                <h4
                    style={{
                        textAlign: "center",
                        fontWeight: "600",
                        marginBottom: "10px",
                        width: "100%",
                    }}
                >
                    Rate
                </h4>
                <div
                    className="grid w-full h-full gap-10"
                    style={{padding: "0 10px", maxWidth: "94%"}}
                >
                    <div className="flex items-center gap-10 justify-between">
                        <span>Conversion Rate (BUY):</span>
                        <span>{stats?.conversation_fee_buy}</span>
                    </div>
                    <div className="flex items-center gap-10 justify-between">
                        <span>Conversion Rate (Sell):</span>
                        <span>{stats?.conversation_fee_sell}</span>
                    </div>
                </div>
                {/*<div*/}
                {/*    className="flex flex-column justify-start align-start w-full"*/}
                {/*    style={{padding: "10px", marginTop: "20px", fontSize: "14px"}}*/}
                {/*>*/}
                {/*    <h4*/}
                {/*        style={{*/}
                {/*            textAlign: "center",*/}
                {/*            fontWeight: "600",*/}
                {/*            marginBottom: "10px",*/}
                {/*            width: "100%",*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        Profit*/}
                {/*    </h4>*/}
                {/*</div>*/}

                {/*<div*/}
                {/*    className="grid w-full h-full gap-10"*/}
                {/*    style={{padding: "0 10px", maxWidth: "94%"}}*/}
                {/*>*/}
                {/*    <div className="flex items-center gap-10 justify-between">*/}
                {/*        <span>Expenses:</span>*/}
                {/*        <span>10.000</span>*/}
                {/*    </div>*/}
                {/*    <div className="flex items-center gap-10 justify-between">*/}
                {/*        <span>Partner Fee:</span>*/}
                {/*        <span>4.000</span>*/}
                {/*    </div>*/}
                {/*    <div className="flex items-center gap-10 justify-between">*/}
                {/*        <span>Profit:</span>*/}
                {/*        <span>86.000</span>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </Fragment>
    );
}

export default Statistics;

