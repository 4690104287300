import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Partners from "../Percentage/PartnersPercentages/Partners";
import { publicRequest } from "../../utils/requestMethods";
import { ClipLoader } from "react-spinners";
import MainSelect from "../ReusableComponents/Select";
import { balkanCountries } from "../ReusableComponents/Countries";
import PartnersCurrencyTable from "./PartnersCurrencyTable";

const PartnersCurrency = ({ fiat }) => {
  const user = useSelector((state) => state.user.currentUser);

  const [loading, setLoading] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const [selectedPartner, setSelectedPartner] = useState([]);
  const [countryFilter, setCountryFilter] = useState(null);
  const [currencies, setCurrencies] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [localCurrencies, setLocalCurrencies] = useState([]);

  useEffect(() => {
    if (!user.isAdmin) {
      let partner = [user.partnerId?._id];
      setSelectedPartner(partner);
    }

    getData();
  }, []);

  const getData = async () => {
    try {
      setTableLoading(true);
      const response = await publicRequest.get("exchanges/rate");
      setCurrencies(response.data);
      setTableLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (fiat) {
      let newRates = currencies.map((currency) => {
        if (currency.auto_rate) {
          let rate = fiat.find((f) => f.symbol === currency.symbol);
          if (rate) {
            let buyingRateFee = Number(currency.buying_rate_fee / 100) || 0.002;
            let sellingRateFee =
              Number(currency.selling_rate_fee / 100) || 0.002;

            currency.rate = Number(rate.rate).toFixed(5);
            currency.buying_rate = Number(
              Number(rate.rate) + Number(rate.rate * buyingRateFee)
            ).toFixed(5);
            currency.selling_rate = Number(
              Number(rate.rate) - Number(rate.rate * sellingRateFee)
            ).toFixed(5);
          }
        }
        return currency;
      });

      let newLocalRate = localCurrencies.map((currency) => {
        if (currency.auto_rate) {
          let rate = fiat.find((f) => f.symbol === currency.symbol);
          if (rate) {
            let buyingRateFee = Number(currency.buying_rate_fee / 100) || 0.002;
            let sellingRateFee =
              Number(currency.selling_rate_fee / 100) || 0.002;
            currency.rate = Number(rate.rate).toFixed(5);
            currency.buying_rate = Number(
              Number(rate.rate) - Number(rate.rate * buyingRateFee)
            ).toFixed(5);
            currency.selling_rate = Number(
              Number(rate.rate) + Number(rate.rate * sellingRateFee)
            ).toFixed(5);
          }
        }
        return currency;
      });

      setCurrencies(newRates);
      setLocalCurrencies(newLocalRate);
    }
  }, [fiat, localCurrencies, currencies]);

  const handleCountryFilter = (e) => {
    if (e === null) {
      setCountryFilter(null);
    } else {
      setCountryFilter(e.name);
    }
  };

  const filterName = (e) => {
    setSearchFilter(e.target.value);
  };

  return (
    <div className="flex flex-column items-start gap-10 mt-20 h-full">
      <div className="flex items-start gap-10 w-full">
        {(user.isAdmin || user.isMainOffice) && (
          <div className="grid" style={{ width: "20%" }}>
            <MainSelect
              placeholder="Filter by Country"
              isClearable={true}
              isSearchable={true}
              options={balkanCountries}
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.name}
              onChange={(e) => handleCountryFilter(e)}
            />
            <input
              name="username"
              type="text"
              placeholder="Search partner by name..."
              className="some-input"
              onChange={(e) => filterName(e)}
            />
            <div
              className="card partners-card"
            >
              <Partners
                setSelectedPartner={setSelectedPartner}
                selectedPartner={selectedPartner}
                loadingProp={loading}
                searchFilter={searchFilter}
                countryFilter={countryFilter}
                entity={currencies}
                setEntity={setLocalCurrencies}
                tableLoading={tableLoading}
                setTableLoading={setTableLoading}
                multiSelect={true}
              />
            </div>
          </div>
        )}

        <div
          className="clientsTable"
          style={{
            overflowX: "hidden",
            padding: 0,
            width: user.isAdmin || user.isMainOffice ? "85%" : "100%",
          }}
        >
          <div
            className="clientsTableMaterialTableContainer"
            style={{ width: "100%", overflowX: "hidden" }}
          >
            <div
              className={`card h-full ${loading ? "flex-center" : ""}`}
              style={{
                width: "calc(100% - 23px)",
                minHeight: "550px",
                margin: "0",
              }}
            >
              {loading ? (
                <div className="grid place-center">
                  <ClipLoader size={30} color="orange" />
                </div>
              ) : (
                <PartnersCurrencyTable
                  currencies={currencies}
                  setCurrencies={setCurrencies}
                  tableLoading={tableLoading}
                  setTableLoading={setTableLoading}
                  localCurrencies={localCurrencies}
                  selectedPartner={selectedPartner}
                  setSelectedPartner={setSelectedPartner}
                  setLocalCurrencies={setLocalCurrencies}
                  fiat={fiat}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnersCurrency;

