import React, {useEffect, useState} from "react";
import {BiExport, BiImport} from "react-icons/bi";
import {capitalizeWords} from "../../../utils/utils";

export function TransferStatus({ transaction, partner, side }) {
    const [type, setType] = useState('send');
    const checkType = async () => {
        switch (transaction.transaction_type) {
            case 'send':
                return (transaction?.source_partner?._id !== partner?._id) ? 'receive' : 'send'
            case 'credit':
                return (transaction?.source_partner?._id !== partner?._id) ? 'debit' : 'credit'

            case 'receive':
                return (transaction?.source_partner?._id !== partner?._id) ? 'send' : 'receive'

            case 'debit':
                return (transaction?.source_partner?._id !== partner?._id) ? 'credit' : 'debit'
            default:
                return transaction.transaction_type
        }
    }

    useEffect(() => {
        checkType().then((result) => setType(result));
    }, [])

    return (
        <div>
            {(type === "send" || type === 'debit' || side === "send" || side === "debit" || type === 'withdrawal' || side === "withdrawal") ? (
                <div className="flex items-center gap-5">
                    <BiExport size={18}/>
                    {side ? capitalizeWords(side) :
                        capitalizeWords(type)
                    }
                </div>
            ) : (
                <div className="flex items-center gap-5">
                    <BiImport size={18}/>
                    {side ? capitalizeWords(side) :
                        capitalizeWords(type)
                    }
                </div>
            )}
        </div>
    )
}
