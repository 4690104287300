import React, {useEffect, useMemo, useRef, useState} from "react";
import iconGenerator from "../../utils/iconGenerator";
import {publicRequest} from "../../utils/requestMethods";
import MainModal from "../ReusableComponents/MainModal";
import ReusableStatusComponent from "../ReusableComponents/ReusableStatusComponent";
import {useSelector} from "react-redux";
import MainTable from "../ReusableComponents/Tables/MRT_Main";
import {NumberFormatter} from "../ReusableComponents/NumberFormat";
import Moment from "moment/moment";
import ExchangePopupInfo from "../ReusableComponents/PopUpInfo/Exchange/ExchangePopupInfo";

const ExchangeTable = ({
                           currencyId,
                           noMarginTop,
                           customHeight,
                           scanData,
                           setScanData,
                       }) => {
    const accounts = useSelector((state) => state.user.accounting);

    const [exchanges, setExchanges] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState([]);
    const [isOpenPopUp, setIsOpenPopUp] = useState(false);
    const [walletAddress, setWalletAddress] = useState("");
    const [transaction, setTransaction] = useState({});
    const [step, setStep] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [rejected, setRejected] = useState(false);
    const [success, setSuccess] = useState(false);
    const [fail, setFail] = useState(false);
    const params = useSelector((state) => state.user.params);

    const popUpData = useRef({});
    const tableContainerRef = useRef(null);

    const columns = useMemo(
        () => [
            {
                accessorKey: "order_number",
                header: "Order.Num",
                size: 80,
                muiTableHeadCellProps: () => ({
                    sx: {
                        paddingLeft: "25px",
                    },
                }),
                muiTableBodyCellProps: () => ({
                    sx: {
                        paddingLeft: "25px",
                    },
                }),
            },
            {
                accessorKey: "createdAt",
                header: "Date",
                muiTableBodyCellProps: {
                    align: "center",
                },
                size: 100,
                accessorFn: (e) => (
                    <div>{Moment(e.createdAt).format("DD.MM.YYYY HH:mm")}</div>
                ),
            },
            {
                accessorKey: "user_id",
                header: "User",
                filterable: false,
                muiTableBodyCellProps: {
                    align: "center",
                },
                size: 120,
                accessorFn: (e) => <div>{e.user_id?.fullName}</div>,
                filterSelectOptions: params.filters.global.users.filterSelectOptions,
                filterVariant: params.filters.global.users.filterVariant,
            },
            {
                accessorKey: "partner_id",
                header: "Partner",
                filterable: false,
                muiTableBodyCellProps: {
                    align: "center",
                },
                size: 100,
                accessorFn: (e) => <div>{e.partner_id?.partnerName}</div>,
                filterSelectOptions: params.filters.global.partners.filterSelectOptions,
                filterVariant: params.filters.global.partners.filterVariant,
            },
            {
                accessorKey: "side",
                header: "Type",
                muiTableBodyCellProps: {
                    align: "center",
                },
                size: 80,
                accessorFn: (e) => (
                    <span style={{color: e.side === "BUY" ? "green" : "red"}}>
                        {e.side?.toUpperCase()}
                    </span>
                ),
                filterSelectOptions: params.filters.transactions.exchange.type.filterSelectOptions,
                filterVariant: params.filters.transactions.exchange.type.filterVariant,
            },
            {
                accessorKey: "currency_from.symbol",
                header: "Base Currency",
                muiTableBodyCellProps: {
                    align: "center",
                },

                size: 120,

                accessorFn: (e) => (
                    <div>
                        <div className="flex items-center gap-5">
                            <img
                                src={iconGenerator(128, "icon", e.currency_from.symbol, true)}
                                alt=""
                                style={{borderRadius: "12px"}}
                                className="dashboardChartHeaderIcon"
                            />
                            <NumberFormatter value={e.amount_from} suffix={` ${e.currency_from.symbol}`}/>
                        </div>
                    </div>
                ),
                filterSelectOptions: params.filters.transactions.exchange.currencies.filterSelectOptions,
                filterVariant: params.filters.transactions.exchange.currencies.filterVariant,
            },
            {
                accessorKey: "currency_to.symbol",
                header: "Quote Currency",
                filterable: false,
                muiTableBodyCellProps: {
                    align: "center",
                },
                size: 120,
                accessorFn: (e) => (
                    <div>
                        <div className="flex items-center gap-5">
                            <img
                                src={iconGenerator(128, "icon", e.currency_to.symbol, true)}
                                alt=""
                                style={{borderRadius: "12px"}}
                                className="dashboardChartHeaderIcon"
                            />
                            <NumberFormatter value={e.amount_to} suffix={` ${e.currency_to.symbol}`}/>
                        </div>
                    </div>
                ),
                filterSelectOptions: params.filters.transactions.exchange.currencies.filterSelectOptions,
                filterVariant: params.filters.transactions.exchange.currencies.filterVariant,
            },
            {
                accessorKey: "conversion_fee",
                header: "Conversion Fee",
                filterable: false,
                muiTableBodyCellProps: {
                    align: "center",
                },
                size: 80,
                accessorFn: (e) => {
                    return (
                        <NumberFormatter
                            style={{color: e?.is_manual_fee ? "red" : "black"}}
                            value={e?.conversion_fee}
                            customDecimal={4}
                        />
                    );
                }
            },
            {
                accessorKey: "status",
                header: "Status",
                size: 100,
                filterSelectOptions: [
                    {text: "Pending", value: "1"},
                    {text: "Approved", value: "2"},
                    {text: "In Progress", value: "3"},
                    {text: "Completed", value: "4"},
                    {text: "Declined", value: "5"},
                ],
                filterVariant: "multi-select",
                accessorFn: (e) => <ReusableStatusComponent status={e.status}/>,
            },
        ],
        []
    );

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setLoading(false);
        setTableLoading(false);
        setStep(-1);
        setIsOpen(false);
    };

    // useEffect(() => {
    //   retrieveExchanges();
    // }, []);

    const handleInfo = (data) => {
        popUpData.current = data;
        setIsOpen(true);
    };

    const retrieveExchanges = async () => {
        setLoading(true);
        const response = await publicRequest.get("exchanges/");
        setExchanges(response.data);
        setLoading(false);
    };

    const handleOnSubmit = async () => {
        Swal.fire({
            title: `Are you sure you want to approve this exchange?`,
            icon: 'question',
            showDenyButton: true,
            confirmButtonText: "Yes",
            denyButtonText: `Cancel`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    setLoading(true);
                    const response = await publicRequest.patch(
                        "/exchange/approval/" + popUpData.current._id
                    );
                    setLoading(false);
                } catch (e) {
                }
                setStep(4);
                setTimeout(() => {
                    setStep(-1);
                    setIsOpen(false);
                    setTableLoading(false);
                }, 2000);
            }
        })
    };

    const denyRequest = async () => {
        Swal.fire({
            title: `Are you sure you want to deny this exchange?`,
            icon: 'question',
            showDenyButton: true,
            confirmButtonText: "Yes",
            denyButtonText: `Cancel`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);
                const response = await publicRequest.patch(
                    "/exchange/declined/" + popUpData.current._id
                );
                setLoading(false);
                setStep(5);
                setTimeout(() => {
                    setStep(-1);
                    setTableLoading(false);
                }, 2000);
            }
        })
    };

    const makeTransaction = async () => {
        setLoading(true);
        if (popUpData.current.details === "USDT") {
            try {
                const transaction = await makeSmartContractTransaction(
                    popUpData.current,
                    walletAddress
                );

                popUpData.current["txId"] = transaction.txid;
                popUpData.current["address_from"] = {
                    address: walletAddress,
                    is_manual: false,
                };
                // const response = await publicRequest.put(
                //   `/transactions/approval/dex/${popUpData.current.row_id}`,
                //   popUpData.current
                // );

                // console.log(response);
            } catch (e) {
                console.log(e);
            } finally {
                setStep(3);
                setLoading(false);
            }
        } else {
            try {
                const transaction = await makeTRXTransaction(
                    popUpData.current,
                    walletAddress
                );
                setTransaction(transaction);
            } catch (e) {
                console.log(e);
            } finally {
                setStep(3);
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        const getScanData = async () => {
            try {
                const response = await publicRequest.get(`/exchanges/${scanData.id}`);
                if (response.status === 200) {
                    popUpData.current = response.data;
                    setIsOpen(true);
                    setScanData({});
                }
            } catch (e) {
                console.error(e);
            }
        };

        if (scanData?.type === 'exchange') {
            getScanData();
        }
    }, [scanData]);

    return (
        <div
            className="clientsTable"
            style={{
                width: "100%",
                overflowX: "hidden",
                padding: 0,
                marginTop: noMarginTop ? "0" : "1%",
            }}
        >
            <MainModal
                closable={true}
                closeOnEsc={true}
                isOpen={isOpen}
                onRequestClose={closeModal}
                closeModal={closeModal}
                content={
                    <>
                        <ExchangePopupInfo
                            data={popUpData.current}
                            setIsOpenPopUp={setIsOpen}
                            connectLedger={handleOnSubmit}
                            step={step}
                            setStep={setStep}
                            loading={loading}
                            makeTransaction={makeTransaction}
                            walletAddress={walletAddress}
                            rejected={rejected}
                            transaction={transaction}
                            setTransaction={setTransaction}
                            denyRequest={denyRequest}
                        />
                    </>
                }
            />
            <div
                className="clientsTableMaterialTableContainer"
                style={{width: "100%", overflowX: "hidden"}}
            >
                <MainTable
                    columns={columns}
                    requestURL="v2/exchanges"
                    onRowClick={handleInfo}
                    tableContainerRef={tableContainerRef}
                    customHeight={customHeight}
                    density={'compact'}
                />
            </div>
        </div>
    );
};

export default ExchangeTable;

