import {Box, Menu} from "@mui/material";
import Divider from "@mui/material/Divider";
import React from "react";

export const MRT_ToggleAdvancedFilterMenu = ({
                                                 anchorEl,
                                                 setAnchorEl,
                                                 table,
                                             }) => {

    const {
        getAllColumns,
        getAllLeafColumns,
        getCenterLeafColumns,
        getIsAllColumnsVisible,
        getIsSomeColumnsPinned,
        getIsSomeColumnsVisible,
        getLeftLeafColumns,
        getRightLeafColumns,
        getState,
        toggleAllColumnsVisible,
        options: {
            enableColumnOrdering,
            enableHiding,
            enablePinning,
            localization,
        },
    } = table;
    const {density, columnOrder, columnPinning} = getState();


    return (
        <Menu
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
                dense: density === 'compact',
            }}
        >
            <Box>
                <span>Advanced Filters</span>
            </Box>
            <Divider/>

        </Menu>
    )

}
