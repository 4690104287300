import { Document, Page, Text, View, StyleSheet, Svg, Path } from '@react-pdf/renderer';
import moment from 'moment';
import QRCode from 'qrcode.react';
import { renderToStaticMarkup } from 'react-dom/server';
import ReactHtmlParser from 'react-html-parser';


const styles = StyleSheet.create({
    document: {
        width: 300,
        paddingTop: 30,
        flexDirection: "row",
        flexGrow: 1
    },
    page: {
        paddingVertical: 20,
        display: "flex",
        flexDirection: "column"
    },
    title: {
        fontWeight: "bold",
        textAlign: "center",
        fontSize: 16,
        paddingBottom: 10,
        borderBottomWidth: 1,
        borderBottomStyle: "dashed",
        marginBottom: 10,
    },
    divider: {
        borderBottomWidth: 1,
        borderBottomStyle: "dashed",
        marginBottom: 10,
    },
    view: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 5,
        paddingHorizontal: 10
    },
    text: {
        fontSize: 12,
        marginBottom: 5
    },
    boldText: {
        fontWeight: "bold",
        fontSize: 14
    },
    image: {
        width: 300,
        height: 300
    }
});

const parseQrCodeMarkup = (markup) => {
    let parsedQrCodeSvg = null;

    ReactHtmlParser(markup).forEach(el => {
        const { type } = el;
        if (type === 'svg') {
            parsedQrCodeSvg = el;
        }
    });

    return parsedQrCodeSvg;
};

const qrCodeComponent = (
    <QRCode
        value={localStorage.getItem("orderId")}
        renderAs="svg"
        size={80}
    />
);

const qrCodeComponentStaticMarkup = renderToStaticMarkup(qrCodeComponent);

const parsedQrCodeSvg = parseQrCodeMarkup(qrCodeComponentStaticMarkup);
if (!parsedQrCodeSvg) {

}

// let data = JSON.parse(localStorage.getItem("order"))

export const InternalPdf = ({ data }) => (

    <Document style={styles.document}>
        <Page style={styles.page} size="A6">
            <Text style={styles.title}>INTERNAL</Text>
            <View style={styles.divider}>
                <View style={styles.view}>
                    <Text style={styles.text}>Date</Text>
                    <Text style={styles.text}>{moment(data?.createdAt).format('DD.MM.Y')}</Text>
                </View>

                <View style={styles.view}>
                    <Text style={styles.boldText}>Transfer ID</Text>
                    <Text style={styles.boldText}>{data?.order_number}</Text>
                </View>
            </View>
            <View style={styles.divider}>
                {data?.transaction_type === 'send' ?
                    <View>
                        <View style={styles.view}>
                            <Text style={styles.text}>Sender</Text>
                            <Text style={styles.text}>{data?.source_partner?.partnerName}</Text>
                        </View>
                        <View style={styles.view}>
                            <Text style={styles.text}>Receiver</Text>
                            <Text style={styles.text}>{data?.destination_partner?.partnerName}</Text>
                        </View>
                    </View> :
                    <View>
                        <View style={styles.view}>
                            <Text style={styles.text}>Receiver</Text>
                            <Text style={styles.text}>{data?.source_partner?.partnerName}</Text>
                        </View>
                        <View style={styles.view}>
                            <Text style={styles.text}>Sender</Text>
                            <Text style={styles.text}>{data?.destination_partner?.partnerName}</Text>
                        </View>
                    </View>}
                <View style={styles.view}>
                    <Text style={styles.text}>Amount</Text>
                    <Text style={styles.text}>{data?.amount} ${data?.currency?.symbol}</Text>
                </View>
                <View style={styles.view}>
                    <Text style={styles.text}>Fee</Text>
                    <Text style={styles.text}>{data?.fee} %</Text>
                </View>
                <View style={styles.view}>
                    <Text style={styles.boldText}>Total</Text>
                    <Text style={styles.boldText}>{data?.summary} {data?.currency?.symbol}</Text>
                </View>
            </View>
            <View style={styles.divider}>
                <View style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: 5, paddingBottom: 15 }}>
                    <Svg
                        style={{ width: 100, height: 100 }}
                        viewBox="0 0 29 29"
                    >
                        {parsedQrCodeSvg.props.children.filter(c => c.type === 'path').map((child, index) => (
                            <Path
                                key={index}
                                d={child.props.d}
                                fill={child.props.fill}
                            />
                        ))}
                    </Svg>
                </View>
            </View>
        </Page>
    </Document>

)
