import React, {useEffect, useState} from "react";
import {publicRequest} from "../../utils/requestMethods";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import Settings from "./Tabs/Settings";
import DEX from "./Tabs/DEX";

function GlobalSettings() {
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false)
    const [saveSuccess, setSaveSuccess] = useState(false)
    const [settings, setSettings] = useState({});
    const [successApi, setSuccessApi] = useState(false);
    const [failApi, setFailApi] = useState(false);
    const [selectedRule, setSelectedRule] = useState([]);
    const [connected, setConnected] = useState(false);
  const [activeItem, setActiveItem] = useState(-1);
  const [accounts, setAccounts] = useState([]);

  const getGlobalSettings = async () => {
    setLoading(true);
    await publicRequest.get("/settings").then((response) => {
      if (response.status === 200) {
        if (response.data?.name) {
          setSettings(response.data);
        }
        setLoading(false);
      }
    });
  };

  const saveGlobalSettings = async () => {
    setSaveLoading(true)
    settings?._id
      ? await publicRequest.put("/settings", settings)
      : await publicRequest.post("/settings", settings);

    setSaveLoading(false)
    setSaveSuccess(true)
    setTimeout(() => {
      setSaveSuccess(false)
    }, 2000)
  };

  useEffect(() => {
    getGlobalSettings();
  }, []);

  const checkPermissions = async () => {
    if (
      settings.exchanges.binance.apiKey.length &&
      settings.exchanges.binance.privateKey.length
    ) {
      setLoading(true);
      publicRequest
        .post("/exchanges/cex/check", {
          exchange: "binance",
          data: settings.exchanges.binance,
        })
        .then((r) => {
          if (r.status === 200 || r.status === 201) {
            setSuccessApi(true);
            setLoading(false);
            setTimeout(() => {
              setSuccessApi(false);
            }, 2000);
          } else {
            setFailApi(true);
            setLoading(false);
            setTimeout(() => {
              setFailApi(false);
            }, 2000);
          }
        });
    }
  };

  const handleInputChange = ({ target: { name, value } }) => {
    _.set(settings, name, value);
    setSettings({ ...settings });
  };

  const handleNetworkAdd = (index) => {
    setActiveItem(index);
  };

  return (
    <div className="users" style={{ padding: "0" }}>
      <Tabs
        style={{
          maxWidth: "100%",
          width: "100%",
        }}
      >
          <TabList
              style={{
                  padding: "7px 20px 0 20px",
                  background: "white",
                  margin: "0",
              }}
          >
              <Tab>Settings</Tab>
              {/*<Tab>CEX</Tab>*/}
              <Tab>DEX</Tab>
          </TabList>

          <TabPanel>
              <Settings
                  saveGlobalSettings={saveGlobalSettings}
                  handleInputChange={handleInputChange}
                  settings={settings}
                  saveSuccess={saveSuccess}
                  saveLoading={saveLoading}
              />
          </TabPanel>
          {/*<TabPanel>*/}
          {/*    <CEX*/}
          {/*        settings={settings}*/}
          {/*        setSettings={setSettings}*/}
          {/*        saveGlobalSettings={saveGlobalSettings}*/}
          {/*        handleInputChange={handleInputChange}*/}
          {/*        activeItem={activeItem}*/}
          {/*        handleNetworkAdd={handleNetworkAdd}*/}
          {/*        loading={loading}*/}
          {/*        setLoading={setLoading}*/}
          {/*        checkPermissions={checkPermissions}*/}
          {/*        failApi={failApi}*/}
          {/*        successApi={successApi}*/}
          {/*        saveSuccess={saveSuccess}*/}
          {/*        saveLoading={saveLoading}*/}
          {/*    />*/}
          {/*</TabPanel>*/}
          <TabPanel>
              <DEX
                  settings={settings}
                  setSettings={setSettings}
                  saveGlobalSettings={saveGlobalSettings}
                  handleInputChange={handleInputChange}
                  activeItem={activeItem}
                  handleNetworkAdd={handleNetworkAdd}
                  loading={loading}
                  setLoading={setLoading}
                  checkPermissions={checkPermissions}
                  failApi={failApi}
                  successApi={successApi}
                  saveSuccess={saveSuccess}
                  saveLoading={saveLoading}
              />
          </TabPanel>
      </Tabs>
    </div>
  );
}

export default GlobalSettings;

