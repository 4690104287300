import React, { useLayoutEffect } from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { ClipLoader } from "react-spinners";
import Content from "../Content";
import { TbArrowNarrowLeft } from "react-icons/tb";
import iconGenerator from "../../../../../utils/iconGenerator";
import { ImArrowRight, ImCancelCircle } from "react-icons/im";
import Button from "../../../Button";
import { BsPrinterFill } from "react-icons/bs";
import { internalPrint } from "../../../../../utils/templates/internalTransfer";
import { litePrint } from "../../../../../utils/templates/liteTransfer";
import { InternalPdf } from "../../../../../utils/templates/pdfs/internal"
import { LitePdf } from "../../../../../utils/templates/pdfs/lite"
import { ApproveButton } from "../../../Status/ApproveButton";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { IoMdDownload } from "react-icons/io";

function AccountingPopupInfo({
    noPrint,
    data,
    setStep,
    step,
    connectLedger,
    loading,
    makeTransaction,
    setIsOpenPopUp,
    walletAddress,
    rejected,
    denyRequest,
}) {

    useLayoutEffect(() => {
        if (data.transfer_type === "internal_transfer") {
            localStorage.setItem("orderId", btoa((JSON.stringify({ id: data._id, type: 'internal_transfer' }))))
        } else {
            localStorage.setItem("orderId", btoa((JSON.stringify({ id: data._id, type: 'lite_transfer' }))))
        }
    }, [data])

    const print = async (data) => {
        switch (data.transfer_type) {
            case "internal_transfer":
                await internalPrint(data);
                break;
            case "lite_transfer":
                await litePrint(data);
                break;
            default:
                break;
        }
    };
    return (
        <div style={{ marginTop: "20px", paddingBottom: "40px" }}>
            {step === 1 ? (
                <>
                    <div
                        onClick={() => setStep(-1)}
                        style={{ position: "absolute", top: "10px", left: "10px" }}
                        className="cursor-pointer"
                    >
                        <TbArrowNarrowLeft size={24} color="777777" />
                    </div>
                    <div className="flex flex-column items-center justify-center w-full h-full">
                        <h4>Connect to Ledger</h4>
                        <div
                            style={{
                                background: "#f7f8fa",
                                padding: "20px 0",
                                marginTop: "20px",
                                width: "85%",
                                textAlign: "center",
                                fontSize: "12px",
                                display: "grid",
                                color: "#9c9c9c",
                            }}
                        >
                            <img
                                src={require("../../../../../assets/ledger.gif")}
                                style={{ maxWidth: "66%", margin: "0 auto" }}
                            />
                            <span
                                style={{
                                    marginTop: "30px",
                                    padding: "0 85px",
                                }}
                            >
                                Make sure your Ledger is unlocked, and connect the device to
                                your computer.
                            </span>
                            <div className="or-divider" style={{ margin: "30px 0" }}>
                                and
                            </div>
                            <img
                                src={require("../../../../../assets/selecttron.gif")}
                                style={{ maxWidth: "66%", margin: "0 auto" }}
                            />
                            <span
                                style={{
                                    marginTop: "30px",
                                    padding: "0 85px",
                                }}
                            >
                                Open the Tron app in Ledger.
                            </span>
                        </div>
                        {loading ? (
                            <div
                                style={{
                                    position: "absolute",
                                    bottom: "50px",
                                    width: "100%",
                                    left: "50%",
                                }}
                            >
                                <ClipLoader size={24} color="orange" />
                            </div>
                        ) : (
                            <div
                                style={{
                                    position: "absolute",
                                    bottom: "50px",
                                    width: "100%",
                                    left: "4.5%",
                                }}
                            >
                                <Button title="Re-Check" onClick={connectLedger} />
                            </div>
                        )}
                    </div>
                </>
            ) : step === 2 ? (
                <>
                    <div
                        onClick={() => setStep(-1)}
                        style={{ position: "absolute", top: "10px", left: "10px" }}
                        className="cursor-pointer"
                    >
                        <TbArrowNarrowLeft size={24} color="777777" />
                    </div>
                    <div className="flex flex-column items-center justify-center w-full h-full">
                        <h4 style={{ position: "absolute", top: "10px" }}>
                            Confirm transaction info
                        </h4>
                        <div
                            className="flex justify-center items-center flex-column items-center w-full"
                            style={{
                                borderBottom: "1px solid #d7d7d7",
                                paddingBottom: "10px",
                            }}
                        >
                            <img
                                src={iconGenerator(128, "icon", data.details)}
                                alt=""
                                className="dashboardChartHeaderIcon"
                                style={{ width: "70px", height: "70px" }}
                            />
                            <span>Transfering out</span>
                            <span style={{ marginTop: "10px", fontWeight: "bold" }}>
                                <NumberFormatter value={data.amount.toFixed(2)} />{" "}
                                {data.symbol?.split("/")[0]}
                            </span>
                        </div>
                        <div className="flex gap-10 w-full items-center">
                            <div
                                style={{
                                    background: "#f7f8fa",
                                    marginTop: "20px",
                                    textAlign: "left",
                                    fontSize: "12px",
                                    display: "grid",
                                    color: "#9c9c9c",
                                    flex: 1,
                                    wordBreak: "break-all",
                                    padding: "10px",
                                    gap: "10px",
                                }}
                            >
                                <span style={{ fontWeight: "600" }}>From</span>
                                <span>{walletAddress}</span>
                            </div>
                            <div style={{ width: "fit-content" }}>
                                <ImArrowRight size={30} color="#ddd" />
                            </div>
                            <div
                                style={{
                                    background: "#f7f8fa",
                                    marginTop: "20px",
                                    textAlign: "right",
                                    fontSize: "12px",
                                    display: "grid",
                                    color: "#9c9c9c",
                                    flex: 1,
                                    wordBreak: "break-all",
                                    padding: "10px",
                                    gap: "10px",
                                }}
                            >
                                <span style={{ fontWeight: "600" }}>To</span>
                                <span>{data?.address_to?.address}</span>
                            </div>
                        </div>

                        {loading ? (
                            <div
                                className="flex items-center gap-5"
                                style={{ position: "absolute", bottom: "20%" }}
                            >
                                <>
                                    <ClipLoader size={24} color="orange" />
                                    Waiting for confirmation.
                                </>
                            </div>
                        ) : (
                            <>
                                <div
                                    className="flex items-center gap-5"
                                    style={{ position: "absolute", bottom: "20%" }}
                                >
                                    {rejected && (
                                        <>
                                            <FaTimes
                                                color="white"
                                                size={14}
                                                style={{
                                                    background: "red",
                                                    padding: "5px",
                                                    borderRadius: "50%",
                                                }}
                                            />
                                            Rejected
                                        </>
                                    )}
                                </div>
                                <div
                                    style={{
                                        position: "absolute",
                                        bottom: "10px",
                                        width: "92%",
                                        left: "4.5%",
                                    }}
                                >
                                    <div className="flex items-center gap-10">
                                        <Button title="Reject" onClick={() => setStep(-1)} />
                                        <Button title="Sign" onClick={makeTransaction} />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </>
            ) : step === 3 ? (
                <>
                    <div
                        className="flex flex-column items-center justify-center w-full h-full"
                        style={{ position: "absolute", top: "-6%", left: "0" }}
                    >
                        <FaCheck
                            size={40}
                            color="white"
                            style={{
                                background: "#25da25",
                                padding: "10px",
                                borderRadius: "50%",
                            }}
                        />
                        <div
                            style={{
                                marginTop: "20px",
                                textAlign: "center",
                                fontSize: "12px",
                                display: "grid",
                                color: "#9c9c9c",
                            }}
                        >
                            <span style={{ fontWeight: "600" }}>
                                Transaction has been broadcast.
                            </span>
                            <span
                                style={{
                                    padding: "0 85px",
                                }}
                            >
                                Transaction has been broadcast successfully.
                            </span>
                            <span
                                className="cursor-pointer"
                                style={{ color: "#4f8ae8", marginTop: "25px" }}
                                onClick={() => {
                                    setStep(4);
                                }}
                            >
                                View details
                            </span>
                        </div>
                        {loading ? (
                            <div
                                style={{
                                    position: "absolute",
                                    bottom: "50px",
                                    width: "100%",
                                    left: "50%",
                                }}
                            >
                                <ClipLoader size={24} color="orange" />
                            </div>
                        ) : (
                            <div
                                style={{
                                    position: "absolute",
                                    bottom: "50px",
                                    width: "100%",
                                    left: "4.5%",
                                }}
                            >
                                <Button
                                    title="Done"
                                    onClick={() => {
                                        setStep(-1);
                                        setIsOpenPopUp(false);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </>
            ) : step === 4 ? (
                <>
                    <div
                        className="flex flex-column items-center justify-center w-full h-full"
                        style={{ position: "absolute", top: "-6%", left: "0" }}
                    >
                        <FaCheck
                            size={40}
                            color="white"
                            style={{
                                background: "#25da25",
                                padding: "10px",
                                borderRadius: "50%",
                            }}
                        />
                        <div
                            style={{ fontWeight: "300", fontSize: "14px", margin: "5px 0" }}
                        >
                            Success transfer
                        </div>
                    </div>
                </>
            ) : step === 5 ? (
                <>
                    <div
                        className="flex flex-column items-center justify-center w-full h-full"
                        style={{ position: "absolute", top: "-6%", left: "0" }}
                    >
                        <FaTimes
                            size={30}
                            color="white"
                            style={{
                                background: "red",
                                padding: "10px",
                                borderRadius: "50%",
                            }}
                        />
                        <div
                            style={{ fontWeight: "300", fontSize: "14px", margin: "5px 0" }}
                        >
                            The request has been denied
                        </div>
                    </div>
                </>
            ) : loading ? (
                <div
                    className="grid h-full w-full place-center"
                    style={{ position: "absolute", top: "-20px", left: 0 }}
                >
                    <ClipLoader color="orange" size={30} />
                </div>
            ) : (
                <Content data={data} />
            )}

            {data.status === 4 && !noPrint && (
                <div
                    style={{
                        width: "100%",
                        position: "absolute",
                        bottom: "0em",
                        borderTop: "1px solid #d8d8d8",
                        left: "0",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            width: "97%",
                            borderBottom: "0",
                            background: "#fff",
                        }}
                    >
                        <div style={{
                            background: "#efefef",
                            padding: "10px",
                            borderRadius: "50%",
                            margin: "5px 5px 5px 20px",
                            width: "25px",
                            height: "25px",
                            fontSize: "25px",
                        }}
                            title="Decline"
                            onClick={() => console.log(data)}
                            className="cursor-pointer">
                            {data && (
                                <PDFDownloadLink document={data.transfer_type === "internal_transfer" ? <InternalPdf data={data} /> : <LitePdf data={data} />} fileName={`${data.order_number}.pdf`}>
                                    {({ blob, url, loading, error }) => (<IoMdDownload color="#8a8a8a" />)}
                                </PDFDownloadLink>
                            )}
                        </div>
                        <div
                            style={{
                                background: "#efefef",
                                padding: "10px",
                                borderRadius: "50%",
                                margin: "5px 5px 5px 10px",
                                width: "25px",
                                height: "25px",
                                fontSize: "25px",
                            }}
                            title="Decline"
                            className="cursor-pointer"
                            onClick={() => print(data)}
                        >
                            <BsPrinterFill color="#8a8a8a" />
                        </div>
                    </div>
                </div>
            )}
            {step === -1 && !loading && (
                <div
                    style={{
                        width: "100%",
                        position: "absolute",
                        bottom: "0em",
                        borderTop: "1px solid #d8d8d8",
                        left: "0",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            width: "97%",
                            borderBottom: "0",
                            background: "#fff",
                        }}
                    >
                        <div
                            style={{
                                background: "#efefef",
                                padding: "10px",
                                borderRadius: "50%",
                                margin: "5px 5px 5px 20px",
                                width: "25px",
                                height: "25px",
                                fontSize: "25px",
                            }}
                            title="Decline"
                            className="cursor-pointer"
                            onClick={() => denyRequest(data._id)}
                        >
                            <ImCancelCircle color="red" />
                        </div>
                        <ApproveButton
                            data={data}
                            checkVisibility={true}
                            handleOnClick={() => connectLedger(data._id)}
                            type={'transfer'}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default AccountingPopupInfo;

