import { Paper } from "@material-ui/core";
import React from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import Moment from "moment";

function OfficeUserDetails({ data }) {
  return (
    <Paper
      variant="outlined"
      style={{ maxWidth: "100%", marginTop: "2em", paddingBottom: "0.5em" }}
    >
      <center>
        <div
          style={{
            borderBottom: "1px solid lightgrey",
            maxWidth: "95%",
            marginBottom: "2em",
            paddingTop: "0.5em",
            paddingBottom: "0.5em",
          }}
        >
          <span
            style={{ fontSize: "16px", fontWeight: "600", textAlign: "center" }}
          >
            Office User Confirmation{" "}
            {data.office_status ? (
              <FaCheck color="green" size={14} />
            ) : (
              <FaTimes color="red" size={14} />
            )}
          </span>
        </div>
      </center>

      {data.office_status && (
        <div className="grid w-full">
          <div
            className="flex items-center justify-center gap-30"
            style={{ marginTop: "-20px" }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", rowGap: "" }}
            >
              <span
                style={{
                  fontWeight: "400",
                  color: "#666666",
                  fontSize: "14px",
                  lineHeight: "1.5",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                Office User
              </span>
              <span
                style={{
                  overflow: "hidden",
                  fontWeight: "600",
                  textOverflow: "ellipsis",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "16px",
                  lineHeight: "1.5",
                  textAlign: "center",
                  textTransform: "capitalize",
                }}
              >
                {data.office_user_confirmation?.fullName}
              </span>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", rowGap: "" }}
            >
              <span
                style={{
                  fontWeight: "400",
                  color: "#666666",
                  fontSize: "14px",
                  lineHeight: "1.5",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                Office User Confirmation Date
              </span>
              <span
                style={{
                  overflow: "hidden",
                  fontWeight: "600",
                  textOverflow: "ellipsis",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "16px",
                  lineHeight: "1.5",
                  textAlign: "center",
                  textTransform: "capitalize",
                }}
              >
                {Moment(data.office_user_confirmation_date).format(
                  "DD.MM.YYYY HH:mm"
                )}
              </span>
            </div>
          </div>
        </div>
      )}
    </Paper>
  );
}

export default OfficeUserDetails;


