import React, {Fragment, useEffect, useState} from "react";
import {FaTimes} from "react-icons/fa";
import {BiSelectMultiple} from "react-icons/bi";
import {ClipLoader} from "react-spinners";
import {publicRequest} from "../../../utils/requestMethods";

function Partners({
                      setSelectedPartner,
                      selectedPartner,
                      loadingProp,
                      countryFilter,
                      entity,
                      setEntity,
                      searchFilter,
                      multiSelect,
                      setPartnerCurrency
                  }) {
    const [partners, setPartners] = useState([]);
    const [loading, setLoading] = useState(false);
    const [partnerCurrencies, setPartnerCurrencies] = useState([]);
    const [keyHeld, setKeyHeld] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [staticPartners, setStaticPartners] = useState([]);

    useEffect(() => {
        getPartners();
    }, []);

    function downHandler({key}) {
        if (key === "Meta" || key === "Control") {
            setKeyHeld(true);
        }
    }

    function upHandler({key}) {
        if (key === "Meta" || key === "Control") {
            setKeyHeld(false);
        }
    }

    useEffect(() => {
        window.addEventListener("keydown", downHandler);
        window.addEventListener("keyup", upHandler);
        return () => {
            window.removeEventListener("keydown", downHandler);
            window.removeEventListener("keyup", upHandler);
        };
    }, []);

    const getPartners = async () => {
        try {
            setLoading(true);
            const response = await publicRequest.get("partner/");

            setPartners(response.data);
            if (typeof setPartnerCurrency === 'function') {
                setPartnerCurrency(response.data);
            }
            setStaticPartners(response.data);

            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        let arr = staticPartners
        if (countryFilter === null) {
            setPartners(staticPartners);
        } else {
            setPartners(arr.filter((x) => x.country.includes(countryFilter)));
        }
    }, [countryFilter]);

    useEffect(() => {
        let arr = partners;
        if (searchFilter?.length < 1) {
            setPartners(staticPartners);
        } else {
            setPartners(
                arr.filter((x) =>
                    x.partnerName?.toLowerCase().includes(searchFilter?.toLowerCase())
                )
            );
        }
    }, [searchFilter]);

    const handleSelector = async (id) => {
        if (!loadingProp) {
            if (keyHeld && multiSelect) {
                if (selectedPartner.includes(id)) {
                    setSelectedPartner(selectedPartner.filter((x) => x !== id));
                } else {
                    setSelectedPartner([...selectedPartner, id]);
                }
            } else {
                if (selectedPartner?.includes(id)) {
                    setSelectedPartner([]);
                    setTableLoading(true);
                    if (typeof setEntity === 'function') {
                        let arr = entity;
                        setEntity(arr);
                    }
                    setTableLoading(false);
                } else {
                    setSelectedPartner([id]);
                    // getLocalCurrencies(id);
                }
            }
            // if (selectedPartner?.includes(id)) {
            //   setSelectedPartner([]);
            //   setTableLoading(true);
            //   setTableLoading(false);
            // } else {
            //   setSelectedPartner([id]);
            //   getLocalCurrencies(id);
            // }
        }
    };

    const removeSelection = () => {
        setSelectedPartner([]);
    };

    const getLocalCurrencies = async (id) => {
        try {
            setTableLoading(true);
            const response = await publicRequest("partner/");

            response.data.map((x) => {
                if (x._id === id) {
                }
            });

            setTableLoading(false);
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <Fragment>
            <div
                className="flex flex-column justify-start align-start"
                style={{width: "100%"}}
            >
                <div
                    className="flex items-center justify-between w-full"
                    style={{
                        borderBottom: "1px solid #d7d7d7",
                        paddingBottom: "5px",
                        height: "43px",
                    }}
                >
                    <h4 style={{width: "100%"}}>Partners</h4>
                    <div className="flex items-center gap-5">
                        {selectedPartner?.length > 0 && (
                            <FaTimes
                                color="white"
                                title="Clear"
                                className="cursor-pointer"
                                size={15}
                                style={{
                                    background: "orange",
                                    borderRadius: "50%",
                                    padding: "3px",
                                }}
                                onClick={removeSelection}
                            />
                        )}
                        {multiSelect && (
                            <BiSelectMultiple
                                color="orange"
                                title="Select All"
                                className="cursor-pointer"
                                size={20}
                                onClick={() => setSelectedPartner(partners.map((x) => x._id))}
                            />
                        )}
                    </div>
                </div>
                {loading ? (
                    <div
                        className="grid h-full w-full place-center"
                        style={{padding: "20px 0"}}
                    >
                        <ClipLoader color="orange" size={24}/>
                    </div>
                ) : (
                    <>
                        {partners.length < 1 ? (
                            <div
                                className="grid h-full w-full place-center"
                                style={{padding: "20px 0"}}
                            >
                                There are no partners available!
                            </div>
                        ) : (
                            <>
                                {partners.map((item) => (
                                    <div
                                        className={`flex flex-column gap-5 cursor-pointer ${selectedPartner?.includes(item._id)
                                            ? "color-hover active"
                                            : ""
                                        }`}
                                        style={{
                                            padding: "10px",
                                            width: "calc(100% - 20px)",
                                            marginTop: "5px",
                                        }}
                                        onClick={() => handleSelector(item._id)}
                                    >
                    <span
                        style={{
                            fontWeight: "600",
                            fontSize: "14px",
                        }}
                    >
                      {item.partnerName}
                    </span>
                                    </div>
                                ))}
                            </>
                        )}
                    </>
                )}
            </div>
        </Fragment>
    );
}

export default Partners;

