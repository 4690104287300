import {Cut, Line, Printer, QRCode, Row, Text} from "react-thermal-printer";
import moment from "moment";
import print from "../printer";

export const litePrint = async (order) => {

    const transactionId = order._id;
    const {_id, currency: {symbol}, amount, fee, summary, country} = order;
    const TXCode = btoa((JSON.stringify({id: transactionId, type: 'lite_transfer'})));


    await print(
        <Printer type="epson" width={46} characterSet="iso8859_2_latin2">
            <Text bold={true} align={'center'} size={{width: 2, height: 2}}>LITE</Text>
            <Line/>
            <Row left={'Date'} right={moment(order.createdAt).format('DD.MM.Y')}/>
            <Row left={<Text bold={true}>Transfer ID</Text>} right={<Text bold={true}>{order.order_number}</Text>}/>
            <Line/>
            <Row left={'SEND'} right={`${amount} ${symbol}`}/>
            <Row left={'FEE'} right={`${fee} ${symbol}`}/>
            <Row left={<Text bold={true} size={{width: 1, height: 1}}>TOTAL</Text>}
                 right={<Text bold={true} size={{width: 1, height: 1}}>{summary} {symbol}</Text>}
            />
            <Line/>
            <QRCode cellSize={6} model={'model2'} align={"center"} content={TXCode}/>
            <Line/>
            <Text align={'center'} size={{width: 1, height: 1}}>Receipt</Text>
            <Line/>
            <Cut lineFeeds={4}/>
            <Text bold={true} align={'center'} size={{width: 2, height: 2}}>LITE</Text>
            <Line/>
            <Row left={'Date'} right={moment(order.createdAt).format('DD.MM.Y')}/>
            <Row left={'TID'} right={`#${order.order_number}`}/>
            <Line/>
            <QRCode cellSize={8} model={'model2'} align={"center"} content={TXCode}/>
            <Line/>
            <Text bold={true} align={'center'}>{order.comment}</Text>
            <Line/>
            <Cut lineFeeds={4}/>
        </Printer>
    );
}
