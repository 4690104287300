import React, { Fragment } from "react";
import Details from "./Content/Details";
import SourcePartnerDetails from "./Content/SourcePartnersDetails";
import DestinationPartnerDetails from "./Content/DestinationPartnerDetails";
import OfficeUserDetails from "./Content/OfficeUserDetails";

function Content({ data }) {
    return (
        <Fragment>
            <Details data={data} />
            <SourcePartnerDetails data={data} />
            <DestinationPartnerDetails data={data} />
            <OfficeUserDetails data={data} />
        </Fragment>
    );
}

export default Content;

