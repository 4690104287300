import React, {useContext, useEffect, useState} from "react";
import "./DashboardSliderSecondRight.css";
import CustomAriaLive from "../../React-Select/React-Select";
import {useSelector} from "react-redux";
import {TbArrowsSort} from "react-icons/tb";
import {RiUserAddLine} from "react-icons/ri";
import {BsFillPrinterFill} from "react-icons/bs";
import {publicRequest} from "../../../utils/requestMethods";
import MainModal from "../../ReusableComponents/MainModal";
import ClientRegisterFields from "../../ReusableComponents/ClientRegisterFields";
import CurrencyOption from "../CurrencyOption";
import CryptoOption from "../CryptoOption";
import {ClipLoader} from "react-spinners";
import {FaCheck, FaTimes} from "react-icons/fa";
import {cryptoPrint} from "../../../utils/templates/crypto";
import {SocketContext} from "../../../context/socket";
import Swal from "sweetalert2";

const DashboardSliderSecondRight = ({
                                        afterClick,
                                        choosenCrypto,
                                        choosenOrderType,
                                        choosenCoinPrice,
                                        choosenNetworkTotalFee,
                                        choosenNetworkAddress,
                                        choosenNetwork,
                                        addressError,
                                        setAddressError,
                                        setSlide,
                                        setAfterClick,
                                        manualAddress,
                                        setChoosenNetwork,
                                        success,
                                        setSuccess,
                                        loading,
                                        setLoading,
                                        selectedNetworkChain,
                                        setSelectedNetworkChain
                                    }) => {
        const redux = useSelector((state) => state.user);
        const user = redux.currentUser;
        const exFee = redux.externalFee;
        const percentages = redux.percentages.crypto;
        const [clientError, setClientError] = useState(false);
        const [successAdd, setSuccessAdd] = useState(false);
        const [message, setMessage] = useState("");
        const [fail, setFail] = useState(false);

        const [pairPrice, setPairPrice] = useState({
            symbol: "",
            price: 0,
            manual: false,
            changed: false,
        });

        const [fee, setFee] = useState({fee: 0, manual: false});

        const [reverseCurrency, setReverseCurrency] = useState(false);
        const [pairs, setPairs] = useState([]);
        const [pairFee, setPairFee] = useState(false);
        const [quantityError, setQuantityError] = useState(false);
        const [entity, setEntity] = useState([]);

        useEffect(() => {
            choosenOrderType === "BUY"
                ? setPairs([
                    {label: "You receive", symbol: choosenCrypto, amount: 0},
                    {label: "Equivalent", symbol: "USDT", amount: 0},
                ])
                : setPairs([
                    {label: "You pay", symbol: "USDT", amount: 0, default: true},
                    {
                        label: "Client receive",
                        symbol: choosenCrypto,
                        amount: 0,
                        default: false,
                    },
                ]);
            setReverseCurrency(false);
            setClient(null);
            resetComponents();
        }, [choosenCrypto, choosenOrderType, choosenNetwork]);

        const resetComponents = () => {
            setFee({fee: 0, manual: false});
            setInputList(inputListDefault);
            setPairFee(false);
            generateFee(fee);
        };

        useEffect(() => {
            if (!pairPrice.manual && !pairPrice.changed) {
                setPairPrice({
                    symbol: choosenCrypto,
                    price: choosenCoinPrice,
                    manual: false,
                    changed: false,
                });
            }
        }, [choosenCoinPrice]);

        const inputListDefault = [
            {
                symbol: "EUR",
                buying_rate: 0,
                selling_rate: 0,
                buying_rate_fee: 0,
                selling_rate_fee: 0,
                price: 0,
                manualPrice: false,
                manualRate: false,
                default: true,
                changeRate: false,
                changePrice: false,
                attached: false,
            },
        ];
        // Currency List
        const [inputList, setInputList] = useState(inputListDefault);

        const [currencyList, setCurrencyList] = useState([]);

        const [register, setRegister] = useState(false);
        const [client, setClient] = useState("");

        const [modifiedData, setModifiedData] = useState({
            firstName: "",
            lastName: "",
            zip: "",
            partnerId: null,
            address: "",
            city: "",
            country: "",
            phone1: "",
            phone2: "",
            email: "",
        });
        const [isOpen, setIsOpen] = React.useState(false);

        function openModal() {
            setIsOpen(true);
            setModifiedData({
                firstName: "",
                lastName: "",
                zip: "",
                partnerId: null,
                address: "",
                city: "",
                country: "",
                phone1: "",
                phone2: "",
                email: "",
            });
        }

        const successEdit = (params, user) => {
            setSuccessAdd(true);
            setMessage(params);
            setClient(user);
            setTimeout(() => {
                setSuccessAdd(false);
                setIsOpen(false);
            }, 2000);
        };

        function closeModal() {
            setIsOpen(false);
            clearModel();
        }

        const clearModel = () => {
            setModifiedData({
                firstName: "",
                lastName: "",
                zip: "",
                partnerId: null,
                address: "",
                city: "",
                country: "",
                phone1: "",
                phone2: "",
                email: "",
            });
        };

        //Select client
        const handleSelected = (e) => {
            if (e === null || e.value === null) {
                setClient(null);
            } else {
                setClient(e);
                setClientError(false);
            }
        };

        const generateFee = (fee) => {
            const partnerPercentage = percentages ?? [];

            if (!partnerPercentage.length && !fee.manual) {
                return 0;
            }

            if (pairs.length) {
                let sumOfAmount = pairs.find((pair) => pair.symbol === "USDT").amount;

                if (!sumOfAmount) {
                    return 0;
                }

                let currentFee = partnerPercentage.find(
                    (prc) =>
                        parseFloat(sumOfAmount) >= prc.fromValue &&
                        parseFloat(sumOfAmount) <= prc.toValue &&
                        prc.percentageSide === choosenOrderType
                );

                if (currentFee === undefined) {
                    currentFee = partnerPercentage.find(
                        (prc) =>
                            prc.percentageSide === choosenOrderType.toUpperCase() && prc.fromValue <= 10
                    )
                }


                if (!fee.manual) {
                    let fee = parseFloat(currentFee?.percentageValue);
                    if (choosenOrderType === "SELL") {
                        if (exFee.enabled && exFee?.fee?.fee) {
                            fee += parseFloat(exFee?.fee?.fee);
                        }
                    }
                    return fee;
                }
                return fee.fee;
            }
        };
        const coinToPrecision = (type) => {
            return choosenNetworkTotalFee?.market?.precision[type];
        };

        const coinTradeFee = () => {
            return choosenNetworkTotalFee?.fees?.makerCommission
        }

        const checkWithdraw = (amount) => {
            return (
                amount >= choosenNetworkTotalFee.withdrawMin &&
                amount <= choosenNetworkTotalFee.withdrawMax
            );
        };

        const calculateBuyPrice = () => {
            if (!pairs.length) {
                return;
            }

            if (reverseCurrency) {
                let sumOfAmount = inputList.reduce(
                    (partialSum, a) =>
                        partialSum +
                        (a.currency === "EUR"
                            ? Number(a.price) * a.rate
                            : Number(a.price) / a.rate),
                    0
                );

                if (!fee.manual) {
                    let calculatedFee = generateFee(fee.fee);
                    if (fee.fee !== calculatedFee) {
                        setFee({...fee, ...{fee: calculatedFee}});
                    }
                }

                const totalFee = (Number(fee.fee) / 100) * Number(sumOfAmount);
                sumOfAmount += totalFee;

                // Calculate quantity
                if (pairs[0].symbol === "USDT") {
                    pairs[0].amount = sumOfAmount.toFixed(coinToPrecision("price"));
                } else {
                    let amount = Number(sumOfAmount) / Number(pairPrice.price);
                    pairs[0].amount = amount.toPrecision(coinToPrecision("amount"));
                }
                // Calculate quantity
                if (pairs[1].symbol === "USDT") {
                    pairs[1].amount = sumOfAmount.toFixed(coinToPrecision("price"));
                } else {
                    let amount = Number(sumOfAmount) / Number(pairPrice.price);
                    pairs[1].amount = amount.toPrecision(coinToPrecision("amount"));
                }
            } else {

                // Calculate quantity
                if (pairs[0].symbol === "USDT") {
                    let amount = Number(pairs[0].amount) / Number(pairPrice.price);
                    if (pairs[1].symbol === "USDT") {
                        pairs[1].amount = amount.toFixed(coinToPrecision("price"));
                    } else {
                        pairs[1].amount = amount.toFixed(coinToPrecision("amount"));
                    }
                } else {
                    let amount = Number(pairs[0].amount) * Number(pairPrice.price);
                    if (pairs[1].symbol === "USDT") {
                        pairs[1].amount = amount.toFixed(2);
                    } else {
                        pairs[1].amount = amount.toFixed(coinToPrecision("price"));
                    }
                }

                const costUSDT = pairs.find((pair) => pair.symbol === "USDT");

                if (!fee.manual) {
                    let calculatedFee = generateFee(fee.fee);
                    if (fee.fee !== calculatedFee) {
                        setFee({...fee, fee: calculatedFee});
                    }
                }

                const totalFee = (Number(fee.fee) / 100) * Number(costUSDT.amount);
                const totalManualChanged = inputList.filter((cur) => cur.manualPrice);
                const sumOfManualChanged = totalManualChanged.reduce(
                    (partialSum, a) =>
                        partialSum +
                        (a.currency === "EUR"
                            ? Number(a.price) * a.rate
                            : Number(a.price) / a.rate),
                    0
                );
                let totalCurrency =
                    (Number(costUSDT.amount) - totalFee - sumOfManualChanged) /
                    (inputList.length - totalManualChanged.length);

                inputList.map((cur) => {
                    if (!cur.manualPrice && !cur.changePrice) {
                        if (cur.currency === "EUR") {
                            cur.price = Number(totalCurrency / cur.rate).toFixed(2);
                        } else {
                            cur.price = Number(totalCurrency / (1 / cur.rate)).toFixed(2);
                        }
                    }
                });
            }
        };

        const calculateSellPrice = () => {
            if (!pairs.length) {
                return;
            }

            if (reverseCurrency) {
                setPairFee(true);
                let sumOfAmount = inputList.reduce(
                    (partialSum, a) =>
                        partialSum +
                        (a.currency === "EUR"
                            ? Number(a.price) * a.rate
                            : Number(a.price) / a.rate),
                    0
                );

                let calculatedFee = generateFee(fee);

                if (fee.fee !== calculatedFee) {
                    setFee({...fee, fee: calculatedFee});
                }
                const totalFee = Number(sumOfAmount) * (Number(calculatedFee) / 100);
                sumOfAmount -= totalFee.toFixed(2);

                const defaultPairIndex = pairs.findIndex((pair) => pair.default);
                const secondPairIndex = defaultPairIndex === 0 ? 1 : 0;

                pairs[defaultPairIndex].amount = sumOfAmount.toFixed(2);
                const clientReceive = Number(
                    Number(sumOfAmount) -
                    Number(
                        Number(choosenNetworkTotalFee.withdrawFee) * Number(pairPrice.price)
                    )
                );

                if (
                    pairs[defaultPairIndex].symbol === "USDT" &&
                    pairs[secondPairIndex].symbol === "USDT"
                ) {
                    pairs[secondPairIndex].amount = clientReceive.toFixed(2);
                } else {
                    let totalAmount = clientReceive / pairPrice.price;
                    pairs[secondPairIndex].amount = parseFloat(
                        totalAmount - (totalAmount * coinTradeFee())
                    ).toFixed(coinToPrecision("amount"));
                }

                setPairs(pairs);
            } else {
                const changedInput = pairs.find((pair) => pair.changed);

                if (changedInput === undefined) {
                    return;
                }

                const defaultPairIndex = pairs.findIndex((pair) => pair.default);
                const secondPairIndex = defaultPairIndex === 0 ? 1 : 0;

                let totalWithdrawalFee = changedInput.default
                    ? choosenNetworkTotalFee.withdrawFee
                    : Number(choosenNetworkTotalFee.withdrawFee) * Number(pairPrice.price);
                let totalPrice = changedInput.default
                    ? Number(changedInput.amount)
                    : Number(changedInput.amount) * Number(pairPrice.price);

                let sumOfAmount = changedInput.default
                    ? totalPrice / Number(pairPrice.price)
                    : totalPrice;

                if (pairFee) {
                    sumOfAmount -= totalWithdrawalFee;
                }

                let tradeFee = coinTradeFee();

                if (tradeFee) {
                    sumOfAmount -= (sumOfAmount * parseFloat(tradeFee))
                }

                if (
                    pairs[defaultPairIndex].symbol === "USDT" &&
                    pairs[secondPairIndex].symbol === "USDT"
                ) {
                    pairs[1].amount = sumOfAmount.toFixed(coinToPrecision("price"));
                } else {
                    pairs[1].amount = sumOfAmount.toFixed(coinToPrecision("amount"));
                }

                setPairs(pairs);

                let calculatedFee = generateFee(fee);

                if (fee.fee !== calculatedFee) {
                    setFee({...fee, fee: calculatedFee});
                }

                const totalFee = (Number(calculatedFee) / 100) * Number(totalPrice);
                totalPrice += totalFee;

                if (!pairFee) {
                    totalPrice +=
                        Number(choosenNetworkTotalFee.withdrawFee) * Number(pairPrice.price);
                }

                const totalManualChanged = inputList.filter((cur) => cur.manualPrice);
                const sumOfManualChanged = totalManualChanged.reduce(
                    (partialSum, a) =>
                        partialSum +
                        (a.currency === "EUR"
                            ? Number(a.price) * a.rate
                            : Number(a.price) / a.rate),
                    0
                );
                let totalCurrency =
                    (totalPrice - sumOfManualChanged) /
                    (inputList.length - totalManualChanged.length);

                inputList.map((cur) => {
                    if (!cur.manualPrice && !cur.changePrice) {
                        if (cur.currency === "EUR") {
                            cur.price = Number(totalCurrency / cur.rate).toFixed(2);
                        } else {
                            cur.price = Number(totalCurrency / (1 / cur.rate)).toFixed(2);
                        }
                    }
                });
            }
        };

        useEffect(() => {
            let currencies = inputList.map((currency) => {
                currency.changePrice = false;
                currency.changeRate = false;
                currency.manualPrice = false;
                currency.manualRate = false;
                currency.price = 0;
                return currency;
            });

            pairs.map((pair) => {
                pair.amount = 0;
                return pair;
            });

            setInputList(currencies);
            resetComponents();
        }, [reverseCurrency]);

        useEffect(() => {
            switch (choosenOrderType) {
                case "BUY":
                    calculateBuyPrice();
                    break;
                case "SELL":
                    calculateSellPrice();
                    break;
            }
        }, [pairs, inputList, pairFee, fee, pairPrice]);

    const socket = useContext(SocketContext);
    const [fiat, setFiat] = useState('');

    useEffect(() => {
        if (socket) {
            socket.fiatPrice((data) => {
                setFiat(data);
            })
        }
    }, []);

    useEffect(() => {
        if (fiat) {
            try {
                let usdRate = fiat.find((f) => f.symbol === "USD");
                let {
                    buying_rate: usdBr,
                    selling_rate: usdSr,
                } = usdRate;

                    const list = currencyList.map((cur) => {
                        let liveRate = fiat.find((f) => f.symbol === cur.symbol);
                        let {
                            buying_rate: liveBr,
                            selling_rate: liveSr,
                            symbol
                        } = liveRate;

                        if (!liveRate.auto_rate) {
                            liveBr = parseFloat(cur.buying_rate)
                            liveSr = parseFloat(cur.selling_rate)
                        }
                        if (symbol === "USD") {
                            cur.rate = parseFloat("1").toFixed(4);
                        } else {
                            if (choosenOrderType === 'SELL') {
                                cur.rate = Number(liveBr / usdBr).toFixed(4);
                                cur.live_rate = Number(liveBr / usdBr).toFixed(4);
                            } else {
                                cur.rate = Number(liveSr / usdSr).toFixed(4);
                                cur.live_rate = Number(liveSr / usdSr).toFixed(4);
                            }
                        }
                        return cur;
                    });

                    let newActive = inputList.map((il) => {
                        let rate = list.find((f) => f.symbol === il.symbol);
                        if (!il.manualRate && !il.changeRate && !il.changePrice) {
                            il = {...rate, ...il}
                        }
                        return il;
                    });

                    setCurrencyList(list);
                    setInputList(newActive)
                } catch
                    (e) {
                    console.log(e);
                }
            }
        }, [fiat])

        const totalWithdrawPrice = (show = false) => {
            let totalInUSD = inputList.reduce(
                (partialSum, a) =>
                    partialSum +
                    (a.symbol === "USD"
                        ? Number(a.price) / a.rate
                        : Number(a.price) * (1 / a.rate)),
                0
            );


            let eurRate = inputList.find((il) => il.symbol === "EUR");
            if (!eurRate) {
                eurRate = currencyList.find((il) => il.symbol === "EUR");
            }
            if (eurRate) {
                return Number(Number(totalInUSD) / (1 / eurRate.rate));
            }
            return 0;
        };


        const calculateQuantity = (onlySent = false) => {
            const defaultPairIndex = pairs.findIndex((pair) => pair.default);
            const secondPairIndex = defaultPairIndex === 0 ? 1 : 0;

            if (onlySent || choosenOrderType.toLowerCase() === 'buy') {
                return parseFloat(pairs[secondPairIndex].amount);
            }

            if (choosenNetwork === "BNB") {
                let amount = parseFloat(pairs[secondPairIndex].amount);
                let withdrawFee = parseFloat(choosenNetworkTotalFee.withdrawFee || 0);
                let tradeFee = parseFloat(pairs[secondPairIndex].amount * coinTradeFee() || 0);
                let totalAmount = (amount + withdrawFee + tradeFee).toFixed(coinToPrecision("amount"))
                if (choosenCrypto === 'USDT') {
                    return totalAmount;
                } else {
                    return ((totalAmount * 10 ** coinToPrecision('amount')) + 1) / 10 ** coinToPrecision('amount')
                }
            } else {
                return parseFloat(pairs[secondPairIndex].amount).toFixed(coinToPrecision("amount"))
            }
        }

    const generateConversationFee = () => {
        const EUR = currencyList.find(cl => cl.symbol === 'EUR');

        return EUR ? 1 / EUR.rate : 0;
    }

    const postExchange = async () => {
        let postObj = {
            symbol: `${choosenCrypto}/USDT`,
            network: choosenNetworkTotalFee.network,
            network_name: choosenNetworkTotalFee.networkName,
            network_fee: parseFloat(choosenNetworkTotalFee.withdrawFee),
            conversion_fee: generateConversationFee(),
            side: choosenOrderType.toLowerCase(),
            address_to: {
                address: choosenNetworkAddress,
                is_manual: manualAddress,
            },
            address_from: choosenNetwork === "BNB" ? null : choosenOrderType.toLowerCase() === 'sell' ? {
                address: selectedNetworkChain.address,
                is_manual: false
            } : null,
            exchange: choosenNetwork === "BNB" ? "Binance" : "DEX",
            mode: "MANUAL",
            last_price_filled: pairPrice.price,
            client_id: client,
            exchange_rate: inputList,
            fiat_payment: (await totalWithdrawPrice()).toFixed(2),
            value_fee: fee,
            quantity: calculateQuantity(),
            quantity_sent: calculateQuantity(true)
        };

            if (client === "" || client === null) {
                setClientError(true);
            } else if (addressError) {
                setAddressError(true);
            } else {
                Swal.fire({
                    title: `Are you sure you want to create this transaction?`,
                    icon: 'question',
                    showDenyButton: true,
                    confirmButtonText: "Yes",
                    denyButtonText: `Cancel`,
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        setLoading(true);
                        setClientError(false);
                        const response = await publicRequest.post(
                            "/transactions/market/order",
                            postObj
                        );
                        if (response.status === 200) {
                            await cryptoPrint(response.data);
                            setSuccess(true);
                            setFail(false);
                            setTimeout(() => {
                                setSlide(0);
                                setAfterClick(-1);
                                setChoosenNetwork("");
                                setSuccess(false);
                            }, 2000);
                        } else {
                            setFail(true);
                            setSuccess(false);
                            setTimeout(() => {
                                setSlide(0);
                                setAfterClick(-1);
                                setChoosenNetwork("");
                                setFail(false);
                            }, 2000);
                        }
                        setLoading(false);
                    }
                })
            }
        };

        return (
            <>
                <div className="dashboardSliderSecondRight">
                    {loading ? (
                        <div className="grid h-full w-full place-center">
                            <ClipLoader size={30} color="orange"/>{" "}
                        </div>
                    ) : success ? (
                        <div
                            className="flex flex-column items-center justify-center w-full"
                            style={{position: "absolute", top: "-6%", left: "0", height: "fit-content"}}
                        >
                            <FaCheck
                                size={30}
                                color="white"
                                style={{
                                    background: "#25da25",
                                    padding: "10px",
                                    borderRadius: "50%",
                                }}
                            />
                            <div
                                style={{fontWeight: "300", fontSize: "14px", margin: "5px 0"}}
                            >
                                The transaction was successful!
                            </div>
                        </div>
                    ) : fail ? (
                        <div
                            className="flex flex-column items-center justify-center w-full h-full"
                            style={{position: "absolute", top: "-6%", left: "0"}}
                        >
                            <FaTimes
                                size={30}
                                color="white"
                                style={{
                                    background: "red",
                                    padding: "10px",
                                    borderRadius: "50%",
                                }}
                            />
                            <div
                                style={{fontWeight: "300", fontSize: "14px", margin: "5px 0"}}
                            >
                                There was an error with the transaction!
                            </div>
                        </div>
                    ) : (
                        <>
                            <MainModal
                                closable={true}
                                isOpen={isOpen}
                                onRequestClose={closeModal}
                                closeModal={closeModal}
                                footer={true}
                                refetch={() => {
                                }}
                                successEdit={successEdit}
                                success={successAdd}
                                entity={entity}
                                setEntity={setEntity}
                                user={user}
                                endpoint="client/register"
                                modifiedData={modifiedData}
                                setModifiedData={setModifiedData}
                                submitOrEdit="submit"
                                setIsOpen={setIsOpen}
                                content={
                                    <div className="flex justify-between gap-10 flex-wrap">
                                        <h3 className="usersTableRegisterClientFormTitle">
                                            Register New Client
                                        </h3>
                                        <ClientRegisterFields
                                            modifiedData={modifiedData}
                                            setModifiedData={setModifiedData}
                                            success={successAdd}
                                            message={message}
                                        />
                                    </div>
                                }
                            />
                            {afterClick !== -1 && (
                                <>
                                    <div className="dashboardSliderSecondRightWrapper">
                                        <div className="dashboardSliderRightHeader">
                                            <CustomAriaLive
                                                handleSelected={(e) => handleSelected(e)}
                                                client={client}
                                                error={clientError}
                                            />
                                            <RiUserAddLine
                                                size={20}
                                                color="#fff"
                                                onClick={() => openModal()}
                                                className="addUserIcon"
                                            />
                                        </div>
                                        {clientError && (
                                            <span
                                                style={{
                                                    position: "absolute",
                                                    top: "-20px",
                                                    color: "red",
                                                    left: "44%",
                                                    transform: "translateX(-50%)",
                                                }}
                                            >
                                            Choose a client first!
                                        </span>
                                        )}

                                        {reverseCurrency ? (
                                            <>
                                                <CurrencyOption
                                                    currencyList={inputList}
                                                    setCurrencyList={setInputList}
                                                    availableCurrencyList={currencyList}
                                                    setAvailableCurrencyList={setCurrencyList}
                                                    reverseCurrency={reverseCurrency}
                                                    choosenOrderType={choosenOrderType}
                                                />
                                                <div
                                                    className="dashboardSliderThirdRightExchangeCurrencyTransferContainerIconWrapper">
                                                    <TbArrowsSort
                                                        onClick={() => setReverseCurrency(!reverseCurrency)}
                                                        color="#fff"
                                                        size={30}
                                                        className="dashboardSliderFirstRightExchangeCurrencyTransferContainerIcon"
                                                    />
                                                </div>
                                                <CryptoOption
                                                    pairs={pairs}
                                                    setPairs={setPairs}
                                                    pairPrice={pairPrice}
                                                    setPairPrice={setPairPrice}
                                                    fee={fee}
                                                    setFee={setFee}
                                                    choosenOrderType={choosenOrderType}
                                                    pairFee={pairFee}
                                                    setPairFee={setPairFee}
                                                    currencyList={inputList}
                                                    reverseCurrency={reverseCurrency}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <CryptoOption
                                                    chooseSymbol={choosenCrypto}
                                                    pairs={pairs}
                                                    setPairs={setPairs}
                                                    pairPrice={pairPrice}
                                                    setPairPrice={setPairPrice}
                                                    fee={fee}
                                                    setFee={setFee}
                                                    pairFee={pairFee}
                                                    setPairFee={setPairFee}
                                                    choosenOrderType={choosenOrderType}
                                                    currencyList={inputList}
                                                    reverseCurrency={reverseCurrency}
                                                />
                                                <div
                                                    className="dashboardSliderThirdRightExchangeCurrencyTransferContainerIconWrapper">
                                                    <TbArrowsSort
                                                        onClick={() => setReverseCurrency(!reverseCurrency)}
                                                        color="#fff"
                                                        size={30}
                                                        className="dashboardSliderFirstRightExchangeCurrencyTransferContainerIcon"
                                                    />
                                                </div>
                                                <CurrencyOption
                                                    currencyList={inputList}
                                                    availableCurrencyList={currencyList}
                                                    setCurrencyList={setInputList}
                                                    setAvailableCurrencyList={setCurrencyList}
                                                    reverseCurrency={reverseCurrency}
                                                    choosenOrderType={choosenOrderType}
                                                />
                                            </>
                                        )}
                                        {(!user.isAdmin && !user.isMainOffice) && (
                                            <div
                                                className="printerIconWrapper"
                                                style={{
                                                    position: "absolute",
                                                    bottom: "0",
                                                    right: "5px",
                                                    width: "fit-content",
                                                }}
                                            >
                                                <BsFillPrinterFill
                                                    color="#b8b8b8"
                                                    size={16}
                                                    className="printerIcon"
                                                    onClick={() => postExchange()}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </>
        );
    }
;
export default DashboardSliderSecondRight;

