import React, {Fragment, useEffect, useState} from "react";
import {FaCheck} from "react-icons/fa";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import "react-tabs/style/react-tabs.css";
import PartnerApiFields from "./PartnerApiFields";
import PartnerCurrencies from "./PartnerCurrencies";
import PartnerLimits from "./PartnerLimits";
import PartnerPercentage from "./PartnerPercentage";
import PartnerUserFields from "./PartnerUserFields";
import {balkanCountries} from "../../ReusableComponents/Countries";
import MainSelect from "../../ReusableComponents/Select";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

function UserRegisterFields({
  partnerData,
  setPartnerData,
  getRoles,
  submitOrEdit,
  handleChoosenPartner,
  partners,
  message,
  success,
  inputErrors,
}) {
  const [tempCurrencies, setTempCurrencies] = useState([]);
  const [tempPercentages, setTempPercentages] = useState([]);
  const [phonePrefix, setPhonePrefix] = useState("");

  const handleChange = ({ target: { name, value } }) => {
    setPartnerData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    setTempCurrencies(partnerData.currencies);
  }, [partnerData]);

  const handleUserChange = ({ target: { name, value } }) => {
    setPartnerData((prev) => ({
      ...prev,
      user: {
        ...partnerData.user,
        [name]: value
      },
    }));
  };

  const handleListChange = (e) => {
    const eRef = e
    if (e === null || tempCurrencies.findIndex((obj) => obj._id === e._id) !== -1) {
    } else {
      setTempCurrencies([...tempCurrencies, e]);
      setPartnerData((prev) => ({
        ...prev,
        currencies: [...partnerData.currencies, e],
      }));
    }
  };

  const handlePercentageChange = (e) => {
    if (e === null) {
    } else {
      setTempPercentages([
        ...tempPercentages.filter((x) => x.groupName !== e.groupName),
        e,
      ]);
      setPartnerData((prev) => ({
        ...prev,
        percentages: [
          ...partnerData.percentages.filter((x) => x.groupName !== e.groupName),
          e,
        ],
      }));
    }
  };

  const removeCurrency = (id) => {
    setPartnerData((prev) => ({
      ...prev,
      currencies: partnerData.currencies.filter((x) => x._id !== id),
    }));
    setTempCurrencies(tempCurrencies.filter((x) => x._id !== id));
  };

  const removePercentage = (value) => {
    setPartnerData((prev) => ({
      ...prev,
      percentages: partnerData.percentages.filter((x) => x.value !== value),
    }));
    setTempPercentages(tempPercentages.filter((x) => x.value !== value));
  };

  return (
    <Fragment>
      {success ? (
        <div
          className="grid place-center w-full h-full"
          style={{ transform: "translateY(150%)" }}
        >
          <FaCheck
            size={40}
            color="white"
            style={{
              background: "#25da25",
              padding: "10px",
              borderRadius: "50%",
            }}
          />
          <div
            style={{
              fontWeight: "300",
              fontSize: "14px",
              margin: "5px 0",
            }}
          >
            Partner {message} Successfully!
          </div>
        </div>
      ) : (
        <Tabs className="w-full">
          <TabList>
            <Tab>Details</Tab>
            {submitOrEdit === "partnerSubmit" && <Tab>User</Tab>}
            <Tab>API</Tab>
            <Tab>Currency</Tab>
            <Tab>Percentage</Tab>
            <Tab>Limits</Tab>
          </TabList>

          <>
            <TabPanel>
              <div className="w-full flex flex-wrap justify-between">
                {submitOrEdit === "partnerSubmit" ? (
                  <>
                    <div className="usersTableRegisterFormItem">
                      <label className="usersTableRegisterFormLabel flex items-center" style={{ color: inputErrors.partnerName ? "red" : "inherit", gap: 3 }}>
                        Partner Name
                        <p style={{ color: "red" }}>{inputErrors.partnerName}</p>
                      </label>
                      <input
                        name="partnerName"
                        onChange={(e) => handleChange(e)}
                        value={partnerData.partnerName}
                        type="text"
                        className="usersTableRegisterFormInput"
                        style={{ borderColor: inputErrors.partnerName ? "red" : "#d7d7d7" }}
                      />
                    </div>
                    <div className="usersTableRegisterFormItem">
                      <label className="usersTableRegisterFormLabel flex items-center" style={{ color: inputErrors.email ? "red" : "inherit", gap: 3 }}>
                        Email
                        <p style={{ color: "red" }}>{inputErrors.email}</p>
                      </label>
                      <input
                        name="email"
                        onChange={(e) => handleChange(e)}
                        value={partnerData.email}
                        type="text"
                        className="usersTableRegisterFormInput"
                        style={{ borderColor: inputErrors.email ? "red" : "#d7d7d7" }}
                      />

                    </div>
                    <div className="usersTableRegisterFormItem">
                      <label className="usersTableRegisterFormLabel">
                        Address
                      </label>
                      <input
                        name="address"
                        onChange={(e) => handleChange(e)}
                        value={partnerData.address}
                        type="text"
                        className="usersTableRegisterFormInput"
                      />
                    </div>
                    <div className="usersTableRegisterFormItem">
                      <label className="usersTableRegisterFormLabel flex items-center" style={{ color: inputErrors.city ? "red" : "inherit", gap: 3 }}>
                        City
                        <p style={{ color: "red" }}>{inputErrors.city}</p>
                      </label>
                      <input
                        name="city"
                        onChange={(e) => handleChange(e)}
                        value={partnerData.city}
                        type="text"
                        className="usersTableRegisterFormInput"
                        style={{ borderColor: inputErrors.city ? "red" : "#d7d7d7" }}
                      />
                    </div>
                    <div
                      className="usersTableRegisterFormItem"
                      style={{ marginLeft: "-7px", width: "50%" }}
                    >
                      <label
                        className="usersTableRegisterFormLabel flex items-center" style={{ marginBottom: 11, color: inputErrors.country ? "red" : "inherit", gap: 3 }}>
                        Country
                        <p style={{ color: "red" }}>{inputErrors.country}</p>
                      </label>
                      <MainSelect
                        placeholder="Select a Country"
                        cls="top-450"
                        isClearable={true}
                        error={inputErrors.country ? true : false}
                        isSearchable={true}
                        options={balkanCountries}
                        onChange={(e) => {
                          if (e === null) {
                            setPartnerData((prev) => ({
                              ...prev,
                              country: null,
                            }));
                          } else {
                            setPartnerData((prev) => ({
                              ...prev,
                              country: e,
                            }));
                            setPhonePrefix(e.code);
                          }
                        }}
                        getOptionLabel={(e) => e.name}
                        getOptionValue={(e) => e.code}
                        defaultValue={partnerData?.country}
                        style={{ borderColor: inputErrors.city ? "red" : "#d7d7d7" }}
                      />
                    </div>
                    <div className="usersTableRegisterFormItem">
                      <label className="usersTableRegisterFormLabel flex items-center" style={{ marginBottom: 7, color: inputErrors.phone1 ? "red" : "inherit", gap: 3 }}>
                        Phone 1
                        <p style={{ color: "red" }}>{inputErrors.phone1}</p>
                      </label>
                      <PhoneInput
                        placeholder="Enter phone number"
                        name="phone1"
                        defaultCountry={phonePrefix}
                        onChange={(e) =>
                          setPartnerData((prev) => ({
                            ...prev,
                            phone1: e,
                          }))
                        }
                        value={partnerData?.phone1}
                        className="usersTableRegisterFormInput"
                        style={{ borderColor: inputErrors.phone1 ? "red" : "#d7d7d7" }}
                      />
                    </div>
                    <div className="usersTableRegisterFormItem">
                      <label className="usersTableRegisterFormLabel">
                        Phone 2
                      </label>
                      <PhoneInput
                        placeholder="Enter phone number"
                        name="phone2"
                        defaultCountry={phonePrefix}
                        onChange={(e) =>
                          setPartnerData((prev) => ({
                            ...prev,
                            phone2: e,
                          }))
                        }
                        value={partnerData?.phone2}
                        className="usersTableRegisterFormInput"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="usersTableRegisterFormItem">
                      <label className="usersTableRegisterFormLabel flex items-center" style={{ color: inputErrors.partnerName ? "red" : "inherit", gap: 3 }}>
                        Partner Name
                        <p style={{ color: "red" }}>{inputErrors.partnerName}</p>
                      </label>
                      <input
                        name="partnerName"
                        onChange={(e) => handleChange(e)}
                        value={partnerData.partnerName}
                        type="text"
                        className="usersTableRegisterFormInput"
                        style={{ borderColor: inputErrors.partnerName ? "red" : "#d7d7d7" }}
                      />
                    </div>
                    <div className="usersTableRegisterFormItem">
                      <label className="usersTableRegisterFormLabel flex items-center" style={{ color: inputErrors.email ? "red" : "inherit", gap: 3 }}>
                        Email
                        <p style={{ color: "red" }}>{inputErrors.email}</p>
                      </label>
                      <input
                        name="email"
                        onChange={(e) => handleChange(e)}
                        value={partnerData.email}
                        type="text"
                        className="usersTableRegisterFormInput"
                        style={{ borderColor: inputErrors.email ? "red" : "#d7d7d7" }}
                      />

                    </div>
                    <div className="usersTableRegisterFormItem">
                      <label className="usersTableRegisterFormLabel">
                        Address
                      </label>
                      <input
                        name="address"
                        onChange={(e) => handleChange(e)}
                        value={partnerData.address}
                        type="text"
                        className="usersTableRegisterFormInput"
                      />
                    </div>
                    <div className="usersTableRegisterFormItem">
                      <label className="usersTableRegisterFormLabel flex items-center" style={{ marginBottom: 7, color: inputErrors.phone1 ? "red" : "inherit", gap: 3 }}>
                        Phone 1
                        <p style={{ color: "red" }}>{inputErrors.phone1}</p>
                      </label>
                      <PhoneInput
                        placeholder="Enter phone number"
                        name="phone1"
                        defaultCountry={phonePrefix}
                        onChange={(e) =>
                          setPartnerData((prev) => ({
                            ...prev,
                            phone1: e,
                          }))
                        }
                        value={partnerData?.phone1}
                        className="usersTableRegisterFormInput"
                        style={{ borderColor: inputErrors.phone1 ? "red" : "#d7d7d7" }}
                      />
                    </div>
                    <div className="usersTableRegisterFormItem">
                      <label className="usersTableRegisterFormLabel">
                        Phone 2
                      </label>
                      <PhoneInput
                        placeholder="Enter phone number"
                        name="phone2"
                        defaultCountry={phonePrefix}
                        onChange={(e) =>
                          setPartnerData((prev) => ({
                            ...prev,
                            phone2: e,
                          }))
                        }
                        value={partnerData?.phone2}
                        className="usersTableRegisterFormInput"
                      />
                    </div>
                  </>
                )}
              </div>
            </TabPanel>
            {submitOrEdit === "partnerSubmit" && (
              <TabPanel className="flex flex-wrap gap-x-20">
                <PartnerUserFields
                  onChange={(e) => handleUserChange(e)}
                  partnerChange={handleChoosenPartner}
                  partnerData={partnerData}
                  partners={partners}
                  setPartnerData={setPartnerData}
                  getRoles={getRoles}
                  submitOrEdit="partnerSubmit"
                />
              </TabPanel>
            )}
            <TabPanel>
              <PartnerApiFields
                partnerData={partnerData}
                setPartnerData={setPartnerData}
              />
            </TabPanel>
            <TabPanel>
              <PartnerCurrencies
                partnerData={partnerData}
                handleListChange={handleListChange}
                removeCurrency={removeCurrency}
                tempCurrencies={tempCurrencies}
                setTempCurrencies={setTempCurrencies}
              />
            </TabPanel>
            <TabPanel>
              <PartnerPercentage
                handlePercentageChange={handlePercentageChange}
                partnerData={partnerData}
                setPartnerData={setPartnerData}
                removePercentage={removePercentage}
                tempPercentages={tempPercentages}
                setTempPercentages={setTempPercentages}
              />
            </TabPanel>
            <TabPanel>
              <PartnerLimits
                handlePercentageChange={handlePercentageChange}
                partnerData={partnerData}
                setPartnerData={setPartnerData}
                removePercentage={removePercentage}
                tempPercentages={tempPercentages}
                setTempPercentages={setTempPercentages}
              />
            </TabPanel>
          </>
        </Tabs>
      )}
    </Fragment>
  );
}

export default UserRegisterFields;

