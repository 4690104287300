import React, { Fragment } from "react";
import CoinsTable from "./CoinsTable";

function Coins() {
  return (
    <div className="users">
      <CoinsTable />
    </div>
  );
}

export default Coins;

