import React, {useContext, useEffect, useState} from "react";
import {SocketContext} from "../../../context/socket";

const Rates = ({}) => {
    const socket = useContext(SocketContext);
    const [fiat, setFiat] = useState('');
    const [rates, setRates] = useState([]);

    useEffect(() => {
        if (socket) {
            socket.fiatPrice((data) => {
                setFiat(data);
            });

        }

        setRates([
            {
                symbol: 'EUR',
                name: "EUR/USD",
                rate: 0,
            },
            {
                symbol: 'USD',
                name: "USD/EUR",
                rate: 0,
            },
            {
                symbol: 'MKD',
                name: "EUR/MKD",
                rate: 0,
            }
        ]);
    }, []);

    useEffect(() => {
        if (fiat && rates.length) {
            const newRates = rates.map((rate) => {
                let symbol = rate.symbol

                if (symbol === "EUR") {
                    symbol = 'USD';
                }

                const live = fiat.find((rr) => rr.symbol === symbol)
                if (live) {
                    if (rate.symbol === 'EUR') {
                        rate.rate = parseFloat(1 / live.rate).toFixed(4);
                    } else {
                        rate.rate = parseFloat(live.rate).toFixed(4);
                    }
                }

                return rate;
            });
            setRates(newRates)
        }
    }, [fiat])


    return (
        <div
            className={"rightSidebarRates flex w-full flex-column"}>
            <>
                {rates.map((rate) => (
                        <div className="flex items-center gap-10 justify-between" style={{fontSize: 14}}>
                            <span>{rate.name}</span>
                            <span>{rate.rate}</span>
                        </div>
                    )
                )}
            </>
        </div>
    )
}


export default Rates;
