import React, {useState} from 'react'
import {FaTimes} from 'react-icons/fa'
import {ImArrowRight} from 'react-icons/im'
import {TbArrowNarrowLeft} from 'react-icons/tb'
import {ClipLoader} from 'react-spinners'
import iconGenerator from '../../utils/iconGenerator'
import Button from './Button'
import {NumberFormatter} from './NumberFormat'
import {makeSmartContractTransaction, makeTRXTransaction,} from "../../utils/tron";
import {publicRequest} from "../../utils/requestMethods";
import Swal from 'sweetalert2'


function LedgerSteps({
                         step,
                         setStep,
                         loading,
                         data,
                         setLoading,
                         connectLedger,
                         walletAddress,
                         setTransaction,
                         setAction,
                         setError,
                     }) {
    const [rejected, setRejected] = useState(false);


    const makeTransaction = async () => {
        Swal.fire({
            title: `Are you sure you want to sign this transaction?`,
            icon: 'question',
            showDenyButton: true,
            confirmButtonText: "Yes",
            denyButtonText: `Cancel`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);
                if (["USDT", "USDC", "USDD"].includes(data.symbol.split('/')[0])) {
                    await makeSmartContractTransaction(
                        data,
                        walletAddress
                    ).then(async (transaction) => {
                        if (transaction?.error) {
                            // if (transaction?.error === 502) {
                            //     await publicRequest.post(
                            //         `/transactions/declined/${data._id}`,
                            //     );
                            // }

                            setError(transaction.message);
                            setRejected(true);
                            setLoading(false);
                            setStep(5);
                        } else {
                            data["txId"] = transaction.txid;
                            let response = await publicRequest.put(
                                `/transactions/approval/dex/${data._id}`,
                                data
                            );
                            setTransaction(response.data)

                            if (response.status === 200) {
                                setAction({
                                    type: "edited",
                                    id: response.data._id,
                                    data: response.data,
                                });

                                setStep(3);
                                setLoading(false);
                            }
                        }
                    }).catch((error) => {
                        console.log(error);
                        setRejected(true);
                        setLoading(false);
                        setStep(5);
                        setError(error.message);
                    });
                } else {
                    try {
                        const transaction = await makeTRXTransaction(
                            data,
                            walletAddress
                        );
                        data["txId"] = transaction.txid;
                        let response = await publicRequest.put(
                            `/transactions/approval/dex/${data._id}`,
                            data
                        );
                        setTransaction(response.data)
                        if (response.status === 200) {
                            setAction({
                                type: "edited",
                                id: response.data._id,
                                data: response.data,
                            });
                        }
                    } catch (e) {
                        console.log(e);
                    } finally {
                        setStep(3);
                        setLoading(false);
                    }
                }
            }
        })

    };

    return (
        step === 1 ? (
            <>
                <div
                    onClick={() => setStep(-1)}
                    style={{position: "absolute", top: "10px", left: "10px"}}
                    className="cursor-pointer"
                >
                    <TbArrowNarrowLeft size={24} color="777777"/>
                </div>
                <div className="flex flex-column items-center justify-center w-full h-full">
                    <h4>Connect to Ledger</h4>
                    <div
                        style={{
                            background: "#f7f8fa",
                            padding: "20px 0",
                            marginTop: "20px",
                            width: "85%",
                            textAlign: "center",
                            fontSize: "12px",
                            display: "grid",
                            color: "#9c9c9c",
                        }}
                    >
                        <img
                            src={require("../../assets/ledger.gif")}
                            style={{maxWidth: "66%", margin: "0 auto"}}
                        />
                        <span
                            style={{
                                marginTop: "30px",
                                padding: "0 85px",
                            }}
                        >
                            Make sure your Ledger is unlocked, and connect the device to
                            your computer.
                        </span>
                        <div className="or-divider" style={{margin: "30px 0"}}>
                            and
                        </div>
                        <img
                            src={require("../../assets/selecttron.gif")}
                            style={{maxWidth: "66%", margin: "0 auto"}}
                        />
                        <span
                            style={{
                                marginTop: "30px",
                                padding: "0 85px",
                            }}
                        >
                            Open the Tron app in Ledger.
                        </span>
                    </div>
                    {loading ? (
                        <div
                            style={{
                                position: "absolute",
                                bottom: "50px",
                                width: "100%",
                                left: "50%",
                            }}
                        >
                            <ClipLoader size={24} color="orange"/>
                        </div>
                    ) : (
                        <div
                            style={{
                                position: "absolute",
                                bottom: "50px",
                                width: "100%",
                                left: "4.5%",
                            }}
                        >
                            <Button title="Re-Check" onClick={connectLedger}/>
                        </div>
                    )}
                </div>
            </>
        ) : step === 2
            ?
            (<>
                <div
                    onClick={() => setStep(-1)}
                    style={{position: "absolute", top: "10px", left: "10px"}}
                    className="cursor-pointer"
                >
                    <TbArrowNarrowLeft size={24} color="777777"/>
                </div>
                <div className="flex flex-column items-center justify-center w-full h-full">
                    <h4 style={{position: "absolute", top: "10px"}}>
                        Confirm transaction info
                    </h4>
                    <div
                        className="flex justify-center items-center flex-column items-center w-full"
                        style={{
                            borderBottom: "1px solid #d7d7d7",
                            paddingBottom: "10px",
                            marginTop: "25px"
                        }}
                    >
                        <img
                            src={iconGenerator(128, "icon", data.symbol.split("/")[0])}
                            alt=""
                            className="dashboardChartHeaderIcon"
                            style={{width: "70px", height: "70px"}}
                        />
                        <span>Transfering out</span>
                        <span style={{marginTop: "10px", fontWeight: "bold"}}>
                            <NumberFormatter value={data.quantity_sent}/>{" "}
                            {data.symbol.split("/")[0]}
                        </span>
                    </div>
                    <div className="flex gap-10 w-full items-center">
                        <div
                            style={{
                                background: "#f7f8fa",
                                marginTop: "20px",
                                textAlign: "left",
                                fontSize: "12px",
                                display: "grid",
                                color: "#9c9c9c",
                                flex: 1,
                                wordBreak: "break-all",
                                padding: "10px",
                                gap: "10px",
                            }}
                        >
                            <span style={{fontWeight: "600"}}>From</span>
                            <span>{walletAddress}</span>
                        </div>
                        <div style={{width: "fit-content", marginTop: "20px"}}>
                            <ImArrowRight size={30} color="#ddd"/>
                        </div>
                        <div
                            style={{
                                background: "#f7f8fa",
                                marginTop: "20px",
                                textAlign: "right",
                                fontSize: "12px",
                                display: "grid",
                                color: "#9c9c9c",
                                flex: 1,
                                wordBreak: "break-all",
                                padding: "10px",
                                gap: "10px",
                            }}
                        >
                            <span style={{fontWeight: "600"}}>To</span>
                            <span>{data?.address_to?.address}</span>
                        </div>
                    </div>

                    {loading ? (
                        <div
                            className="flex items-center gap-5"
                            style={{position: "absolute", bottom: "20%"}}
                        >
                            <>
                                <ClipLoader size={24} color="orange"/>
                                Waiting for confirmation.
                            </>
                        </div>
                    ) : (
                        <>
                            <div
                                className="flex items-center gap-5"
                                style={{position: "absolute", bottom: "20%"}}
                            >
                                {rejected && (
                                    <>
                                        <FaTimes
                                            color="white"
                                            size={14}
                                            style={{
                                                background: "red",
                                                padding: "5px",
                                                borderRadius: "50%",
                                            }}
                                        />
                                        Rejected
                                    </>
                                )}
                            </div>
                            <div
                                style={{
                                    position: "absolute",
                                    bottom: "10px",
                                    width: "92%",
                                    left: "4.5%",
                                }}
                            >
                                <div className="flex items-center gap-10">
                                    <Button title="Reject" onClick={() => {
                                        setStep(-1);
                                        setLoading(false)
                                    }}/>
                                    <Button
                                        title="Sign"
                                        onClick={() => makeTransaction(data._id)}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </>)
            :
            <></>
    )
}

export default LedgerSteps
