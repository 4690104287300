import React, { useEffect, useMemo, useRef, useState, } from "react";
import { useSelector } from "react-redux";
import Partners from "./Partners";
import MainModal from "../../ReusableComponents/MainModal";
import { FaPlusCircle, FaSave, FaTrash } from "react-icons/fa";
import { publicRequest } from "../../../utils/requestMethods";
import MainSelect from "../../ReusableComponents/Select";
import { balkanCountries } from "../../ReusableComponents/Countries";
import PercentageAddFields from "../PercetageTable/PercentageAddFields";
import Swal from "sweetalert2";
import MainTable from "../../ReusableComponents/Tables/MRT_Main";

const CryptoPercentages = ({
    groups,
    percentageType,
    isLoading,
    setIsLoading,
}) => {
    const user = useSelector((state) => state.user.currentUser);

    const popUpInformation = useRef("");
    const [selectedPartner, setSelectedPartner] = useState([]);
    const [searchFilter, setSearchFilter] = useState("");
    const [loading, setLoading] = useState(false);
    const [submitOrEdit, setSubmitOrEdit] = useState("");
    const [countryFilter, setCountryFilter] = useState(null);
    const [cryptoPercentages, setCryptoPercentages] = useState([]);
    const [mainPercentages, setMainPercentages] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState("");
    const [editSuccess, setEditSuccess] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [fail, setFail] = useState(false);
    const [addLoading, setAddLoading] = useState(false);
    const [requestURL, setRequestURL] = useState("v2/percentages/crypto");
    const [action, setAction] = useState({ type: "", id: null, data: null });
    const [noData, setNoData] = useState(false)
    const [translateLabelNoData, setTranslateLabelNoData] = useState("Select a partner to show percentages!")


    const logTableRef = useRef();

    const mainColumns = useMemo(
        () => [
            {
                accessorKey: "percentageSide",
                header: "Side",
                size: 50,
            },
            {
                accessorKey: "percentageName",
                header: "Name",
                enableGrouping: false,
                size: 60,
            },
            {
                accessorKey: "percentageValue",
                header: "Percentage (%)",
                size: 120,
                accessorFn: (item) => (
                    <input
                        name="percentageValue"
                        value={item?.percentageValue}
                        type="text"
                        onFocus={(e) =>
                            setTimeout(() => {
                                e.target.select();
                            }, 100)
                        }
                        onClick={(e) =>
                            setTimeout(() => {
                                e.target.select();
                            }, 100)
                        }
                        className="usersTableRegisterFormInput"
                        style={{ width: "70px" }}
                        onChange={(e) => handleInputChange(item, e)}
                    />
                ),
            },
            {
                accessorKey: "percentagePartner",
                header: "Partner (%)",
                size: 100,
                accessorFn: (item) => (
                    <input
                        name="percentagePartner"
                        value={item.percentagePartner}
                        type="text"
                        onFocus={(e) =>
                            setTimeout(() => {
                                e.target.select();
                            }, 100)
                        }
                        onClick={(e) =>
                            setTimeout(() => {
                                e.target.select();
                            }, 100)
                        }
                        className="usersTableRegisterFormInput"
                        style={{ width: "70px" }}
                        onChange={(e) => handleInputChange(item, e)}
                    />
                ),
            },
            {
                accessorKey: "percentageExpenses",
                header: "Expenses (%)",
                size: 110,
                accessorFn: (item) => (
                    <input
                        name="percentageExpenses"
                        value={item.percentageExpenses}
                        type="text"
                        onFocus={(e) =>
                            setTimeout(() => {
                                e.target.select();
                            }, 100)
                        }
                        onClick={(e) =>
                            setTimeout(() => {
                                e.target.select();
                            }, 100)
                        }
                        className="usersTableRegisterFormInput"
                        style={{ width: "70px" }}
                        onChange={(e) => handleInputChange(item, e)}
                    />
                ),
            },
            {
                accessorKey: "fromValue",
                header: "From",
                size: 80,
                accessorFn: (item) => (
                    <input
                        name="fromValue"
                        value={item.fromValue}
                        type="text"
                        onFocus={(e) =>
                            setTimeout(() => {
                                e.target.select();
                            }, 100)
                        }
                        onClick={(e) =>
                            setTimeout(() => {
                                e.target.select();
                            }, 100)
                        }
                        className="usersTableRegisterFormInput"
                        style={{ width: "70px" }}
                        onChange={(e) => handleInputChange(item, e)}
                    />
                ),
            },
            {
                accessorKey: "toValue",
                header: "To",
                size: 80,
                accessorFn: (item) => (
                    <input
                        name="toValue"
                        value={item.toValue}
                        type="text"
                        onFocus={(e) =>
                            setTimeout(() => {
                                e.target.select();
                            }, 100)
                        }
                        onClick={(e) =>
                            setTimeout(() => {
                                e.target.select();
                            }, 100)
                        }
                        className="usersTableRegisterFormInput"
                        style={{ width: "70px" }}
                        onChange={(e) => handleInputChange(item, e)}
                    />
                ),
            },
            {
                accessorKey: " ",
                header: " ",
                size: 70,
                accessorFn: (item) => (
                    <div>
                        <FaTrash
                            onClick={() => handleDelete(item)}
                            size={15}
                            color="#0000008a"
                        />
                    </div>
                ),
            },
        ],
        [selectedPartner]
    );

    const [percentageBuyData, setPercentageBuyData] = useState({
        percentageName: "",
        fromValue: 0,
        toValue: 0,
        percentageValue: 0,
        percentagePartner: 0,
        percentageExpenses: 0,
        percentageSide: "BUY",
        percentageType: "crypto",
    });

    const [percentageSellData, setPercentageSellData] = useState({
        percentageName: "",
        fromValue: 0,
        toValue: 0,
        percentageValue: 0,
        percentagePartner: 0,
        percentageExpenses: 0,
        percentageSide: "SELL",
        percentageType: "crypto",
    });

    const handleInfo = (event, data) => {
        popUpInformation.current = data;
        setIsOpenPopUp(true);
    };

    useEffect(() => {
        if (selectedPartner.length === 1) {
            setNoData(false)
            setTranslateLabelNoData("No results found")
            setRequestURL(`v2/percentages/${selectedPartner}/crypto/`);
        } else {
            setTranslateLabelNoData("Select a partner to show percentages!")
            setNoData(true)
        }
    }, [selectedPartner]);

    const handleCountryFilter = (e) => {
        if (e === null) {
            setCountryFilter(null);
        } else {
            setCountryFilter(e.name);
        }
    };

    const handleInputChange = (item, e) => {
        const { name, value } = e.target;

        item[name] = value;

        setAction({
            type: "editedRow",
            id: item._id,
            data: item,
            name: name,
            value: value,
        });
    };

    const savePartnerPercentages = async () => {
        try {
            setAddLoading(true);
            let response;

            let cryptoPercentages = Object.entries(
                logTableRef.current.getRowModel().rowsById
            ).map((x) => x[1].original);

            if (selectedPartner.length) {
                response = await publicRequest.patch("percentage/update/partners", {
                    partners: selectedPartner,
                    type: "crypto",
                    percentages: cryptoPercentages.slice(0, -2),
                });
            } else {
                response = await publicRequest.patch("percentage/update/all", {
                    type: "crypto",
                    percentages: cryptoPercentages.slice(0, -2),
                });
            }
            setSaveSuccess(true);
            setTimeout(() => {
                setSaveSuccess(false);
            }, 2000);
            setAddLoading(false);
        } catch (e) {
            setAddLoading(false);
            console.log(e);
        }
    };

    const openModal = (params) => {
        setIsOpen(true);
        setSubmitOrEdit(params);
        setPercentageBuyData({
            percentageName: "",
            fromValue: 0,
            toValue: 0,
            percentageValue: 0,
            percentagePartner: 0,
            percentageExpenses: 0,
            percentageSide: "BUY",
            percentageType: "crypto",
        });
        setPercentageSellData({
            percentageName: "",
            fromValue: 0,
            toValue: 0,
            percentageValue: 0,
            percentagePartner: 0,
            percentageExpenses: 0,
            percentageSide: "SELL",
            percentageType: "crypto",
        });
    };

    const closeModal = () => {
        setIsOpen(false);
        setPercentageBuyData({
            percentageName: "",
            fromValue: 0,
            toValue: 0,
            percentageValue: 0,
            percentagePartner: 0,
            percentageExpenses: 0,
            percentageSide: "Buy",
            percentageType: "crypto",
        });
        setPercentageSellData({
            percentageName: "",
            fromValue: 0,
            toValue: 0,
            percentageValue: 0,
            percentagePartner: 0,
            percentageExpenses: 0,
            percentageSide: "SELL",
            percentageType: "crypto",
        });
    };

    const successEdit = (params) => {
        setLoading(true);
        if (params === "Fail") {
            setFail(true);
        } else {
            setSuccess(true);
        }
        setMessage(params);
        setTimeout(() => {
            setSuccess(false);
            setFail(false);
            setIsOpen(false);
            setLoading(false);
        }, 2000);
    };

    const handleAddPercentage = async () => {
        if (submitOrEdit === "edit") {
            if (percentageBuyData._id) {
                await addBuy();
            } else {
                await addSell();
            }
            setEditSuccess(true);
            setTimeout(() => {
                setEditSuccess(false);
            }, 2000);
        } else {
            await addBuy();
            await addSell();
            setEditSuccess(true);
            setTimeout(() => {
                setEditSuccess(false);
            }, 2000);
        }
    };

    const addBuy = async () => {
        try {
            if (submitOrEdit === "submit") {
                const response = await publicRequest.post(
                    "percentage/",
                    percentageBuyData
                );
                if (!response) {
                    successEdit("Fail");
                } else {
                    successEdit("Added");
                    setAction({
                        type: "added",
                        id: 1,
                        data: response.data,
                    });
                }
            } else {
                const response = await publicRequest.patch(
                    `percentage/${percentageBuyData._id}`,
                    percentageBuyData
                );
                if (!response) {
                    successEdit("Fail");
                } else {
                    successEdit("Edited");
                    setAction({
                        type: "edited",
                        id: response.data._id,
                        data: response.data,
                    });
                }
            }
        } catch (e) {
            setSuccess("Fail");
            console.log(e);
        }
    };

    const addSell = async () => {
        try {
            if (submitOrEdit === "submit") {
                const response = await publicRequest.post(
                    "percentage/",
                    percentageSellData
                );
                if (!response) {
                    successEdit("Fail");
                } else {
                    successEdit("Added");
                    setAction({
                        type: "added",
                        id: 1,
                        data: response.data,
                    });
                }
            } else {
                const response = await publicRequest.patch(
                    `percentage/${percentageSellData._id}`,
                    percentageSellData
                );
                if (!response) {
                    successEdit("Fail");
                } else {
                    successEdit("Edited");
                    setAction({
                        type: "edited",
                        id: response.data._id,
                        data: response.data,
                    });
                }
            }
        } catch (e) {
            setSuccess("Fail");
            console.log(e);
        }
    };

    const filterName = (e) => {
        setSearchFilter(e.target.value);
    };

    const handleDelete = (propsData) => {
        const deleteFunc = async () => {
            try {
                await publicRequest
                    .post(`percentage/${propsData._id}`, {
                        partners: selectedPartner,
                        type: "crypto",
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            setAction({
                                type: "deleted",
                                id: propsData._id,
                                data: {},
                            });
                        }
                    });
            } catch (err) {
                console.log(err);
            }
        };

        Swal.fire({
            title: `Do you want to delete ${propsData.percentageName}?`,
            showDenyButton: true,
            confirmButtonText: "Accept",
            denyButtonText: `Refuse`,
        }).then((result) => {
            if (result.isConfirmed) {
                deleteFunc();
            }
        });
    };

    return (
        <div className="flex flex-column items-start gap-10 mt-10 h-full">
            <div className="w-full">
                {saveSuccess && (
                    <div className="success-alert">
                        {selectedPartner.length === 1
                            ? "Partner"
                            : selectedPartner.length > 1
                                ? "Partners"
                                : "Percentages"}{" "}
                        Saved Successfully!
                    </div>
                )}
                <div style={{ maxWidth: "fit-content", marginLeft: "auto" }}>
                    <div className="flex items-center gap-10">
                        <MainModal
                            closable={true}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            onRequestClose={closeModal}
                            closeModal={closeModal}
                            footer={true}
                            successEdit={successEdit}
                            customSubmit={handleAddPercentage}
                            success={success}
                            user={user}
                            big={true}
                            isLoading={loading}
                            entity={cryptoPercentages}
                            setEntity={setCryptoPercentages}
                            endpoint="percentage"
                            editEndpoint="percentage"
                            submitOrEdit={submitOrEdit}
                            content={
                                <div className="flex gap-10 flex-wrap justify-between">
                                    <h3 className="usersTableRegisterClientFormTitle">
                                        {submitOrEdit === "submit"
                                            ? "Add New Percentage"
                                            : "Edit Percentage"}
                                    </h3>

                                    <PercentageAddFields
                                        percentageData={[]}
                                        setPercentageData={() => console.log("")}
                                        percentageBuyData={percentageBuyData}
                                        percentageSellData={percentageSellData}
                                        setPercentageBuyData={setPercentageBuyData}
                                        setPercentageSellData={setPercentageSellData}
                                        userInfo={user}
                                        submitOrEdit={submitOrEdit}
                                        success={success}
                                        message={message}
                                        groups={groups}
                                        percentageType={percentageType}
                                        isLoading={isLoading}
                                        setIsLoading={setIsLoading}
                                        fail={fail}
                                    />
                                </div>
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="flex items-start gap-20 h-full w-full">
                {(user.isAdmin || user.isMainOffice) && (
                    <div className="flex flex-column " style={{ width: "20%" }}>
                        <div>
                            <MainSelect
                                placeholder="Filter by Country"
                                isClearable={true}
                                isSearchable={true}
                                options={balkanCountries}
                                getOptionLabel={(e) => e.name}
                                getOptionValue={(e) => e.name}
                                onChange={(e) => handleCountryFilter(e)}
                            />
                        </div>
                        <input
                            name="username"
                            type="text"
                            placeholder="Search partner by name..."
                            className="some-input"
                            onChange={(e) => filterName(e)}
                        />
                        <div
                            className="card partners-card"
                        >
                            <Partners
                                setSelectedPartner={setSelectedPartner}
                                selectedPartner={selectedPartner}
                                loadingProp={loading}
                                searchFilter={searchFilter}
                                countryFilter={countryFilter}
                                multiSelect={false}
                                setLocalCurrencies={() => console.log("ignore me")}
                            />
                        </div>
                    </div>
                )}

                <div
                    className="clientsTable"
                    style={{
                        overflowX: "hidden",
                        padding: 0,
                        width: user.isAdmin || user.isMainOffice ? "80%" : "100%",
                    }}
                >
                    <div
                        className="clientsTableMaterialTableContainer"
                        style={{ width: "100%", overflowX: "hidden" }}
                    >
                        <div
                            className={`card h-full ${loading ? "flex-center" : ""}`}
                            style={{
                                width: "calc(100% - 23px)",
                                minHeight: "550px",
                                margin: "0",
                            }}
                        >
                            <MainTable
                                columns={mainColumns}
                                requestURL={requestURL}
                                translateNoData={translateLabelNoData}
                                setAction={setAction}
                                noData={noData}
                                selectedPartner={selectedPartner}
                                action={action}
                                tableContainerRef={logTableRef}
                                tableHeight={"calc(100vh - 150px)"}
                                enableGrouping={true}
                                isSocketUpdatable={false}
                                renderFetchFooterToolbar={false}
                                renderCurrencyFooterToolbar={false}
                                grouping={["percentageSide"]}
                                onRowClick={() => {
                                }}
                                toolbarActions={{
                                    search: true,
                                    columnsFilter: true,
                                    columnsVisible: true,
                                    density: true,
                                    fullScreen: true,
                                }}
                                renderTopToolbarCustomActions={[
                                    selectedPartner < 1 ? {} :
                                        {
                                            type: "add",
                                            name: "Add",
                                            icon: <FaPlusCircle size={20} />,
                                            onClick: () => {
                                                openModal("submit");
                                            },
                                        },
                                    selectedPartner < 1 ? {} :
                                        {
                                            type: "save",
                                            name: addLoading ? "Saving..." : "Save",
                                            icon: <FaSave size={20} />,
                                            onClick: savePartnerPercentages,
                                        },
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CryptoPercentages;

