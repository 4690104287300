import React from "react";
import { FaSave } from "react-icons/fa";
import Button from "../../ReusableComponents/Button";

function Settings({ saveGlobalSettings, settings, handleInputChange, saveSuccess, saveLoading }) {
  return (
    <div
      className="users"
      style={{
        margin: '0 auto',
        width: "98%",
      }}
    >
      {saveSuccess && (
        <div className="success-alert">
          Settings Saved Successfully!
        </div>
      )}
      <div
        className="usersUserListContainer"
        style={{ paddingBottom: "" }}
      ></div>
      <div
        className="card"
        style={{
          padding: "10px 20px 30px 20px",
          marginTop: "20px",
          width: "calc(100% - 40px)",
        }}
      >
        <div className="flex flex-column  flex-wrap gap-20">
          <div className="flex items-center jusitfy-between">
            <h3 className="custom-heading w-full">Details</h3>
            <div style={{ maxWidth: "fit-content", marginLeft: "auto" }}>
              <Button
                title={saveLoading ? "Saving..." : "Save"}
                icon={<FaSave color="white" size={20} />}
                onClick={saveGlobalSettings}
                cls=""
              />
            </div>
          </div>

          <div
            className="usersTableRegisterFormItem"
            style={{ width: "calc(33% - 10px)" }}
          >
            <label className="usersTableRegisterFormLabel">Partner Name</label>
            <input
              name="name"
              type="text"
              value={settings?.name}
              onChange={(e) => {
                handleInputChange(e);
              }}
              className="usersTableRegisterFormInput"
              style={{ width: "98%" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;

