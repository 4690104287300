import print from "../printer";
import {Cut, Line, Printer, QRCode, Row, Text} from "react-thermal-printer";
import moment from "moment/moment";

export const withdrawalPrint = async (order) => {
    const transactionId = order._id;
    const symbols = order.currency.symbol;

    const TXCode = btoa((JSON.stringify({id: transactionId, type: 'withdrawal'})));

    await print(
        <Printer type="epson" width={46} characterSet="iso8859_2_latin2">
            <Text bold={true} align={'center'}
                  size={{width: 1, height: 1}}>WITHDRAWAL</Text>
            <Line/>
            <Row left={'Date'} right={moment(order.createdAt).format('DD.MM.Y')}/>
            <Row left={<Text bold={true}>TID</Text>} right={<Text>{order.order_number}</Text>}/>
            <Line/>
            <Row left={"Amount"} right={`${order?.amount} ${symbols}`}/>
            <Row left={"Fee"} right={`${order.fee} %`}/>
            <Row left={<Text bold={true}>Summary</Text>} right={<Text>{order.summary} {symbols}</Text>}/>
            <Line/>
            <QRCode cellSize={6} model={'model2'} align={"center"} content={TXCode}/>
            <Line/>
            <Text align={"center"}>Thanks for using our service</Text>
            <Cut lineFeeds={4}/>
        </Printer>
    );
}
