import React, { Fragment } from "react";
import Details from "./Content/Details";

function Content({ data }) {
  return (
    <Fragment>
      <Details data={data} />
    </Fragment>
  );
}

export default Content;

