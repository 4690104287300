const coins = require("./coins.json");

export const getCoinMarketCapIcon = (symbol, size = 128) => {
    const path = coins.data.find((coin) => coin.symbol === symbol?.toUpperCase());

    if (symbol?.toUpperCase() === 'MATIC') {
        size = 128;
    }

    if (path === undefined) {
        return require("../../assets/dex.png");
    }

    return `https://s2.coinmarketcap.com/static/img/coins/${size}x${size}/${path.id}.png`;
};

