import React, {useEffect, useRef, useState} from "react";
import {Line, LineChart, ResponsiveContainer} from "recharts";
import {TfiArrowsHorizontal} from "react-icons/tfi";
import {publicRequest} from "../../../utils/requestMethods";
import "./exchangeChart.css";
import {BsFillPrinterFill} from "react-icons/bs";
import {TbArrowsSort} from "react-icons/tb";
import Button from "../../ReusableComponents/Button";
import {FaPlus, FaTimes} from "react-icons/fa";
import MainModal from "../../ReusableComponents/MainModal";
import {exchangePrint} from "../../../utils/templates/exchnage";

import {SortableContainer, SortableElement, sortableHandle,} from "react-sortable-hoc";
import {arrayMoveImmutable} from "array-move";
import MainSelect from "../../ReusableComponents/Select";
import {useDispatch, useSelector} from "react-redux";
import {ClipLoader} from "react-spinners";
import iconGenerator from "../../../utils/iconGenerator";
import {reFetch, updatePairSorting} from "../../../redux/userRedux";
import {sortingPairs} from "../../../utils/sorting";
import {NumberFormatter} from "../../ReusableComponents/NumberFormat";
import {NumericFormat} from "react-number-format";
import {debounce} from 'lodash'; // Import the debounce function from the lodash library

const DragHandle = sortableHandle(() => (
    <div title="Drag to change position." className="dragHandle">
        <TfiArrowsHorizontal
            style={{transform: "translateX(5%)"}}
            size={15}
            color="#444"
        />
    </div>
));

const SortableItem = SortableElement(
    ({
         rowIndex,
         item,
         buySellIndex,
         clickedBuy,
         clickedSell,
         handleSell,
         handleBuy,
         handleInputChange,
         switchCurrencies,
         rechart,
         postExchange,
         amount,
         total,
         handleSwitchCurrenciesClick,
         handleRateChange,
         handleRemovePair,
         setShowCoinOptions,
         showCoinOptions,
         handleRateOnFocus,
         inputRef,
         handleFocus,
         user,
         rateError
     }) => (
        <div
            key={rowIndex}
            className="exchange-charts"
            style={{
                width: "unset",
                borderRight:
                    buySellIndex === rowIndex && clickedBuy
                        ? "4px solid green"
                        : buySellIndex === rowIndex && clickedSell
                            ? "4px solid red"
                            : "",
            }}
        >
            <div
                className=" items-center justify-between"
                style={{display: showCoinOptions === item._id ? "flex" : "none"}}
            >
                <DragHandle/>
                <FaTimes
                    size={15}
                    color="#444"
                    className="cursor-pointer showOnHover"
                    onClick={() => handleRemovePair(item)}
                />
            </div>
            <div className="mainChartWrapper">
                <div
                    onDoubleClick={() => setShowCoinOptions(item._id)}
                    className="exchangeChartHeader"
                    style={{
                        width: buySellIndex === rowIndex ? "93%" : "",
                        background: buySellIndex === rowIndex ? "orange" : "",
                        boxShadow:
                            buySellIndex === rowIndex
                                ? "inset -10px 0px 30px 0 rgb(0 0 0 / 20%)"
                                : "",
                    }}
                >
                    <div className="exchangeFirstChartHeaderLeft w-full">
                        <div className="exchangeFirstChartImgContainer">
                            <img
                                src={iconGenerator(null, null, item.currency_from.symbol, true)}
                                width={45}
                                height={44}
                                className="exchangeFirstChartHeaderIcon"
                                alt={item.currency_from.symbol}
                            />
                            <img
                                src={iconGenerator(null, null, item.currency_to.symbol, true)}
                                width={35}
                                height={34}
                                className="exchangeFirstChartHeaderSmallIcon"
                                alt={item.currency_to.symbol}
                            />
                        </div>
                        <div
                            className="flex items-center justify-between"
                            style={{width: "100%"}}
                        >
                            <span
                                className="exchangeFirstChartHeaderTitle"
                                style={{
                                    color: buySellIndex === rowIndex ? "#fff" : "",
                                    paddingLeft: 15,
                                }}
                            >
                                {item.currency_from.symbol}/{item.currency_to.symbol}
                            </span>
                            {buySellIndex === rowIndex && (
                                <span
                                    className="exchangeFirstChartHeaderTitle"
                                    style={{color: buySellIndex === rowIndex ? "#fff" : ""}}
                                >
                                    {
                                        <NumberFormatter
                                            value={item?.account?.balance}
                                            suffix={` ${item?.account?.currency.symbol}`}
                                        />
                                    }
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex" style={{justifyContent: "space-between"}}>
                    <div className="exchangeChartItems">
                        <div className="exchangeChartHeaderChangeWrapper">
                            <span className="exchangeChartHeaderChangeTitle">
                                CHANGE (24H)
                            </span>
                            <p className="exchangeChartHeaderChangeCalc">-0%</p>
                        </div>
                        <div className="exchangeChartGraph">{rechart}</div>
                        <div className="dashboardChartBuySellWrapper">
                            <div
                                className="dashboardChartSell"
                                onClick={() => handleSell(rowIndex, item.selling_rate)}
                            >
                                <span className="dashboardChartSellTitle">SELL</span>
                                <p className="dashboardChartSellPrice">{item.selling_rate}</p>
                            </div>
                            <div
                                className="dashboardChartBuy"
                                onClick={() => handleBuy(rowIndex, item.buying_rate)}
                            >
                                <span className="dashboardChartBuyTitle">BUY</span>
                                <p className="dashboardChartBuyPrice">{item.buying_rate}</p>
                            </div>
                        </div>
                    </div>
                    {buySellIndex === rowIndex && (
                        <>
                            <div
                                style={{
                                    display: "none",
                                    // position:"fixed",
                                    // top:0,
                                    // left:0,
                                    // width:"100vw",
                                    // height:"100vh",
                                    // background:"#fff",
                                    // zIndex:"9999999999"
                                }}
                            ></div>
                            <div className="exchangeFirstChartRight">
                                <div className="exchangeFirstChartRightTop">
                                    <div className="exchangeFirstChartRightTopLeft">
                                        <img
                                            src={iconGenerator(
                                                null,
                                                null,
                                                switchCurrencies
                                                    ? item.currency_to.symbol
                                                    : item.currency_from.symbol,
                                                true
                                            )}
                                            width={35}
                                            height={35}
                                            alt={
                                                switchCurrencies
                                                    ? item.currency_to.symbol
                                                    : item.currency_from.symbol
                                            }
                                        />

                                        <span className="exchangeFirstChartRightTopSpan">
                                            {switchCurrencies
                                                ? item.currency_to.symbol
                                                : item.currency_from.symbol}
                                        </span>
                                    </div>
                                    <div
                                        className="exchangeFirstChartRightTopValueWrapper"
                                        style={{maxWidth: "103px"}}
                                    >
                                        <NumericFormat
                                            displayType={"input"}
                                            thousandSeparator=","
                                            decimalSeparator="."
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            value={amount > 1 || amount === undefined ? amount : ""}
                                            placeholder="0.00"
                                            className="exchangeFirstChartRightTopValue"
                                            onFocus={(e) => {
                                                handleFocus(e);
                                                console.log(e);
                                            }}
                                            onValueChange={(values, sourceInfo) =>
                                                handleInputChange(values, sourceInfo, rowIndex)
                                            }
                                            onBlur={(e) =>
                                                (e.target.value = e.target.value ? amount : 0)
                                            }
                                            style={{
                                                maxWidth: "150px",
                                                wordBreak: "break-all",
                                                textAlign: "right",
                                            }}
                                        />
                                        {/*                    <input*/}
                                        {/*                        className="exchangeFirstChartRightTopValue"*/}
                                        {/*                        onChange={(event) => handleInputChange(event, rowIndex)}*/}
                                        {/*                        value={amount}*/}
                                        {/*                        onFocus={(e) => handleFocus(e, amount)}*/}
                                        {/*                        onBlur={(e) =>*/}
                                        {/*                            (e.target.value = e.target.value ? amount : 0)*/}
                                        {/*                        }*/}
                                        {/*                        style={{maxWidth: "50px", wordBreak: "break-all"}}*/}
                                        {/*                    />*/}
                                        {/*                    /!* <span className="exchangeFirstChartRightTopValueSmall">*/}
                                        {/*  .00*/}
                                        {/*</span> *!/*/}
                                    </div>
                                </div>
                                <div className="exchangeFirstChartRightMiddle">
                                    <div
                                        className="exchangeFirstChartRightMiddleLeft"
                                        style={{padding: "3px"}}
                                        onClick={handleSwitchCurrenciesClick}
                                    >
                                        <TbArrowsSort color="#b8b8b8" size={30}/>
                                    </div>
                                    <div
                                        className="exchangeFirstChartRightMiddleValueWrapper"
                                        style={{width: "50%", maxWidth: "113px"}}
                                    >
                                        <input
                                            style={{
                                                background: "transparent",
                                                border: "0",
                                                padding: "0",
                                                marginTop: "0",
                                                textAlign: "right"
                                            }}
                                            name="rate"
                                            type="text"
                                            className="usersTableRegisterFormInput"
                                            onDoubleClick={() => {
                                                item.manualRate = false;
                                            }}
                                            onFocus={(e) => handleRateOnFocus(e, item)}
                                            onChange={(e) => handleRateChange(e, item)}
                                            value={clickedBuy ? item.buying_rate_input : item.selling_rate_input}
                                        />
                                        {/* <span className="exchangeFirstChartRightTopValue">
                      {clickedBuy ? buy : clickedSell && sell}
                    </span> */}
                                        <span
                                            style={{
                                                position: "absolute",
                                                color: "red",
                                                marginRight: "-7px",
                                                marginTop: "45px",
                                                fontSize: 10,
                                            }}>{rateError?.message}</span>
                                    </div>
                                </div>
                                <div className="exchangeFirstChartRightTop">
                                    <div className="exchangeFirstChartRightTopLeft">
                                        <img
                                            src={iconGenerator(
                                                null,
                                                null,
                                                switchCurrencies
                                                    ? item.currency_from.symbol
                                                    : item.currency_to.symbol,
                                                true
                                            )}
                                            width={35}
                                            height={35}
                                            alt={
                                                switchCurrencies
                                                    ? item.currency_from.symbol
                                                    : item.currency_to.symbol
                                            }
                                        />
                                        <span className="exchangeFirstChartRightTopSpan">
                                            {switchCurrencies
                                                ? item.currency_from.symbol
                                                : item.currency_to.symbol}
                                        </span>
                                    </div>
                                    <div
                                        className="exchangeFirstChartRightTopValueWrapper"
                                        style={{maxWidth: "104px"}}
                                    >
                                        <span
                                            style={{
                                                maxWidth: "150px",
                                                wordBreak: "break-all",
                                                fontStyle: "14px",
                                            }}
                                        >
                                            <NumberFormatter value={amount > 0 ? total : 0}/>
                                        </span>
                                        {/* <span className="exchangeFirstChartRightTopValueSmall">
                      00
                    </span> */}
                                    </div>
                                </div>
                                {user.isAdmin || user.isMainOffice ? <div style={{height: 35}}></div> : (
                                    <div className="exchangeFirstChartRightBottom">
                                        <BsFillPrinterFill
                                            color="#b8b8b8"
                                            size={16}
                                            className="printerIcon"
                                            onClick={postExchange}
                                        />
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
);

const SortableList = SortableContainer(
    ({
         items,
         buySellIndex,
         clickedBuy,
         clickedSell,
         handleSell,
         handleBuy,
         handleInputChange,
         setSwitchCurrencies,
         switchCurrencies,
         rechart,
         amount,
         buy,
         total,
         sell,
         handleSwitchCurrenciesClick,
         setSocketStop,
         // handleSetBuy,
         // handleSetSell,
         rateError,
         handleRateChange,
         handleRateOnFocus,
         releaseSocket,
         handleRemovePair,
         setShowCoinOptions,
         showCoinOptions,
         postExchange,
         inputRef,
         handleFocus,
         user
     }) => {
        return (
            <div
                style={{width: "97%", gap: "3px"}}
                className="flex flex-wrap justify-center"
            >
                {items?.map((item, index) => (
                    <SortableItem
                        rowIndex={index}
                        rechart={rechart}
                        index={index}
                        item={item}
                        buySellIndex={buySellIndex}
                        clickedBuy={clickedBuy}
                        clickedSell={clickedSell}
                        handleSell={handleSell}
                        handleBuy={handleBuy}
                        handleInputChange={handleInputChange}
                        setSwitchCurrencies={setSwitchCurrencies}
                        switchCurrencies={switchCurrencies}
                        amount={amount}
                        buy={buy}
                        total={total}
                        sell={sell}
                        handleSwitchCurrenciesClick={handleSwitchCurrenciesClick}
                        setSocketStop={setSocketStop}
                        // handleSetBuy={handleSetBuy}
                        // handleSetSell={handleSetSell}
                        rateError={rateError}
                        releaseSocket={releaseSocket}
                        handleRemovePair={handleRemovePair}
                        setShowCoinOptions={setShowCoinOptions}
                        showCoinOptions={showCoinOptions}
                        postExchange={postExchange}
                        handleRateChange={handleRateChange}
                        handleRateOnFocus={handleRateOnFocus}
                        inputRef={inputRef}
                        handleFocus={handleFocus}
                        user={user}
                    />
                ))}
            </div>
        );
    }
);

const ExchangeChart = ({fiat}) => {
    const user = useSelector((state) => state.user.currentUser);
    const accounting = useSelector((state) => state.user.accounting);
    const currencies = useSelector((state) => state.user.currencies);
    const [exchangeRates, setExchangeRates] = useState([]);
    const [buy, setBuy] = useState([]);
    const [sell, setSell] = useState([]);
    const [clickedBuy, setClickedBuy] = useState(true);
    const [clickedSell, setClickedSell] = useState(false);
    const [buySellIndex, setBuySellIndex] = useState();
    const [amount, setAmount] = useState(0);
    const [total, setTotal] = useState(0);
    const [switchCurrencies, setSwitchCurrencies] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [socketStop, setSocketStop] = useState(false);
    const [stateIndex, setStateIndex] = useState(-1);
    const [changedRate, setChangedRate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showCoinOptions, setShowCoinOptions] = useState(false);
    const [error, setError] = useState(false)

    const [exchangeOptions, setExchangeOptions] = useState([]);

    const optionsRef = useRef();

    const [modifiedData, setModifiedData] = useState({
        currency_from: "",
        currency_to: "",
        rate: 0,
        buying_rate: 0,
        selling_rate: 0,
    });

    const inputRef = useRef(null);

    const [order, setOrder] = useState({});
    useEffect(() => {
        let used = exchangeRates.find((er) => er.used);
        if (used) {
            setOrder(used);
        }
    }, [exchangeRates]);

    useEffect(() => {
        calculateTotal();
    }, [amount, exchangeRates, buy, sell]);

    useEffect(() => {
        if (fiat) {
            let newPairsRate = exchangeRates.map((pair) => {

                let symbol1 = fiat.find(
                    (ff) => ff.symbol === pair.currency_from.symbol
                );
                let symbol2 = fiat.find((ff) => ff.symbol === pair.currency_to.symbol);


                if (!symbol1.auto_rate) {
                    let manualRate = currencies.find(pc => pc.symbol === symbol1.symbol);
                    if (manualRate) {
                        symbol1.rate = manualRate.rate;
                    }
                }

                if (!symbol2.auto_rate) {
                    let manualRate = currencies.find(pc => pc.symbol === symbol2.symbol);
                    if (manualRate) {
                        symbol2.rate = manualRate.rate;
                    }
                }

                let buyingPip = pair.buying_rate_fee ? pair.buying_rate_fee / 100 : 0;
                let sellingPip = pair.selling_rate_fee ? pair.selling_rate_fee / 100 : 0;
                let sellingRateWithFee;
                let buyingRateWithFee;

                if (pair?.used) {
                    if (!switchCurrencies) {
                        let buyingRateWithoutFee =
                            Number(symbol2?.rate) / Number(symbol1?.rate);
                        buyingRateWithFee =
                            buyingRateWithoutFee - buyingRateWithoutFee * buyingPip;

                        let sellingRateWithoutFee =
                            Number(symbol2?.rate) / Number(symbol1?.rate);
                        sellingRateWithFee =
                            sellingRateWithoutFee + sellingRateWithoutFee * sellingPip;
                    } else {
                        let buyingRateWithoutFee =
                            Number(symbol1?.rate) / Number(symbol2?.rate);
                        buyingRateWithFee =
                            buyingRateWithoutFee + buyingRateWithoutFee * buyingPip;

                        let sellingRateWithoutFee =
                            Number(symbol1?.rate) / Number(symbol2?.rate);
                        sellingRateWithFee =
                            sellingRateWithoutFee - sellingRateWithoutFee * sellingPip;
                    }
                } else {
                    let buyingRateWithoutFee =
                        Number(symbol2?.rate) / Number(symbol1?.rate);
                    buyingRateWithFee =
                        buyingRateWithoutFee - buyingRateWithoutFee * buyingPip;

                    let sellingRateWithoutFee =
                        Number(symbol2?.rate) / Number(symbol1?.rate);
                    sellingRateWithFee =
                        sellingRateWithoutFee + sellingRateWithoutFee * sellingPip;
                }

                pair = {
                    ...pair,
                    ...{
                        buying_rate: Number(buyingRateWithFee).toFixed(4),
                        selling_rate: Number(sellingRateWithFee).toFixed(4),
                    }
                }

                if (!pair?.manualRate) {
                    pair = {
                        ...pair,
                        ...{
                            buying_rate_input: Number(buyingRateWithFee).toFixed(4),
                            selling_rate_input: Number(sellingRateWithFee).toFixed(4),
                        },
                    };
                }
                return pair;
            });

            setExchangeRates(newPairsRate);
        }
    }, [fiat]);

    useEffect(() => {
        setExchangeOptions(user?.partnerId?.currencies);
    }, []);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        getExchangeRates();
    }, []);

    const getExchangeRates = async () => {
        const order = user.options?.exchange?.pairs ?? [];
        try {
            setLoading(true);
            let id = user?.partnerId?._id || "";
            const response = await publicRequest.get(
                `exchanges/exchange/pairs/${id}`
            );
            setExchangeRates(sortingPairs(order, response.data));
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        let buyRates = exchangeRates.map((x) => {
            return x.buying_rate;
        });
        setBuy(buyRates);

        let sellRates = exchangeRates.map((x) => {
            return x.selling_rate;
        });
        setSell(sellRates);
    }, [exchangeRates]);

    const addNewExchange = async () => {
        if (exchangeRates.findIndex((obj) => obj.currency_from._id == modifiedData.currency_from && obj.currency_to._id == modifiedData.currency_to) !== -1) {
            setError(true)
        } else {
            setError(false)
            const response = await publicRequest.post(
                "exchanges/exchange/pairs",
                modifiedData
            );
            getExchangeRates();
            setIsOpen(false);
        }
    };

    const dispatch = useDispatch();
    const onSortEnd = async ({oldIndex, newIndex}) => {
        let arr = arrayMoveImmutable(exchangeRates, oldIndex, newIndex);
        for (let i = 0; i < arr.length; i++) {
            arr[i].ordering = i;
            setExchangeRates(arr);
        }

        let ids = arr.map((pair) => {
            return pair._id;
        });

        dispatch(updatePairSorting(ids));
    };

    const onSortStart = (event) => {
        event.preventDefault();
        setBuySellIndex(-1);
    };

    const rechartData = [
        {name: "Page B", uv: 200, pv: 200, amt: 2000},
        {name: "Page C", uv: 400, pv: 500, amt: 1800},
        {name: "Page D", uv: 300, pv: 300, amt: 1500},
        {name: "Page E", uv: 400, pv: 100, amt: 1200},
        {name: "Page 2", uv: 340, pv: 500, amt: 1800},
        {name: "Page 3", uv: 360, pv: 300, amt: 1500},
        {name: "Page 4", uv: 350, pv: 100, amt: 1200},
        {name: "Page 5", uv: 410, pv: 500, amt: 1800},
        {name: "Page 6", uv: 400, pv: 300, amt: 1500},
        {name: "Page 7", uv: 380, pv: 100, amt: 1200},
        {name: "Page 8", uv: 400, pv: 500, amt: 1800},
    ];

    const handleSwitchCurrenciesClick = () => {
        setSwitchCurrencies(!switchCurrencies);
        setTotal(0);
        setAmount(0);
    };

    const rechart = (
        <ResponsiveContainer width="100%" height={100}>
            <LineChart data={rechartData}>
                <Line dataKey="uv" stroke="#C0392B" dot={false}/>
            </LineChart>
        </ResponsiveContainer>
    );

    const accountingSum = async (index, side) => {

        if (index === -1 || side === undefined) {
            return;
        }

        let pair = exchangeRates[index];
        let account;

        if (side === "buy") {
            account = accounting.find(
                (aa) => aa.currency.symbol === pair?.currency_to.symbol
            );
        } else {
            account = accounting.find(
                (aa) => aa.currency.symbol === pair?.currency_from.symbol
            );
        }

        exchangeRates[index] = {...exchangeRates[index], ...{account: account}};
        setExchangeRates([...exchangeRates]);
    };

    const handleBuy = async (index, buyRate) => {
        setBuySellIndex(index);
        setClickedSell(false);
        setClickedBuy(true);
        setAmount(0);
        setTotal(0);
        setSwitchCurrencies(false);
        let newEr = exchangeRates.map((er, i) => {
            er.used = i === index;
            er.manualRate = false;
            er.buying_rate_input = er.buying_rate;
            er.sell_rate_input = er.selling_rate;
            return er;
        });

        setExchangeRates(newEr);

        await accountingSum(index, "buy");
        releaseSocket();
    };

    const handleFocus = (event, value) => {
        setTimeout(() => {
            event.target.select();
        }, 100);
    };

    const handleSell = async (index, sellRate) => {
        setBuySellIndex(index);
        setClickedBuy(false);
        setClickedSell(true);
        setAmount(0);
        setTotal(0);
        setSwitchCurrencies(false);

        let newEr = exchangeRates.map((er, i) => {
            er.used = i === index;
            er.manualRate = false;
            er.buying_rate_input = er.buying_rate;
            er.sell_rate_input = er.selling_rate;
            return er;
        });

        setExchangeRates(newEr);

        await accountingSum(index, "sell");
        releaseSocket();
    };

    const handleCurrencyChange = (e, type) => {
        if (e === null && type === "from") {
            setModifiedData({...modifiedData, currency_from: null});
        } else if (e === null && type === "to") {
            setModifiedData({...modifiedData, currency_to: null});
        } else {
            if (type === "from") {
                setModifiedData({...modifiedData, currency_from: e._id});
            } else {
                setModifiedData({...modifiedData, currency_to: e._id});
            }
        }
    };

    const handleInputChange = (values, source, index) => {
        setAmount(values.floatValue);
        setStateIndex(index);
    };

    const calculateTotal = () => {
        let value = amount;
        const used = exchangeRates.find(er => er.used === true);
        if (used) {
            let rate = clickedBuy ? used.buying_rate_input : used.selling_rate_input;
            let total = Number(value) * Number(rate);

            setTotal(total);
        }
    };
    //
    // const handleSetBuy = (e, index) => {
    //     setChangedRate(true);
    //     setBuy({...buy, [index]: e.target.value});
    // };
    //
    // const handleSetSell = (e, index) => {
    //     setChangedRate(true);
    //     setSell({...sell, [index]: e.target.value});
    // };

    const [rateError, setRateError] = useState({});
    const checkRateValue = (props) => {
        const {clickedBuy, pairs, setExchangeRates} = props
        const index = pairs.findIndex(er => er.used);
        const pair = pairs[index];
        if (clickedBuy) {
            let value = pair.buying_rate_input;
            let maxFee = parseFloat(pair.buying_rate) + (parseFloat(pair.buying_rate) * (0.1 / 100));
            let gteMaxFee = (value > maxFee);
            if (gteMaxFee) {
                setRateError({message: `Rate cannot be under ${maxFee.toFixed(4)}`});
                setTimeout(() => {
                    setRateError({});
                }, 2000);
                pair.buying_rate_input = pair.buying_rate;
                pairs[index] = pair;
                setExchangeRates(pairs);
            }
        } else {
            let value = pair.selling_rate_input;
            let minFee = parseFloat(pair.selling_rate) - (parseFloat(pair.selling_rate) * (0.1 / 100));
            let lteMinFee = (value < minFee);
            if (lteMinFee) {
                setRateError({message: `Rate cannot be over ${minFee.toFixed(4)}`});
                setTimeout(() => {
                    setRateError({});
                }, 2000);
                pair.selling_rate_input = pair.selling_rate;
                pairs[index] = pair;
                setExchangeRates(pairs);
            }
        }
    }

    const checkRate = useRef(debounce((props) => {
        checkRateValue(props);
    }, 1000)).current;

    const handleRateChange = (e) => {
        const value = e.target.value
        let pairs = Array.from(exchangeRates);
        const index = pairs.findIndex(er => er.used);
        const pair = pairs[index];
        if (clickedBuy) {
            pair.buying_rate_input = value;
        } else {
            pair.selling_rate_input = value;
        }
        pair.manualRate = true;
        pairs[index] = pair;
        checkRate.cancel();
        checkRate({clickedBuy, pairs, setExchangeRates});
        setExchangeRates(pairs);
    };

    const handleRateOnFocus = (e, item) => {
        let index = exchangeRates.findIndex((er) => er._id === item._id);
        exchangeRates[index] = {...exchangeRates[index], ...{manualRate: true}};
        setExchangeRates(exchangeRates);

        setTimeout(() => {
            e.target.select();
        }, 100);
    };
    const releaseSocket = () => {
        setChangedRate(false);
        setSocketStop(false);
    };

    const handleRemovePair = async (item) => {
        const response = await publicRequest.delete(
            `exchanges/exchange/pairs/${item._id}`
        );
        if (response.status === 200) {
            setExchangeRates(exchangeRates.filter((x) => x._id !== item._id));
        }
    };

    const postExchange = async () => {
        const side = clickedBuy ? "BUY" : "SELL";

        let from_amount = !switchCurrencies ? amount : total;

        if (parseFloat(from_amount) < 1) {
            return;
        }

        let postExchangeObj = {
            currency_from: order.currency_from,
            currency_to: order.currency_to,
            amount_from: !switchCurrencies ? amount : total,
            amount_to: !switchCurrencies ? total : amount,
            side: side,
            symbol: `${order.currency_from.symbol}/${order.currency_to.symbol}`,
            conversion_fee: clickedBuy ? order.buying_rate_input : order.selling_rate_input,
            is_manual_fee: order.manualRate,
            status: 4,
        };

        const response = await publicRequest.post("exchanges/", postExchangeObj);
        if (response.status === 200) {
            await exchangePrint(response.data);
            setAmount(0)
            setTotal(0)

            let result = await publicRequest.get(`v2/accounts/refresh`);
            if (result.status === 200) {
                dispatch(reFetch(result.data))
                await accountingSum(stateIndex, clickedBuy ? "buy" : "sell");
                setAmount(0)
                setTotal(0)
                clickedBuy ? handleBuy(-1) : handleSell(-1);
            }
        }
    };

    useEffect(() => {
        setTimeout(async () => {
            await accountingSum(stateIndex, clickedBuy ? "buy" : "sell");
        }, 100)
    }, [accounting]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (optionsRef.current && !optionsRef.current.contains(event.target)) {
                setShowCoinOptions(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [optionsRef]);

    return (
        <>
            <div
                style={{width: "100%", marginTop: "20px"}}
                className="flex items-center justify-end"
            >
                <Button
                    onClick={openModal}
                    title="Add Pair"
                    icon={<FaPlus size={18} color="white"/>}
                    cls="max-w-fit"
                />
                <MainModal
                    closable={true}
                    setIsOpen={setIsOpen}
                    isOpen={isOpen}
                    onRequestClose={closeModal}
                    closeModal={closeModal}
                    customSubmit={addNewExchange}
                    footer={true}
                    content={
                        <>
                            <div className="flex gap-10 flex-wrap justify-between" style={{position: "relative"}}>
                                <h3
                                    className="usersTableRegisterClientFormTitle"
                                    style={{marginBottom: "30px"}}
                                >
                                    Add Exchange
                                </h3>
                                <div className="usersTableRegisterFormItem">
                                    <label className="usersTableRegisterFormLabel">
                                        Currency From
                                    </label>
                                    <MainSelect
                                        isSearchable={true}
                                        getOptionLabel={(e) => e.symbol}
                                        getOptionValue={(e) => e._id}
                                        isClearable={true}
                                        options={exchangeOptions}
                                        onChange={(e) => handleCurrencyChange(e, "from")}
                                    />
                                </div>
                                <div className="usersTableRegisterFormItem">
                                    <label className="usersTableRegisterFormLabel">
                                        Currency To
                                    </label>
                                    <MainSelect
                                        isSearchable={true}
                                        getOptionLabel={(e) => e.symbol}
                                        getOptionValue={(e) => e._id}
                                        isClearable={true}
                                        options={exchangeOptions}
                                        onChange={(e) => handleCurrencyChange(e, "to")}
                                    />
                                </div>
                                {error && (
                                    <div style={{
                                        position: "absolute",
                                        top: "120%",
                                        left: "50%",
                                        color: "red",
                                        fontSize: "14px",
                                        transform: "translateX(-50%)"
                                    }}>
                                        The pair already exists.
                                    </div>
                                )}
                            </div>
                        </>
                    }
                />
            </div>
            <div className="exchangeChart" ref={optionsRef}>
                <div className="exchangeChartWrapper">
                    {loading ? (
                        <div className="grid place-center" style={{height: "75vh"}}>
                            <ClipLoader size={30} color="orange"/>{" "}
                        </div>
                    ) : (
                        <SortableList
                            items={exchangeRates}
                            onSortEnd={onSortEnd}
                            onSortStart={(_, event) => onSortStart(event)}
                            axis={"xy"}
                            useDragHandle
                            buySellIndex={buySellIndex}
                            clickedBuy={clickedBuy}
                            clickedSell={clickedSell}
                            handleSell={handleSell}
                            handleBuy={handleBuy}
                            handleInputChange={handleInputChange}
                            setSwitchCurrencies={setSwitchCurrencies}
                            switchCurrencies={switchCurrencies}
                            rechart={rechart}
                            amount={amount}
                            buy={buy}
                            total={total}
                            sell={sell}
                            postExchange={postExchange}
                            handleSwitchCurrenciesClick={handleSwitchCurrenciesClick}
                            setSocketStop={setSocketStop}
                            // handleSetBuy={handleSetBuy}
                            // handleSetSell={handleSetSell}
                            rateError={rateError}
                            changedRate={changedRate}
                            setChangedRate={setChangedRate}
                            releaseSocket={releaseSocket}
                            handleRateChange={handleRateChange}
                            handleRateOnFocus={handleRateOnFocus}
                            handleRemovePair={handleRemovePair}
                            setShowCoinOptions={setShowCoinOptions}
                            showCoinOptions={showCoinOptions}
                            handleFocus={handleFocus}
                            inputRef={inputRef}
                            user={user}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default ExchangeChart;

