import React from "react";

function Button({
  onClick,
  title,
  icon,
  type,
  variant,
  cls,
  small,
  visible,
  disabled,
  titleHover,
}) {
  return type === "table" ? (
    variant === "info" ? (
      <button
        onClick={onClick}
        color="primary"
        variant="contained"
        style={{
          textTransform: "none",
          marginRight: "10px",
          color: "white",
          backgroundColor: "#0E86D4",
          border: "none",
          width: "22px",
          height: "22px",
          borderRadius: "50px",
          cursor: "pointer",
        }}
        size="big"
      >
        <i className="fa fa-info-circle" title="Info"></i>
      </button>
    ) : variant === "delete" ? (
      <button
        onClick={onClick}
        color="primary"
        variant="contained"
        style={{
          textTransform: "none",
          marginRight: "10px",
          color: "white",
          backgroundColor: "crimson",
          border: "none",
          width: "22px",
          height: "22px",
          borderRadius: "50px",
          cursor: "pointer",
        }}
        size="big"
      >
        <i className="fa fa-trash" title="Delete"></i>
      </button>
    ) : (
      <button
        onClick={onClick}
        color="primary"
        variant="contained"
        style={{
          textTransform: "none",
          marginRight: "10px",
          color: "white",
          backgroundColor: "#ff8303",
          border: "none",
          width: "22px",
          height: "22px",
          borderRadius: "50px",
          cursor: "pointer",
        }}
        size="big"
      >
        <i className="fa fa-pencil" title="Edit"></i>
      </button>
    )
  ) : (
    <button
      onClick={onClick}
      disabled={disabled}
      title={titleHover}
      className={`rounded-btn ${disabled ? "disabled-button" : ""} ${cls}`}
      style={{ width: small ? "" : "91%" }}
    >
      {icon}
      <p>{title}</p>
    </button>
  );
}

export default Button;

