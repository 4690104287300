import React from "react";
import "./clients.css";
import ClientsTable from "./ClientsTable/ClientsTable";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const Clients = () => {
  return (
    <div className="clients">
      <Tabs
        style={{
          maxWidth: "100%",
          width: "100%",
        }}
      >
        <TabList
          style={{
            padding: "7px 20px 0 20px",
            background: "white",
            margin: "0",
          }}
        >
          <Tab>Clients</Tab>
        </TabList>

        <TabPanel>
          <ClientsTable />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Clients;

