import React, { useEffect, useState } from "react";
import { HiOutlineChevronLeft } from "react-icons/hi";
import ReusableTitle from "../ReusableComponents/ReusableTitle";
import DashboardSliderFirstLeft from "./DashboardSliderFirstLeft/DashboardSliderFirstLeft";
import DashboardSliderFirstRight from "./DashboardSliderFirstRight/DashboardSliderFirstRight";
import DashboardSliderSecondLeft from "./DashboardSliderSecondLeft/DashboardSliderSecondLeft";
import DashboardSliderSecondRight from "./DashboardSliderSecondRight/DashboardSliderSecondRight";
import "./slider.css";

const Slider = ({
  pendingQuery,
  setNetworkLoading,
  choosenNetwork,
  cryptoCurrencies,
  coinInfo,
  choosenCrypto,
  choosenOrderType,
  networks,
  networkLoading,
  afterClick,
  setAfterClick,
  singleCryptoCurrencies,
  choosenCryptoBNBPrice,
  fiat,
  socket,
  choosenCoinPrice,
  setChoosenCoinPrice,
  setChoosenNetwork,
  permissions,
}) => {
  const [slide, setSlide] = useState(0);
  const [networkFee, setNetworkFee] = useState(0);
  const [networkFeeInQty, setNetworkFeeInQty] = useState(0);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [approveSliderInfo, setApproveSliderInfo] = useState([]);
  const [approveTransaction, setApproveTransaction] = useState({ _id: null });
  const [approveSliderInfoType, setApproveSliderInfoType] = useState(false);

  const [choosenNetworkTotalFee, setChoosenNetworkFee] = useState(0);
  const [choosenNetworkAddress, setChoosenNetworkAddress] = useState([]);
  const [selectedNetworkChain, setSelectedNetworkChain] = useState({})
  const [manualAddress, setManualAddress] = useState(false);
  const [addressError, setAddressError] = useState(true);

  useEffect(() => {
    if (choosenNetwork === "") {
      setSlide(0);
    } else {
      setSlide(1);

      if (choosenCrypto === "USDT") {
        setChoosenCoinPrice(Number(1).toFixed(3));
      }
    }
  }, [choosenNetwork, choosenCrypto]);

  const backSlide = () => {
    setChoosenNetwork("");
    setApproveSliderInfo([]);
    setApproveSliderInfoType(false);
    setSlide(0);
  };

  return (
    <div className="slider" style={{ alignItems: loading || success ? "center" : "flex-start" }}>
      {slide === 0 && (
        <>
          <div className="sliderLeft">
            <DashboardSliderFirstLeft
              setApproveSliderInfoType={setApproveSliderInfoType}
              setApproveSliderInfo={setApproveSliderInfo}
              approveSliderInfoType={approveSliderInfoType}
              approveTransaction={approveTransaction}
              setApproveTransaction={setApproveTransaction}
              pendingQuery={pendingQuery}
              socket={socket}
              setAfterClick={setAfterClick}
              setSlide={setSlide}
              permissions={permissions}
            />
          </div>
          <div className="sliderMiddleLine"></div>
          <div className="sliderRight">
            <DashboardSliderFirstRight
              approveSliderInfo={approveSliderInfo}
              approveSliderInfoType={approveSliderInfoType}
              approveTransaction={approveTransaction}
              setApproveTransaction={setApproveTransaction}
              fiat={fiat}
              setAfterClick={setAfterClick}
              setSlide={setSlide}
              setApproveSliderInfoType={setApproveSliderInfoType}
              setApproveSliderInfo={setApproveSliderInfo}
              pendingQuery={pendingQuery}
              permissions={permissions}
              loading={loading}
              setLoading={setLoading}
              success={success}
              setSuccess={setSuccess}
            />
          </div>
        </>
      )}
      {slide === 1 && (
        <>
          <div className="sliderLeft">
            <button
              onClick={backSlide}
              className="normal-btn flex items-center cursor-pointer"
              style={{
                position: "absolute",
                top: "10px",
                left: "10px",
                fontSize: "15px",
                fontWeight: "600",
                zIndex: "9999",
                width: "fit-content",
              }}
            >
              <HiOutlineChevronLeft color="#777777" size={20} />
              Back
            </button>
            <div style={{ marginBottom: afterClick !== -1 ? "0px" : "0" }}>
              <ReusableTitle
                title={`We ${choosenOrderType}`}
                color={choosenOrderType === "SELL" ? "red" : "green"}
              />
            </div>
            <DashboardSliderSecondLeft
              choosenCryptoBNBPrice={choosenCryptoBNBPrice}
              choosenCrypto={choosenCrypto}
              choosenOrderType={choosenOrderType}
              networks={networks}
              coinInfo={coinInfo}
              networkLoading={networkLoading}
              afterClick={afterClick}
              setAfterClick={setAfterClick}
              singleCryptoCurrencies={singleCryptoCurrencies}
              setNetworkFee={setNetworkFee}
              setNetworkFeeInQty={setNetworkFeeInQty}
              choosenNetwork={choosenNetwork}
              setNetworkLoading={setNetworkLoading}
              setChoosenCoinPrice={setChoosenCoinPrice}
              choosenCoinPrice={choosenCoinPrice}
              setChoosenNetworkFee={setChoosenNetworkFee}
              choosenNetworkAddress={choosenNetworkAddress}
              setChoosenNetworkAddress={setChoosenNetworkAddress}
              addressError={addressError}
              setAddressError={setAddressError}
              setSlide={setSlide}
              setManualAddress={setManualAddress}
              setChoosenNetwork={setChoosenNetwork}
              permissions={permissions}
              selectedNetworkChain={selectedNetworkChain}
              setSelectedNetworkChain={setSelectedNetworkChain}
            />
          </div>

          <div className="sliderRight">
            <DashboardSliderSecondRight
              choosenCoinPrice={choosenCoinPrice}
              choosenNetworkTotalFee={choosenNetworkTotalFee}
              choosenCrypto={choosenCrypto}
              choosenOrderType={choosenOrderType}
              singleCryptoCurrencies={singleCryptoCurrencies}
              cryptoCurrencies={cryptoCurrencies}
              afterClick={afterClick}
              setAfterClick={setAfterClick}
              coinInfo={coinInfo}
              networkFee={networkFee}
              networkFeeInQty={networkFeeInQty}
              choosenNetworkAddress={choosenNetworkAddress}
              choosenNetwork={choosenNetwork}
              fiat={fiat}
              addressError={addressError}
              setAddressError={setAddressError}
              setSlide={setSlide}
              manualAddress={manualAddress}
              setChoosenNetwork={setChoosenNetwork}
              permissions={permissions}
              loading={loading}
              setLoading={setLoading}
              success={success}
              setSuccess={setSuccess}
              selectedNetworkChain={selectedNetworkChain}
              setSelectedNetworkChain={setSelectedNetworkChain}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Slider;

